import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/helpers/auth.guard';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { GiftCatalogComponent } from './modules/gift-catalog/gift-catalog.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/login/login.component';
import { OrdersComponent } from './modules/orders/orders.component';
import { PlansComponent } from './modules/rewards/plans/plans.component';
import { RewardsComponent } from './modules/rewards/rewards.component';
import { RulesComponent } from './modules/rewards/rules/rules.component';
import { TasksComponent } from './modules/rewards/tasks/tasks.component';
import { StoreCatalogComponent } from './modules/store-catalog/store-catalog.component';
import { UserComponent } from './modules/user/user.component';
import { UsersComponent } from './modules/users/users.component';
import { QrCodeComponent } from './modules/qr-code/qr-code.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { CompleteRegistrationComponent } from './modules/complete-registration/complete-registration.component';
import { ROLE_TYPE } from './core/constants/role-type';
import { RegisterComponent } from './modules/register/register.component';
import { MerchantDetailsComponent } from './modules/register/merchant-details/merchant-details.component';
import { RegisterCompanyComponent } from './modules/register/register-company/register-company.component';
import { CompanyContactComponent } from './modules/register/company-contact/company-contact.component';
import { RegisterCompleteComponent } from './modules/register/register-complete/register-complete.component';
import { RegisterPlanComponent } from './modules/register/register-plan/register-plan.component';

const routes: Routes = [
  // { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: '', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'qr-code', component: QrCodeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'login/forgot-password', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register/register-company', component: RegisterCompanyComponent },
  { path: 'register/company-contact', component: CompanyContactComponent },
  { path: 'register/register-plan', component: RegisterPlanComponent },
  { path: 'register/merchant-details', component: MerchantDetailsComponent },
  { path: 'register/register-complete', component: RegisterCompleteComponent },
  { path: 'complete-registration', component: CompleteRegistrationComponent },
  { path: 'complete-registration/:inviteCode', component: CompleteRegistrationComponent },
  //{ path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'user/:key', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'rewards', component: RewardsComponent, canActivate: [AuthGuard] },
  { path: 'rewards/tasks', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'rewards/plans', component: PlansComponent, canActivate: [AuthGuard] },
  { path: 'rewards/rules', component: RulesComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'gift-catalog', component: GiftCatalogComponent, canActivate: [AuthGuard] },
  { path: 'store-catalog', component: StoreCatalogComponent, canActivate: [AuthGuard] },
  { path: 'admin-users', component: AdminUsersComponent, canActivate: [AuthGuard], data: { roles: [ROLE_TYPE.Administrator, ROLE_TYPE.PowerUser] } },
  { path: 'dashboard', redirectTo: '', pathMatch: 'full' },

  // Othwerwise redirect to
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
