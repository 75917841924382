<div class="container-fluid register register-complete">

    <app-registration-header stepNumber="-1"></app-registration-header>

    <app-loading loadingSize="lg" alignText="center" *ngIf="working"></app-loading>

    <div class="row" *ngIf="!working">
        <div class="col form-col">

            <div class="error alert alert-danger" *ngIf="errorMessage">
                <span class="far fa-exclamation-circle fa-lg icon"></span>
                {{errorMessage}}
            </div>

            <h1>Thank you, and welcome to Pummelo Rewards!</h1>

            <p>
                That's everything we need to get <span class="{{hasTenantName ? 'tenant-name' : ''}}">{{tenantName}}</span> set up.  We'll start working on our end
                to get everything ready to go.
            </p>
            <p>
                <b>We'll be in touch soon!</b> The process usually takes about 5-10 minutes.  You are
                welcome to close this window and we'll send you an email when everything is ready.
            </p>

            <!-- <div class="loading-progress">
                <div class="progress-text">
                    <span *ngIf="provisioningProgress < 100">
                        Company setup progress (<span class="percent"><span class="percent-number">{{ provisioningProgress | number : '1.0-0'}}</span>%</span>)...
                    </span>
                    <span *ngIf="provisioningProgress === 100">Setup complete!</span>
                </div>
                <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" [ngStyle]="{'width': provisioningProgress + '%'}"></div>
               </div>
            </div> -->

            <div class="pml-actions">
                <button type="button" class="btn btn-outline-primary cancel" (click)="signOut()">
                    sign out
                </button>
            </div>
            
        </div>
    </div>

</div>
