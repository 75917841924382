import { Rule } from './rule';

export interface RuleSaveInfo {
    rule: Rule;
    saveType: RuleSaveType
}

export enum RuleSaveType {
    newRule = 'NewRule',
    updatedRule = 'UpdatedRule'
}
