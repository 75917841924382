import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KeyNamePair } from 'src/app/core/models/key-name-pair';
import { DialogDataOrder } from 'src/app/core/models/order/dialog-data-order';
import { Order } from 'src/app/core/models/order/order';
import { OrderItem } from 'src/app/core/models/order/order-item';
import { OrderTransaction } from 'src/app/core/models/order/order-transaction';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OrderService } from 'src/app/core/services/order.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { DialogAreYouSureComponent } from '../dialog-are-you-sure/dialog-are-you-sure.component';
import { OrderStatusUpdate } from 'src/app/core/models/order/order-status-update';
import { OrderSaveInfo } from 'src/app/core/models/order/order-save-info';
import { map, Observable, takeWhile, timer } from 'rxjs';

@Component({
  selector: 'app-dialog-order',
  templateUrl: './dialog-order.component.html',
  styleUrls: ['./dialog-order.component.scss']
})
export class DialogOrderComponent implements OnInit {

  working: boolean;
  workingItem: number;
  workingAllItems: boolean;
  cancelling: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  order: Order = {};
  orderStatus: string;      // TODO: enum?
  hasPaymentInfo: boolean;
  paymentTransaction: OrderTransaction;
  hasRefundInfo: boolean;
  officeFulfillAdvanced: boolean;
  refundTransaction: OrderTransaction;
  fulfillmentGroups: OrderItem[][];
  fulfillmentStatuses: KeyNamePair[];

  @Output() saveOrderEvent = new EventEmitter<Order>();

  showOrderUpdatedMessage: boolean = false;
  updatedStatus: string = 'saved';
  timeRemaining$: Observable<number>;

  constructor(
    private orderService: OrderService,
    private parseErrorService: ParseErrorService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DialogOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataOrder,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.workingAllItems = false;
    this.workingItem = -1;
    this.cancelling = false;
    this.errorMessage = '';
    this.officeFulfillAdvanced = false;

    this.order = this.data?.selectedOrder;
    this.orderStatus = this.order?.info?.status.toLocaleLowerCase() ?? 'unknown';
    this.fulfillmentStatuses = [{ key: 'Paid', name: 'Paid' }, { key: 'Pickup', name: 'Pickup' }, { key: 'Complete', name: 'Complete/delivered' }];

    this.popuplateOrderTransactionDetails();

    this.groupAndPopulateFufillmentItems();
  }

  popuplateOrderTransactionDetails(): void {
    this.hasPaymentInfo = this.hasTransactionInfoAvailable('Charge');
    this.hasRefundInfo = this.hasTransactionInfoAvailable('Refund');

    if (this.hasPaymentInfo) {
      this.paymentTransaction = this.order.breakdown[0].transactions.find(t => t.type === 'Charge');
    }
    if (this.hasRefundInfo) {
      this.refundTransaction = this.order.breakdown[0].transactions.find(t => t.type === 'Refund');
    }
  }

  groupAndPopulateFufillmentItems(): void {
    // get distinct fulfillment providers
    const fulfillmentProviders = this.order.breakdown.map(breakdown => breakdown.items.map(item => item.details[0].fulfilledBy ?? 'office')).flat();
    const distinctFulfillmentProviders = [...new Set(fulfillmentProviders)];
    distinctFulfillmentProviders.forEach(provider => { console.log(provider); });

    // group items by fulfillment provider (array of arrays)
    this.fulfillmentGroups = [];
    distinctFulfillmentProviders.forEach(provider => {
      if (provider === 'office') {
        provider = null;
      }
      const group = this.order.breakdown[0].items.filter(item => item.details[0].fulfilledBy === provider);
      this.fulfillmentGroups.push(group);
    });
    console.log(`number of fulfillment groups: ${this.fulfillmentGroups.length}`);

  }

  formatOrderId(id: string): string {
    if (id && id.length > 5) {
      const idParts = id.match(/.{1,5}/g);
      if (idParts.length === 2) {
        return idParts.join('-');
      }
      return id;
    }
    return id;
  }

  formatOrderTimestampToLocaleDateTime(utcOrderDate: Date, dateFormat: string = 'dd-MMM-yyyy'): string {
    //const utcDateString = this.datePipe.transform(utcOrderDate, 'MM/dd/yyyy HH:mm:ss UTC');
    //const localOrderDate = new Date(utcDateString);
    var localOrderDate = new Date(Date.parse(utcOrderDate.toString()));

    return this.datePipe.transform(localOrderDate, dateFormat);
  }

  getOrderSubtotal(): number {
    let subtotal = 0;
    if (this.order && this.order.breakdown && this.order.breakdown[0]?.items) {
      this.order.breakdown.forEach(breakdown => {
        breakdown.items.forEach(item => {
          subtotal += item.details[0].amount * item.quantity;
        });
      });
    }
    return subtotal;
  }

  hasTransactionInfoAvailable(transactionType: string): boolean {
    if (this.order && this.order.breakdown && this.order.breakdown[0]?.transactions && this.order.breakdown[0]?.transactions.find(t => t.type === transactionType)) {
      return true;
    }
    return false;
  }

  allItemsSameStatus(items: OrderItem[]): boolean {
    if (items && items.length > 0) {
      const status = items[0].status;
      return items.every(item => item.status === status);
    }

    return false;
  }

  getNextStepStatusText(item: OrderItem, actualStatusName: boolean = false): string {
    // NOTE: !item.details[0].fulfilledBy means fulfilled by office
    if (item && item.details && !item.details[0].fulfilledBy) {
      if (item.status.toLocaleLowerCase() === 'paid') {
        //return actualStatusName ? 'pickup' : 'Ready for Pickup';
        return 'pickup';
      }

      if (item.status.toLocaleLowerCase() === 'pickup') {
        return 'Complete';
      }

    }
    return 'Paid';
  }

  countDownTimer(seconds: number): void {
    //https://stackblitz.com/edit/rxjs-angular-countdown?file=src%2Fapp%2Fcount-down%2Fcount-down.component.html
    this.timeRemaining$ = timer(0, 1000).pipe(
      map(n => (seconds - n) * 1000),
      takeWhile(n => n >= 0),
    );
  }

  testCountdown(): void {
    this.showOrderUpdatedMessage = true;
    this.countDownTimer(5);
  }

  updateOrderItemStatus(item: OrderItem, status: string, itemIndex: number, currencyCode: string = 'USD'): void {
    this.workingItem = itemIndex;

    // setTimeout(() => {
    //   item.status = status;

    //   this.workingItem = -1;
    // }, 1000);

    //http://order-dev.workscale.io/api/swagger/ui#/

    const orderStatusUpdate: OrderStatusUpdate = {
      amounts: [{
        currencyCode: currencyCode,
        items: [{
          key: item.key,
          status: status.toLocaleUpperCase()
        }]
      }]
    };

    this.orderService.updateOrderByKey(this.order.key, orderStatusUpdate).subscribe({
      next: (response: Order | any) => {
        //const orderIndex: number = this.orders.map(x => x.key).indexOf(orderToUpdate.key);
        this.order = response;

        // Update the Fulfillment groups which is what the UI is bound to
        this.groupAndPopulateFufillmentItems();

        // Update the orderStatus, which shows/hides the 'cancel order' button
        this.orderStatus = this.order?.info?.status.toLocaleLowerCase() ?? 'unknown';

        // Now update/emit the order back to the orders page to update the order list with the new order info
        this.saveOrderEvent.emit(this.order);

        this.workingItem = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingItem = -1;
      }
    });

  }

  updateAllOrderItemStatus(items: OrderItem[], status: string, currencyCode: string = 'USD'): void {
    this.workingAllItems = true;

    // setTimeout(() => {
    //   items.forEach((item, index) => {
    //     //this.updateOrderItemStatus(item, status, index);
    //     item.status = status;
    //   });

    //   this.workingAllItems = false;
    // }, 1000);

    let orderStatusUpdateAmounts = [];
    items.forEach((item, index) => {
      orderStatusUpdateAmounts.push({
        currencyCode: currencyCode,
        items: [{
          key: item.key,
          status: status.toLocaleUpperCase()
        }]
      });
    });

    const orderStatusUpdate: OrderStatusUpdate = {
      amounts: orderStatusUpdateAmounts
    };

    this.orderService.updateOrderByKey(this.order.key, orderStatusUpdate).subscribe({
      next: (response: Order | any) => {
        //const orderIndex: number = this.orders.map(x => x.key).indexOf(orderToUpdate.key);
        this.order = response;

        // Update the Fulfillment groups which is what the UI is bound to
        this.groupAndPopulateFufillmentItems();

        // Update the orderStatus, which shows/hides the 'cancel order' button
        this.orderStatus = this.order?.info?.status.toLocaleLowerCase() ?? 'unknown';

        // Now update/emit the order back to the orders page to update the order list with the new order info
        this.saveOrderEvent.emit(this.order);

        this.workingAllItems = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingAllItems = false;
      }
    });
  }


  openConfirmProductDeleteDialog(e: Event): void {
    this.cancelling = true;

    const deleteElement = e.currentTarget as HTMLSpanElement;
    const deleteDiv: HTMLDivElement = deleteElement.parentElement.parentElement.querySelector('.item-delete-overlay');

    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {
      autoFocus: false,
      position: { top: '10vh' },
      maxWidth: '430px',
      width: '430px',
      panelClass: 'are-you-sure',
      data: {
        deleteObjectName: this.formatOrderId(this.order.id),
        deleteObjectTypeName: 'order',
        message: 'This order will be cancelled.  Are you sure you want to proceed? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data && result.data.confirmDelete) {  // result === '' means cancel button was clicked

        const orderStatusUpdate: OrderStatusUpdate = {
          status: 'CANCELLED'
        };

        // API PATCH (update template task)
        this.orderService.updateOrderByKey(this.order.key, orderStatusUpdate).subscribe({
          next: (response: Order | any) => {
            //const orderIndex: number = this.orders.map(x => x.key).indexOf(orderToUpdate.key);
            this.order = response;

            // Just a hack for now to show modified date since the object returned from the API does not have the lastModifiedUtc updated property
            if (this.order.info && this.order.info.lastModifiedUtc) {
              const now = new Date();
              const nowUtc = now.toISOString();
              this.order.info.lastModifiedUtc = new Date(Date.parse(nowUtc));
            }

            // Update the orderStatus, which shows/hides the 'cancel order' button
            this.orderStatus = this.order?.info?.status.toLocaleLowerCase() ?? 'unknown';

            this.cancelling = false;

            // At this point, probably close the dialog....not sure how I feel about this
            // const orderSaveInfo: OrderSaveInfo = {
            //   order: this.order,
            //   saveType: 'update'
            // };
            // this.dialogRef.close(orderSaveInfo);
            //this.newLinkedUserSaveEvent.emit(eventData);
            this.saveOrderEvent.emit(this.order);

            // Order has been cancelled, close the dialog after a few seconds
            //this.errorMessage = 'Order has been cancelled.  This dialog will close in 3 seconds.';
            this.showOrderUpdatedMessage = true;
            this.updatedStatus = 'cancelled';
            this.countDownTimer(5);
            setTimeout(() => {
              this.dialogRef.close();
            }, 5000);
          },
          error: (err: any) => {
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            this.cancelling = false;
          }
        });

      } else {
        this.cancelling = false;
      }
    });
  }

}
