import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant';
import { TenantService } from './tenant.service';

const TENANT = 'tenant';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected tenantKey: string;
  protected appKey: string;

  constructor(
    public http: HttpClient,
    protected tenantService: TenantService) {
    this.tenantService.currentTenant.subscribe(tenant => this.updateCurrentTenantKey(tenant));

    this.appKey = environment.appKey;
  }

  updateCurrentTenantKey(tenant: Tenant): void {
    if (!tenant) {
      this.tenantKey = '';
      return;
    }

    this.tenantKey = tenant.key;
  }

  executeApi<T>(httpMethod: string, endpoint: string, headers: HttpHeaders, params: any = null): Observable<T> {
    return this.http[httpMethod]<any>(endpoint, {headers: headers}, params)
      .pipe(
        map((resp: any) => {
          if (resp.data) {
            return resp.data;
          }
          return of(resp);
        })
      );
  }

  executeApiWithResponseStatus<T>(httpMethod: string, endpoint: string, params: any = null): Observable<T> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.authenticationSubscriptionKey);

    return this.http[httpMethod]<any>(endpoint, {headers: headers, observe: 'response'}, params);
  }

    /**
   *    Have to have a separate item for POST calls because they send the params in a different order
   *    than all other calls (see // https://www.angularfix.com/2022/04/angular-httpclient-patch-headers-in.html)
   *    for additional detail
   * @param endpoint
   * @param headers
   * @param params
   * @returns
   */
  executeApiPost<T>(endpoint: string, headers: HttpHeaders, params: any = null): Observable<T> {
    return this.http.post<any>(endpoint, params, { headers: headers })
      .pipe(
        map((resp: any) => {
          if (resp.data) {
            return resp.data;
          }
          return of(resp);
        })
      );
  }

  /**
   *    Have to have a separate item for PATCH calls because they send the params in a different order
   *    than all other calls (see // https://www.angularfix.com/2022/04/angular-httpclient-patch-headers-in.html)
   *    for additional detail
   * @param endpoint
   * @param headers
   * @param params
   * @returns
   */
  executeApiPatch<T>(endpoint: string, headers: HttpHeaders, params: any = null): Observable<T> {
    return this.http.patch<any>(endpoint, params, {headers: headers})
      .pipe(
        map((resp: any) => {
          if (resp.data) {
            return resp.data;
          }
          return of(resp);
        })
      );
  }

}
