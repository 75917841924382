import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HelperImage } from 'src/app/core/helpers/helper-image';
import { Badge } from 'src/app/core/models/badge';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { SaveInfo, SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-edit-plan-milestone',
  templateUrl: './edit-plan-milestone.component.html',
  styleUrls: ['./edit-plan-milestone.component.scss']
})
export class EditPlanMilestoneComponent implements OnInit {

  BADGE_PATH = 'assets/images/badges/';

  //@Input() public test: TemplateTest;
  @Input() public level: TemplateLevel
  @Input() public templateTasks: TemplateTask[];
  @Output() milestoneCancelEvent = new EventEmitter();
  @Output() milestoneSaveEvent = new EventEmitter<SaveInfo<TemplateLevel>>();

  editMilestoneForm: UntypedFormGroup;
  taskName: string;

  working: boolean;
  workingResources: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  showMilestoneBadges: boolean;
  selectedMilestoneBadge: Badge;
  imageModified: boolean;

  constructor(
    private rewardsService: RewardsService,
    private imageHelper: HelperImage,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.showMilestoneBadges = false;
    this.imageModified = false;

    this.editMilestoneForm = this.fb.group({
      levelTask: [this.level.templateTaskKey ?? '', Validators.required],
      levelInterval: ['Ever'],    // hidden field with default value EVER
      levelOccurrence: ['1'],      // hidden field with default value 1
      levelRequiresReview: [true], // hidden field with default value TRUE
      levelBadgeName: ['']
    });
    this.formChanges();

    if (this.level && this.level.imageUrl) {
      this.selectedMilestoneBadge = {
        fileName: this.level.imageUrl,
        name: this.level.name,
        color: this.level.additionalInfo && this.level.additionalInfo['badgeColor'] && this.level.additionalInfo['badgeColor'].length > 0 ? this.level.additionalInfo['badgeColor'] : null
      };
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.editMilestoneForm.controls;
  }

  formChanges(): void {
    // Method to reflect user and form changes
    this.f['levelTask'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.level.templateTaskKey) {
          if (val === this.level.templateTaskKey) {
            this.f['levelTask'].markAsPristine();
          }
        }
      }
    });

  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  getTaskName(e: Event): void {
    const taskElement: HTMLSelectElement = <HTMLSelectElement>e.currentTarget;
    this.taskName = taskElement[taskElement.options.selectedIndex].textContent;
  }

  updateItemWithBadge(badge: Badge, formItem: AbstractControl): void {
    this.imageModified = true;

    this.updateSelectedBadge(badge);
    formItem.setValue(badge.name);
    this.showMilestoneBadges = !this.showMilestoneBadges;

    // Mark the form as dirty so that the modify button is enabled
    this.editMilestoneForm.markAsDirty();
  }

  updateSelectedBadge(badge: Badge): void {
    this.selectedMilestoneBadge = badge;
    this.level.additionalInfo = { 'badgeColor': badge.color };
  }

  async updateMilestoneInPlan(): Promise<void> {
    // Make sure there are changes to save, if not, close the form
    if (!this.editMilestoneForm.dirty) {
      this.milestoneCancelEvent.emit();
      return;
    }

    // Update task item from form
    if (this.f['levelTask'].value !== this.level.templateTaskKey) {
      this.level.templateTaskKey = this.f['levelTask'].value;
      this.level.name = this.taskName;
    }

    // Check the image and update if it is new
    if (this.imageModified) {
      const milestoneImage = await this.imageHelper.createImageObject(this.selectedMilestoneBadge, this.BADGE_PATH);
      this.level.image = milestoneImage ?? null;
      this.level.imageUrl = this.BADGE_PATH + this.selectedMilestoneBadge.fileName;
    }

    // Save to parent form
    this.milestoneSaveEvent.emit({ result: this.level, saveType: SaveType.updated });
  }

  cancelMilestoneEdit(): void {
    this.milestoneCancelEvent.emit();
  }

}
