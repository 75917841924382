<div class="avatar user-avatar " [style.background-color]="avatarBgColor" (click)="updateAvatar = !updateAvatar">
    <img src="{{avatarImg}}" class="img" title="user" />
</div>

<span class="fas fa-times-circle fa-lg fa-fw icon close new-user-close" style="--dialog-width: {{dialogWidth}}" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">
    <div class="select-avatar">
        <app-choose-avatar [user]="user" [isNewUser]="true"
            (closeAvatarEvent)="closeAvatarWindow()"
            (previewAvatarEvent)="previewAvatar($event)" 
            (resetAvatarToOriginalEvent)="resetAvatarToOriginal()"
            (updateAvatarEvent)="updateUserWithAvatar($event)" 
            *ngIf="updateAvatar">
        </app-choose-avatar>
    </div>

    <div class="pml-dialog-header-footer">
        <div class="user-header pml-dialog-header">
            <h3>{{fullNameDisplay(user.info?.name, 'Add New User')}}</h3>
            <div class="user-type {{isMinor ? 'minor' : ''}}">
                <img src="assets/images/child.svg" class="child-icon" title="minor" *ngIf="isMinor" />
                {{isMinor ? 'Child' : 'Adult'}}
            </div>
        </div>

    </div>

    <ng-container *ngTemplateOutlet="newUserFormTemplate"></ng-container>
</div>

<ng-template #newUserFormTemplate>
    <div class="new-user-form pml-form">
        <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

        <form class="pml-form horiz-layout" [formGroup]="userForm" (ngSubmit)="userForm.valid" (ngSubmit)="saveUser()" autocomplete="off">
            <div class="pml-row w-fit-content">
                <label>name</label>
                <span class="required" *ngIf="isInvalid(f['first'])">{{getError(f['first'].errors, 'Either FIRST or LAST name')}}</span>
                <div class="input-data name">
                    <input type="text" class="form-control med-text first" formControlName="first" placeholder="first name" />
                    <input type="text" class="form-control middle" formControlName="middle" placeholder="middle" />
                    <input type="text" class="form-control med-text" formControlName="last" placeholder="last name" />
                </div>
                <div class="suffix-row {{toggleSuffix ? 'show' : ''}}">
                    <a [routerLink]="[]" class="add-sfx" (click)="toggleSuffix = !toggleSuffix">
                        <span class="far fa-{{toggleSuffix ? 'minus' : 'plus'}} fa-sm fa-fw icon"></span>
                        {{toggleSuffix ? 'hide' : 'add'}} suffix
                    </a>
                    <input type="text" class="form-control short-text suffix" formControlName="suffix" placeholder="suffix" *ngIf="toggleSuffix" />
                </div>
            </div>
            <div class="pml-row email w-fit-content" *ngIf="!isMinor">
                <label>email</label>
                <span class="required" *ngIf="isInvalid(f['emailAddress'])">{{getError(f['emailAddress'].errors, 'Email address')}}</span>
                <div class="input-data">
                    <input type="text" class="form-control" formControlName="emailAddress" placeholder="email" />
                </div>
            </div>
            <div class="pml-row email w-fit-content" *ngIf="isMinor">
                <label>username</label>
                <span class="required" *ngIf="isInvalid(f['username'])">{{getError(f['username'].errors, 'username')}}</span>
                <div class="input-data">
                    <input type="text" class="form-control" formControlName="username" placeholder="username" />
                </div>
            </div>
            <div class="pml-row w-fit-content">
                <label>Date of Birth</label>
                <span class="required" *ngIf="isInvalid(f['year'], 'dob')">{{getError(f['year'].errors, 'DOB Year')}}</span>
                <div class="input-data">
                    <select formControlName="month" class="form-select dob first" (change)="validateDOBInput()">
                        <option value="0" disabled selected hidden>Month</option>
                        <option *ngFor="let thisMonth of months; let m = index" [value]="m+1">{{thisMonth}}</option>
                    </select>
                    <select formControlName="day" class="form-select dob" (change)="validateDOBInput()">
                        <option value="0" disabled selected hidden>Day</option>
                        <option *ngFor="let thisDay of getDays(userForm.value)" [value]="thisDay" (change)="validateDOBInput()">
                            {{thisDay}}
                        </option>
                    </select>
                    <input type="number" class="form-control year last" formControlName="year" placeholder="year" min="1900" max="2050" (change)="validateDOBInput()" />
                </div>
            </div>
            <div class="pml-row toggle">
                <div class="group-item">
                    <label class="form-check-label toggle-1 {{isMinor ? 'active' : ''}}" for="isMinor">Minor</label>
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="isMinor" checked (change)="isMinorToggle($event)">
                        <label class="form-check-label toggle-2 {{!isMinor ? 'active' : ''}}" for="isMinor">Adult</label>
                    </div>
                </div>
            </div>
            <div class="pml-row submit-form pml-dialog-actions">
                <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="resetFormToDefault()">
                <button type="submit" class="btn btn-primary save"
                    [disabled]="saving || !(userForm.valid && userForm.dirty)">
                    <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!saving" class="">create user</span>
                    <span *ngIf="saving" class="">creating...</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>