<form [formGroup]="editChallengeForm" class="edit-form" (ngSubmit)="editChallengeForm.valid && updateChallengeInPlan()" autocomplete="off">
    <div class="pml-row add-item-row first" *ngIf="!workingResources">
        <div class="pml-col add-badge">
            <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                context: {selectedBadge: selectedTestBadge, showBadgeSelection: showTestBadges}"></ng-container>
        </div>
        <div class="pml-col">
            <ng-container *ngTemplateOutlet="taskDropdownTemplate; 
                context: {form: editChallengeForm, controlName: 'testTask', label: 'Task', formGet: f}"></ng-container>
        </div>
        <div class="pml-col">
            <label>Rule</label>
            <span class="required" *ngIf="isInvalid(f['testRule'])">{{getError(f['testRule'].errors)}}</span>
            <select class="form-select bonus-type-dd rule" formControlName="testRule" (change)="getRuleName($event)">
                <option [ngValue]="null" [disabled]="true" [selected]="true">Rule</option>
                <option *ngFor="let test of templateTests" [ngValue]="test.key">{{test.name}}</option>
            </select>
        </div>
        <div class="pml-col">
            <label>Duration Type</label>
            <span class="required" *ngIf="isInvalid(f['testTimeframe'])">{{getError(f['testTimeframe'].errors)}}</span>
            <select class="form-select bonus-type-dd timeframe" formControlName="testTimeframe">
                <option [ngValue]="null" [disabled]="true" [selected]="true">Interval</option>
                <option *ngFor="let tf of timeframeTypes" [ngValue]="timeframeValues[tf]">
                    {{timeframeToLabelMapping[tf]}}
                </option>
            </select>
        </div>
        <div class="pml-col number">
            <label>duration</label>
            <span class="required"
                *ngIf="isInvalid(f['testTimeframeQuantity'])">{{getError(f['testTimeframeQuantity'].errors)}}</span>
            <input type="number" class="form-control number" formControlName="testTimeframeQuantity"
                placeholder="duration" value="" />
        </div>
        <div class="pml-col actions">
            <button type="submit" class="btn btn-primary nested-submit" [disabled]="working || !(editChallengeForm.valid && editChallengeForm.dirty)">
                Modify
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="cancelChallengeEdit()">Cancel</button>
        </div>
    </div>
    <div class="pml-row add-item-row add-item-icon" *ngIf="!workingResources">
        <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
            context: {form: editChallengeForm, badgeControlName:'testBadgeName', formGet: f, section: 'tests', selectedBadge: selectedTestBadge, showBadgeSelection: showTestBadges}"></ng-container>
    </div>
</form>

<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet">
    <ng-container [formGroup]="form">
        <label>{{label}}</label>
        <span class="required" *ngIf="isInvalid(formGet[controlName])">{{getError(formGet[controlName].errors)}}</span>
        <select class="form-select bonus-type-dd task" formControlName="{{controlName}}" (change)="getTaskName($event)">
            <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
            <option *ngFor="let task of templateTasks" [ngValue]="task.key">{{task.name}}</option>
        </select>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionTemplate 
    let-form="form" 
    let-badgeControlName="badgeControlName" 
    let-formGet="formGet" 
    let-selectedBadge="selectedBadge"
    let-showBadgeSelection="showBadgeSelection">
    <ng-container [formGroup]="form">
        <div class="pml-col">
            <input type="hidden" [formControlName]="badgeControlName" value="" />

            <app-choose-badge [selectedBadge]="selectedBadge" 
                (closeBadgeEvent)="showTestBadges = !showTestBadges"
                (selectBadgeEvent)="updateItemWithBadge($event, formGet[badgeControlName])" 
                *ngIf="showBadgeSelection"></app-choose-badge>
        </div>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionBtnTemplate let-selectedBadge="selectedBadge" let-showBadgeSelection="showBadgeSelection">
    <span class="fa-stack fa-xs {{showBadgeSelection ? 'active' : ''}}" (click)="showTestBadges = !showTestBadges" 
          title="{{!selectedBadge ? 'Add Icon' : 'Change Icon'}}" 
          *ngIf="!selectedBadge">
        <i class="fa-light fa-image fa-stack-2x"></i>
        <i class="fa-regular fa-circle-plus fa-stack-1x"></i>
    </span>
    <div class="img" 
        style.backgroundColor="{{selectedBadge.color ? selectedBadge.color : 'rgba(0,0,0,0.08)'}}"
        *ngIf="selectedBadge">
        <img src="{{imageModified ? BADGE_PATH : ''}}{{selectedBadge?.fileName}}" 
            title="{{selectedBadge?.name}}" 
            class="selected-badge {{showBadgeSelection ? 'active' : ''}}" 
            (click)="showTestBadges = !showTestBadges" />
    </div>
</ng-template>
