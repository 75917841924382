// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  appKey: 'QTE3MkNFRENBRTQ3NDc0QjYxNUM1NEQ1MTBBNUQ4NEE4REVBMzAzMkU5NTg1ODc0MzBCNDEzNTM4QkUzRjMzM35QdW1tZWxv',

  authenticationUrl: 'https://wksdevapim.azure-api.net/authentication/api/v1',
  tenantUrl: 'https://wksdevapim.azure-api.net/tenant/api/v1',
  teamUrl: 'https://wksdevapim.azure-api.net/team/api/v1',
  subscriptionUrl: 'https://wksdevapim.azure-api.net/subscription/api/v1',
  paymentUrl: 'https://wksdevapim.azure-api.net/payment/api/v1',
  rewardUrl: 'https://wksdevapim.azure-api.net/reward/api/v1',
  offerUrl: 'https://wksdevapim.azure-api.net/offer/api/v1',
  orderUrl: 'https://wksdevapim.azure-api.net/order/api/v1',

  authenticationSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  tenantSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  teamSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  subscriptionSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  paymentSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  rewardSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  offerSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 ',
  orderSubscriptionKey: 'aafdce0555d5478c95d157bdf2de98c5 '
};
