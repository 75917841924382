<h1>Rules</h1>

<ng-container *ngTemplateOutlet="contentHeaderTemplate"></ng-container>

<app-loading loadingText="loading rules..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail" style="display: flex;">
</app-error-display>

<ng-container *ngTemplateOutlet="contentRuleDisplayTemplate; context: {rules: rules}"></ng-container>

<ng-template #contentHeaderTemplate>
    <div class="content-header">
        <div class="customer-actions" *ngIf="!working">
            <a [routerLink]="[]" class="new action-item first" (click)="openRuleDialog(null, true)">
                <span class="fal fa-plus icon"></span>
                new
            </a>
        </div>
        <div class="rule-count" *ngIf="!working && errorMessage.length === 0 && rules.length > 0">
            <span class="count">{{rules.length}}</span> {{rules.length === 1 ? 'rule' : 'rules'}} found
        </div>
    </div>
</ng-template>

<ng-template #contentRuleDisplayTemplate let-rules="rules">
    <div class="rules" *ngIf="!working && rules.length > 0">
        <div class="rule {{i === 0 ? 'first' : ''}}" *ngFor="let rule of rules; index as i">
            <div class="rule-detail" (click)="openRuleDialog(rule, false)">
                <div class="name">{{rule.name}}</div>
                <div class="detail">
                    <div class="evaluator">
                        <ng-container *ngTemplateOutlet="evalDisplayTemplate; context: {rule: rule}"></ng-container>
                    </div>

                    <div class="equals">
                        <span class="fa-light fa-hand-back-point-right fa2x icon"></span>
                    </div>

                    <div class="award">
                        <ng-container *ngTemplateOutlet="awardDisplayTemplate; context: {
                            bonusTypeCode: getActionParamValueFromRule(rule, 'bonusTypeCode'), 
                            bonusAmount: getActionParamValueFromRule(rule,'bonusAmount') }">
                        </ng-container>
                    </div>

                </div>
                <div class="actions">
                    <span class="action-item edit">
                        <span class="text">edit</span>
                        <span class="fad fa-edit icon"></span>
                    </span>
                </div>
            </div>
            <div class="actions">

                <span class="action-item delete" (click)="openConfirmTeamDeleteDialog($event, rule)">
                    <span class="text">delete</span>
                    <span class="far fa-trash-alt icon"></span>
                </span>
            </div>
            <div class="rule-delete-overlay">
                <div class="fa-duotone fa-spinner fa-pulse fa-lg icon"></div>
                <div class="text">deleting...</div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #evalDisplayTemplate let-rule="rule">
    <ng-container *ngFor="let expression of rule.expressions; let i = index">

        <ng-container *ngIf="expression.propertyOrMethod === 'EvaluateResultType'">
            <ng-container *ngFor="let param of expression?.parameters">
                <span class="eval-result-type" *ngIf="param.parameterName === 'resultTypeCode'">
                    {{resultType(param.value)}}
                </span>
                <span class="eval-type" *ngIf="param.parameterName === 'evaluationType'">
                    {{evaluationType(param.value)}}
                </span>
                <span class="eval-amt" *ngIf="param.parameterName === 'amount'">
                    {{param.value}}
                </span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="rule.evaluatorType.toLowerCase().indexOf('userplanactivityevaluator') > -1 && i === 0">
            <span class="activity-completed">completed</span>
        </ng-container>

        <ng-container *ngIf="expression.propertyOrMethod === 'HasStreak'">
            <span class="eval-text">streak of</span>
            <span class="eval-amt">{{expression.parameters[0].value}}</span>
        </ng-container>

        <ng-container *ngIf="expression.propertyOrMethod === 'HasPercentage'">
            <span class="eval-amt">{{expression.parameters[0].value}}%</span>
            <span class="eval-text">complete</span>
        </ng-container>

    </ng-container>
</ng-template>

<ng-template #awardDisplayTemplate let-bonusTypeCode="bonusTypeCode" let-bonusAmount="bonusAmount">
    <span class="bonus-amt">
        {{bonusAmount | number:'1.0':'en-US'}}
    </span>
    <span class="bonus-type">
        {{bonusType(bonusTypeCode)}}
        <!-- <span class="fa-light fa-ticket fa2x icon" *ngIf="param.value === 'TICK'"></span>
        <span class="fa-regular fa-gem fa2x icon" *ngIf="param.value === 'GEM'"></span> -->
    </span>
</ng-template>