<div class="task-header customer-actions">
    <h3>Assigned Tasks</h3>

    <div class="task-date" *ngIf="userPlan">
        <a [routerLink]="" class="toggle-date prev" (click)="toggleDate(-1)">
            <span class="fa-regular fa-angle-left icon"></span>
        </a>
        {{taskDateDisplay}}
        <a [routerLink]="" class="toggle-date next {{taskDate === today ? 'disabled' : ''}}" (click)="toggleDate(1)">
            <span class="fa-regular fa-angle-right icon"></span>
        </a>
    </div>

    <a [routerLink]="[]" class="new action-item first" 
        (click)="showAddTask = !showAddTask" 
        *ngIf="!working && (userPlan?.tasks || userPlan?.tasks?.length > 0)">
        <span class="fal fa-plus icon"></span>
        add
    </a>
</div>

<div class="add-plan-item add-task">
    <ng-container *ngTemplateOutlet="addUserTask"></ng-container>
</div>

<app-loading loadingText="loading tasks..." alignText="left" loadingSize="lg" *ngIf="working"></app-loading>
<app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

<div class="tasks" *ngIf="!working && userPlan && userPlan.tasks">
    <!-- <div class="plan-name">{{userPlan.name}}</div> -->
    <div class="task" *ngFor="let task of userPlan.tasks; let i = index">
        <div class="task-content" [matMenuTriggerFor]="taskMenu">
            <div class="points">
                <div class="coin-container">
                    <div class="is-complete">
                        <app-svg-helper svgName="checkmark" svgColor="{{task.imageBgColor}}" class="svg-helper" *ngIf="task.completed"></app-svg-helper>
                    </div>

                    <div class="amount" [style.color]="task.completions?.length/task.occurrence > .33 ? task.imageBgColor : ''">
                        {{task.potentialResults?.success?.bonusAmount}}
                    </div>
                </div>
            </div>
            <div class="task-info">
                <div class="name">{{task.name}}</div>
                <div class="status">{{buildTaskStatus(task)}}</div>
            </div>
            <div class="bg-overlay {{!task.completed && task.completions?.length > 0 ? 'partial' : ''}}" 
                [style.background-color]="task.completed || task.completions?.length > 0 ? task.imageBgColor : 'transparent'"
                [style.height]="!task.completed && task.completions?.length > 0 ? 'calc((100% + 4px)*'+ task.completions.length/task.occurrence +')' : 'calc(100% + 4px)'">
            </div>
        </div>
        
        <mat-menu #taskMenu class="pml-menu task-menu">
            <ng-container *ngTemplateOutlet="taskMenuTemplate; context: {task: task, user: user, i: i, areYouSureMenu: areYouSureMenu}"></ng-container>
        </mat-menu>
        <div class="spinner-overlay" *ngIf="savingTask === i">
            <span class="fa-duotone fa-loader fa-spin-pulse fa-2x spinner"></span>
        </div>
        <mat-menu #areYouSureMenu class="are-you-sure-menu" yPosition="above">
            <div class="small-are-you-sure">
                <div class="confirm-row">
                    <div class="graphic">
                        <span class="fa-light fa-face-thinking fa-2x icon"></span>
                    </div>
                    <div class="text">
                        Do you really want to <span class="emphasis">permanently delete</span> this task?
                    </div>
                </div>
                <div class="actions">
                    <a [routerLink]="[]" class="dont">Don't delete</a>
                    <a [routerLink]="[]" class="btn btn-danger" (click)="deleteUserTask(user.key, task.key, $event, i)">Yes, delete task</a>
                </div>
            </div>
        </mat-menu>
    </div>
</div>

<div class="none-found" *ngIf="!working && (!userPlan?.tasks || userPlan?.tasks?.length === 0)">
    <span class="fa-light fa-face-unamused icon fa-lg"></span>
    No tasks found for
    <span class="user-name">
        <ng-container *ngTemplateOutlet="userNameDisplay; context: {user: user}"></ng-container>
    </span>
</div>

<ng-template #userNameDisplay let-user="user">
    <ng-container *ngIf="user && user.info && user.info.name && user.info.name.first && user.info.name.last; else elseBlock">
        {{user.info.name.first}} {{user.info.name.last}}
    </ng-container>
</ng-template>
<ng-template #elseBlock let-user="user">
    {{getAlternateName(user)}}
</ng-template>

<ng-template #taskMenuTemplate let-task="task" let-user="user" let-i="i" let-areYouSureMenu="areYouSureMenu">
    <button mat-menu-item (click)="markUserTaskAsComplete(task.key, user.key, $event, i)" *ngIf="!task.completed">
        <span class="text">Mark complete</span>
        <img src="assets/images/icon-check-mark.svg" class="icon" title="mark as done" />
    </button>
    <button mat-menu-item (click)="markUserTaskAsNotComplete(user.key, task.key, task.completions[0]?.key, $event, i)" *ngIf="task.completions && task.completions.length > 0">
        <span class="text">Not complete</span>
        <span class="fa-solid fa-arrow-rotate-left fa-lg icon fa-icon"></span>
    </button>
    <button mat-menu-item class="last delete" [matMenuTriggerFor]="areYouSureMenu">
        <span class="text">Remove</span>
        <img src="assets/images/icon-delete.svg" class="icon" title="Delete challenge" />
    </button>
</ng-template>

<ng-template #addUserTask>
    <form [formGroup]="newTaskForm" class="pml-form" (ngSubmit)="newTaskForm.valid && addTaskToPlan()" autocomplete="off">
        <div class="pml-row add-item-row" *ngIf="showAddTask && !workingResources">
            <div class="pml-col">
                <ng-container *ngTemplateOutlet="taskDropdownTemplate; context: {form: newTaskForm, controlName: 'taskTask', label: 'Task', formGet: f}"></ng-container>
            </div>
            <div class="pml-col number">
                <label>Occurrence</label>
                <span class="required" *ngIf="isInvalid(f['taskOccurrence'])">{{getError(f['taskOccurrence'].errors)}}</span>
                <input type="number" class="form-control number occurrence" formControlName="taskOccurrence" placeholder="times/day" value="" />
            </div>
            <div class="pml-col actions">
                <input type="hidden" value="daily" formControlName="taskInterval" />
                <input type="hidden" value="false" formControlName="taskRequiresReview" />
                <button type="submit" class="btn btn-primary nested-submit">
                    <span *ngIf="savingAddTask" class="far fa-spinner fa-pulse fa-sm spinner"></span>
                    <span *ngIf="!savingAddTask" class="">add</span>
                    <span *ngIf="savingAddTask" class="">adding...</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet">
    <ng-container [formGroup]="form">
        <label>{{label}}</label>
        <span class="required" *ngIf="isInvalid(formGet[controlName])">{{getError(formGet[controlName].errors)}}</span>
        <select class="form-select bonus-type-dd" formControlName="{{controlName}}">
            <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
            <option *ngFor="let task of templateTasks" [ngValue]="task.key">{{task.name}}</option>
        </select>
    </ng-container>
</ng-template>