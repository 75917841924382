import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EVALUATOR_TYPES } from 'src/app/core/constants/evaluator-types';
import { DialogDataRule } from 'src/app/core/models/dialog-data-rule';
import { EvaluatorType } from 'src/app/core/models/reward/evaluator-type';
import { ResourceType } from 'src/app/core/models/reward/resource-type';
import { Rule } from 'src/app/core/models/reward/rule';
import { RuleResource } from 'src/app/core/models/reward/rule-resource';
import { RuleSaveInfo, RuleSaveType } from 'src/app/core/models/reward/rule-save-info';

@Component({
  selector: 'app-dialog-rule',
  templateUrl: './dialog-rule.component.html',
  styleUrls: ['./dialog-rule.component.scss']
})
export class DialogRuleComponent implements OnInit {

  working: boolean;
  newRule: boolean;
  evaluator: RuleResource;
  evaluatorTypes: EvaluatorType[];
  bonusTypeResources: ResourceType[] = [];
  resultTypeResources: ResourceType[] = [];
  ruleResources: RuleResource[] = [];
  selectedRule: Rule;
  errorMessage: string;
  EVAL_TYPE_INFO: { [key: string]: string }[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogRuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRule) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.newRule = this.data?.newRule;
    this.evaluator = this.data?.evaluator;
    this.evaluatorTypes = this.data?.evaluatorTypes;
    this.selectedRule = this.data?.selectedRule;
    this.bonusTypeResources = this.data?.bonusTypeResources;
    this.resultTypeResources = this.data?.resultTypeResources;
    this.ruleResources = this.data.newRule ? this.data?.ruleResources : [];
  }

  getEvalTypeInfo(evaluatorType: string): { [key: string]: string } {
    let evalTypeInfo: { [key: string]: string } = {};

    switch (evaluatorType) {
      case EVALUATOR_TYPES.activities:
        evalTypeInfo = {
          description: 'Completion of a specific single activity.',
          example: 'All As on report card'
        };
        break;
      case EVALUATOR_TYPES.awards:
        evalTypeInfo = {
          description: 'Streak of some number of units (e.g., days, weeks) of a task or a percentage completed of a task',
          example: 'Streak of 10 days (has streak of 10)'
        };
        break;
      case EVALUATOR_TYPES.results:
        evalTypeInfo = {
          description: 'Completion of a certain number of tasks.',
          example: 'User brushes teeth for the 10th time (completions = 10)'
        };
        break;
    }

    return evalTypeInfo;
  }

  backToEvalSelect(): void {
    this.evaluator = null;
  }

  saveDialog(ruleEmitInfo: RuleSaveInfo): void {
    // if save was successful, close the dialog and pass the data back to the referring page
    // this.data.newRule = ruleEmitInfo.saveType === RuleSaveType.newRule ? true : false;
    // this.data.selectedRule = ruleEmitInfo.rule;
    this.dialogRef.close(ruleEmitInfo);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
