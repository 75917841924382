import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Status } from 'src/app/core/enums/status';
import { FulfillableProductRequest } from 'src/app/core/models/offer/fulfillable-product-request';
import { Product } from 'src/app/core/models/offer/product';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';

@Component({
  selector: 'app-fulfillable-product-form',
  templateUrl: './fulfillable-product-form.component.html',
  styleUrls: ['./fulfillable-product-form.component.scss']
})
export class FulfillableProductFormComponent implements OnInit {

  @Input() public product: Product;
  @Input() public storeTypeKey: string;
  @Input() public allowedCurrencies: string[];
  @Output() productAddEvent = new EventEmitter<Product>();
  @Output() productCancelEvent = new EventEmitter();

  productForm: UntypedFormGroup;

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  allowedCurrency: string;  // TODO:  Eventually this will be an array and need to handle in the input items

  constructor(
    private offerService: OfferService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.saving = false;
    this.errorMessage = '';

    this.allowedCurrency = this.allowedCurrencies[0] ?? 'PTS';
    //this.allowedCurrencies.push('LBS');

    this.createForm();

    // this.amountItems.controls.forEach((amountItem: FormGroup) => {
    //   console.log(`amountItem: ${amountItem.controls['currencyCode'].value}`);
    // });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.productForm.controls;
  }

  createForm(): void {
    this.productForm = this.fb.group({
      key: [this.product?.key ?? ''],
      name: [this.product?.name ?? '', Validators.required],
      description: [this.product?.description ?? ''],
      cost: [this.product?.amount ?? ''],
      amount: ['', Validators.required]

      //amounts: this.fb.array([])
      // AMOUNT
      // this is the amount that the patient will pay per currency
      // TODO: (eventually) make this a list of amounts based on the allowedCurrencies
      // amounts: this.fb.array(
      //   this.allowedCurrencies.map((currency: string) => {
      //     return this.fb.group({
      //       amount: ['', Validators.required],
      //       currencyCode: [currency]
      //     });
      //     //https://stackoverflow.com/questions/67061716/problem-with-validatons-inside-a-formarray
      //   })
      // )
    });

    // // For each allowed currency add a form control to the amounts array
    // this.allowedCurrencies.forEach((currency: string) => {
    //   const amountItem = this.fb.group({
    //     amount: ['', Validators.required],
    //     currencyCode: [currency]
    //   });

    //   const amountsArray = this.f['amounts'] as FormArray;
    //   amountsArray.push(amountItem);
    // });
  }

  getCurrencyCode(item: any): string {
    var itemGroup = item as FormGroup;
    return itemGroup.controls['currencyCode'].value;
  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  saveProductForm(): void {
    this.saving = true;

    // POST the fulfillable product data
    // http://offer-dev.workscale.io/api/swagger/ui#/Manage%20-%20Tenant%20-%20App/TenantAppManageFulfillableProductPost

    let productToAdd: FulfillableProductRequest = {
      storeTypeKey: this.storeTypeKey,
      name: this.f['name'].value,
      amounts: [{
        amount: this.f['amount'].value,
        currencyCode: this.allowedCurrency ?? 'PTS'
      }],
      status: 'Active'
    };

    if (this.f['description'].value) {
      productToAdd.description = this.f['description'].value;
    }

    this.offerService.addFulfillableProduct(productToAdd, this.product.key).subscribe({
      next: (response: Product | any) => {
        this.product = response;

        this.productAddEvent.emit(this.product);
        this.saving = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.saving = false;
      }
    });
  }

  cancelProductForm(): void {
    this.productCancelEvent.emit();
  }

}
