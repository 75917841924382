import { Pipe, PipeTransform } from '@angular/core';
import { AppUser } from '../models/app-user';

@Pipe({
  name: 'childUsers'
})
export class ChildUsersPipe implements PipeTransform {

  transform(appUsers: Array<AppUser>, parentUserKey: string): Array<AppUser> {
    if (!appUsers || !parentUserKey) {
      return [];
    }

    return appUsers.filter(x => x.linkInfo?.parentUserKey !== null);
  }

}
