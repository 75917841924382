import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/offer/product';
import { ProductImage } from '../models/offer/product-image';
import { ProductImageRequest } from '../models/offer/product-image-request';
import { StoreType } from '../models/offer/store-type';
import { TenantAppSettings } from '../models/tenant-app-settings';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';
import { TenantService } from './tenant.service';
import { FulfillableProductRequest } from '../models/offer/fulfillable-product-request';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends BaseService {

  endpointOffer = environment.offerUrl;
  headersOffer = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.offerSubscriptionKey);

  private storeFrontEnabledSubject: BehaviorSubject<boolean>;
  public storeFrontEnabled: Observable<boolean>;

  constructor(
    http: HttpClient,
    tenantService: TenantService,
    private localStorageService: LocalStorageService) {
    super(http, tenantService);

    this.storeFrontEnabledSubject = new BehaviorSubject<boolean>(null);
    this.storeFrontEnabled = this.storeFrontEnabledSubject.asObservable();
  }

  set storeFrontEnabledValue(value: boolean) {
    if (value !== undefined || value !== null) {
      this.storeFrontEnabledSubject.next(value);
      this.storeFrontEnabled = this.storeFrontEnabledSubject.asObservable();
    }
  }

  getStoreTypes(): Observable<StoreType[]> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/storeTypes`;

    return this.executeApi<StoreType[]>('get', apiUrl, this.headersOffer);
  }

  /**
   *
   * @param storeTypeCode {string} either GIF (gift) or STO (real store)
   */
  getProducts(storeTypeCode: string = 'GIF', query: string = '', continuationToken: string = ''): Observable<Product[]> {
    let apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/products/${storeTypeCode}`;

    let ampOrQuestion = '?';

    if (query !== '') {
      apiUrl += `${ampOrQuestion}${query}`;
      ampOrQuestion = '&';
    }

    if (continuationToken !== '') {
      apiUrl += `${ampOrQuestion}continuationToken=${continuationToken}`;
      ampOrQuestion = '&';
    }

    return this.executeApi<Product[]>('get', apiUrl, this.headersOffer);
  }

  addNewProduct(productToAdd: Product): Observable<Product> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/product`;

    return this.executeApiPost<Product>(apiUrl, this.headersOffer, productToAdd);
  }

  updateProduct(productToUpdate: Product, productKey: string): Observable<Product> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/product/${productKey}`;

    return this.executeApiPatch<Product>(apiUrl, this.headersOffer, productToUpdate);
  }

  deleteProductByKey(productKey: string): Observable<any> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/product/${productKey}`;

    return this.executeApiWithResponseStatus<any>('delete', apiUrl);
  }

  getTenantSettings(): Observable<TenantAppSettings> {
    const apiUrl = `${this.endpointOffer}/tenant/${this.tenantKey}/app/${this.appKey}/settings`;

    return this.executeApi<TenantAppSettings>('get', apiUrl, this.headersOffer);
  }

  /**
   *
   * @param productKey Product Key (string)
   * @param productImage Product Image Request object (ProductImageRequest)
   * @returns ProductImage
   */
  addProductImage(productKey: string, productImage: ProductImageRequest): Observable<ProductImage> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/product/${productKey}/image`;

    return this.executeApiPost<ProductImage>(apiUrl, this.headersOffer, productImage);
  }

  deleteProductImage(productImageKey: string): Observable<any> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/product/image/${productImageKey}`;

    const myHeadersOffer = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.offerSubscriptionKey)
      .set('observe', 'response');

    return this.executeApi<any>('delete', apiUrl, myHeadersOffer);
  }


  getFulfillableProducts(continuationToken: string = ''): Observable<Product[]> {
    let apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/fulfillable/products`;

    if (continuationToken !== '') {
      apiUrl += `?continuationToken=${continuationToken}`;
    }

    return this.executeApi<Product[]>('get', apiUrl, this.headersOffer);
  }

  addFulfillableProduct(productToAdd: FulfillableProductRequest, productKey: string): Observable<Product> {
    const apiUrl = `${this.endpointOffer}/manage/tenant/${this.tenantKey}/app/${this.appKey}/fulfillable/product/${productKey}`;

    return this.executeApiPost<Product>(apiUrl, this.headersOffer, productToAdd);
  }

}
