<h1>Users</h1>

<ng-container *ngTemplateOutlet="customerActionsTemplate"></ng-container>

<app-loading loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<div class="users" *ngIf="!working">
    <ng-container *ngTemplateOutlet="usersHeaderTemplate"></ng-container>

    <div class="user {{selectedUser === i ? 'active': ''}}" *ngFor="let user of appUsers; index as i">
        <div class="user-row">
            <a [routerLink]="[]" class="name user-link" (click)="openUserDialog(user)">
                {{fullNameDisplay(user.info?.name, '', currentSortCode)}}
            </a>
            <span class="role">{{user.roleType}}</span>
        </div>
    </div>

    <div class="none-found" *ngIf="!working && (!appUsers || appUsers.length === 0)">
        <span class="fa-light fa-face-unamused icon fa-lg"></span>
        No users found
    </div>

    <div class="bottom-paging  paging-container">
        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
    </div>
</div>


<ng-template #customerActionsTemplate>

    <div class="customer-actions paging-container">
        <a [routerLink]="[]" class="new action-item first" (click)="openNewUserDialog()">
            <span class="fal fa-plus icon"></span>
            new
        </a>
        <div class="search action-item input-group">
            <span class="far fa-search icon input-group-text"></span>
            <input type="text" class="form-control with-trailing-button" name="search" placeholder="Search"
                [(ngModel)]="userSearchValue" (keyup)="searchValidator($event)" />
            <button type="button" class="btn btn-light" (click)="searchUsers($event)" [disabled]="isSearchButtonDisabled()">search</button>
        </div>
        <div class="clear-search-results" *ngIf="areResultsFromSearch">
            <a [routerLink]="[]" (click)="clearSearchResults()">
                <span class="fa-regular fa-xmark fa-sm icon"></span>
                Clear search results
            </a>
        </div>

        <div class="sort action-item">
            <label>sort by</label>
            <div class="btn-group">
                <input type="radio" class="btn-check" name="sortby" id="firstName" checked (change)="updateUserSort($event)">
                <label class="btn btn-outline-primary" for="firstName">First Name</label>

                <input type="radio" class="btn-check" name="sortby" id="lastName" (change)="updateUserSort($event)">
                <label class="btn btn-outline-primary" for="lastName">Last Name</label>
            </div>
        </div>
        
    </div>

</ng-template>

<ng-template #usersHeaderTemplate>
    <div class="users-header">
        <div class="header-messages">
            <div class="user-invite-message" *ngIf="invitedUserMessage !== ''">
                <span class="fa-light fa-envelope-circle-check fa-2x fa-fw icon"></span>
                {{invitedUserMessage}}
                <span class="email">{{invitedUserEmail}}</span>
            </div>        
        </div>
        <div class="header-count">
            <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>

            <div class="user-count" *ngIf="!working && !paging.pageCount.showResultsPaging">
                <div class="count">
                    <span class="number">{{paging.pageCount.countTotal}}</span> {{paging.pageCount.countTotal === '1' ? 'user' : 'users'}} found
                </div>
            </div>    
        </div>
    </div>

</ng-template>

<ng-template #pagingTemplate>
    <div class="paging" *ngIf="!working && paging.pageCount.showResultsPaging">
        <ng-container *ngTemplateOutlet="pagePrevButtonTemplate; context: {continuationToken: paging.pageCount.prevContinuationToken}"></ng-container>
        <div class="count">
            {{paging.pageCount.countStart}} to {{paging.pageCount.countEnd}} of {{paging.pageCount.countTotal}}
        </div>
        <ng-container *ngTemplateOutlet="pageNextButtonTemplate; context: {continuationToken: paging.pageCount.nextContinuationToken}"></ng-container>
    </div>
</ng-template>

<ng-template #pagePrevButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page prev" (click)="pageUsers(continuationToken, PREV)">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || continuationToken === ''">
        <div class="page prev disabled">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #pageNextButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page next" (click)="pageUsers(continuationToken, NEXT)">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || (continuationToken && continuationToken === '')">
        <div class="page next disabled">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>
</ng-template>