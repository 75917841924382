<h1>Customers</h1>

<ng-container *ngTemplateOutlet="customerActionsTemplate"></ng-container>

<app-loading loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<div class="users" *ngIf="!working">
    <div class="user {{selectedUser === i ? 'active': ''}}" *ngFor="let user of appUsers; index as i">
        <div class="user-row">
            <a [routerLink]="[]" class="name user-link" (click)="openUserDialog(user)">
                {{fullNameDisplay(user.info?.name, '', currentSortCode)}}
                <!-- ({{user.displayName}}) -->
                <img src="assets/images/child.svg" class="child-icon" title="minor" *ngIf="user.info?.isMinor" />
            </a>
            <ng-container *ngTemplateOutlet="hasLinkedUsersTemplate; context: {user: user, app: getAppInfo(user), i: i}"></ng-container>
        </div>
        <div class="user-row child-users" *ngIf="selectedUser === i">
            <app-loading alignText="left" *ngIf="workingChildren"></app-loading>
            <ng-container *ngFor="let child of childUsers | filterSelf: user.key">
                <a [routerLink]="[]" class="children user-link" (click)="openUserDialog(child)">
                    {{fullNameDisplay(child.info?.name)}}
                    <img src="assets/images/child.svg" class="child-icon" title="mark as done" *ngIf="child.info?.isMinor" />
                </a>
            </ng-container>
        </div>
    </div>

    <div class="none-found" *ngIf="!working && (!appUsers || appUsers.length === 0)">
        <span class="fa-light fa-face-unamused icon fa-lg"></span>
        No users found
    </div>

    <div class="bottom-paging  paging-container">
        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
    </div>

</div>

<ng-template #hasLinkedUsersTemplate let-user="user" let-i="i">
    <div class="children-count" *ngIf="user?.linkInfo?.linkedCount && user?.linkInfo?.linkedCount > 0">
        <div class="pill {{selectedUser === i ? 'active': ''}}" (click)="toggleSelectedUser(i, user.key)">
            <span class="far fa-{{selectedUser === i ? 'minus': 'plus'}} fa-sm fa-fw icon"></span>
            <span class="count">{{user?.linkInfo?.linkedCount}}</span>
            {{user.linkInfo?.linkedCount === 1 ? 'relation' : 'relations'}}
        </div>
    </div>    
</ng-template>

<ng-template #customerActionsTemplate>

    <div class="customer-actions paging-container">
        <a [routerLink]="[]" class="new action-item first" (click)="openNewUserDialog()">
            <span class="fal fa-plus icon"></span>
            new
        </a>
        <div class="search action-item input-group">
            <span class="far fa-search icon input-group-text"></span>
            <input type="text" class="form-control with-trailing-button" name="search" placeholder="Search" [(ngModel)]="userSearchValue" (keyup)="searchValidator($event)" />
            <button type="button" class="btn btn-light" (click)="searchUsers($event)" [disabled]="isSearchButtonDisabled()">search</button>
        </div>
        <div class="clear-search-results" *ngIf="areResultsFromSearch">
            <a [routerLink]="[]" (click)="clearSearchResults()">
                <span class="fa-regular fa-xmark fa-sm icon"></span>
                Clear search results
            </a>
        </div>

        <div class="sort action-item">
            <label>sort by</label>
            <div class="btn-group">
                <input type="radio" class="btn-check" name="sortby" id="firstName" checked (change)="updateUserSort($event)">
                <label class="btn btn-outline-primary" for="firstName">First Name</label>

                <input type="radio" class="btn-check" name="sortby" id="lastName" (change)="updateUserSort($event)">
                <label class="btn btn-outline-primary" for="lastName">Last Name</label>
            </div>
        </div>
        
        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>

        <div class="user-count" *ngIf="!working && !pagingCount.showResultsPaging">
            <div class="count">
                <span class="number">{{pagingCount.countTotal}}</span> {{pagingCount.countTotal === '1' ? 'user' : 'users'}} found
            </div>
        </div>
    </div>

</ng-template>

<ng-template #pagingTemplate>
    <div class="paging" *ngIf="!working && pagingCount.showResultsPaging">
        <ng-container *ngTemplateOutlet="pagePrevButtonTemplate; context: {continuationToken: pagingCount.prevContinuationToken}"></ng-container>
        <div class="count">
            {{pagingCount.countStart}} to {{pagingCount.countEnd}} of {{pagingCount.countTotal}}
        </div>
        <ng-container *ngTemplateOutlet="pageNextButtonTemplate; context: {continuationToken: pagingCount.nextContinuationToken}"></ng-container>
    </div>
</ng-template>

<ng-template #pagePrevButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page prev" (click)="pageUsers(continuationToken, PREV)">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || continuationToken === ''">
        <div class="page prev disabled">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #pageNextButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page next" (click)="pageUsers(continuationToken, NEXT)">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || (continuationToken && continuationToken === '')">
        <div class="page next disabled">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>
</ng-template>