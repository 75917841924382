<header class="sticky-top" *ngIf="user">

    <div class="user">
        <!-- <span class="label">Company:</span> -->
        <ng-container *ngTemplateOutlet="tenantSelectTemplate"></ng-container>
        <!-- <div class="tenant" *ngFor="let ten of userTenants">{{ten.name}}</div> -->


        <div class="account-action">
            <div class="actions">
                <a routerLink="" class="name">{{usernameDisplay(user)}}</a>
                <a routerLink="" class="logout" (click)="logout()">
                    Log out
                    <span class="fad fa-sign-out icon fa-lg"></span>
                </a>
            </div>
            <div class="user-icon">
                <!-- <img src="assets/images/avatar-boy.png" title="User" /> -->
                <div class="avatar user-avatar {{defaultAvatar ? 'default' : ''}}"
                    [style.background-color]="avatarBgColor">
                    <img src="{{avatarImg}}" class="img" [title]="user.displayName ?? user.username" />
                </div>
            </div>
        </div>
    </div>

</header>


<ng-template #tenantSelectTemplate>
    <ng-container *ngIf="userTenants?.length === 1">
        <div class="tenant">
            {{tenant.name}}
        </div>
    </ng-container>
    <ng-container *ngIf="userTenants?.length > 1">
        <div class="tenant dropdown">
            <div class="button-wrapper {{t.menuOpen ? 'active': ''}}" #tenantDropdown [matMenuTriggerFor]="tenantMenu" #t="matMenuTrigger">
                {{tenant.name}}
                <span class="fa-regular fa-angle-down caret"></span>
            </div>
        </div>
        <mat-menu #tenantMenu class="pml-menu tenant-menu">
            <ng-container *ngFor="let userTenant of userTenants">
                <a [routerLink]="" class="tenant-menu-item {{userTenant.key === tenant.key ? 'selected' : ''}}" (click)="changeTenant(userTenant, $event)">
                    {{userTenant.name}}
                </a>
            </ng-container>
        </mat-menu>
    </ng-container>
</ng-template>