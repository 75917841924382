<div class="summary-header customer-actions">
    <h3>Task Completion</h3>

    <!-- <a [routerLink]="[]" class="new action-item first">
        <span class="fal fa-plus icon"></span>
        add
    </a> -->

    <div class="sort action-item">
        <label>show completed</label>
        <div class="btn-group">
            <input type="radio" class="btn-check" name="sortby" id="occurrences" checked (click)="changeEvaluationType('ByOccurrence')">
            <label class="btn btn-outline-primary" for="occurrences">Occurrences</label>

            <input type="radio" class="btn-check" name="sortby" id="dailyTasks" (click)="changeEvaluationType('ByAward')">
            <label class="btn btn-outline-primary" for="dailyTasks">Daily Tasks</label>
        </div>
    </div>

</div>

<app-loading loadingText="loading task completion data..." alignText="left" loadingSize="lg" *ngIf="working"></app-loading>
<app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-center"></app-error-display>

<div class="summary-content" *ngIf="!working && activitySummary && activitySummary.length > 0">
    <div class="summary" *ngFor="let summary of activitySummary; let i = index">

        <div class="chart-header" [style.border-left]="'8px solid ' + summary.bgColor">
            <h5>{{summary.name}}</h5>
            <div class="average" [style.color]="summary.bgColor">{{summary.dataPointAverage ? summary.dataPointAverage : '--'}}%</div>
        </div>
        <div class="pml-chart">
            <canvas baseChart
                [type]="'line'" 
                [data]="loadChartData(summary.chartLabels, summary.chartDataPoints)" 
                [options]="lineChartOptions"
                [plugins]="chartPlugins('chart' + i, summary.chartDataPoints)" 
                [legend]="lineChartLegend">
            </canvas>
        </div>
    </div>
</div>