<div class="registration-header">
    <div class="branding-col">

        <a routerLink="[]" class="brand">
            <!-- <img src="assets/images/logo-horizontal.png" title="Pummelo" /> -->
            <img src="assets/images/logo-horizontal.svg" title="Pummelo" />
        </a>

    </div>
    <div class="header-right" *ngIf="stepNumber !== '-1'">
        <div class="step">Step {{stepNumber}} of 4</div>
    </div>
</div>