<div class="container-fluid login">
    <div class="row">
        <div class="col branding-col">

            <div class="branding">
                <a routerLink="[]" class="brand">
                    <!-- <img src="assets/images/logo-horizontal.png" title="Pummelo" /> -->
                    <img src="assets/images/logo-horizontal.svg" title="Pummelo" />
                </a>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col">

            <div class="card">
                <div class="card-body">
                    <h1>Sign in to Pummelo</h1>
                    <!-- <h4 class="{{errorMessage ? 'with-error' : ''}}">Lorem ipsum dolor sit amet</h4> -->

                    <div class="error alert alert-danger" *ngIf="errorMessage">
                        <span class="far fa-exclamation-circle fa-lg icon"></span>
                        {{errorMessage}}
                    </div>

                    <form class="login" [formGroup]="loginForm" (ngSubmit)="loginUser()" autocomplete="off">
                        <div class="mb-3 form-row">
                            <label class="form-label">
                                Username
                                <span class="required"
                                    *ngIf="(loginForm.controls['username'].invalid && loginForm.controls['username'].touched)">required</span>
                            </label>
                            <input type="text" formControlName="username" class="form-control"
                                placeholder="Username or email address" required>
                        </div>

                        <div class="mb-3 form-row">
                            <label class="form-label">
                                Password
                                <span class="required"
                                    *ngIf="(loginForm.controls['password'].invalid && loginForm.controls['password'].touched)">required</span>
                            </label>
                            <input type="password" formControlName="password" class="form-control"
                                placeholder="Password" />
                        </div>

                        <div class="form-row">
                            <a routerLink="forgot-password" class="forgot">Forgot your password?</a>
                        </div>

                        <div class="mb-3 submit-row">
                            <button type="submit" class="form-control btn btn-primary btn-search" [disabled]="!loginForm.valid || isWorking()">
                                <span *ngIf="isWorking()" class="far fa-spinner fa-pulse spinner"></span>
                                <span *ngIf="!isWorking()" class="">Log in</span>
                                <span *ngIf="isWorking()" class="">Logging in...</span>
                            </button>
                        </div>

                        <div class="new-user-row">
                            <span class="text">New to Pummelo?</span>
                            <a routerLink="/register">Create an account</a>.
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>