<form [formGroup]="editTaskForm" class="edit-form" (ngSubmit)="editTaskForm.valid && updateTaskInPlan()" autocomplete="off">
    <div class="pml-row add-item-row" *ngIf="!workingResources">
        <div class="pml-col">
                <label>Task</label>
                <span class="required" *ngIf="isInvalid(f['taskTask'])">{{getError(f['taskTask'].errors)}}</span>
                <select class="form-select task" formControlName="taskTask" (change)="getTaskName($event)">
                    <option *ngFor="let availableTask of availableTasks" [ngValue]="availableTask.key">{{availableTask.name}}</option>
                </select>
        </div>
        <div class="pml-col number">
            <label>Occurrence</label>
            <span class="required" *ngIf="isInvalid(f['taskOccurrence'])">{{getError(f['taskOccurrence'].errors)}}</span>
            <input type="number" class="form-control number occurrence" formControlName="taskOccurrence" placeholder="times/day" value="" />
        </div>
        <div class="pml-col actions">
            <input type="hidden" value="daily" formControlName="taskInterval" />
            <input type="hidden" value="false" formControlName="taskRequiresReview" />
            <button type="submit" class="btn btn-primary nested-submit" [disabled]="working || !(editTaskForm.valid && editTaskForm.dirty)">
                Modify
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="cancelTaskEdit()">Cancel</button>
        </div>
    </div>
</form>
