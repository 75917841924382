<h1>Template Plans</h1>

<ng-container *ngTemplateOutlet="contentHeaderTemplate"></ng-container>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<app-loading loadingText="loading active plans..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<ng-container *ngTemplateOutlet="itemGridTemplate; context: {plans: plans}"></ng-container>


<ng-template #contentHeaderTemplate>
    <div class="content-header">
        <div class="customer-actions" *ngIf="!working">
            <a [routerLink]="[]" class="new action-item first" (click)="openPlanDialog(null, true)">
                <span class="fal fa-plus icon"></span>
                new
            </a>
        </div>
        <div class="item-count" *ngIf="!working && errorMessage.length === 0 && plans.length > 0">
            <span class="count">{{plans.length}}</span> {{plans.length === 1 ? 'plan' : 'plans'}} found
        </div>
    </div>
</ng-template>

<ng-template #itemGridTemplate let-plans="plans">
    <div class="items" *ngIf="!working && plans.length > 0">
        <div class="item {{i === 0 ? 'first' : ''}}" *ngFor="let plan of plans; index as i">
            <div class="item-detail" (click)="openPlanDialog(plan)">
                <div class="name">{{plan.name}}</div>
                <div class="detail">
                    <span class="sub-item" *ngIf="plan.tasks">
                        <span class="count">{{plan.tasks.length}}</span>
                        {{plan.tasks.length === 1 ? 'daily task' : 'daily tasks'}}
                    </span>
                    <span class="sub-item" *ngIf="plan.tests">
                        <span class="count">{{plan.tests.length}}</span>
                        {{plan.tests.length === 1 ? 'challenge' : 'challenges'}}
                    </span>
                    <span class="sub-item" *ngIf="plan.levels">
                        <span class="count">{{plan.levels.length}}</span>
                        {{plan.levels.length === 1 ? 'milestone' : 'milestones'}}
                    </span>
                </div>
                <div class="actions">
                    <span class="action-item edit">
                        <span class="text">edit</span>
                        <span class="fad fa-edit icon"></span>
                    </span>
                </div>
            </div>

            <div class="actions">
                <span class="action-item delete" (click)="openConfirmPlanDeleteDialog($event, plan)">
                    <span class="text">delete</span>
                    <span class="far fa-trash-alt icon"></span>
                </span>
            </div>

            <div class="item-delete-overlay">
                <div class="fa-duotone fa-spinner fa-pulse fa-lg icon"></div>
                <div class="text">deleting...</div>
            </div>
        </div>
    </div>
</ng-template>
