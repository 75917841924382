import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { AppUser } from 'src/app/core/models/app-user';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplatePlan } from 'src/app/core/models/reward/template-plan';
import { TemplatePlansResponse } from 'src/app/core/models/reward/template-plans-response';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-user-plan-add',
  templateUrl: './user-plan-add.component.html',
  styleUrls: ['./user-plan-add.component.scss']
})
export class UserPlanAddComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  plans: TemplatePlan[] = [];
  saving: boolean;
  showPlanDetails: number;
  planBgColors: string[];

  @Input() public user: AppUser;
  @Output() enrollUserInPlanEvent = new EventEmitter<UserPlanResponse>();


  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.showPlanDetails = -1;

    this.planBgColors = AVATAR_COLORS;
    this.getAllPlans();
  }

  getAllPlans(): void {
    this.working = true;

    this.rewardsService.getTemplatePlans().subscribe({
      next: (response: TemplatePlansResponse | any) => {
        this.plans = response?.items;

        this.plans.forEach((plan: TemplatePlan) => {
          // Set task, test, & milestone bg colors
          this.setBgColors(plan.tasks, this.planBgColors, 8);
          this.setBgColors(plan.tests, this.planBgColors, 4);
          this.setBgColors(plan.levels, this.planBgColors, 6);

          plan.isSelected = plan.isDefault ? true : false;
        });

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  getUserDisplayName(user: AppUser): string {
    // Note:  won't pass unit test without pulling this into a method.  Not sure why.
    if (user && user.info && user.info.name && user.info.name.first && user.info.name.last) {
      return `${user.info.name.first} ${user.info.name.last}`;
    } else if (user?.displayName && user.displayName.length > 0) {
      return user.displayName;
    } else {
      return 'this user';
    }
  }

  setBgColors(
    planItems: TemplateTask[] | TemplateTest[] | TemplateLevel[],
    planBgColors: string[],
    i: number): void {
    planItems?.forEach((item: TemplateTask | TemplateTest | TemplateLevel) => {
      item.imageBgColor = planBgColors[i];
      i++;
      if (i === planBgColors.length) {
        i = 0;
      }
    });
  }

  updatePlanIsSelected(plan: TemplatePlan, e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    plan.isSelected = currentCheckbox.checked;
  }

  enrollUserInPlan(userKey: string, plans: TemplatePlan[]): void {
    this.saving = true;

    const selectedPlanKeys = plans.filter((plan: TemplatePlan) => plan.isSelected).map((plan: TemplatePlan) => plan.key);
    //planKeys.forEach((key: string) => console.log(`planKey: ${key}`));

    this.rewardsService.enrollUserInPlan(userKey, selectedPlanKeys).subscribe({
      next: (response: UserPlanResponse | any) => {
        // Call back to parent control and update plan
        this.enrollUserInPlanEvent.emit(response);

        this.saving = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.saving = false;
      }
    });
  }

}
