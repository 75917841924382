<div class="sidebar px-0" *ngIf="user">
<!-- sidebar -->
    <a href="/" class="d-flex align-items-center text-white text-decoration-none navbar-brand">
        <img src="assets/images/logo-mark.svg" class="d-lg-none logo mark-logo" title="Pummelo" />
        <img src="assets/images/logo-horizontal-white.svg" class="d-none d-lg-inline logo expandable" title="Pummelo" />
        <img src="assets/images/portal.svg" class="d-none d-lg-inline portal-text expandable" title="Pummelo" />
    </a>

    <div class="nav flex-column mb-0">
        <!-- <div class="nav-items admin">
            <a routerLink="" class="nav-item nav-link dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class="fad fa-home fa-lg fa-fw icon"></i>
                <span class="ms-1 d-none d-lg-inline expandable">dashboard</span>
            </a>
        </div> -->

        <!-- <div class="nav-items admin">
            <a routerLink="" class="nav-item nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class="fad fa-user-friends fa-lg fa-fw icon"></i>
                <span class="ms-1 d-none d-lg-inline expandable">customers</span>
            </a>
        </div> -->

        <div class="nav-items">
            <ng-container *ngTemplateOutlet="navGroup; context: {pages: defaultPages, headerLabel: '', showHeader: false}"></ng-container>
        </div>

        <div class="nav-items">
            <ng-container *ngTemplateOutlet="navGroup; context: {pages: shoppingPages, headerLabel: 'E-COMMERCE', showHeader: true}"></ng-container>
        </div>

        <div class="nav-items">
            <ng-container *ngTemplateOutlet="navGroup; context: {pages: rewardPages, headerLabel: 'Rewards Setup', showHeader: true}"></ng-container>
        </div>

        <div class="nav-items" *ngIf="managementSectionEnabled">
            <ng-container *ngTemplateOutlet="navGroup; context: {pages: adminPages, headerLabel: 'Management', showHeader: true}"></ng-container>
        </div>

        <!-- <div class="nav-items in-office">
            <ng-container *ngTemplateOutlet="navGroup; context: {pages: officePages, headerLabel: 'In Office'}"></ng-container>
        </div> -->
    </div>


    <div class="toggle">
        <a [routerLink]="[]" class="toggle-link" id="toggleLink" (click)="onSidenavToggle($event)">
            <span class="fas fa-chevron-double-right fa-lg icon expanded"></span>
        </a>
    </div>
</div>


<ng-template #navGroup let-pages="pages" let-headerLabel="headerLabel" let-showHeader="showHeader">
    <h4 class="d-none d-lg-inline expandable" *ngIf="showHeader">
        <span class="text">{{headerLabel}}</span>
    </h4>

    <a [routerLink]="page?.link" class="nav-item nav-link {{page?.class}}" 
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
        *ngFor="let page of pages">
        <span class="{{page?.iconType || 'fa-duotone'}} fa-{{page?.icon}} fa-lg fa-fw icon {{page?.additionalIconClass}}"></span>
        <span class="ms-1 d-none d-lg-inline expandable">{{ page?.name}} </span>
    </a>

    
</ng-template>