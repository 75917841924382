import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ParseErrorService } from '../services/parse-error.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private parseError: ParseErrorService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // TODO:  Update to include the microsoft errors I'm looking for 440 (i think and others)
            if ([400, 401, 403, 440].includes(err.status)) {
                if (this.parseError.isExpiredTokenError(err)) {
                    this.authService.logout(false, 'sessionExpired', true); // TODO:  Create enums for codes
                }

            }

            return throwError(() => err);
        }));
    }
}

export const errorInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];
