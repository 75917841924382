export enum TaskInterval {
    daily,
    weekly,
    biweekly,
    monthly,
    quarterly,
    semiannually,
    annually,
    ever
}

export const taskIntervalToLabelMapping: Record<TaskInterval, string> = {
    [TaskInterval.daily]: 'Daily',
    [TaskInterval.weekly]: 'Weekly',
    [TaskInterval.biweekly]: 'Bi-Weekly',
    [TaskInterval.monthly]: 'Monthly',
    [TaskInterval.quarterly]: 'Quarterly',
    [TaskInterval.semiannually]: 'Semi-Annually',
    [TaskInterval.annually]: 'Annually',
    [TaskInterval.ever]: 'Ever',
};
