import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AppUser } from 'src/app/core/models/app-user';
import { Wallet } from 'src/app/core/models/payment/wallet';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-user-balance',
  templateUrl: './user-balance.component.html',
  styleUrls: ['./user-balance.component.scss']
})
export class UserBalanceComponent implements OnInit, OnChanges {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  wallet: Wallet;

  @Input() public user: AppUser;

  constructor(
    private paymentService: PaymentService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;

    this.getWallet();
  }

  ngOnChanges(): void {
    this.getWallet();
  }

  getWallet(): void {
    this.working = true;

    this.paymentService.getUserWalletByKey(this.user.key).subscribe({
      next: (response: Wallet | any) => {
        this.wallet = response;

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

}
