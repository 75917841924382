import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateOfBirth } from '../models/date-of-birth';

@Injectable({
  providedIn: 'root'
})
export class HelperDobService {

  constructor(private datePipe: DatePipe) { }

  formatDate(dateToBeFormatted: Date, format: string): string {
    return this.datePipe.transform(dateToBeFormatted, format);
  }

  formatDOB(dob: DateOfBirth, format: string): string {
    if (this.isValid(dob)) {
      const dateToFormat = this.parseDOB(dob);
      return this.formatDate(dateToFormat, format);
    }
    return undefined;
  }

  parseDOB(dob: DateOfBirth): Date {
    if (!dob.year || !dob.month || !dob.day) {
      return undefined;
    }
    //return new Date(`${this.year}-${this.month}-${this.day}`);
    return new Date(dob.year, dob.month - 1, dob.day);
  }

  public isValid(dob: DateOfBirth): boolean {
    if (!dob) {
      return false;
    }

    if (!dob.year || !dob.month || !dob.day) {
      return false;
    }

    if (!Date.parse(`${dob.year}-${dob.month}-${dob.day}`)) {
      return false;
    }

    return true;
  }

}
