<form [formGroup]="taskForm" class="task-form" (ngSubmit)="taskForm.valid && saveTask()" autocomplete="off">
    <div class="name edit {{isNewTask ? 'new' : ''}}">
        <label *ngIf="showLabel">Task Name</label>
        <div class="input-items">
            <span class="required" *ngIf="f['name'].invalid && (f['name'].dirty || f['name'].touched)">required</span>
            <input type="text" class="form-control" formControlName="name" placeholder="task name" />
            <input type="hidden" formControlName="key" />
            
            <div class="save-cancel">
                <button type="submit" class="btn btn-primary save" [disabled]="isSaving()">
                    <span *ngIf="isSaving()" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!isSaving()" class="">save</span>
                    <span *ngIf="isSaving()" class="">saving...</span>
                </button>
                <input type="button" class="btn btn-link" value="cancel" (click)="cancelTaskForm()">
            </div>
        </div>
    </div>
</form>