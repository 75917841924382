import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { SaveInfo, SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {

  @Input() public task: TemplateTask;
  @Input() public showLabel: boolean;
  @Input() public isNewTask: boolean;
  @Output() taskCancelEvent = new EventEmitter();
  @Output() taskSaveEvent = new EventEmitter<SaveInfo<TemplateTask>>();

  taskForm: UntypedFormGroup;

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.taskForm = this.fb.group({
      key: [this.task?.key ?? ''],
      name: [this.task?.name ?? '', Validators.required]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.taskForm.controls;
  }

  isSaving(): boolean {
    if (this.working === true) {
      return true;
    } else {
      return false;
    }
  }

  cancelTaskForm(): void {
    this.taskForm.reset();
    this.taskCancelEvent.emit();
  }

  saveTask(): void {
    this.working = true;

    if (this.isNewTask) {
      this.task = {
        name: this.f['name'].value
      };
      // API POST (new template task)
      this.rewardsService.createNewTemplateTask(this.task).subscribe({
        next: (response: TemplateTask | any) => {
          this.task = response;

          this.taskSaveEvent.emit({result: this.task, saveType: SaveType.new});
          this.working = false;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    } else {
      // API PATCH (update template task)
      this.rewardsService.updateTemplateTask(this.task).subscribe({
        next: (response: TemplateTask | any) => {
          this.task = response;

          this.taskSaveEvent.emit({result: this.task, saveType: SaveType.updated});
          this.working = false;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    }
  }

}
