import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleType } from 'src/app/core/enums/role-type';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { Avatar } from 'src/app/core/models/avatar';
import { DialogDataUserAdmin } from 'src/app/core/models/dialog-data-user-admin';
import { Name } from 'src/app/core/models/name';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dialog-user-admin',
  templateUrl: './dialog-user-admin.component.html',
  styleUrls: ['./dialog-user-admin.component.scss', '../dialog-user-new/dialog-user-new.component.scss']
})
export class DialogUserAdminComponent implements OnInit {
  DEFAULT_AVATAR_COLOR = '#FF6D6D';
  DEFAULT_AVATAR_IMG = 'avatar-logo.png';
  AVATAR_PATH = 'assets/images/avatars/';

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  user: AppUser;
  dialogWidth: string;

  avatarBgColor: string;
  avatarImg: string;
  defaultAvatar: boolean;
  updateAvatar: boolean;

  toggleSuffix: boolean;
  userForm: UntypedFormGroup;

  roleTypes: KeyValue<string | RoleType, string>[];

  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogUserAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUserAdmin,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.updateAvatar = false;

    this.avatarBgColor = this.DEFAULT_AVATAR_COLOR;
    this.avatarImg = `${this.AVATAR_PATH}${this.DEFAULT_AVATAR_IMG}`;
    this.defaultAvatar = true;

    this.dialogWidth = this.data.dialogWidth;
    this.user = this.data.user ? this.data.user : {};

    // Convert enum to key value pair for display on the page
    this.roleTypes = Object.entries(RoleType)
      .filter(([value, key]) => typeof key === 'number')
      .map(([value, key]) => ({key, value}));

    //this is a hack, but keeps insync with the enum
    this.roleTypes.forEach((roleType: KeyValue<string|RoleType, string>) => {
      if (roleType.value == 'PowerUser') {
        roleType.value = 'Power User';
      }
    });

    this.createForm();
    this.formChanges();
    this.checkForAvatar();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  getKeys(obj: any) { return Object.keys(obj); }

  createForm(): void {
    this.userForm = this.fb.group({
      first: [this.user?.info?.name?.first ?? '', Validators.required],
      middle: [this.user?.info?.name?.middle ?? ''],
      last: [this.user?.info?.name?.last ?? ''],
      suffix: [this.user?.info?.name?.suffix ?? ''],
      emailAddress: [this.user?.emailAddress ?? '', [Validators.required, Validators.email]],
      username: [this.user?.username ?? ''],
      roleType: [this.getRoleKeyFromValue(this.user?.roleType) ?? ''],
      isMinor: [this.user?.info?.isMinor ?? false]
    });
  }

  getRoleKeyFromValue(roleTypeName: string): string | any {
    if (roleTypeName === null || roleTypeName === undefined) {
      return null;
    }

    // Hack
    this.roleTypes.forEach((role: KeyValue<string|RoleType, string>) => {
      if (role.value == 'Power User') {
        role.value = 'PowerUser';
      }
    });

    const selectedRole = this.roleTypes.find(item => item.value.toLocaleLowerCase() === roleTypeName.toLocaleLowerCase());

    // Hack
    this.roleTypes.forEach((role: KeyValue<string|RoleType, string>) => {
      if (role.value == 'PowerUser') {
        role.value = 'Power User';
      }
    });

    return selectedRole.key as string;
  }

  formChanges(): void {
    // Cute little item to make title reflect user changes
    this.f['first'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.first = val;
      }
    });

    this.f['middle'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.middle = val;
      }
    });

    this.f['last'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.last = val;
      }
    });

    this.f['suffix'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.suffix = val;
      }
    });

  }

  userNameObjectExists(): boolean {
    if (!this.user || !this.user.info || !this.user.info.name) {
      return false;
    }
    return true;
  }

  createNameObject(): void {
    if (!this.user) {
      this.user = {};
    }
    if (!this.user.info) {
      this.user.info = {};
    }
    if (!this.user.info.name) {
      this.user.info.name = {};
    }
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    return 'Error';
  }

  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name) ?? nullPlaceholder;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveUser(): void {
    this.saving = true;
    const newUser: AppUser = this.populateUserDataFromFormInput();

    this.userService.createAppUser(newUser).subscribe({
      next: (response: AppUser | any) => {
        this.user = response;

        // Update the parent page (USERS) rather than do a reload of that page
        this.dialogRef.close({
          data: {
            newUser: response
          }
        });

        this.saving = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      }
    });
  }

  populateUserDataFromFormInput(): AppUser {
    if (!this.userNameObjectExists) {
      this.createNameObject();
    }

    this.user.info.name.first = this.f['first'].value;

    if (this.f['middle'] && this.f['middle'].value.length > 0) {
      this.user.info.name.middle = this.f['middle'].value;
    }

    if (this.f['last'] && this.f['last'].value.length > 0) {
      this.user.info.name.last = this.f['last'].value;
    }

    if (this.f['suffix'] && this.f['suffix'].value.length > 0) {
      this.user.info.name.suffix = this.f['suffix'].value;
    }

    if (this.f['emailAddress'] && this.f['emailAddress'].value.length > 0) {
      this.user.emailAddress = this.f['emailAddress'].value;
      this.user.username = this.f['emailAddress'].value;
      this.user.displayName = this.f['emailAddress'].value.substring(0, this.f['emailAddress'].value.lastIndexOf('@'));
    }

    this.user.info.isMinor = false;
    this.user.status = 'Active';
    this.user.roleType = 'Customer';  // TODO

    return { ...this.user };
  }



  checkForAvatar(): void {

    if (this.user && this.user.info && this.user.info.additionalInfo) {
      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = `assets/images/avatars/${this.user.info.additionalInfo['avatar']}`;
        this.defaultAvatar = false;
      }

      if (this.user.info.additionalInfo['avatarColor']) {
        // Get the avatar color and convert to a usable RGBA format
        this.avatarBgColor = this.user.info.additionalInfo['avatarColor'];
      }
    }
  }

  closeAvatarWindow(): void {
    this.updateAvatar = !this.updateAvatar;
  }

  previewAvatar(avatar: Avatar): void {
    if (avatar?.color) {
      this.avatarBgColor = avatar.color;
    }

    // Get the avatar image
    if (avatar?.fileName) {
      this.avatarImg = `${this.AVATAR_PATH}${avatar.fileName}`;
      this.defaultAvatar = false;
    }
  }

  resetAvatarToOriginal(): void {
    if (this.user && this.user.info && this.user.info.additionalInfo) {
      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = `${this.AVATAR_PATH}${this.user.info.additionalInfo['avatar']}`;
        this.defaultAvatar = false;
      }

      if (this.user.info.additionalInfo['avatarColor']) {
        // Get the avatar color and convert to a usable RGBA format
        this.avatarBgColor = this.user.info.additionalInfo['avatarColor'];
      }
    } else {
      this.avatarBgColor = this.DEFAULT_AVATAR_COLOR;
      this.avatarImg = `${this.AVATAR_PATH}${this.DEFAULT_AVATAR_IMG}`;
      this.defaultAvatar = true;
    }
  }

  updateUserWithAvatar(avatar: Avatar): void {
    if (!this.user) {
      this.user = {};
    }

    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          avatar: avatar.fileName,
          avatarColor: avatar.color
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        avatar: avatar.fileName,
        avatarColor: avatar.color
      };
    } else {
      this.user.info.additionalInfo['avatar'] = avatar.fileName;
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
    }

    this.avatarBgColor = avatar.color;
    this.avatarImg = `${this.AVATAR_PATH}${avatar.fileName}`;
    this.defaultAvatar = false;

    this.closeAvatarWindow();
  }

}
