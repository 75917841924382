import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TenantService } from './tenant.service';
import { Observable } from 'rxjs';
import { SubscriptionPlan } from '../models/registration/subscription-plan';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends BaseService {

  endpointSubscription = environment.subscriptionUrl;
  headersSubscription = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.subscriptionSubscriptionKey);

  constructor(
    http: HttpClient,
    tenantService: TenantService) {

    super(http, tenantService);

  }

  getPlans(): Observable<SubscriptionPlan[]> {
    const apiUrl = `${this.endpointSubscription}/tenant/${this.tenantKey}/app/${environment.appKey}/plans`;

    return this.executeApi<SubscriptionPlan[]>('get', apiUrl, this.headersSubscription);
  }

  selectPlan(appPlanKey: string) : Observable<boolean> {
    const apiUrl = `${this.endpointSubscription}/tenant/${this.tenantKey}/app/subscription`;

    return this.executeApiPost<boolean>(apiUrl, this.headersSubscription, { 'appPlanKeys': [ appPlanKey ] });
  }

  getPlansTEMP(): Observable<SubscriptionPlan[]> {
    const plansLocaction = 'assets/data/temp-plans.json';
    return this.http.get<SubscriptionPlan[]>(plansLocaction);
  }

}
