<div class="avatar user-avatar {{defaultAvatar ? 'default' : ''}}" [style.background-color]="avatarBgColor"
    (click)="updateAvatar = !updateAvatar" *ngIf="!isNewUser">
    <img src="{{avatarImg}}" class="img" title="user" />
</div>

<span class="avatar new-user" *ngIf="isNewUser">
    <span class="fal fa-plus fa-3x icon"></span>
</span>

<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div class="nav nav-tabs">
    <a [routerLink]="[]" class="nav-item {{activeTab === PROFILE_TAB ? 'active' : ''}}"
        (click)="setActiveTab(PROFILE_TAB)">Profile</a>
    <a [routerLink]="[]" class="nav-item {{activeTab === CHALLENGES_TAB ? 'active' : ''}}"
        (click)="setActiveTab(CHALLENGES_TAB)">Challenges</a>
</div>

<div mat-dialog-content class="dialog-container">
    <app-choose-avatar [user]="user" (closeAvatarEvent)="closeAvatarWindow()"
        (previewAvatarEvent)="previewAvatar($event)" (resetAvatarToOriginalEvent)="resetAvatarToOriginal()"
        (updateAvatarEvent)="updateUserWithAvatar($event)" *ngIf="updateAvatar"></app-choose-avatar>

    <ng-container *ngIf="activeTab === PROFILE_TAB">

        <ng-container *ngTemplateOutlet="userAccountFormTemplate; context: {user: user}"></ng-container>

        <ng-container
            *ngTemplateOutlet="linkedUsersTemplate; context: {user: user, linkedUsers: linkedUsers, allowAddLinkedUser: !hasParent}">
        </ng-container>

        <ng-container
            *ngTemplateOutlet="parentUserTemplate; context: {parentUser: parentUser, showParentUserInfo: hasParent}">
        </ng-container>

        <ng-container *ngTemplateOutlet="addLinkedUserTemplate; context: {parentUser: user}"></ng-container>

    </ng-container>

    <ng-container *ngIf="activeTab === CHALLENGES_TAB">

        <ng-container *ngTemplateOutlet="userChallengesTemplate; context: {user: user}"></ng-container>

    </ng-container>

</div>


<ng-template #userAccountFormTemplate let-user="user">
    <form class="account-form" [formGroup]="accountForm" (ngSubmit)="savePersonalForm()" autocomplete="off">
        <div class="edit-form {{editAccount ? 'edit' : ''}}">
            <div class="form-row display-name">
                <div class="form-col first-col">
                    <div class="group-item large-text-item">
                        <label>
                            username
                            <span class="required"
                                *ngIf="editAccount && (a['displayName'].invalid && (a['displayName'].dirty || a['displayName'].touched))">
                                {{getErrorText(a['displayName'].errors)}}
                            </span>
                        </label>
                        <input type="text" formControlName="displayName" class="form-control first large-text"
                            placeholder="username" *ngIf="editAccount" (blur)="checkUsername($event)" />
                        <div class="username-spinner" *ngIf="editAccount && workingUsername">
                            <span class="far fa-circle-notch fa-spin spinner"></span>
                        </div>
                        <div class="available-names" *ngIf="editAccount && availableNames.length > 0">
                            <label>Available names: </label>
                            {{availableNames}}
                        </div>
                        <div class="display" *ngIf="!editAccount">{{user.displayName}}</div>
                    </div>
                </div>
                <div class="form-col toggle-col">
                    <div class="group-item">
                        <label class="form-check-label toggle-1 {{isMinor ? 'active' : ''}}"
                            for="userIsMinor">Minor</label>
                        <div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input" id="userIsMinor" checked
                                (change)="isMinorToggle($event)">
                            <label class="form-check-label toggle-2 {{!isMinor ? 'active' : ''}}"
                                for="userIsMinor">Adult</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row {{editAccount ? '' : 'display-column-spacing'}}">
                <div class="form-col first-col group-item">
                    <label>name</label>
                    <span class="data" *ngIf="editAccount">
                        <input type="text" class="form-control short-text first" formControlName="title"
                            placeholder="title" />
                        <span class="no-wrap">
                            <input type="text" class="form-control med-text" formControlName="first"
                                placeholder="first name" />
                            <input type="text" class="form-control middle" formControlName="middle"
                                placeholder="middle" />
                            <input type="text" class="form-control med-text" formControlName="last"
                                placeholder="last name" />
                        </span>
                    </span>
                    <div class="suffix-row" *ngIf="editAccount">
                        <a [routerLink]="[]" class="add-sfx" (click)="toggleSuffix = !toggleSuffix">
                            <span class="far fa-{{toggleSuffix ? 'minus' : 'plus'}} fa-sm fa-fw icon"></span>
                            {{toggleSuffix ? 'hide' : 'add'}} suffix
                        </a>
                        <input type="text" class="form-control short-text suffix" formControlName="suffix"
                            placeholder="suffix" *ngIf="toggleSuffix" />
                    </div>
                    <div class="display" *ngIf="!editAccount">{{fullNameDisplay(user.info?.name, '--')}}</div>
                </div>
                <div class="form-col group-item">
                    <label>Date of Birth</label>
                    <div class="data" *ngIf="editAccount">
                        <select formControlName="month" class="form-select first">
                            <option value="0" disabled selected hidden>Month</option>
                            <option *ngFor="let thisMonth of months; let m = index" [value]="m+1">{{thisMonth}}</option>
                        </select>
                        <select formControlName="day" class="form-select">
                            <option value="0" disabled selected hidden>Day</option>
                            <option *ngFor="let thisDay of getDays(accountForm.value)" [value]="thisDay">{{thisDay}}
                            </option>
                        </select>
                        <input type="number" class="form-control year last" formControlName="year" placeholder="year"
                            min="1900" max="2050" />
                    </div>
                    <div class="display" *ngIf="!editAccount">{{dobDisplay(user?.info?.dateOfBirth)}}</div>
                </div>
            </div>
            <div class="form-row contact-row {{editAccount ? '' : 'display-column-spacing'}}">
                <div class="form-col first-col">
                    <div class="group-item large-text-item">
                        <label>
                            email
                            <span class="required"
                                *ngIf="editAccount && (a['emailAddress'].invalid && (a['emailAddress'].dirty || a['emailAddress'].touched))">{{getErrorText(a['emailAddress'].errors)}}</span>
                        </label>
                        <input type="text" formControlName="emailAddress" class="form-control first large-text"
                            placeholder="email" *ngIf="editAccount" />
                        <div class="display" *ngIf="!editAccount">--</div>
                    </div>
                </div>
                <div class="form-col">
                    <div class="group-item large-text-item">
                        <label>
                            phone number
                        </label>
                        <input type="text" formControlName="phoneNumber" class="form-control first large-text"
                            placeholder="(xxx) xxx-xxxx" *ngIf="editAccount" />
                        <div class="display" *ngIf="!editAccount">--</div>
                    </div>
                </div>
            </div>
            <div class="edit-button" *ngIf="!editAccount">
                <button type="button" class="btn btn-link edit" (click)="editAccount = !editAccount">
                    <span class="fas fa-pencil icon"></span>
                    Edit
                </button>
            </div>
            <div class="form-row submit-form" *ngIf="editAccount">
                <button type="submit" class="btn btn-primary save"
                    [disabled]="isSavingProfile()">
                    <span *ngIf="isSavingProfile()" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!isSavingProfile()" class="">save</span>
                    <span *ngIf="isSavingProfile()" class="">saving...</span>
                </button>
                <input type="button" class="btn btn-outline-dark" value="cancel" (click)="editAccount = !editAccount; isNewUser ? closeDialog() : ''">
            </div>
        </div>
    </form>
</ng-template>

<ng-template #linkedUsersTemplate let-user="user" let-linkedUsers="linkedUsers"
    let-allowAddLinkedUser="allowAddLinkedUser">
    <div class="linked-users" *ngIf="user.linkInfo?.parentUserKey === null && allowAddLinkedUser">
        <h3>Household members</h3>
        <app-loading loadingText="loading household members" alignText="left" *ngIf="workingChildren"></app-loading>

        <div class="users" *ngIf="!workingChildren">
            <ng-container *ngFor="let linkedUser of linkedUsers | childUsers:user.key; index as i">
                <a [routerLink]="[]" class="linked-user" (click)="viewLinkedUser(linkedUser, user)">
                    <img src="{{tempGetChildAvatar(linkedUser, i)}}" title="view {{linkedUser.displayName}} detail" />
                    <div class="linked-user-text">{{linkedUser.displayName}}</div>
                </a>
            </ng-container>
            <!-- <a [routerLink]="[]" class="linked-user add-linked-user" (click)="viewLinkedUser(null, user)"> -->
            <a [routerLink]="[]" class="linked-user add-linked-user" (click)="addLinkedUser(user)">
                <div class="add-icon">
                    <span class="fal fa-plus fa-lg icon"></span>
                </div>
                <span class="add-text">Add a person</span>
            </a>
        </div>

        <!-- LINKED USERS {{user.linkInfo?.linkedCount ?? 0}} -->

    </div>
</ng-template>

<ng-template #addLinkedUserTemplate let-parentUser="parentUser">
    <div class="linked-user-form-container" *ngIf="editHouseholdMember">
        <h2>New Household Member</h2>
        <form class="linked-user-form" [formGroup]="linkedUserForm" (ngSubmit)="saveLinkedUserForm()"
            autocomplete="off">
            <div class="form-row">
                <div class="form-col first-col group-item">
                    <label>name</label>
                    <div class="data no-wrap">
                        <input type="text" class="form-control med-text first" formControlName="first"
                            placeholder="first name" />
                        <!-- <input type="text" class="form-control middle" formControlName="middle" placeholder="middle" /> -->
                        <input type="text" class="form-control long-text" formControlName="last"
                            placeholder="last name" />
                    </div>
                </div>
                <div class="form-col toggle-col">
                    <div class="group-item">
                        <label class="form-check-label toggle-1 {{linkedUserIsMinor ? 'active' : ''}}"
                            for="linkedUserIsMinor">Minor</label>
                        <div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input" id="linkedUserIsMinor"
                                (change)="isMinorToggle($event)">
                            <label class="form-check-label toggle-2 {{!linkedUserIsMinor ? 'active' : ''}}"
                                for="linkedUserIsMinor">Adult</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row contact-row">
                <div class="form-col first-col">
                    <div class="group-item large-text-item">
                        <label>
                            email
                            <span class="required"
                                *ngIf="(b['emailAddress'].invalid && (b['emailAddress'].dirty || b['emailAddress'].touched))">{{getErrorText(b['emailAddress'].errors)}}</span>
                        </label>
                        <input type="text" formControlName="emailAddress" class="form-control first email" placeholder="email" />
                    </div>
                </div>
                <div class="form-col">
                    <div class="group-item large-text-item">
                        <label>
                            phone number
                        </label>
                        <input type="text" formControlName="phoneNumber" class="form-control first phone"
                            placeholder="(xxx) xxx-xxxx" />
                    </div>
                </div>
            </div>
            <div class="form-row submit-form">
                <input type="submit" class="btn btn-primary save" value="save">
                <input type="button" class="btn btn-outline-dark" value="cancel"
                    (click)="editHouseholdMember = !editHouseholdMember">
            </div>
        </form>
    </div>
</ng-template>

<ng-template #parentUserTemplate let-parentUser="parentUser" let-showParentUserInfo="showParentUserInfo">
    <div class="linked-users" *ngIf="showParentUserInfo">
        <h3>Parent User</h3>
        <app-loading loadingText="loading parent user" alignText="left" *ngIf="workingParent"></app-loading>

        <div class="users" *ngIf="!workingParent">
            <a [routerLink]="[]" class="linked-user" (click)="viewLinkedUser(parentUser)">
                <img src="{{tempGetChildAvatar(parentUser, 3)}}" title="view {{parentUser.displayName}} detail" />
                <div class="linked-user-text">{{parentUser.displayName}}</div>
            </a>
        </div>

        <!-- LINKED USERS {{user.linkInfo?.linkedCount ?? 0}} -->

    </div>
</ng-template>

<ng-template #userChallengesTemplate let-user="user">
    <div class="challenges">
        <h2>Milestones</h2>

        <div class="milestones">
            <div class="start">
                <svg class="circle-svg" xmlns="http://www.w3.org/2000/svg">
                    <circle class="circle" cx="9" cy="9" r="7" stroke-width="3" />
                </svg>
            </div>
            <div class="line-row start">
                <div class="left up">
                    <svg class="corner">
                        <path class="path left-up" />
                    </svg>
                </div>
                <div class="middle start"></div>
                <div class="right down">
                    <svg class="corner">
                        <path class="path right-down" />
                    </svg>
                </div>
            </div>

            <ng-container *ngTemplateOutlet="contentRowTemplate; 
                                             context: {align: 'right',
                                                       tokenAmount: '20',
                                                       tokenColor: 'pink',
                                                       tokenTitle: 'Set up your profile on Pummelo',
                                                       tokenSub: 'tickets earned on Mar 12'}"></ng-container>
            <div class="line-row">
                <div class="left down">
                    <svg class="corner">
                        <path class="path left-down" />
                    </svg>
                </div>
                <div class="middle"></div>
                <div class="right up">
                    <svg class="corner">
                        <path class="path right-up" />
                    </svg>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="contentRowTemplate; 
                                             context: {align: '',
                                                       tokenAmount: '10',
                                                       tokenColor: 'blue',
                                                       tokenTitle: 'Install palette expander',
                                                       tokenSub: 'tickets earned on Oct 11'}"></ng-container>
            <div class="line-row">
                <div class="left up">
                    <svg class="corner">
                        <path class="path left-up" />
                    </svg>
                </div>
                <div class="middle"></div>
                <div class="right down">
                    <svg class="corner">
                        <path class="path right-down" />
                    </svg>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="contentRowTemplate; 
                                             context: {align: 'right',
                                                       tokenAmount: '50',
                                                       tokenColor: 'orange',
                                                       tokenTitle: 'Did something else',
                                                       tokenSub: 'tickets earned on Nov 17'}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #contentRowTemplate let-align="align" let-tokenAmount="tokenAmount" let-tokenColor="tokenColor"
    let-tokenTitle="tokenTitle" let-tokenSub="tokenSub">
    <div class="content-row {{align === 'right' ? align : ''}}" (click)="challengeMenuFormmating($event)"
        [matMenuTriggerFor]="menu1" (menuClosed)="menuChallengeClose()">
        <ng-container *ngTemplateOutlet="ticketTemplate; context: {amount: tokenAmount, color: tokenColor}">
        </ng-container>

        <div class="detail">
            <span class="title">{{tokenTitle}}</span>
            <span class="sub">{{tokenAmount}} {{tokenSub}}</span>
        </div>
        <div class="hover-content-row"></div>

        <mat-menu #menu1 class="ticket-menu">
            <ng-container *ngTemplateOutlet="challengeMenuTemplate; context: {menuName: '#menu1'}"></ng-container>
        </mat-menu>
    </div>
</ng-template>

<ng-template #ticketTemplate let-amount="amount" let-color="color">
    <span class="fa-stack fa-2x ticket {{color}}">
        <span class="fas fa-circle fa-stack-2x"></span>
        <span class="fas fa-ticket-alt fa-stack-1x fa-inverse icon"></span>
        <span class="value fa-stack-1x">{{amount}}</span>
        <span class="fa-stack checked" *ngIf="color !== 'disabled'">
            <span class="far fa-check check-icon"></span>
        </span>
    </span>
</ng-template>

<ng-template #challengeMenuTemplate let-menuName="menuName">
    <button mat-menu-item>
        <span class="text">Mark done!</span>
        <img src="assets/images/icon-check-mark.svg" class="icon" title="mark as done" />
    </button>
    <button mat-menu-item>
        <span class="text">Edit</span>
        <img src="assets/images/icon-edit.svg" class="icon" title="Edit" />
    </button>
    <button mat-menu-item>
        <span class="text">Rearrange</span>
        <img src="assets/images/icon-reorder.svg" class="icon" title="Rearrange items" />
    </button>
    <button mat-menu-item>
        <span class="text">Add new</span>
        <img src="assets/images/icon-add.svg" class="icon" title="Add new challenge" />
    </button>
    <button mat-menu-item class="last delete">
        <span class="text">Remove</span>
        <img src="assets/images/icon-delete.svg" class="icon" title="Delete challenge" />
    </button>
</ng-template>