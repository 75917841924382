import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, catchError, map, mergeMap, Observable, of, tap, throwError, timestamp } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant';
import { LocalStorageService } from './local-storage.service';

const ACCESS_TOKEN = 'access_token';
const TENANT = 'tenant';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  endpointAuth = environment.authenticationUrl;
  headersAuth = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.authenticationSubscriptionKey);

  endpointTenant = environment.tenantUrl;
  headersTenant = this.headersAuth;

  public userTenants: Observable<Array<Tenant>>;
  private userTenantsSubject: BehaviorSubject<Array<Tenant>>;

  public currentTenant: Observable<Tenant>;
  private currentTenantSubject: BehaviorSubject<Tenant>;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private jwtHelper: JwtHelperService) {

    this.userTenantsSubject = new BehaviorSubject<Array<Tenant>>(null);
    this.userTenants = this.userTenantsSubject?.asObservable();

    this.currentTenantSubject = new BehaviorSubject<Tenant>(this.localStorageService.getToken(TENANT) ?
      JSON.parse(this.localStorageService.getToken(TENANT)) :
      null);
    this.currentTenant = this.currentTenantSubject?.asObservable();
  }

  updateToken(tenant: Tenant): Observable<any> {
    return this.http.put<any>(`${this.endpointAuth}/token`, { tenantKey: tenant.key, appKey: environment.appKey }, { headers: this.headersAuth }).pipe(
      tap(resp => {
        if (resp && resp.data && resp.data.token) {
          this.localStorageService.saveToken(ACCESS_TOKEN, resp.data.token);  // This is the updated tenant token

          this.setCurrentTenant(tenant);
        }
      })
    );
  }

  setCurrentTenant(tenant: Tenant): void {
    // Set the localStorage
    this.localStorageService.saveToken(TENANT, JSON.stringify(tenant));
    this.currentTenantSubject.next(tenant);
  }

  getCurrentTenant(): Tenant | null {
    return localStorage.getItem(TENANT) ? JSON.parse(localStorage.getItem(TENANT)) : null;
  }

  getUserTenants(): Observable<Array<Tenant>> {
    // TODO:  Talk to Darin about the difference between these two endpoints and which SHOULD be used in this scenario
    return this.http.get<any>(`${this.endpointAuth}/app/${environment.appKey}/user/tenants`, { headers: this.headersAuth }).pipe(
      map(resp => {
        // Note to self:  MAP allows changes the return value
        //                TAP returns the original value, can't change it
        if (resp && resp.data && resp.data.length > 0) {
          this.userTenantsSubject.next(resp.data);
        }
        return resp.data;
      }),
      catchError(err => throwError(() => err))
    );
  }

  createNewTenant(companyName: string, companyType: string): Observable<any> {
    const apiUrl = `${this.endpointTenant}/tenant`;
    const body = {
      name: companyName,
      type: companyType
    };

    return this.http.post<any>(apiUrl, body, { headers: this.headersTenant }).pipe(
      map((resp: any) => {
        if (resp.data) {
          return resp.data;
        }
        return of(resp);
      }),
      catchError(err => throwError(() => err))
    );
  }

  getTenantQrCode(): Observable<string> {
    var tenant: Tenant = this.getCurrentTenant();
    const apiUrl = `${this.endpointTenant}/manage/tenant/${tenant.key}/invite/generate`;

    return this.http.post<any>(apiUrl, null, { headers: this.headersTenant })
    .pipe(
      map((resp: any) => {
        if (resp.data) {
          return resp.data;
        }
        return of(resp);
      }),
      catchError(err => throwError(() => err))
    );
  }

  /**
   *
   *  LOGIC for loading tenants:
   *  Check the current JWT token to see if it is an elevated (TENANT) token by checking to see
   *  if the TENANT claim is present.
   *     - If it IS present, then we simply call getCurrentTenant, write that tenant info to
   *       localstorage, and then return the tenant object.
   *     - If it IS NOT present, then we need to get a list of all user tenants for this user
   *       (getUserTenants method), look in local storage to see if there is a tenant object
   *       present.
   *       -  If TENANT object IS present then we match that tenant to the item returned from
   *          the getUserTenants response and set that tenant as current tenant (tenantToBeSet)
   *       -  If TENANT object IS NOT present, then we select the first result from the getUserTenants
   *          response and set that tenant as the current tenant (tenantToBeSet)
   *       -  Lastly, once tenantToBeSet is set, we call updateToken() to get our elevated (tenant)
   *          token.
   *          - NOTE:  Getting an elevated token is a very slow call, so we try to avoid it if possible.
   *
   * @returns TBD
   */
  setAppTenant(tenants: Array<Tenant>, token: string): Tenant {
    let tenantToBeSet: Tenant;
    //const tokenHasTenant: boolean = this.isClaimPresent(token, 'http://schemas.workscale.com/identity/claims/tenant');
    // TODO:  Getting rid of the line above as it is causing issues when logging out and logging in w/another tenant
    const tokenHasTenant = false;
    const existingTenantKey: string = tokenHasTenant ? this.getClaimValueFromJwt(token, 'http://schemas.workscale.com/identity/claims/tenant') : '';

    if (tenants && tenants.length === 0) {
      // User doesn't belong to a tenant yet
    } else if (tokenHasTenant) {
      // Just need to update tenant object in local storage.  This is probably not necessary, but want to make
      // sure that the data in local storage is the most up-to-date
      if (tenants?.length > 0) {
        tenantToBeSet = tenants.find(c => c.key === existingTenantKey);
      }
    } else  if (!tokenHasTenant && this.localStorageService.getToken(TENANT) && tenants.length > 0) {
      // No tenant found in JWT key, but there is one in localStorage.  Take the tenant key
      // from localStorage tenant object and update the token
      const savedTenant: Tenant = JSON.parse(this.localStorageService.getToken(TENANT));
      if (savedTenant && savedTenant.key.length > 0) {
        tenantToBeSet = savedTenant;
      } else {
        // this should never happen, but is a safety check just in case the savedTenant
        // doesn't have the information needed
        tenantToBeSet = tenants[0];
      }
    } else if (!tokenHasTenant && !this.localStorageService.getToken(TENANT) && tenants.length > 0) {
      // No tenant found in JWT or in local storage, so we take the first
      // item in the list of tenants from getUserTenants and set that as the
      // current tenant
      tenantToBeSet = tenants[0];
    }
    this.setCurrentTenant(tenantToBeSet);

    return tenantToBeSet;
  }

  private isClaimPresent(jwtToken: string, claim: string): boolean {
    const token = this.jwtHelper.decodeToken(jwtToken);

    if (token[claim] && token[claim].length > 0) {
      // Elevated token exists
      return true;
    }
    return false;
  }

  private getClaimValueFromJwt(jwtToken, claim: string): string {
    const token = this.jwtHelper.decodeToken(jwtToken);

    if (token[claim] && token[claim].length > 0) {
      // Elevated token exists
      return token[claim].value;
    }
    return null;
  }

}
