import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/offer/product';
import { Order } from '../models/order/order';
import { OrderStatusUpdate } from '../models/order/order-status-update';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {

  endpointOrder = environment.orderUrl;
  headersOrder = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.orderSubscriptionKey);

  constructor(
    http: HttpClient,
    tenantService: TenantService,
    private localStorageService: LocalStorageService) {
    super(http, tenantService);
  }

  getOrders(
    infoStatusCode: string = '',
    query: string = '',
    continuationToken: string = ''): Observable<Order> {
    let apiUrl = `${this.endpointOrder}/manage/tenant/${this.tenantKey}/app/${this.appKey}/orders`;

    let ampOrQuestion = '?';
    if (infoStatusCode !== '') {
      apiUrl += `${ampOrQuestion}Info.Status=${infoStatusCode}`;
      ampOrQuestion = '&';
    }

    if (query !== '') {
      apiUrl += `${ampOrQuestion}${query}`;
      ampOrQuestion = '&';
    }

    if (continuationToken !== '') {
      apiUrl += `${ampOrQuestion}continuationToken=${continuationToken}`;
      ampOrQuestion = '&';
    }

    return this.executeApi<Order>('get', apiUrl, this.headersOrder);
  }

  updateOrderByKey(orderKey: string, orderStatusUpdate: OrderStatusUpdate): Observable<Order> {
    const apiUrl = `${this.endpointOrder}/manage/tenant/${this.tenantKey}/app/${this.appKey}/order/${orderKey}`;

    //return this.executeApiPatch<Order>(apiUrl, this.headersOrder, orderStatusUpdate);
    return this.executeApiPatch<Order>(apiUrl, this.headersOrder, orderStatusUpdate);
  }

  updateOrderItemsStatusByKey

}
