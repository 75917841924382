<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">
    <div class="product pml-dialog-header-footer">
        <div class="product-header pml-dialog-header">
            <h1>{{newProduct ? 'Add Product Listing' : selectedProduct?.name}}</h1>

            <div class="nav nav-tabs">
                <a [routerLink]="[]" class="nav-item first {{activeTab === GENERAL_TAB ? 'active' : ''}}"
                    (click)="setActiveTab(GENERAL_TAB)">
                    General
                    <span class="fa-solid fa-triangle-exclamation icon" *ngIf="!dataItemsFormValid"></span>
                </a>
                <a [routerLink]="[]" class="nav-item {{activeTab === PHOTOS_TAB ? 'active' : ''}}"
                    (click)="setActiveTab(PHOTOS_TAB)">
                    Photos
                    <span class="fa-solid fa-triangle-exclamation icon" *ngIf="existingImagesLength === 0"></span>
                </a>
                
            </div>
        </div>
        
        <form class="pml-form horiz-layout" [formGroup]="productForm" (ngSubmit)="productForm.valid && saveProductForm()" autocomplete="off">
            <div class="pml-dialog-content">
                <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

                <ng-container *ngIf="activeTab === GENERAL_TAB">
                    <ng-container *ngTemplateOutlet="generalProductTemplate; context: {form: productForm}"></ng-container>
                </ng-container>
            
                <ng-container *ngIf="activeTab === PHOTOS_TAB">
                    <ng-container *ngTemplateOutlet="photosProductTemplate; context: {form: productForm}"></ng-container>
                </ng-container>
            </div>

            <div class="form-row submit-form pml-dialog-actions">
                <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="cancelProductForm()">
                <button type="submit" class="btn btn-primary save"
                    [disabled]="working || !(productForm.valid && productForm.dirty)">
                    <span *ngIf="working" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!working" class="">save & finish</span>
                    <span *ngIf="working" class="">saving...</span>
                </button>
            </div>
        </form>
    </div>
</div>

<ng-template #generalProductTemplate let-form="form">

    <ng-container [formGroup]="form">
        <div class="pml-row">
            <div class="pml-col label">
                <label>
                    Product Name
                    <span class="req" *ngIf="!isContent">*</span>
                </label>
            </div>
            <div class="pml-col input">
                <ng-container *ngIf="!isContent">
                    <span class="required" *ngIf="isInvalid(f['name'])">{{getError(f['name'].errors)}}</span>
                    <input type="textbox" class="form-control" formControlName="name" placeholder="Ex: PureH20 Watter Bottle" value="" />
                    <span class="fa-regular fa-circle-question help"></span>

                    <input type="hidden" formControlName="key" />
                </ng-container>
                <ng-container *ngIf="isContent">
                    <div class="product-value name">{{selectedProduct.name}}</div>
                </ng-container>
            </div>
        </div>
        <div class="pml-row">
            <div class="pml-col label">
                <label>
                    Offer Price ({{getCurrencyCode()}})
                    <span class="req">*</span>
                </label>
            </div>
            <div class="pml-col input sm">
                <ng-container>
                    <span class="required" *ngIf="isInvalid(f['amount'])">{{getError(f['amount'].errors)}}</span>
                    <input type="textbox" class="form-control" formControlName="amount" placeholder="Ex: 200" value="" />
                    <span class="fa-regular fa-circle-question help"></span>
                </ng-container>
                <!-- <ng-container *ngIf="!canEdit">
                    <div class="product-value">{{getAmountDisplay(selectedProduct.amount, selectedProduct.currencyCode)}}</div>
                </ng-container> -->
            </div>
        </div>
        <div class="pml-row">
            <div class="pml-col label">
                <label>
                    Manufacturer UPC
                </label>
            </div>
            <div class="pml-col input sm">
                <ng-container *ngIf="!isFulfillable && !isContent">
                    <input type="textbox" class="form-control" formControlName="manufacturerCode" placeholder="Ex: 123456789012" value="" />
                    <span class="fa-regular fa-circle-question help"></span>
                </ng-container>
                <ng-container *ngIf="isFulfillable || isContent">
                    <div class="product-value">{{selectedProduct.manufacturerCode ?? '--'}}</div>
                </ng-container>
            </div>
        </div>
        <div class="pml-row">
            <div class="pml-col label multi-line">
                <label>
                    Product Description
                </label>
            </div>
            <div class="pml-col input multi-line">
                <ng-container *ngIf="!isContent">
                    <span class="required" *ngIf="isInvalid(f['description'])">{{getError(f['description'].errors)}}</span>
                    <textarea class="form-control" formControlName="description" rows="3" title="description" value=""></textarea>
                    <span class="fa-regular fa-circle-question help"></span>
                </ng-container>
                <ng-container *ngIf="isContent">
                    <div class="product-value description">{{selectedProduct.description ?? '--'}}</div>
                </ng-container>
            </div>
        </div>
        <div class="pml-row">
            <div class="pml-col label">
                <label>
                    Status
                    <span class="req" *ngIf="!isFulfillable">*</span>
                </label>
            </div>
            <div class="pml-col input sm">
                <ng-container *ngIf="!isFulfillable">
                    <span class="required" *ngIf="isInvalid(f['status'])">{{getError(f['status'].errors)}}</span>
                    <select class="form-select" formControlName="status" title="status">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let item of statusEnum | keyvalue" [ngValue]="item.key">
                            {{item.value}}
                        </option>
                    </select>
                    <span class="fa-regular fa-circle-question help"></span>
                </ng-container>
                <ng-container *ngIf="isFulfillable">
                    <div class="product-value">{{selectedProduct.status}}</div>
                </ng-container>
            </div>
        </div>
    </ng-container>

</ng-template>

<ng-template #photosProductTemplate let-form="form">
    <ng-container [formGroup]="form">
        
        <span class="required" *ngIf="f['imageLength'].invalid">{{getError(f['imageLength'].errors)}}</span>
        <input type="hidden" formControlName="imageLength" value="" />
        <div class="images {{selectedProduct?.images?.length === 1 && (isContent || isFulfillable) ? 'single' : ''}}">

            <div class="image" *ngFor="let img of selectedProduct?.images; index as i">
                <img src="{{img.url}}" title="{{selectedProduct.name}} - image {{i + 1}}" />
                
                <div class="image-control-overlay">
                    <span class="fas fa-times-circle fa-2x fa-fw delete" (click)="deleteImage(img, i)" *ngIf="!isContent && !isFulfillable"></span>

                    <div class="baseline-controls">
                        <!-- <div class="left {{i === 0 ? 'disabled' : ''}}">
                            <span class="fa-sharp fa-angle-left fa-lg prev" *ngIf="i > 0"></span>
                        </div> -->
                        <div class="middle">
                            <span class="main" *ngIf="i === 0">Main Image</span>
                        </div>
                        <!-- <div class="right {{i === selectedProduct.images.length - 1 ? 'disabled' : ''}}">
                            <span class="fa-sharp fa-angle-right fa-lg next" *ngIf="i < selectedProduct.images.length - 1"></span>
                        </div> -->
                    </div>
                </div>

                <div class="loading-overlay {{loadingDelete === i ? 'show-loading' : ''}}">
                    <div class="fa-duotone fa-spinner fa-pulse fa-2x icon"></div>
                    <div class="text">deleting...</div>
                </div>
            </div>

            <ng-container *ngIf="!isContent && !isFulfillable">
                <label class="image add-image" *ngFor="let number of remainingAddImageElements; index as i" for="imageUpload-{{i}}">
                    <!-- <input type="file" accept="image/*" id="imageUpload-{{i}}" (change)="addNewImage($event, i)" hidden />
                    <span class="fa-solid fa-circle-plus fa-3x icon"></span> -->
                    <span class="fa-solid fa-circle-plus fa-3x icon" (click)="addImageToProduct(i)"></span>

                    <div class="loading-overlay {{loadingAdd === i ? 'show-loading' : ''}}">
                        <div class="fa-duotone fa-spinner fa-pulse fa-2x icon"></div>
                        <div class="text">uploading...</div>
                    </div>
                </label>
            </ng-container>
        </div>

    </ng-container>
</ng-template>