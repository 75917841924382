import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { combineLatest, lastValueFrom } from 'rxjs';
import { TemplatePlan } from 'src/app/core/models/reward/template-plan';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { Timeframe, timeframeToLabelMapping } from 'src/app/core/enums/time-frame';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TaskInterval, taskIntervalToLabelMapping } from 'src/app/core/enums/task-interval';
import { PlanSaveInfo, PlanSaveType } from 'src/app/core/models/reward/plan-save-info';
import { Badge } from 'src/app/core/models/badge';
import { Image } from 'src/app/core/models/image';
import { SaveInfo } from 'src/app/core/models/save-info';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  BADGE_PATH = 'assets/images/badges/';

  @Input() public selectedPlan: TemplatePlan;
  @Input() public isNewPlan: boolean;
  @Output() planCancelEvent = new EventEmitter();
  @Output() planSaveEvent = new EventEmitter<PlanSaveInfo>();

  templateTests: TemplateTest[] = [];
  templateTasks: TemplateTask[] = [];
  templateLevels: TemplateLevel[] = [];

  planForm: UntypedFormGroup;
  newTestForm: UntypedFormGroup;
  newTaskForm: UntypedFormGroup;
  newLevelForm: UntypedFormGroup;

  showAddTest: boolean;
  showAddTask: boolean;
  showAddLevel: boolean;
  showTestBadges: boolean;
  showTaskBadges: boolean;
  showLevelBadges: boolean;
  showEditPlanTask: number;
  showEditPlanChallenge: number;
  showEditPlanMilestone: number;

  timeframeToLabelMapping: Record<Timeframe, string>;
  timeframeTypes: Array<string>;
  timeframeValues: Array<string | Timeframe>;
  taskIntervalToLabelMapping: Record<TaskInterval, string>;
  taskIntervalTypes: Array<string>;
  taskIntervalValues: Array<string | TaskInterval>;
  selectedTestBadge: Badge;
  selectedTaskBadge: Badge;
  selectedLevelBadge: Badge;

  working: boolean;
  workingResources: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.showAddTest = false;
    this.showAddTask = false;
    this.showAddLevel = false;
    this.showTestBadges = false;
    this.showTaskBadges = false;
    this.showLevelBadges = false;
    this.showEditPlanTask = -1;
    this.showEditPlanChallenge = -1;
    this.showEditPlanMilestone = -1;
    this.working = false;
    this.workingResources = false;
    this.errorMessage = '';

    this.createForms();

    this.setupTimeframeSelection();
    this.setupTaskIntervalSelection();
    this.getTemplatePlanResources();

    // Cute little item to make title reflect user changes
    this.f['planName'].valueChanges.subscribe({
      next: (val: any) => {
        if (this.selectedPlan) {
          this.selectedPlan.name = val === '' ? null : val;
        } else {
          this.selectedPlan = { name: val === '' ? null : val };
        }
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.planForm.controls;
  }

  get t(): { [key: string]: AbstractControl } {
    return this.newTestForm.controls;
  }

  get k(): { [key: string]: AbstractControl } {
    return this.newTaskForm.controls;
  }

  get l(): { [key: string]: AbstractControl } {
    return this.newLevelForm.controls;
  }

  getTemplatePlanResources(): void {
    this.workingResources = true;

    var testService = this.rewardsService.getTemplateTests();
    var taskService = this.rewardsService.getTemplateTasks();

    combineLatest([testService, taskService]).subscribe({
      next: (response: any) => {
        this.templateTests = response[0].rules;
        this.templateTasks = response[1].tasks;

        this.workingResources = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingResources = false;
      }
    });
  }

  setupTimeframeSelection(): void {
    // Items needed to create a dropdown from ENUM values
    this.timeframeToLabelMapping = timeframeToLabelMapping;
    this.timeframeTypes = Object.keys(Timeframe).filter((tf) => !isNaN(Number.parseInt(tf, 10)));
    this.timeframeValues = Object.values(Timeframe).filter(x => typeof x === 'string');
  }

  setupTaskIntervalSelection(): void {
    // Items needed to create a dropdown from ENUM values
    this.taskIntervalToLabelMapping = taskIntervalToLabelMapping;
    this.taskIntervalTypes = Object.keys(TaskInterval).filter((ti) => !isNaN(Number.parseInt(ti, 10)));
    this.taskIntervalValues = Object.values(TaskInterval).filter(x => typeof x === 'string');
  }

  createForms(): void {
    // TODO:  Additional forms for edit/modify
    //        https://stackoverflow.com/questions/51560376/how-to-use-reactive-forms-within-ngfor

    this.planForm = this.fb.group({
      key: [this.selectedPlan?.key ?? ''],
      planName: [this.selectedPlan?.name ?? '', Validators.required],
      isDefault: [this.selectedPlan?.isDefault ?? false]
    });

    this.newTestForm = this.fb.group({
      testRule: [null, Validators.required],
      testTask: [null, Validators.required],
      testTimeframe: [null, Validators.required],
      testTimeframeQuantity: ['', Validators.required],
      testBadgeName: [''],
      testBadgeBg: ['']
    });

    this.newTaskForm = this.fb.group({
      taskTask: [null, Validators.required],
      taskInterval: ['daily', Validators.required],      // hidden field with default value DAILY
      taskOccurrence: [null, Validators.required],
      taskRequiresReview: [false, Validators.required],  // hidden field with default value FALSE
      taskBadgeName: ['']
    });

    this.newLevelForm = this.fb.group({
      levelTask: [null, Validators.required],
      levelInterval: ['Ever'],    // hidden field with default value EVER
      levelOccurrence: ['1'],      // hidden field with default value 1
      levelRequiresReview: [true, Validators.required], // hidden field with default value TRUE
      levelBadgeName: ['']
    });
  }

  /**
   *  Filter out items in dropdown that are already selected
   *  RESOURCES:
   *    - https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
   *    - https://stackoverflow.com/questions/51609657/filter-typescript-array-based-on-another-array
   * @param task select task for the display form
   * @returns filtered array for Template Tasks that a user can choose from
   */
  filterAvailableTasks(filterArray: TemplateTask[], task: TemplateTask): TemplateTask[] {
    // Pull out the tasks that are already in use
    const availableTasks: TemplateTask[] = this.templateTasks
      .filter(x => !filterArray.some(y => y.templateTaskKey === x.key))
      .concat(filterArray.filter(x => !this.templateTasks.some(y => y.key === x.templateTaskKey)))
      .concat(this.templateTasks.filter(x => x.key === task?.templateTaskKey))  // Include the selected task in the dropdown
      .sort((a, b) => a.name.localeCompare(b.name));  // sort by task name

    // availableTasks.forEach(item => console.log(item.name));
    // console.log(`Template tasks count: ${this.templateTasks.length}`);
    // console.log(`Existing tasks count: ${this.existingTasks.length}`);
    // console.log(`Available tasks count: ${availableTasks.length}`);

    return availableTasks;
  }

  filterAvailableTasksByLevel(filterArray: any[], task: any): TemplateTask[] {
    // Pull out the tasks that are already in use
    const availableTasks: TemplateTask[] = this.templateTasks
      .filter(x => !filterArray.some(y => y.templateTaskKey === x.key))
      .concat(filterArray.filter(x => !this.templateTasks.some(y => y.key === x.templateTaskKey)))
      //.concat(filterArray.filter(x => !this.templateTasks.some(y => y.key === x.templateTaskKey)).map(z => {key: z.templateTaskKey, name: item.name}))
      .concat(this.templateTasks.filter(x => x.key === task?.templateTaskKey))  // Include the selected task in the dropdown
      .sort((a, b) => a.name.localeCompare(b.name));  // sort by task name

    return availableTasks;
  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  async addTestToPlan(): Promise<void> {
    const newTest: TemplateTest = {
      templateTaskKey: this.t['testTask'].value,
      ruleKey: this.t['testRule'].value,
      timeframe: this.t['testTimeframe'].value,
      timeframeQuantity: this.t['testTimeframeQuantity'].value

      // Temp code
      ,
      task: this.templateTasks.filter(t => t.key === this.t['testTask'].value)[0].name,
      name: this.templateTests.filter(t => t.key === this.t['testRule'].value)[0].name
    };

    if (this.selectedTestBadge) {
      const testImage = await this.updatePlanSectionWithBadgeInfo(this.selectedTestBadge)
      newTest.image = testImage ?? null;
      newTest.imageUrl = this.BADGE_PATH + this.selectedTestBadge.fileName;
      if (this.selectedTestBadge && this.selectedTestBadge.color) {
        newTest.additionalInfo = { 'badgeColor': this.selectedTestBadge.color };
      }
    }

    if (!this.selectedPlan) {
      this.selectedPlan = {};
    }

    if (!this.selectedPlan.tests) {
      this.selectedPlan.tests = [];
    }

    //this.selectedPlan.tests.push(newTest);
    this.selectedPlan.tests.unshift(newTest);
    this.planForm.markAsDirty();
    this.showAddTest = !this.showAddTest;  // Hide the new test flyout

    // Reset the form elements
    this.newTestForm.reset();
    this.selectedTestBadge = null;
  }

  async addTaskToPlan(): Promise<void> {
    const newTask: TemplateTask = {
      templateTaskKey: this.k['taskTask'].value,
      interval: this.k['taskInterval'].value,
      occurrence: this.k['taskOccurrence'].value,
      requiresReview: this.k['taskRequiresReview'].value

      // Temp code
      ,
      name: this.templateTasks.filter(t => t.key === this.k['taskTask'].value)[0]?.name
    };

    if (this.selectedTaskBadge) {
      const taskImage = await this.updatePlanSectionWithBadgeInfo(this.selectedTaskBadge)
      newTask.image = taskImage ?? null;
      //newTask.imageUrl = this.BADGE_PATH + this.selectedTaskBadge.fileName;  // Adding this causes an error
    }

    if (!this.selectedPlan) {
      this.selectedPlan = {};
    }

    if (!this.selectedPlan.tasks) {
      this.selectedPlan.tasks = [];
    }

    this.selectedPlan.tasks.unshift(newTask);
    this.planForm.markAsDirty();
    this.showAddTask = !this.showAddTask;  // Hide the new task flyout

    // Reset the form elements
    this.resetTaskForm();
    this.selectedTaskBadge = null;
  }

  async addLevelToPlan(): Promise<void> {
    const newLevel: TemplateLevel = {
      templateTaskKey: this.l['levelTask'].value,
      interval: this.l['levelInterval'].value,
      occurrence: this.l['levelOccurrence'].value,
      requiresReview: this.l['levelRequiresReview'].value

      // Temp code
      ,
      name: this.templateTasks.filter(t => t.key === this.l['levelTask'].value)[0]?.name
    };

    if (this.selectedLevelBadge) {
      const levelImage = await this.updatePlanSectionWithBadgeInfo(this.selectedLevelBadge);
      newLevel.image = levelImage ?? null;
      newLevel.imageUrl = this.BADGE_PATH + this.selectedLevelBadge.fileName;
      if (this.selectedLevelBadge && this.selectedLevelBadge.color) {
        newLevel.additionalInfo = { 'badgeColor': this.selectedLevelBadge.color };
      }
    }

    if (!this.selectedPlan) {
      this.selectedPlan = {};
    }

    if (!this.selectedPlan.levels) {
      this.selectedPlan.levels = [];
    }

    this.selectedPlan.levels.unshift(newLevel);
    this.planForm.markAsDirty();
    this.showAddLevel = !this.showAddLevel;  // Hide the new level flyout

    // Reset the form elements
    this.resetLevelForm();
    this.selectedLevelBadge = null;

    // TODO:  Talk to Darin/Justin:  Should I auto save here or make the user SAVE changes????
  }

  removeTestFromPlan(testToRemove: TemplateTest): void {
    if (this.selectedPlan.tests.findIndex(t => t.key === testToRemove.key) >= 0) {
      const index = this.selectedPlan.tests.findIndex(t => t.key === testToRemove.key);
      if (index > -1) {
        this.selectedPlan.tests[index].deleted = true;
        this.selectedPlan.tests[index].entityOperation = 3;
      }

      this.planForm.markAsDirty();
    }

    // Call the API to save the plan
    this.savePlan(this.selectedPlan);
  }

  removeTaskFromPlan(taskToRemove: TemplateTask): void {
    if (this.selectedPlan.tasks.findIndex(t => t.key === taskToRemove.key) >= 0) {
      const index = this.selectedPlan.tasks.findIndex(t => t.key === taskToRemove.key);
      if (index > -1) {
        this.selectedPlan.tasks[index].deleted = true;
        this.selectedPlan.tasks[index].entityOperation = 3;
      }

      this.planForm.markAsDirty();
    }

    // Call the API to save the plan
    this.savePlan(this.selectedPlan);
    // TODO:  This closes the plan dialog, need a different action for these
    //        Also, this doesn't appear to be working

  }

  removeLevelFromPlan(levelToRemove: TemplateLevel): void {
    if (this.selectedPlan.levels.findIndex(t => t.key === levelToRemove.key) >= 0) {
      const index = this.selectedPlan.tests.findIndex(t => t.key === levelToRemove.key);
      if (index > -1) {
        this.selectedPlan.levels[index].deleted = true;
        this.selectedPlan.levels[index].entityOperation = 3;
      }

      this.planForm.markAsDirty();
    }

    // Call the API to save the plan
    this.savePlan(this.selectedPlan);
  }

  updateItemWithBadge(badge: Badge, formBadgeName: AbstractControl, section: string): void {
    this.updateSelectedBadge(section, badge);
    formBadgeName.setValue(badge.name);
    this.toggleShowBadges(section);
  }

  updateSelectedBadge(section: string, badge: Badge): void {
    switch (section) {
      case 'tests': {
        this.selectedTestBadge = badge;
        break;
      }
      case 'tasks': {
        this.selectedTaskBadge = badge;
        break;
      }
      case 'levels': {
        this.selectedLevelBadge = badge;
        break;
      }
    }
  }

  toggleShowBadges(badgeSectionName: string): void {
    switch (badgeSectionName) {
      case 'tests': {
        this.showTestBadges = !this.showTestBadges;
        break;
      }
      case 'tasks': {
        this.showTaskBadges = !this.showTaskBadges;
        break;
      }
      case 'levels': {
        this.showLevelBadges = !this.showLevelBadges;
        break;
      }
    }
  }

  isSaving(): boolean {
    if (this.working === true) {
      return true;
    } else {
      return false;
    }
  }

  savePlanForm(): void {
    this.working = true;

    if (!this.selectedPlan) {
      this.selectedPlan = {};
    }

    // Update the name & default values to the plan
    this.selectedPlan.name = this.f['planName'].value;
    this.selectedPlan.isDefault = this.f['isDefault'].value;

    for (let taskIndex = this.selectedPlan.tasks.length - 1; taskIndex >= 0; taskIndex--) {
      const task = this.selectedPlan.tasks[taskIndex];

      if (!task || !task.deleted) {
        continue;
      }

      if (!this.isNewPlan) {
        this.selectedPlan.tasks[taskIndex].entityOperation = 3;
      } else {
        this.selectedPlan.tasks.splice(taskIndex, 1);
      }
    }

    for (let testIndex = this.selectedPlan.tests.length - 1; testIndex >= 0; testIndex--) {
      const test = this.selectedPlan.tests[testIndex];

      if (!test) {
        continue;
      }

      if (!this.isNewPlan) {
        if (test.deleted) {
          test.entityOperation = 3;
        }

        // This is logic to add a new image
        if (test.image && test.image.data && test.image.data.length > 0 && test.imageUrl && test.imageUrl.length > 0) {
          // remove the imageUrl
          // TODO:  Not sure why this is here
          //delete test.imageUrl;
        }
      } else if (test.deleted) {
        this.selectedPlan.tests.splice(testIndex, 1);
      }
    }

    for (let levelIndex = this.selectedPlan.levels.length - 1; levelIndex >= 0; levelIndex--) {
      const level = this.selectedPlan.levels[levelIndex];

      if (!level || !level.deleted) {
        continue;
      }

      if (!this.isNewPlan) {
        this.selectedPlan.levels[levelIndex].entityOperation = 3;
      } else {
        this.selectedPlan.levels.splice(levelIndex, 1);
      }
    }

    // Call the API to save the plan
    this.savePlan(this.selectedPlan);

  }

  savePlan(planToSave: TemplatePlan): void {
    if (this.isNewPlan) {
      // API POST (new template plan)
      this.rewardsService.createNewTemplatePlan(planToSave).subscribe({
        next: (response: TemplatePlan | any) => {
          this.selectedPlan = response;

          this.planSaveEvent.emit({ plan: this.selectedPlan, saveType: PlanSaveType.newPlan })
          this.working = false;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    } else {
      //console.log(JSON.stringify(this.selectedPlan));
      // API PATCH (update template plan)
      this.rewardsService.updateTemplatePlan(planToSave).subscribe({
        next: (response: TemplatePlan | any) => {
          this.selectedPlan = response;

          this.planSaveEvent.emit({ plan: this.selectedPlan, saveType: PlanSaveType.updatedPlan })
          this.working = false;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    }
  }

  cancelPlanForm(): void {
    this.planCancelEvent.emit();
  }

  resetTaskForm(): void {
    this.newTaskForm.reset();
    // Set default/hidden values
    this.k['taskInterval'].setValue('daily');
    this.k['taskRequiresReview'].setValue('false');
  }

  resetLevelForm(): void {
    this.newLevelForm.reset();
    // Set default/hidden values
    this.l['levelInterval'].setValue('Ever');
    this.l['levelOccurrence'].setValue('1');
    this.l['levelRequiresReview'].setValue('true');
  }

  async updatePlanSectionWithBadgeInfo(badge: Badge): Promise<Image> {
    let imageToUpdate: Image;
    const imageBase64String = await this.getBase64FromBadgeImage(badge);
    if (imageBase64String !== '') {
      imageToUpdate = {
        mimeType: 'image/png',
        data: imageBase64String
      };
    }
    return imageToUpdate;
  }

  async getBase64FromBadgeImage(badge: Badge): Promise<string | any> {
    const imageResponse = await fetch(this.BADGE_PATH + badge.fileName);
    if (imageResponse.status === 200) {
      const imageBlob = await imageResponse.blob();

      const resultBase64 = await new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          const result: string = fileReader.result.toString().length > 0 ? fileReader.result.toString().replace('data:image/png;base64,', '') : '';
          resolve(result);
        };
        fileReader.onerror = () => resolve('');
        fileReader.readAsDataURL(imageBlob);
      });

      return resultBase64;
    } else {
      return new Promise((resolve) => { resolve(''); });
    }
  }

  saveEditTask(input: SaveInfo<TemplateTask>): void {
    // Update task on selectedPlan
    // THIS CODE ISN'T NEEDED, selectedPlan is updated from the child component
    //this.selectedPlan.tasks.filter(t => t.key === input.result.key)[0] = input.result;

    // Mark the form as dirty (so save button is enabled) and hide the edit form
    // Also alerts user that plan needs to be saved
    this.planForm.markAsDirty();
    this.showEditPlanTask = -1;


    // Show line item has been modified
  }

  closeEditTask(): void {
    this.showEditPlanTask = -1;
  }

  saveEditChallenge(input: any): void {
    // Mark the form as dirty (so save button is enabled) and hide the edit form
    // Also alerts user that plan needs to be saved
    this.planForm.markAsDirty();
    this.showEditPlanChallenge = -1;

    // TODO:  Show line item has been modified
  }

  closeEditChallenge(): void {
    this.showEditPlanChallenge = -1;
  }

  saveEditMilestone(input: any): void {
    // Mark the form as dirty (so save button is enabled) and hide the edit form
    // Also alerts user that plan needs to be saved
    this.planForm.markAsDirty();
    this.showEditPlanMilestone = -1;

    // TODO:  Show line item has been modified
  }

  closeEditMilestone(): void {
    this.showEditPlanMilestone = -1;
  }

}
