import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from 'src/app/core/models/app-user';
import { Name } from 'src/app/core/models/name';
import { Tenant } from 'src/app/core/models/tenant';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../_shared/dialog-user/dialog-user.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {

  @ViewChild('tenantDropdown') tenantDropdown: ElementRef;
  AVATAR_PATH = 'assets/images/avatars/';

  user: AppUser;
  tenant: Tenant;
  userTenants: Tenant[];

  avatarBgColor: string;
  avatarImg: string;
  defaultAvatar: boolean;

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private router: Router,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;

    // Default avatar values
    this.avatarBgColor = '#fff';
    this.avatarImg = this.AVATAR_PATH + 'default.png';
    this.defaultAvatar = true;

    this.tenant = this.tenantService.getCurrentTenant();

    if (this.tenant) {
      this.userService.user.subscribe((user: AppUser) => {
        this.user = user;

        // If user is null, then pull from the API
        if (!this.user) {
          this.getAppUser();
        }

      });

      this.getUserTenants();

      this.checkForAvatar();

      this.userService.currentUserAvatar.subscribe((img: string) => {
        if (img !== '') {
          this.avatarImg = this.AVATAR_PATH + img;
        }
      });

      this.userService.currentUserAvatarBg.subscribe((color: string) => {
        if (color !== '') {
          this.avatarBgColor = color;
        }
      });

    }
  }

  ngDoCheck(): void {
    const tenantWidth = this.tenantDropdown?.nativeElement.offsetWidth;

    var elems = document.getElementsByClassName('mat-menu-panel') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < elems.length; i++) {
      elems[i].style.minWidth = (tenantWidth + 10) + 'px';
    }
  }

  getAppUser(): void {
    this.working = true;

    this.userService.getAppUser().subscribe({
      next: (response: any) => {
        this.user = response;

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  getUserTenants(): void {
    this.tenantService.userTenants.subscribe({
      next: (response: Tenant[]) => {
        if (response !== null) {
          this.userTenants = response.filter(t => t.roleType !== 'Customer');

          // if (this.userTenants?.length === 1) {
          //   const newTenant: Tenant = { ...this.userTenants[0] };
          //   newTenant.key = newTenant.key.replace('RyeQ--', 'RyanL-');
          //   newTenant.name = 'Ryan\'s Dental Practice';
          //   this.userTenants.push(newTenant);
          // }
        } else {
          this.getUserTenantsFromApi();
        }
      }
    });
  }

  usernameDisplay(user: AppUser): string {
    const fullName = this.buildNameFromUser(user.info?.name);

    if (user.displayName) {
      return user.displayName;
    } else if (fullName) {
      return fullName;
    } else {
      return user.username;
    }
  }

  buildNameFromUser(name: Name): string {
    if (!name) {
      return null;
    }

    let display: string = name.title ?? '';
    display += name.first ? display.length > 0 ? ` ${name.first}` : name.first : '';
    display += name.middle ? display.length > 0 ? ` ${name.middle}` : name.middle : '';
    display += name.last ? display.length > 0 ? ` ${name.last}` : name.last : '';
    display += name.suffix ? display.length > 0 ? ` ${name.suffix}` : name.suffix : '';
    return display;
  }

  getUserTenantsFromApi(): void {
    this.tenantService.getUserTenants().subscribe({
      next: (response: Tenant[]) => {
        this.userTenants = response;

        // if (this.userTenants.length === 1) {
        //   const newTenant: Tenant = { ...this.userTenants[0] };
        //   newTenant.key = newTenant.key.replace('RyeQ--', 'RyanL-');
        //   newTenant.name = 'Ryan\'s Dental Practice';
        //   this.userTenants.push(newTenant);
        // }
      }
    });
  }

  checkForAvatar(): void {
    if (this.user?.info?.additionalInfo) {
      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = this.AVATAR_PATH + this.user.info.additionalInfo['avatar'];
        this.defaultAvatar = false;
      }

      if (this.user.info.additionalInfo['avatarColor']) {
        this.avatarBgColor = this.user.info.additionalInfo['avatarColor'];
      }
    }
  }

  changeTenant(newTenant: Tenant, e: Event): void {
    if (newTenant.key !== this.tenant.key) {
      this.working = true;

      this.tenantService.updateToken(newTenant).subscribe({
        next: (response: any) => {
          // If valid response, then redirect
          this.tenant = newTenant;
          // Reload the page which will pull in current page data for new tenant
          window.location.reload();

          this.working = false;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    } else {
      return;
    }
  }

  logout(): void {
    // log the user out
    this.authService.logout();
  }

}
