import { DatePipe } from '@angular/common';
import { ParseErrorLevel } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { Timeframe, timeframeToLabelMapping } from 'src/app/core/enums/time-frame';
import { AppUser } from 'src/app/core/models/app-user';
import { Badge } from 'src/app/core/models/badge';
import { Image } from 'src/app/core/models/image';
import { PlanItemRequest } from 'src/app/core/models/reward/plan-item-request';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-user-challenge',
  templateUrl: './user-challenge.component.html',
  styleUrls: ['./user-challenge.component.scss']
})
export class UserChallengeComponent implements OnInit {

  BADGE_PATH = 'assets/images/badges/';

  savingChallenge: number;
  errorMessage: string;
  errorDetail: WsApiError;

  templateTests: TemplateTest[] = [];
  templateTasks: TemplateTask[] = [];
  selectedChallengeBadge: Badge;

  newChallengeForm: UntypedFormGroup;
  showAddChallenge: boolean;
  showChallengeBadges: boolean;
  workingResources: boolean;
  savingAddChallenge: boolean;

  timeframeToLabelMapping: Record<Timeframe, string>;
  timeframeTypes: Array<string>;
  timeframeValues: Array<string | Timeframe>;

  planBgColors: string[];

  @Input() public user: AppUser;
  @Input() public userPlan: UserPlanResponse;
  @Input() public working: boolean;
  @Input() public childComponentTemplateTasks: TemplateTask[];
  @Input() public childComponentTemplateTests: TemplateTest[];
  @Output() updateParentTemplateTasks = new EventEmitter<TemplateTask[]>();
  @Output() updateParentTemplateTests = new EventEmitter<TemplateTest[]>();
  @Output() updateParentPlan = new EventEmitter<UserPlanResponse>();

  constructor(
    private rewardService: RewardsService,
    private datePipe: DatePipe,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.savingChallenge = -1;
    this.workingResources = false;
    this.savingAddChallenge = false;
    this.showAddChallenge = false;
    this.showChallengeBadges = false;

    this.planBgColors = AVATAR_COLORS;

    this.createForm();
    this.setupTimeframeSelection();

    if (!this.childComponentTemplateTasks && !this.childComponentTemplateTests) {
      this.getTemplatePlanResources();
    } else {
      this.templateTasks = this.childComponentTemplateTasks;
      this.templateTests = this.childComponentTemplateTests;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newChallengeForm.controls;
  }

  getTemplatePlanResources(): void {
    this.workingResources = true;

    var testService = this.rewardService.getTemplateTests();
    var taskService = this.rewardService.getTemplateTasks();

    combineLatest([testService, taskService]).subscribe({
      next: (response: any) => {
        this.templateTests = response[0].rules;
        this.templateTasks = response[1].tasks;

        this.updateParentTemplateTasks.emit(this.templateTasks);
        this.updateParentTemplateTests.emit(this.templateTests);

        this.workingResources = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingResources = false;
      }
    });
  }

  createForm(): void {
    this.newChallengeForm = this.fb.group({
      testRule: [null, Validators.required],
      testTask: [null, Validators.required],
      testTimeframe: [null, Validators.required],
      testTimeframeQuantity: ['', Validators.required],
      testBadgeName: ['']
    });
  }

  setupTimeframeSelection(): void {
    // Items needed to create a dropdown from ENUM values
    this.timeframeToLabelMapping = timeframeToLabelMapping;
    this.timeframeTypes = Object.keys(Timeframe).filter((tf) => !isNaN(Number.parseInt(tf, 10)));
    this.timeframeValues = Object.values(Timeframe).filter(x => typeof x === 'string');
  }

  getAlternateName(user: AppUser): string {
    // Note:  won't pass unit test without pulling this into a method.  Not sure why.
    if (user?.displayName && user.displayName.length > 0) {
      return user.displayName;
    } else {
      return 'this user';
    }
  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  deleteUserChallenge(userKey: string, testKeyToDelete: string, e: Event, i: number): void {
    this.savingChallenge = i;

    if (this.userPlan.tests.findIndex(t => t.key === testKeyToDelete) >= 0) {
      const index = this.userPlan.tests.findIndex(t => t.key === testKeyToDelete);
      if (index > -1) {
        const testToDelete: TemplateTest = {
          entityOperation: 3,
          ruleKey: this.userPlan.tests[index].ruleKey ?? this.userPlan.tests[index].key,
          key: this.userPlan.tests[index].key,
          templateTaskKey: this.userPlan.tests[index].templateTaskKey,
        }

        const planItemRequest: PlanItemRequest = {
          tests: []
        };
        planItemRequest.tests.push(testToDelete);

        this.saveChallenge(planItemRequest);
      }
    }
  }

  async addChallengeToPlan(): Promise<void> {
    this.savingAddChallenge = true;
    var tempImageUrl = '';

    const newTest: TemplateTest = {
      templateTaskKey: this.f['testTask'].value,
      ruleKey: this.f['testRule'].value,
      timeframe: this.f['testTimeframe'].value,
      timeframeQuantity: this.f['testTimeframeQuantity'].value

      // Temp code
      ,
      task: this.templateTasks.filter(t => t.key === this.f['testTask'].value)[0].name,
      name: this.templateTests.filter(t => t.key === this.f['testRule'].value)[0].name
    };

    if (this.selectedChallengeBadge) {
      const testImage = await this.updatePlanSectionWithBadgeInfo(this.selectedChallengeBadge)
      newTest.image = testImage ?? null;
      // newTest.imageUrl = this.BADGE_PATH + this.selectedChallengeBadge.fileName;
      tempImageUrl = this.BADGE_PATH + this.selectedChallengeBadge.fileName;
    }

    const newPlanItemRequest: PlanItemRequest = {
      tests: []
    };
    newPlanItemRequest.tests.push(newTest);

    this.saveChallenge(newPlanItemRequest);
  }

  saveChallenge(planToSave: PlanItemRequest): void {
    const taskPlanDate: string = this.datePipe.transform(new Date(), 'MM-dd-yyyy');  // Plan date (for challenges)

    this.rewardService.updateUserPlanWithDetailResponse(this.user.key, planToSave, taskPlanDate, taskPlanDate).subscribe({
      next: (response: UserPlanResponse | any) => {
        this.initializeTasksAndLevels(response);

        this.userPlan = response;
        this.updateParentPlan.emit(this.userPlan);

        // this.setBgColors(this.userPlan.tests, this.planBgColors, 5);

        this.savingAddChallenge = false;
        this.showAddChallenge = false;
        this.savingChallenge = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingAddChallenge = false;
        this.savingChallenge = -1;
      }
    });
  }

  // TODO:  Extract this to a helper file
  setBgColors(
    planItems: TemplateTask[] | TemplateTest[] | TemplateLevel[],
    planBgColors: string[],
    i: number): void {
    planItems?.forEach((item: TemplateTask | TemplateTest | TemplateLevel) => {
      item.imageBgColor = planBgColors[i];
      i++;
      if (i === planBgColors.length) {
        i = 0;
      }
    });
  }

  initializeTasksAndLevels(plan: UserPlanResponse): void {
    if (!plan) {
      return;
    }

    plan.tasks.forEach((task: TemplateTask) => {
      task.templateTaskKey = task.key;
    })

    plan.levels.forEach((level: TemplateLevel) => {
      level.templateTaskKey = level.key;
    })

    plan.tests.forEach((test: TemplateTest) => {
      test.ruleKey = test.key;
    })

  }

  updateItemWithBadge(badge: Badge, formItem: AbstractControl, section: string): void {
    this.selectedChallengeBadge = badge;
    formItem.setValue(badge.name);
    this.showChallengeBadges = false;
  }

  async updatePlanSectionWithBadgeInfo(badge: Badge): Promise<Image> {
    let imageToUpdate: Image;
    const imageBase64String = await this.getBase64FromBadgeImage(badge);
    if (imageBase64String !== '') {
      imageToUpdate = {
        mimeType: 'image/png',
        data: imageBase64String
      };
    }
    return imageToUpdate;
  }

  async getBase64FromBadgeImage(badge: Badge): Promise<string | any> {
    const imageResponse = await fetch(this.BADGE_PATH + badge.fileName);
    if (imageResponse.status === 200) {
      const imageBlob = await imageResponse.blob();

      const resultBase64 = await new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          const result: string = fileReader.result.toString().length > 0 ? fileReader.result.toString().replace('data:image/png;base64,', '') : '';
          resolve(result);
        };
        fileReader.onerror = () => resolve('');
        fileReader.readAsDataURL(imageBlob);
      });

      return resultBase64;
    } else {
      return new Promise((resolve) => { resolve(''); });
    }
  }

}
