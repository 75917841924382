export enum Timeframe {
    Day,
    Week,
    Month,
    Year
}

export const timeframeToLabelMapping: Record<Timeframe, string> = {
    [Timeframe.Day]: 'Daily',
    [Timeframe.Week]: 'Weekly',
    [Timeframe.Month]: 'Monthly',
    [Timeframe.Year]: 'Yearly'
};
