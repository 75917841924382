<div class="container-fluid register">

    <app-registration-header stepNumber="4"></app-registration-header>

    <div class="row">
        <div class="col welcome-col">
            Almost there! Please verify your preferred plan. <span class="not-sure">Not sure
                what to pick?</span> We recommend you start with the <span class="standard">Standard</span>
            plan. You can always change plans later.
        </div>
    </div>
    <div class="row">
        <div class="col form-col">

            <h1>Confirm your plan</h1>

            <div class="plans">

                <div class="plan {{i === 0 ? 'first' : ''}}" *ngFor="let plan of appPlans; index as i">
                    <div class="suggested-plan" *ngIf="plan.name.toLocaleLowerCase().includes('standard')">
                        <div class="ribbon">
                            <div class="inner-ribbon-border">
                                <span class="fa-solid fa-star icon"></span>
                            </div>
                        </div>
                    </div>
                    <div class="plan-header" style="--header-color: {{getPlanBgColor(plan.headerBackgroundColor)}}">
                        <h2>{{plan.name}}</h2>
                        <h3>${{getPlanCost(plan.products)}}/month</h3>
                        <div class="header-bottom-curve">
                            <div class="circle"></div>
                        </div>
                    </div>
                    <div class="plan-body">
                        <div class="plan-description">
                            {{plan.descriptionText}}
                        </div>
                        <div class="features">
                            <div class="feature">
                                <span class="fa-solid fa-user-group fa-fw fa-lg icon"></span>
                                <span class="description">Unlimited customers</span>
                            </div>
                            <div class="feature">
                                <span class="fa-solid fa-badge-check fa-fw fa-lg icon"></span>
                                <span class="description">Unlimited tasks</span>
                            </div>
                            <div class="feature">
                                <span class="fa-solid fa-gamepad-modern fa-fw fa-lg icon"></span>
                                <span class="description">Unlimited rewards</span>
                            </div>

                            <div class="feature" *ngIf="planHasCode(plan.products, 'NOTIFICATION.PUSH:ENABLED')">
                                <span class="fa-solid fa-bell fa-fw fa-lg icon"></span>
                                <span class="description">Push notifications</span>
                            </div>

                            <ng-container *ngFor="let feature of plan.products">
                                <ng-container *ngIf="feature.code === 'AUTHENTICATION.TENANT_USER:LIMIT'">
                                    <div class="feature">
                                        <span class="fa-solid fa-key fa-fw fa-lg icon"></span>
                                        <span class="description">Accounts for up to {{feature.value}} staff</span>
                                    </div>
                                    <div class="feature">
                                        <span class="fa-solid fa-circle-plus fa-fw fa-lg icon"></span>
                                        <span class="description">${{feature.amountPerOverage}}/mo per additional staff</span>
                                    </div>
                                </ng-container>

                                <!-- <div class="feature" *ngIf="feature.code === 'OFFER.STORE_FRONT:ENABLED'">
                                    <span class="fa-solid fa-check fa-fw fa-lg icon"></span>
                                    <span class="description">{{feature.code}}</span>
                                </div> -->
                            </ng-container>

                            <div class="feature">
                                <span class="fa-solid fa-envelope-open fa-fw fa-lg icon"></span>
                                <span class="description">Email support</span>
                            </div>

                            <div class="feature" *ngIf="plan.name.toLocaleLowerCase().includes('premium')">
                                <span class="fa-solid fa-phone fa-fw fa-lg icon"></span>
                                <span class="description">Phone support</span>
                            </div>

                            <div class="feature" *ngIf="planHasCode(plan.products, 'OFFER.STORE_FRONT:ENABLED')">
                                <span class="fa-solid fa-store fa-fw fa-lg icon"></span>
                                <span class="description">In-app storefront</span>
                            </div>
                            
                        </div>
                    </div>
                    <div class="plan-footer">
                        <button type="button" class="btn btn-primary" (click)="selectPlan(plan.key, i)"
                            style="--plan-button-color: {{getPlanBgColor(plan.headerBackgroundColor)}}"
                            [disabled]="saving === i">
                            <span *ngIf="saving !== i">Select plan</span>
                            <span *ngIf="saving === i" class="fa-regular fa-spinner fa-pulse spinner"></span>
                            <span *ngIf="saving === i">Adding plan...</span>
                        </button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>