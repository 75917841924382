import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { AppUser } from 'src/app/core/models/app-user';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplatePlan } from 'src/app/core/models/reward/template-plan';
import { TemplatePlansResponse } from 'src/app/core/models/reward/template-plans-response';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-user-plan-add-to-existing',
  templateUrl: './user-plan-add-to-existing.component.html',
  styleUrls: ['./user-plan-add-to-existing.component.scss']
})
export class UserPlanAddToExistingComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  plans: TemplatePlan[] = [];
  saving: boolean;
  showPlanDetails: number;
  planBgColors: string[];

  @Input() public user: AppUser;
  @Input() public templatePlanKeys: string[];
  @Output() hasAvailablePlans = new EventEmitter<boolean>();
  @Output() addPlanToUserPlan = new EventEmitter<UserPlanResponse>();

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.showPlanDetails = -1;

    this.planBgColors = AVATAR_COLORS;
    this.getAllPlans();
  }

  getAllPlans(): void {
    this.working = true;

    this.rewardsService.getTemplatePlans().subscribe({
      next: (response: TemplatePlansResponse | any) => {
        this.plans = response?.items;

        this.plans.forEach((plan: TemplatePlan) => {
          // Set task, test, & milestone bg colors
          this.setBgColors(plan.tasks, this.planBgColors, 8);
          this.setBgColors(plan.tests, this.planBgColors, 4);
          this.setBgColors(plan.levels, this.planBgColors, 6);

          plan.isSelected = this.templatePlanKeys.find(x => x == plan.key) ? true : false;

          // The difference between is selected and isImmutableSelected is for a PATCH action,
          // when a user wants to add a template to a user plan.  The user can select and unselect
          // a plan that is not immutable.  If the plan is immutable, the user can't unselect it.
          plan.isImmutableSelected = this.templatePlanKeys.find(x => x == plan.key) ? true : false;
        });

        // If there are available plans, emit event to parent
        this.hasAvailablePlans.emit(this.plans.length > 0);

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  setBgColors(
    planItems: TemplateTask[] | TemplateTest[] | TemplateLevel[],
    planBgColors: string[],
    i: number): void {
    planItems?.forEach((item: TemplateTask | TemplateTest | TemplateLevel) => {
      item.imageBgColor = planBgColors[i];
      i++;
      if (i === planBgColors.length) {
        i = 0;
      }
    });
  }

  updatePlanIsSelected(plan: TemplatePlan, e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    plan.isSelected = currentCheckbox.checked;
  }

  addTemplateToUserInPlan(userKey: string, plans: TemplatePlan[]): void {
    this.saving = true;

    const selectedPlanKeys = plans.filter((plan: TemplatePlan) => plan.isSelected && !plan.isImmutableSelected).map((plan: TemplatePlan) => plan.key);
    //planKeys.forEach((key: string) => console.log(`planKey: ${key}`));

    this.rewardsService.updateUserPlanByTemplateKeys(userKey, selectedPlanKeys).subscribe({
      next: (response: UserPlanResponse | any) => {
        // Call back to parent control and update plan
        this.addPlanToUserPlan.emit(response);

        this.templatePlanKeys.push(...selectedPlanKeys);
        this.plans.forEach((plan: TemplatePlan) => {
          plan.isImmutableSelected = this.templatePlanKeys.find(x => x == plan.key) ? true : false
          plan.isSelected = this.templatePlanKeys.find(x => x == plan.key) ? true : false;
        });

        this.saving = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.saving = false;
      }
    });
  }

}
