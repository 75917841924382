import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { AppUser } from 'src/app/core/models/app-user';
import { Tenant } from 'src/app/core/models/tenant';
import { TenantAppSettings } from 'src/app/core/models/tenant-app-settings';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['../login/forgot-password/forgot-password.component.scss', './complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  completeRegistrationForm: UntypedFormGroup;
  inviteForm: UntypedFormGroup;
  inviteCode: string;
  inviteCodePresent: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private tenantService: TenantService,
    private offerService: OfferService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;

    // Get an anon token for use when saving
    this.getAnonToken();

    this.inviteCode = this.route.snapshot.paramMap.get('inviteCode');
    this.inviteCodePresent = this.inviteCode !== null && this.inviteCode !== undefined ? true : false;

    if (!this.inviteCodePresent) {
      this.errorMessage = 'No invite code present.  Please enter the invite code sent to your email address in the field below.';
      this.createInviteForm();
    }

    this.createForm();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.completeRegistrationForm.controls;
  }

  get c(): { [key: string]: AbstractControl } {
    return this.inviteForm.controls;
  }

  getAnonToken(): void {
    this.authService.getAnonToken().subscribe({
      next: (response: any) => {
        const token = response;
        this.localStorageService.saveToken('access_token', token.token);
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  createForm(): void {
    this.completeRegistrationForm = this.fb.group({
      //emailAddress: [this.user?.emailAddress ?? '', [Validators.required, Validators.email]],
      //username: [this.user?.username ?? ''],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirmPassword: ['', this.passwordsMatch.bind(this)]
    });

  }

  createInviteForm(): void {
    this.inviteForm = this.fb.group({
      inviteCode: ['', Validators.required],
    });

  }

  passwordsMatch(control: FormControl) {
    const pass = this.completeRegistrationForm?.get('password')?.value;
    const confirmPass = control.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern'] && labelName === 'password') {
      return 'Password must be 8 characters in length and contain at least 1 digit, 1 lowercase character, 1 uppercase character, and 1 special character ';
    }

    if (errors['notSame']) {
      return 'Passwords do not match';
    }

    return 'Error';
  }

  returnToLogin(): void {
    this.router.navigateByUrl('/');
  }

  saveUser(): void {
    this.saving = true;

    // Make sure there is a PW
    const password = (this.f['password'] && this.f['password'].value.length > 0) ? this.f['password'].value : null;
    if (!password) {
      this.errorMessage = 'Please enter a password';
      this.saving = false;
      return;
    }

    this.authService.completeInviteManagementUser(this.inviteCode, password).subscribe({
      next: (response: any) => {

        const token = response.data?.token;
        this.localStorageService.saveToken('access_token', token);

        // Now complete the login flow
        this.completeLogin(token);

        this.saving = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      }
    });

  }

  useInviteCode(): void {
    if (this.c['inviteCode'] && this.c['inviteCode'].value.length > 0) {
      this.router.navigateByUrl(`/complete-registration/${this.c['inviteCode'].value.toUpperCase()}`);
    } else {
      this.errorMessage = 'Please enter an invite code';
    }
  }

  completeLogin(token: string) : void {
    this.saving = true;

    // Complete the login flow once we have received an updated token back from the SAVEUSER
    // function.  This is the same flow found in login.component.ts, but starts on the second
    // step.  If any changes are made there, then this flow needs to be updated as well.

    let tenant: Tenant;

    this.tenantService.getUserTenants().pipe(
      concatMap((response: Array<Tenant>) => {

        // Get the tenant
        if (response && response.length > 0) {
          tenant = this.tenantService.setAppTenant(response, token);
        }

        return this.tenantService.updateToken(tenant);
      }),
      concatMap((response: Tenant) => {

        return this.offerService.getTenantSettings();
      }),
      concatMap((response: TenantAppSettings) => {
        // used for sidebar to determine whether or not to show store front
        this.offerService.storeFrontEnabledValue = response['STORE_FRONT:ENABLED'] ?? false;

        // Now call get AppUser
        return this.userService.getAppUser(tenant.key);
      })
    ).subscribe({
      next: (response: AppUser) => {
        // TODO:  Check to make sure that there is a TENANT key in the token
        this.userService.updateUser(response);

        this.authService.isLoggedIn = true;
        this.router.navigateByUrl('/dashboard');

        this.saving = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      },
      complete: () => {
        this.saving = false;
      }
    });
  }

}
