<div class="challenge-header customer-actions">
    <h3>Current Challenges</h3>

    <a [routerLink]="[]" class="new action-item first"
       (click)="showAddChallenge = !showAddChallenge"
       *ngIf="!working && (userPlan?.tests || userPlan?.tests?.length > 0)">
        <span class="fal fa-plus icon"></span>
        add
    </a>
</div>

<div class="add-plan-item add-challenge">
    <ng-container *ngTemplateOutlet="addUserChallenge"></ng-container>
</div>

<app-loading loadingText="loading current challenges..." alignText="left" loadingSize="lg" *ngIf="working"></app-loading>
<app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

<div class="challenges" *ngIf="!working && userPlan && userPlan.tests">
    <div class="challenge" *ngFor="let test of userPlan?.tests; let i = index">
        <div class="challenge-content" [matMenuTriggerFor]="challengeMenu">
            <div class="icon-container" style="--percentage:{{test?.progress?.actualized/test?.progress?.target}}">
                <div class="icon-bg" [style.background-color]="test.imageBgColor">
                    <img src="{{test.imageUrl}}" class="icon" alt="{{test.name}}" />
                </div>
                <div class="point-coin {{test.progress?.isSuccess ? 'complete' : ''}}">
                    <span class="amount-value">{{test.progress?.potentialResults?.success?.bonusAmount}}</span>
                </div>
                <span class="fa-duotone fa-loader fa-spin-pulse fa-2x spinner" *ngIf="savingChallenge === i"></span>
            </div>
            <div class="name">
                {{test.name}}
            </div>
            <div class="task">
                {{test.task}}
            </div>
        </div>

        <mat-menu #challengeMenu class="pml-menu challenge-menu">
            <ng-container *ngTemplateOutlet="challengeMenuTemplate; context: {i: i, areYouSureMenu: areYouSureMenu}"></ng-container>
        </mat-menu>
        <mat-menu #areYouSureMenu class="are-you-sure-menu wide challenge" yPosition="above">
            <div class="small-are-you-sure">
                <div class="confirm-row">
                    <div class="graphic">
                        <span class="fa-light fa-face-thinking fa-2x icon"></span>
                    </div>
                    <div class="text">
                        Do you really want to <span class="emphasis">permanently delete</span> this challenge?
                    </div>
                </div>
                <div class="actions">
                    <a [routerLink]="[]" class="dont">Don't delete</a>
                    <a [routerLink]="[]" class="btn btn-danger" (click)="deleteUserChallenge(user.key, test.key, $event, i)">Yes, delete challenge</a>
                </div>
            </div>
        </mat-menu>
    </div>
</div>

<div class="none-found" *ngIf="!working && (!userPlan?.tests || userPlan?.tests?.length === 0)">
    <span class="fa-light fa-face-unamused icon fa-lg"></span>
    No Current Challenges found for
    <span class="user-name">
        <ng-container *ngTemplateOutlet="userNameDisplay; context: {user: user}"></ng-container>
    </span>
</div>

<ng-template #userNameDisplay let-user="user">
    <ng-container *ngIf="user && user.info && user.info.name && user.info.name.first && user.info.name.last; else elseBlock">
        {{user.info.name.first}} {{user.info.name.last}}
    </ng-container>
</ng-template>
<ng-template #elseBlock let-user="user">
    {{getAlternateName(user)}}
</ng-template>

<ng-template #challengeMenuTemplate let-i="i" let-areYouSureMenu="areYouSureMenu">
    <button mat-menu-item class="last delete" [matMenuTriggerFor]="areYouSureMenu">
        <span class="text">Remove</span>
        <img src="assets/images/icon-delete.svg" class="icon" title="Delete challenge" />
    </button>
</ng-template>

<ng-template #addUserChallenge>
    <form [formGroup]="newChallengeForm" class="pml-form" (ngSubmit)="newChallengeForm.valid && addChallengeToPlan()" autocomplete="off">
        <div class="pml-row add-item-row first" *ngIf="showAddChallenge && !workingResources">
            <div class="pml-col add-badge">
                <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                    context: {section: 'tests', selectedBadge: selectedChallengeBadge, showBadgeSelection: showChallengeBadges}"></ng-container>    
            </div>
            <div class="pml-col">
                <ng-container *ngTemplateOutlet="taskDropdownTemplate; context: {form: newChallengeForm, controlName: 'testTask', label: 'Task', formGet: f}"></ng-container>
            </div>
            <div class="pml-col">
                <label>Rule</label>
                <span class="required" *ngIf="isInvalid(f['testRule'])">{{getError(f['testRule'].errors)}}</span>
                <select class="form-select bonus-type-dd" formControlName="testRule">
                    <option [ngValue]="null" [disabled]="true" [selected]="true" >Rule</option>
                    <option *ngFor="let test of templateTests" [ngValue]="test.key">{{test.name}}</option>
                </select>
            </div>
            <div class="pml-col">
                <label>Duration Type</label>
                <span class="required" *ngIf="isInvalid(f['testTimeframe'])">{{getError(f['testTimeframe'].errors)}}</span>
                <select class="form-select bonus-type-dd timeframe" formControlName="testTimeframe">
                    <option [ngValue]="null" [disabled]="true" [selected]="true" >Interval</option>
                    <option *ngFor="let tf of timeframeTypes" [ngValue]="timeframeValues[tf]">{{timeframeToLabelMapping[tf]}}</option>
                </select>
            </div>
            <div class="pml-col number">
                <label>duration</label>
                <span class="required" *ngIf="isInvalid(f['testTimeframeQuantity'])">{{getError(f['testTimeframeQuantity'].errors)}}</span>
                <input type="number" class="form-control number" formControlName="testTimeframeQuantity" placeholder="duration" value="" />
            </div>
            <div class="pml-col actions">
                <button type="submit" class="btn btn-primary nested-submit">
                    <span *ngIf="savingAddChallenge" class="far fa-spinner fa-pulse fa-sm spinner"></span>
                    <span *ngIf="!savingAddChallenge" class="">add</span>
                    <span *ngIf="savingAddChallenge" class="">adding...</span>
                </button>
            </div>
        </div>
        <div class="pml-row add-item-row add-item-icon" *ngIf="showAddChallenge && !workingResources">
            <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
                context: {form: newChallengeForm, badgeControlName:'testBadgeName', formGet: f, section: 'tests', selectedBadge: selectedChallengeBadge, showBadgeSelection: showChallengeBadges}"></ng-container>
        </div>
    </form>
</ng-template>

<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet">
    <ng-container [formGroup]="form">
        <label>{{label}}</label>
        <span class="required" *ngIf="isInvalid(formGet[controlName])">{{getError(formGet[controlName].errors)}}</span>
        <select class="form-select bonus-type-dd" formControlName="{{controlName}}">
            <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
            <option *ngFor="let task of templateTasks" [ngValue]="task.key">{{task.name}}</option>
        </select>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionTemplate 
    let-form="form" 
    let-badgeControlName="badgeControlName" 
    let-formGet="formGet" 
    let-section="section"
    let-selectedBadge="selectedBadge"
    let-showBadgeSelection="showBadgeSelection">
    <ng-container [formGroup]="form">
        <div class="pml-col">
            <input type="hidden" [formControlName]="badgeControlName" value="" />

            <app-choose-badge [selectedBadge]="selectedBadge" 
                (closeBadgeEvent)="showChallengeBadges = false"
                (selectBadgeEvent)="updateItemWithBadge($event, formGet[badgeControlName], section)" 
                *ngIf="showBadgeSelection"></app-choose-badge>
        </div>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionBtnTemplate let-section="section" let-selectedBadge="selectedBadge" let-showBadgeSelection="showBadgeSelection">
    <span class="fa-stack fa-xs {{showBadgeSelection ? 'active' : ''}}" (click)="showChallengeBadges = !showChallengeBadges" 
          title="{{!selectedBadge ? 'Add Icon' : 'Change Icon'}}" 
          *ngIf="!selectedBadge">
        <i class="fa-light fa-image fa-stack-2x"></i>
        <i class="fa-regular fa-circle-plus fa-stack-1x"></i>
    </span>
    <img src="{{BADGE_PATH}}{{selectedBadge?.fileName}}" 
        title="{{selectedBadge?.name}}" 
        class="selected-badge {{showBadgeSelection ? 'active' : ''}}" 
        (click)="showChallengeBadges = !showChallengeBadges"
        *ngIf="selectedBadge" />
</ng-template>


