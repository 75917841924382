<div class="avatar user-avatar " [style.background-color]="avatarBgColor" (click)="updateAvatar = !updateAvatar">
    <img src="{{avatarImg}}" class="img" title="user" />
</div>

<span class="fas fa-times-circle fa-lg fa-fw icon close new-user-close" style="--dialog-width: {{dialogWidth}}" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">
    <div class="select-avatar">
        <app-choose-avatar [user]="user" [isNewUser]="true"
            (closeAvatarEvent)="closeAvatarWindow()"
            (previewAvatarEvent)="previewAvatar($event)" 
            (resetAvatarToOriginalEvent)="resetAvatarToOriginal()"
            (updateAvatarEvent)="updateUserWithAvatar($event)" 
            *ngIf="updateAvatar">
        </app-choose-avatar>
    </div>

    <div class="pml-dialog-header-footer">
        <div class="user-header pml-dialog-header">
            <h3>{{fullNameDisplay(user.info?.name, 'Add New User')}}</h3>
        </div>

    </div>

    <ng-container *ngTemplateOutlet="newUserFormTemplate"></ng-container>
</div>

<ng-template #newUserFormTemplate>
    <div class="new-user-form pml-form">
        <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

        <form class="pml-form horiz-layout" [formGroup]="userForm" (ngSubmit)="userForm.valid" (ngSubmit)="saveUser()" autocomplete="off">
            <div class="pml-row w-fit-content">
                <label>name</label>
                <span class="required" *ngIf="isInvalid(f['first'])">{{getError(f['first'].errors, 'First name')}}</span>
                <div class="input-data name">
                    <input type="text" class="form-control med-text first" formControlName="first" placeholder="first name" />
                    <input type="text" class="form-control middle" formControlName="middle" placeholder="middle" />
                    <input type="text" class="form-control med-text" formControlName="last" placeholder="last name" />
                </div>
                <div class="suffix-row {{toggleSuffix ? 'show' : ''}}">
                    <a [routerLink]="[]" class="add-sfx" (click)="toggleSuffix = !toggleSuffix">
                        <span class="far fa-{{toggleSuffix ? 'minus' : 'plus'}} fa-sm fa-fw icon"></span>
                        {{toggleSuffix ? 'hide' : 'add'}} suffix
                    </a>
                    <input type="text" class="form-control short-text suffix" formControlName="suffix" placeholder="suffix" *ngIf="toggleSuffix" />
                </div>
            </div>
            <div class="pml-row email w-fit-content">
                <label>email</label>
                <span class="required" *ngIf="isInvalid(f['emailAddress'])">{{getError(f['emailAddress'].errors, 'Email address')}}</span>
                <div class="input-data">
                    <input type="text" class="form-control" formControlName="emailAddress" placeholder="email" />
                </div>
            </div>
            <div class="pml-row w-fit-content">
                <label>role</label>
                <span class="required" *ngIf="isInvalid(f['roleType'])">{{getError(f['roleType'].errors)}}</span>
                <select class="form-select input-data" formControlName="roleType">
                    <option [ngValue]="null" disabled selected hidden></option>
                    <option *ngFor="let item of roleTypes" [ngValue]="item.key">
                        {{item.value}}
                    </option>
                </select>
            </div>
            <div class="pml-row submit-form pml-dialog-actions">
                <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="closeDialog()">
                <button type="submit" class="btn btn-primary save"
                    [disabled]="saving || !(userForm.valid && userForm.dirty)">
                    <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!saving" class="">update user</span>
                    <span *ngIf="saving" class="">updating...</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>