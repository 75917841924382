<div class="container-fluid register company-details">

    <app-registration-header stepNumber="2"></app-registration-header>

    <div class="row">
        <div class="col welcome-col">
            First, let's create your company in our system.  What is the name of your company?
        </div>
    </div>
    <div class="row">
        <div class="col form-col">

            <div class="error alert alert-danger" *ngIf="errorMessage">
                <span class="far fa-exclamation-circle fa-lg icon"></span>
                {{errorMessage}}
            </div>

            <h1>Company</h1>

            <form class="pml-form" [formGroup]="companyForm" (ngSubmit)="companyForm.valid" (ngSubmit)="registerCompany()" autocomplete="off">
                <div class="pml-row">
                    <div class="required" *ngIf="isInvalid(f['companyName'])">
                        {{getError(f['companyName'].errors, 'COMPANY NAME')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Name of company
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control full-width" formControlName="companyName" placeholder="company name" />
                        <input type="hidden" formControlName="companyType" value="DENTAL" />
                    </div>
                </div>
                <div class="pml-row submit-form pml-actions">
                    <button type="button" class="btn btn-outline-primary cancel" (click)="goBack()">
                        Back
                    </button>
                    <button type="submit" class="btn btn-primary save"
                        [disabled]="saving || !(companyForm.valid && companyForm.dirty)">
                        <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                        <span *ngIf="!saving" class="">Continue</span>
                        <span *ngIf="saving" class="">Saving...</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>