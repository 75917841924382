
export interface SaveInfo<T> {
    result: T;
    saveType: SaveType
}

export enum SaveType {
    new = 'New',
    updated = 'Updated'
}
