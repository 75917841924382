<h1>Orders</h1>

<ng-container *ngTemplateOutlet="pageHeaderTemplate"></ng-container>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<app-loading loadingText="loading orders..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<div class="items" *ngIf="!working && orders.length > 0">

    <div class="order-item-header">
        <h3>Orders received</h3>
        <a [routerLink]="[]" title="refresh orders" class="refresh" (click)="refreshOrders()">
            <span class="fa-solid fa-arrows-rotate icon"></span>
            Refresh
        </a>
    </div>

    <ng-container  *ngFor="let order of orders; index as i">
        <div class="item {{i === 0 ? 'first' : ''}} {{i === orders.length - 1 ? 'last' : ''}}" (click)="openOrderDialog(order)">
            <ng-container *ngTemplateOutlet="orderImagesTemplate; context: {order: order}"></ng-container>

            <div class="item-summary">
                <label class="order-label">Order ID</label>
                <div class="order-id">{{formatOrderId(order.id)}}</div>

                <div class="ordered-by">
                    <div class="name">{{order.info?.customerInformation?.name}}</div>
                    <div class="date-ordered">created {{dateDisplay(order.info?.createdUtc)}}</div>
                </div>
            </div>

            <div class="item-detail">
                <div class="count">
                    {{order.breakdown[0]?.items?.length}} {{order.breakdown[0]?.items?.length === 1 ? 'item' : 'items'}} ordered
                </div>
                <div class="item-names">
                    <ng-container *ngFor="let item of order.breakdown[0]?.items; index as j">
                        <span class="name-separator" *ngIf="j > 0 && j < 4">, </span>
                        <span class="text" *ngIf="j < 4">{{item.details[0]?.name}}</span>
                        <span class="elipsis" *ngIf="j === 4">.....</span>
                    </ng-container>
                </div>
            </div>
            
            <div class="item-status">
                <div class="order-status {{order.info ? order.info.status.toLowerCase() : ''}}">
                    <div class="status-link">
                        <span class="status">{{getStatusValue(order.info?.status)}}</span>
                    </div>
                    <span class="status-date">last updated {{dateDisplay(order.info?.lastModifiedUtc, 'dd-MMM-yyyy')}}</span>
                    <!-- <a [routerLink]="[]" title="set status" class="status-link" [matMenuTriggerFor]="shouldShowStatusDropdown(order.info?.status) ? statusMenu : placeholder">
                        <span class="status">{{getStatusValue(order.info?.status)}}</span>
                        <span class="fa-regular fa-angle-down icon" *ngIf="shouldShowStatusDropdown(order.info?.status)"></span>
                    </a>
                    <span class="status-date">{{dateDisplay(order.info?.lastModifiedUtc, 'dd MMM `yy')}}</span>

                    <mat-menu #statusMenu class="pml-menu order-menu" xPosition="before">
                        <ng-container *ngTemplateOutlet="statusMenuTemplate; context: {i: i, order: order}"></ng-container>
                    </mat-menu> -->
                    <mat-menu #placeholder class="d-none"></mat-menu>
                </div>
            </div>

            <div class="order-item-overlay" *ngIf="savingOrder === i">
                <div class="fa-duotone fa-spinner fa-pulse fa-lg icon"></div>
                <div class="text">updating order...</div>
            </div>
        </div>
    </ng-container>

    <div class="bottom-paging  paging-container">
        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
    </div>
</div>

<div class="none-found" *ngIf="!working && (!orders || orders.length === 0)">
    <span class="fa-light fa-face-unamused icon fa-lg"></span>
    No orders found
</div>

<ng-template #pageHeaderTemplate>
    <div class="content-header paging-container">
        <div class="customer-actions empty">
            <!-- <a [routerLink]="[]" class="new action-item first" (click)="openNewOrderDialog()">
                <span class="fal fa-plus icon"></span>
                new
            </a> -->
        </div>

        <div class="search action-item input-group">
            <span class="far fa-search icon input-group-text"></span>
            <input type="text" class="form-control with-trailing-button" name="search" placeholder="Search by Order ID" [(ngModel)]="orderSearchValue" (keyup)="searchValidator($event)" />
            <button type="button" class="btn btn-light" (click)="searchOrders($event)" [disabled]="isSearchButtonDisabled()">search</button>
        </div>
        <div class="clear-search-results" *ngIf="areResultsFromSearch">
            <a [routerLink]="[]" (click)="clearSearchResults()">
                <span class="fa-regular fa-xmark fa-sm icon"></span>
                Clear search results
            </a>
        </div>

        <div class="sort action-item">
            <label>filter by</label>
            <div class="btn-group">
                <input type="radio" class="btn-check" name="filterby" id="new" (change)="updateOrderFilter($event, 'New')">
                <label class="btn btn-outline-primary" for="new">new</label>

                <input type="radio" class="btn-check" name="filterby" id="received" (change)="updateOrderFilter($event, 'Received')">
                <label class="btn btn-outline-primary" for="received">done</label>

                <input type="radio" class="btn-check" name="filterby" id="all" checked (change)="updateOrderFilter($event, '')">
                <label class="btn btn-outline-primary" for="all">all</label>
            </div>
        </div>

        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
        
        <div class="item-count" *ngIf="!working && errorMessage.length === 0 && orders.length > 0 && !pagingCount.showResultsPaging">
            <span class="count">{{orders.length}}</span> {{orders.length === 1 ? 'order' : 'orders'}} found
        </div>
    </div>
</ng-template>

<ng-template #pagingTemplate>
    <div class="paging" *ngIf="!working && pagingCount.showResultsPaging">
        <ng-container *ngTemplateOutlet="pagePrevButtonTemplate; context: {continuationToken: pagingCount.prevContinuationToken}"></ng-container>
        <div class="count">
            {{pagingCount.countStart}} to {{pagingCount.countEnd}} of {{pagingCount.countTotal}}
        </div>
        <ng-container *ngTemplateOutlet="pageNextButtonTemplate; context: {continuationToken: pagingCount.nextContinuationToken}"></ng-container>
    </div>
</ng-template>

<ng-template #pagePrevButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page prev" (click)="pageOrders(continuationToken, PREV)">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || continuationToken === ''">
        <div class="page prev disabled">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #pageNextButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page next" (click)="pageOrders(continuationToken, NEXT)">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || (continuationToken && continuationToken === '')">
        <div class="page next disabled">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>
</ng-template>

<ng-template #statusMenuTemplate let-i="i" let-order="order">
    <ng-container *ngFor="let status of orderStatuses | filterOrderStatus: order.info?.status">
        <button type="button" mat-menu-item 
            class="order-status-menu-item {{status.key.toLowerCase()}} {{order.info?.status.toLowerCase() === status.key.toLowerCase() ? 'active' : ''}}" 
            (click)="updateStatus($event, order, status.key, i)">
            <span class="text">{{status.value}}</span>
            <app-status-icons [orderStatus]="status.value"></app-status-icons>
        </button>
    </ng-container>
</ng-template>

<ng-template #orderImagesTemplate let-order="order">
    <div class="item-img {{orderItemIsAvatar(order) ? 'is-avatar' : ''}}">
        <ng-container *ngIf="order.breakdown[0]?.items.length === 1">
            <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                title="{{order.breakdown[0]?.items[0]?.details[0]?.name}}" 
                *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
        </ng-container>
        <ng-container *ngIf="order.breakdown[0]?.items.length === 2">
            <div class="multiple-images">
                <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                    title="{{order.breakdown[0]?.items[0]?.details[0]?.name}}" 
                    class="first"
                    *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
                <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                    title="{{order.breakdown[0]?.items[1]?.details[0]?.name}}" 
                    class="second"
                    *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
            </div>
        </ng-container>
        <ng-container *ngIf="order.breakdown[0]?.items.length === 3 || order.breakdown[0]?.items.length > 3">
            <div class="multiple-images">
                <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                    title="{{order.breakdown[0]?.items[0]?.details[0]?.name}}" 
                    class="first"
                    *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
                <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                    title="{{order.breakdown[0]?.items[1]?.details[0]?.name}}" 
                    class="second"
                    *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
                <img src="{{order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url}}" 
                    title="{{order.breakdown[0]?.items[2]?.details[0]?.name}}" 
                    class="third"
                    *ngIf="order.breakdown[0]?.items[0]?.details[0]?.images[0]?.url" />
            </div>
        </ng-container>
    </div>
</ng-template>
