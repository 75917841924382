<div class="add-linked-user">
    <ng-container *ngTemplateOutlet="addLinkedUserTemplate; context: {linkedUser: parentUser}"></ng-container>
</div>

<ng-template #addLinkedUserTemplate let-user="parentUser">
    <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

    <form class="pml-form horiz-layout" [formGroup]="linkedUserForm" (ngSubmit)="linkedUserForm.valid" (ngSubmit)="saveLinkedUser()" autocomplete="off">
        <!-- <div class="pml-row">
            <div class="pml-col">
                <label>display name</label>
                <span class="required display-name" *ngIf="isInvalid(f['displayName'])">{{getError(f['displayName'].errors, 'Display Name')}}</span>
                <div class="input-data name">
                    <input type="text" class="form-control large-text" formControlName="displayName" placeholder="display name" />
                </div>
            </div>
        </div> -->
        <div class="pml-row">
            <div class="pml-col">
                <label>name</label>
                <span class="required" *ngIf="isInvalid(f['first'])">{{getError(f['first'].errors, 'First name')}}</span>
                <div class="input-data name">
                    <input type="text" class="form-control med-text first" formControlName="first"
                        placeholder="first name" />
                    <input type="text" class="form-control middle" formControlName="middle" placeholder="middle" />
                    <input type="text" class="form-control med-text" formControlName="last" placeholder="last name" />
                </div>
                <div class="suffix-row {{toggleSuffix ? 'show' : ''}}">
                    <a [routerLink]="[]" class="add-sfx" (click)="toggleSuffix = !toggleSuffix">
                        <span class="far fa-{{toggleSuffix ? 'minus' : 'plus'}} fa-sm fa-fw icon"></span>
                        {{toggleSuffix ? 'hide' : 'add'}} suffix
                    </a>
                    <input type="text" class="form-control short-text suffix" formControlName="suffix" placeholder="suffix" *ngIf="toggleSuffix" />
                </div>
            </div>
            <div class="pml-col email last">
                <label>email <span class="optional">(optional)</span></label>
                <span class="required" *ngIf="isInvalid(f['emailAddress'])">{{getError(f['emailAddress'].errors, 'Email address')}}</span>
                <div class="input-data">
                    <input type="text" class="form-control" formControlName="emailAddress" placeholder="email" />
                </div>
            </div>
        </div>
        <div class="pml-row second">
            <div class="pml-col dob">
                <label>Date of Birth</label>
                <span class="required" *ngIf="isInvalid(f['year'], 'dob')">{{getError(f['year'].errors, 'DOB Year')}}</span>
                <div class="input-data">
                    <select formControlName="month" class="form-select dob first" (change)="validateDOBInput()">
                        <option value="0" disabled selected hidden>Month</option>
                        <option *ngFor="let thisMonth of months; let m = index" [value]="m+1">{{thisMonth}}</option>
                    </select>
                    <select formControlName="day" class="form-select dob" (change)="validateDOBInput()">
                        <option value="0" disabled selected hidden>Day</option>
                        <option *ngFor="let thisDay of getDays(linkedUserForm.value)" [value]="thisDay"
                            (change)="validateDOBInput()">
                            {{thisDay}}
                        </option>
                    </select>
                    <input type="number" class="form-control year last" formControlName="year" placeholder="year" min="1900"
                        max="2050" (change)="validateDOBInput()" />
                </div>
            </div>
            <div class="pml-col toggle">
                <div class="group-item">
                    <label class="form-check-label toggle-1 {{isMinor ? 'active' : ''}}" for="isMinor">Minor</label>
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="isMinor" checked (change)="isMinorToggle($event)">
                        <label class="form-check-label toggle-2 {{!isMinor ? 'active' : ''}}" for="isMinor">Adult</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="pml-row submit-form pml-dialog-actions">
            <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="cancelLinkedUser()">
            <button type="submit" class="btn btn-primary save"
                [disabled]="saving || !(linkedUserForm.valid && linkedUserForm.dirty)">
                <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!saving" class="">add user</span>
                <span *ngIf="saving" class="">adding...</span>
            </button>
        </div>
    </form>
</ng-template>