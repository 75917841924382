import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PotentialResults } from 'src/app/core/models/reward/potential-results';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTaskResponse } from 'src/app/core/models/reward/template-task-response';
import { SaveInfo, SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { DialogAreYouSureComponent } from '../../_shared/dialog-are-you-sure/dialog-are-you-sure.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  tasks: TemplateTask[] = [];
  potentialResults: PotentialResults;
  showEditForm: number;

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.showEditForm = -1;

    this.getAllTasks();
  }

  getAllTasks(): void {
    this.working = true;

    this.rewardsService.getTemplateTasks().subscribe({
      next: (response: TemplateTaskResponse[] | any) => {
        this.tasks = response.tasks;
        this.potentialResults = response.potentialResults;

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  saveTask(saveInfo: SaveInfo<TemplateTask>): void {
    if (saveInfo.saveType === SaveType.new) {
      this.tasks.unshift(saveInfo.result);
    } else {
      const taskToUpdate: TemplateTask = this.tasks.find(x => x.key === saveInfo.result.key);
      if (taskToUpdate) {
        taskToUpdate.name = saveInfo.result.name;
      }
    }

    this.showEditForm = -1;
  }

  closeTask(): void {
    this.showEditForm = -1;
  }

  openConfirmTaskDeleteDialog(e: Event, taskToDelete: TemplateTask): void {
    const deleteElement = e.currentTarget as HTMLSpanElement;
    const deleteDiv: HTMLDivElement = deleteElement.parentElement.parentElement.querySelector('.item-delete-overlay');

    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {
      autoFocus: false,
      position: { top: '10vh' },
      maxWidth: '430px',
      width: '430px',
      panelClass: 'are-you-sure',
      data: {
        deleteObjectName: taskToDelete.name,
        deleteObjectTypeName: 'task',
        message: 'This task will be deleted from the system, including any user data associated with this task.  Are you sure you want to proceed? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data && result.data.confirmDelete) {  // result === '' means cancel button was clicked

        deleteDiv.classList.add('show-deleting');

        // Call team service here to delete team
        this.rewardsService.deleteTemplateTask(taskToDelete.key).subscribe({
          next: (response: any) => {
            if (response) {
              // Update the list of tests for display on the page
              this.tasks = this.tasks.filter(x => x.key !== taskToDelete.key);
            } else {
              // There is was a problem, display an error
              this.errorMessage = 'There was a problem with the request.';
              deleteDiv.classList.remove('show-deleting');
            }
          },
          error: (err: any) => {
            // Display the error
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            deleteDiv.classList.remove('show-deleting');
          }
        });

      }
    });
  }

}
