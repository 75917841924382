<div class="choose-avatar">
    <div class="avatar-header-row">
        <h3>Pick a profile image</h3>

        <div class="saving" *ngIf="working">
            <span class="fa-regular fa-spinner fa-pulse spinner"></span>
            <span class="text">saving changes to avatar...</span>
        </div>

        <span class="fal fa-times close" (click)="closeAvatar()"></span>
    </div>

    <div class="nav nav-tabs">
        <a [routerLink]="[]" class="nav-item {{activeTab === AVATAR_TAB ? 'active' : ''}}" (click)="activeTab = AVATAR_TAB">Character</a>
        <a [routerLink]="[]" class="nav-item {{activeTab === AVATAR_COLOR_TAB ? 'active' : ''}}" (click)="activeTab = AVATAR_COLOR_TAB">Color</a>
    </div>
    <ng-container *ngIf="activeTab === AVATAR_TAB">
        <ng-container *ngTemplateOutlet="avatarsTemplate; context: {userAvatar: userAvatar, avatars: allAvatars}"></ng-container>
    </ng-container>
    <ng-container *ngIf="activeTab === AVATAR_COLOR_TAB">
        <ng-container *ngTemplateOutlet="colorsTemplate; context: {userAvatar: userAvatar, colors: avatarBgColors}"></ng-container>
    </ng-container>
</div>

<ng-template #avatarsTemplate let-userAvatar="userAvatar" let-avatars="avatars">
    <div class="avatars">
        <ng-container *ngFor="let avatar of avatars">
            <ng-container *ngTemplateOutlet="avatarDisplayTemplate; context {userAvatar: userAvatar, avatar: avatar}"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #colorsTemplate let-userAvatar="userAvatar" let-colors="colors">
    <div class="colors">
        <ng-container *ngFor="let color of colors">
            <div class="color {{userAvatar?.color === color ? 'active' : ''}}" 
                (mouseover)="previewAvatarBgColor(color)" 
                (mouseout)="resetAvatarToOriginal()"
                (click)="updateAvatarBg(color)"
                [style.background-color]="color"></div>
        </ng-container>
    </div>
</ng-template>

<ng-template #avatarDisplayTemplate let-userAvatar="userAvatar" let-avatar="avatar">
    <div class="avatar {{userAvatar?.name === avatar?.name ? 'active' : ''}}" 
        (mouseover)="previewAvatar(avatar)" 
        (mouseout)="resetAvatarToOriginal()"
        (click)="updateAvatar(avatar)"
        [style.background-color]="avatar.color">
        <img src="{{IMG_PATH}}{{avatar.fileName}}" title="{{avatar.name}}" />
    </div>
</ng-template>

