<div class="fulfillable-product-edit">
    <app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail" [errorWidth]="'auto'"></app-error-display>

    <form [formGroup]="productForm" class="task-form" (ngSubmit)="productForm.valid && saveProductForm()" autocomplete="off">
        <div class="pml-col">
            <label>name</label>
            <div class="input-container">
                <span class="required" *ngIf="isInvalid(f['name'])">{{getError(f['name'].errors)}}</span>
                <input type="text" class="form-control" formControlName="name" placeholder="product name" value="" />
            </div>
        </div>
        <div class="pml-col">
            <label>description</label>
            <div class="input-container">
                <input type="text" class="form-control" formControlName="description" placeholder="product description" value="" />
            </div>
        </div>
        <div class="pml-col number">
            <label>your cost</label>
            <div class="input-container">
                <div class="currency-container">
                    <div class="currency">$</div>
                    <div class="cost-text">{{product.amount | currency:'USD':''}}</div>
                    <input type="hidden" formControlName="cost" />
                    <!-- TODO:  Should not be EDITABLE -->
                </div>
            </div>
        </div>
        <div class="pml-col">
            <label>offer price</label>
            <div class="input-container">
                <span class="required" *ngIf="isInvalid(f['amount'])">{{getError(f['amount'].errors)}}</span>
                <div class="currency-container">
                    <input type="text" class="form-control number amount" formControlName="amount" placeholder="redemption amount" value="" />
                    <span class="currency post">PTS</span>
                </div>
            </div>
        </div>
        <!-- <div class="pml-col" *ngFor="let amountItem of amountItems.controls; let i = index">
            <label>amount</label>
            <div class="input-container">
                <span class="required" *ngIf="isInvalid(getControlFromArray(amountItem, 'amount'))">{{getError(getControlFromArray(amountItem, 'amount').errors)}}</span>
                <div class="currency-container">
                    <input type="text" class="form-control number amount" formControlName="amount" placeholder="redemption amount" value="" />
                    <span class="currency post">{{getCurrencyCode(amountItem)}}</span>
                </div>
            </div>
        </div> -->
        <div class="pml-col actions">
            <input type="hidden" formControlName="key" />
            <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="cancelProductForm()">
            <button type="submit" class="btn btn-primary save" [disabled]="saving || !(productForm.valid && productForm.dirty)">
                <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!saving" class="">add product</span>
                <span *ngIf="saving" class="">adding...</span>
            </button>
        </div>        
    </form>
    
</div>