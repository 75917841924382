import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() public loadingText: string;
  @Input() public alignText: string;
  @Input() public loadingSize: string;  // md, lg, 2x
  @Input() public cssClass: string;

  constructor() { }

  ngOnInit(): void {
    if (!this.loadingText || this.loadingText.length === 0) {
      this.loadingText = 'loading...';
    }

    if (!this.alignText || this.alignText.length === 0) {
      this.alignText = '';
    }

    if (!this.loadingSize || this.loadingSize.length === 0) {
      this.loadingSize = '';
    } else {
      this.loadingSize = 'fa-' + this.loadingSize;
    }
  }

}
