import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { BitColorHelper } from 'src/app/core/helpers/bit-color';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { Avatar } from 'src/app/core/models/avatar';
import { DialogDataUser } from 'src/app/core/models/dialog-data-user';
import { EventDataLinkedUser } from 'src/app/core/models/event-data-linked-user';
import { Name } from 'src/app/core/models/name';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { UpdatePlan } from 'src/app/core/models/reward/update-plan';
import { UserPlanActivitySummary } from 'src/app/core/models/reward/user-plan-activity-summary';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user.component.html',
  styleUrls: ['./dialog-user.component.scss']
})
export class DialogUserComponent implements OnInit {

  PROFILE_TAB = 'profileTab';  // Setting as CONST to avoid spelling errors
  PLAN_TAB = 'planTab';
  APP_KEY = environment.appKey;
  BADGE_COLOR = 'badgeColor';

  tempAvatar: string;

  working: boolean;
  workingChildren: boolean;
  workingParent: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  user: AppUser;
  linkedUsers: AppUser[];
  parentUser: AppUser;
  activeTab: string;
  isNewUser: boolean;
  hasParent: boolean;

  avatarBgColor: string;
  avatarImg: string;
  defaultAvatar: boolean;
  updateAvatar: boolean;
  showAdditionalPlanContent: boolean;

  userPlan: UserPlanResponse;
  workingTask: boolean;
  workingPlan: boolean;
  savingTask: number;
  savingMilestone: number;
  planBgColors: string[];
  taskDate: string;
  planDate: string;
  childComponentTemplateTasks: TemplateTask[];
  childComponentTemplateTests: TemplateTest[];
  childComponentActivitySummary: UserPlanActivitySummary[];
  userHasAvailablePlans: boolean;

  @Output() userSaveEvent = new EventEmitter<AppUser>();
  @Output() newLinkedUserSaveEvent = new EventEmitter<AppUser>();

  constructor(
    private rewardService: RewardsService,
    private datePipe: DatePipe,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUser) { }

  ngOnInit(): void {
    this.working = false;
    this.workingChildren = false;
    this.workingParent = false;
    this.errorMessage = '';
    this.activeTab = this.PROFILE_TAB;
    this.updateAvatar = false;
    this.user = this.data.user ? this.data.user : {};
    this.isNewUser = this.data?.isNewUser;
    this.hasParent = this.userHasParent();
    this.userHasAvailablePlans = false;

    this.workingTask = false;
    this.workingPlan = false;
    this.showAdditionalPlanContent = false;

    // if (this.user?.linkInfo?.linkedCount > 0) {
    //   this.getLinkedUsers(this.user.key);
    // }

    // if (!this.data?.parentUser && this.data?.parentUserKey) {
    //   this.getParentUser(this.data.parentUserKey);
    // }

    if (this.data?.parentUser) {
      this.parentUser = this.data.parentUser;
    }

    this.planBgColors = AVATAR_COLORS;
    this.taskDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');  // Initial value
    this.planDate = this.taskDate;
    this.savingTask = -1;
    this.savingMilestone = -1;
    this.getUserPlan();

    // default avatar values
    // this.avatarBgColor = '#ff6d6d';
    // this.avatarImg = 'assets/images/avatars/nacho-black-white.png';
    this.avatarBgColor = '#fff';
    this.avatarImg = 'assets/images/avatars/default.png';
    this.defaultAvatar = true;

    this.checkForAvatar();
  }

  getUserPlan(showWorkingTask: boolean = true, showWorkingPlan: boolean = true): void {
    this.workingTask = showWorkingTask;
    this.workingPlan = showWorkingPlan;

    // FORMAT EXAMPLE:  taskDate = '09-16-2022';
    // NOTE:  Originally we had task date that would move and plan date stayed
    //        as the current date.  But to keep behavior consistent with the
    //        app, we now make them both change
    //const planDate: string = this.datePipe.transform(new Date(), 'MM-dd-yyyy');  // Plan date (for challenges)

    this.rewardService.getUserPlanByKey(this.user.key, this.taskDate, this.planDate).subscribe({
      next: (response: UserPlanResponse | any) => {
        this.initializeTasksAndLevels(response);

        this.userPlan = response;

        this.updatePlanItemColors();

        this.workingTask = false;
        this.workingPlan = false;
        this.savingTask = -1;
        this.savingMilestone = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingTask = false;
        this.workingPlan = false;
        this.savingTask = -1;
        this.savingMilestone = -1;
      }
    });
  }

  initializeTasksAndLevels(plan: UserPlanResponse): void {
    if (!plan) {
      return;
    }

    plan.tasks.forEach((task: TemplateTask) => {
      task.templateTaskKey = task.key;
    })

    plan.levels.forEach((level: TemplateLevel) => {
      level.templateTaskKey = level.key;
    })
  }

  updatePlanByTaskDate(planUpdate: UpdatePlan): void {
    this.taskDate = planUpdate.taskDate;
    this.planDate = this.taskDate;
    this.savingTask = planUpdate.savingTask ?? -1;
    this.savingMilestone = planUpdate.savingMilestone ?? -1;
    this.getUserPlan(planUpdate.workingTask ?? true, planUpdate.workingPlan ?? true);
  }

  updatePlanTaskInfoByTaskDate(planUpdate: UpdatePlan): void {

    // Update tests by key
    this.userPlan.tasks.forEach((task: TemplateTask) => {
      planUpdate.updatedUserPlan.tasks.forEach((updatedTask: TemplateTask) => {
        if (task.key === updatedTask.key) {
          task = Object.assign(task, updatedTask);
        }
      })
    });

    // Update tasks by key
    this.userPlan.tests.forEach((test: TemplateTest) => {
      planUpdate.updatedUserPlan.tests.forEach((updatedTest: TemplateTest) => {
        if (test.key === updatedTest.key) {
          test = Object.assign(test, updatedTest);
        }
      })
    });

    this.savingTask = -1;  // This doesn't get detected at the child level
  }

  updateTemplateTasks(templateTasks: TemplateTask[]) {
    this.childComponentTemplateTasks = templateTasks;
  }

  updateTemplateTests(templateTests: TemplateTest[]) {
    this.childComponentTemplateTests = templateTests;
  }

  updateUserActivitySummary(userPlanActivitySummary: UserPlanActivitySummary[]) {
    this.childComponentActivitySummary = userPlanActivitySummary;
  }

  updatePlanMilestoneInfoByTaskDate(planUpdate: UpdatePlan): void {
    // Update level by key
    this.userPlan.levels.forEach((level: TemplateLevel) => {
      planUpdate.updatedUserPlan.levels.forEach((updatedLevel: TemplateLevel) => {
        if (level.key === updatedLevel.key) {
          level = Object.assign(level, updatedLevel);
        }
      })
    });

    this.savingMilestone = -1;  // This doesn't get detected at the child level
  }

  updateParentPlan(planResonse: UserPlanResponse): void {
    this.userPlan = planResonse;
    this.updatePlanItemColors();
  }

  updatePlanItemColors(): void {
    // Task BG colors
    this.setBgColors(this.userPlan.tasks, this.planBgColors, 8);

    // Test BG colors
    this.setBgColors(this.userPlan.tests, this.planBgColors, 5);

    // Milestone BG colors
    this.setBgColors(this.userPlan.levels, this.planBgColors, 6);
  }

  setBgColors(
    planItems: TemplateTask[] | TemplateTest[] | TemplateLevel[],
    planBgColors: string[],
    i: number): void {
    planItems?.forEach((item: TemplateTask | TemplateTest | TemplateLevel) => {
      if (item.additionalInfo &&
        item.additionalInfo[this.BADGE_COLOR] &&
        item.additionalInfo[this.BADGE_COLOR].length > 0) {
        // If there is a badgeColor stored in additional info, use
        // that as the image bg color
        item.imageBgColor = item.additionalInfo[this.BADGE_COLOR];
      } else {
        // Otherwise pull from the random color list
        item.imageBgColor = planBgColors[i];
        i++;
        if (i === planBgColors.length) {
          i = 0;
        }
      }
    });
  }

  checkForAvatar(): void {

    if (this.user && this.user.info && this.user.info.additionalInfo) {
      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = `assets/images/avatars/${this.user.info.additionalInfo['avatar']}`;
        this.defaultAvatar = false;
      }

      if (this.user.info.additionalInfo['avatarColor']) {
        // Get the avatar color and convert to a usable RGBA format
        this.avatarBgColor = this.user.info.additionalInfo['avatarColor'];
      }
    }
  }

  closeAvatarWindow(): void {
    this.updateAvatar = !this.updateAvatar;
  }

  previewAvatar(avatar: Avatar): void {
    if (avatar?.color) {
      this.avatarBgColor = avatar.color;
    }

    // Get the avatar image
    if (avatar?.fileName) {
      this.avatarImg = `assets/images/avatars/${avatar.fileName}`;
      this.defaultAvatar = false;
    }
  }

  resetAvatarToOriginal(): void {
    this.avatarBgColor = '#fff';
    this.avatarImg = 'assets/images/avatars/default.png';
    this.defaultAvatar = true;

    this.checkForAvatar();
  }

  updateUserWithAvatar(avatar: Avatar): void {
    this.avatarBgColor = avatar.color;
    this.avatarImg = `assets/images/avatars/${avatar.fileName}`;
    this.defaultAvatar = false;
  }

  updateUserWithAvatarBg(avatar: Avatar): void {
    this.avatarBgColor = avatar.color;
    this.avatarImg = `assets/images/avatars/default.png`;
    this.defaultAvatar = false;
  }

  setActiveTab(tabName: string): void {
    this.errorMessage = '';
    this.activeTab = tabName;
  }

  userHasParent(): boolean {
    let hasParent = false;

    hasParent = this.user && this.user.linkInfo && this.user.linkInfo.parentUserKey && this.user.linkInfo.parentUserKey.length > 0 ? true : false;

    return hasParent;
  }

  viewLinkedUser(eventData: EventDataLinkedUser): void {
    this.data = {
      user: eventData.linkedUser,
      isNewUser: eventData.linkedUser === null ? true : false,
      message: '',
      parentUser: eventData.parentUser ? eventData.parentUser : null
    };

    // Causes the TASK COMPLETION (user-activity-summary) component to reload from API
    this.childComponentActivitySummary = null;

    this.ngOnInit();
  }

  newLinkedUserSave(eventData: AppUser): void {
    // Emit this out to the parent component
    this.newLinkedUserSaveEvent.emit(eventData);
  }

  enrollUserInPlan(eventData: UserPlanResponse): void {
    this.userPlan = eventData;

    // Get User plan pulls any plan data for the user for the current day
    // which will be no activity in this case.
    this.getUserPlan();
  }

  updateUserHasAvailablePlans(hasAvailablePlans: boolean): void {
    this.userHasAvailablePlans = hasAvailablePlans;
  }

}
