import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-header',
  templateUrl: './registration-header.component.html',
  styleUrls: ['./registration-header.component.scss']
})
export class RegistrationHeaderComponent implements OnInit {

  @Input() public stepNumber: string;

  constructor() {}

  ngOnInit(): void {
    // TOD
  }

}
