/**
 * Statuses:
 *   NEW
 *   - Status changes allowed:
 *      - Cancelled
 *   - Allow capture of payment, allow the admin to take payment on a specific order
 *
 *   PENDING
 *   - Status changes allowed:
 *      - Cancelled
 *   - Shouldn't stay in this status for longer than capturing and processing payments.  Eventually should end up as Incomplete or Finalize if the payments were handled with an error or successful respectively.
 *
 *   FINALIZE
 *   - Status changes allowed:
 *      - Cancelled
 *   - Should have a way to commit the order.  Finalize is the status saying that the order is in balance and ready to be committed.
 *
 *   RECEIVED
 *   - Status changes allowed:
 *      - Pick Up
 *      - Shipped
 *      - Complete
 *      - Cancelled
 *
 *   PICK UP
 *   - Status changes allowed:
 *      - Complete
 *      - Cancelled
 *
 *   SHIPPED
 *   - Status changes allowed:
 *      - Complete
 *      - Cancelled
 *
 *   COMPLETE
 *   - Status changes allowed:
 *      - Cancelled
 *
 *   INCOMPLETE
 *   - This is where we would need to inspect the order to see if we can resolve payments or whatnot
 *
 *   CANCELLED
 *   - No changes allowed at this point.  Create new order if you want to restart it.
 *
 */
export enum OrderStatus {
    // INITIALIZING = 'Initializing',
    NEW = 'New',
    PENDING = 'Pending',
    FINALIZE = 'Finalize',
    RECEIVED = 'Received',
    PICKUP = 'Pick up',
    PROCESSING = 'Processing',
    COMPLETE = 'Complete',
    INCOMPLETE = 'Incomplete',
    CANCELLED = 'Cancelled'
}

