import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Timeframe, timeframeToLabelMapping } from 'src/app/core/enums/time-frame';
import { HelperImage } from 'src/app/core/helpers/helper-image';
import { Badge } from 'src/app/core/models/badge';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { TemplateTest } from 'src/app/core/models/reward/template-test';
import { SaveInfo, SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-edit-plan-challenge',
  templateUrl: './edit-plan-challenge.component.html',
  styleUrls: ['./edit-plan-challenge.component.scss']
})
export class EditPlanChallengeComponent implements OnInit {

  BADGE_PATH = 'assets/images/badges/';

  @Input() public test: TemplateTest;
  @Input() public templateTasks: TemplateTask[];
  @Input() public templateTests: TemplateTest[];
  @Input() public timeframeTypes: string[];
  @Output() challengeCancelEvent = new EventEmitter();
  @Output() challengeSaveEvent = new EventEmitter<SaveInfo<TemplateTest>>();

  editChallengeForm: UntypedFormGroup;
  taskName: string;
  ruleName: string;

  working: boolean;
  workingResources: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  showTestBadges: boolean;
  selectedTestBadge: Badge;
  imageModified: boolean;

  timeframeToLabelMapping: Record<Timeframe, string>;
  timeframeValues: Array<string | Timeframe>;

  constructor(
    private rewardsService: RewardsService,
    private imageHelper: HelperImage,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.showTestBadges = false;
    this.imageModified = false;

    this.setupTimeframeSelection();

    this.editChallengeForm = this.fb.group({
      testTask: [this.test.templateTaskKey ?? '', Validators.required],
      testRule: [this.test.ruleKey ?? '', Validators.required],
      testTimeframe: [this.test.timeframe ?? '', Validators.required],
      testTimeframeQuantity: [this.test.timeframeQuantity ?? '', Validators.required],
      testBadgeName: ['']
    });
    this.formChanges();

    if (this.test && this.test.imageUrl) {
      this.selectedTestBadge = {
        fileName: this.test.imageUrl,
        name: this.test.name,
        color: this.test.additionalInfo && this.test.additionalInfo['badgeColor'] && this.test.additionalInfo['badgeColor'].length > 0 ? this.test.additionalInfo['badgeColor'] : null
      };
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.editChallengeForm.controls;
  }

  formChanges(): void {
    // reflect user changes
    this.f['testTask'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.test.templateTaskKey) {
          if (val === this.test.templateTaskKey) {
            this.f['testTask'].markAsPristine();
          }
        }
      }
    });

    this.f['testRule'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.test.ruleKey) {
          if (val === this.test.ruleKey) {
            this.f['testRule'].markAsPristine();
          }
        }
      }
    });

    this.f['testTimeframe'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.test.timeframe) {
          if (val === this.test.timeframe) {
            this.f['testTimeframe'].markAsPristine();
          }
        }
      }
    });
  }

  setupTimeframeSelection(): void {
    // Items needed to create a dropdown from ENUM values
    this.timeframeToLabelMapping = timeframeToLabelMapping;
    this.timeframeTypes = Object.keys(Timeframe).filter((tf) => !isNaN(Number.parseInt(tf, 10)));
    this.timeframeValues = Object.values(Timeframe).filter(x => typeof x === 'string');
  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  getTaskName(e: Event): void {
    const taskElement: HTMLSelectElement = <HTMLSelectElement>e.currentTarget;
    this.taskName = taskElement[taskElement.options.selectedIndex].textContent;
  }

  getRuleName(e: Event): void {
    const taskElement: HTMLSelectElement = <HTMLSelectElement>e.currentTarget;
    this.ruleName = taskElement[taskElement.options.selectedIndex].textContent;
  }

  updateItemWithBadge(badge: Badge, formItem: AbstractControl): void {
    this.imageModified = true;

    this.updateSelectedBadge(badge);
    formItem.setValue(badge.name);
    this.showTestBadges = !this.showTestBadges;

    // Mark the form as dirty so that the modify button is enabled
    this.editChallengeForm.markAsDirty();
  }

  updateSelectedBadge(badge: Badge): void {
    this.selectedTestBadge = badge;
    this.test.additionalInfo = { 'badgeColor': badge.color };
  }

  async updateChallengeInPlan(): Promise<void> {
    // Make sure there are changes to save, if not, close the form
    if (!this.editChallengeForm.dirty) {
      this.challengeCancelEvent.emit();
      return;
    }

    // Update task item from form
    if (this.f['testTask'].value !== this.test.templateTaskKey) {
      this.test.templateTaskKey = this.f['testTask'].value;
      this.test.task = this.taskName;
    }

    // Update the rule
    if (this.f['testRule'].value !== this.test.ruleKey) {
      this.test.ruleKey = this.f['testRule'].value;
      this.test.name = this.ruleName;
    }

    // Update the timeframe
    if (this.f['testTimeframe'].value !== this.test.timeframe) {
      this.test.timeframe = this.f['testTimeframe'].value;
    }

    // Update the duration value
    if (this.f['testTimeframeQuantity'].value !== this.test.timeframeQuantity) {
      this.test.timeframeQuantity = this.f['testTimeframeQuantity'].value;
    }

    // Check the image and update if it is new
    if (this.imageModified) {
      const challengeImage = await this.imageHelper.createImageObject(this.selectedTestBadge, this.BADGE_PATH);
      this.test.image = challengeImage ?? null;
      this.test.imageUrl = this.BADGE_PATH + this.selectedTestBadge.fileName;
    }

    // Save to parent form
    this.challengeSaveEvent.emit({result: this.test, saveType: SaveType.updated});
  }

  cancelChallengeEdit(): void {
    this.challengeCancelEvent.emit();
  }

}
