<div class="container-fluid register company-details">

    <app-registration-header stepNumber="5"></app-registration-header>

    <div class="row">
        <div class="col welcome-col">
            Final step, let's set up auto-pay.  We use <a href="https://stripe.com" target="_blank">Stripe</a> to debit your
            account each month. And because you chose the <span class="plan">[Standard or Plus] plan</span>, we'll also use 
            Stripe to process any of your customer's purchases through the app storefront and deposit earnings directly 
            into your account.
        </div>
    </div>
    <div class="row">
        <div class="col form-col">

            <div class="error alert alert-danger" *ngIf="errorMessage">
                <span class="far fa-exclamation-circle fa-lg icon"></span>
                {{errorMessage}}
            </div>

            <h1>Banking Information</h1>

            <form class="pml-form" [formGroup]="merchantForm" (ngSubmit)="merchantForm.valid" (ngSubmit)="saveMerchantInfo()" autocomplete="off">
                <div class="pml-row">
                    <div class="required full-text-width" *ngIf="isInvalid(f['routingNumber'])">
                        {{getError(f['routingNumber'].errors, 'ROUTING NUMBER')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Bank Routing Number
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control large-text" formControlName="routingNumber" placeholder="routing number" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required full-text-width" *ngIf="isInvalid(f['accountNumber'])">
                        {{getError(f['accountNumber'].errors, 'ACCOUNT NUMBER')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Bank Account Number
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control large-text" formControlName="accountNumber" placeholder="account number" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required full-text-width" *ngIf="isInvalid(f['accountHolderName'])">
                        {{getError(f['accountHolderName'].errors, 'ACCOUNT HOLDER NAME')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Account Holder Name
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control large-text" formControlName="accountHolderName" placeholder="name" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                
                
                <div class="pml-row divider">
                    <h1>Merchant Information</h1>
                    <p>
                        The information below is needed to set up your account in Stripe, which we will do for you.  We just need a these two
                        pieces of information to get started.
                    </p>
                </div>
                <div class="pml-row">
                    <div class="required has-help-icon" *ngIf="isInvalid(f['merchantCode'])">
                        {{getError(f['merchantCode'].errors, 'MERCHANT CATEGORY CODE')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Merchant Category Code
                            <span class="required-icon">*</span>
                        </label>
                        <select class="form-select full-width max-mcc" formControlName="merchantCode">
                            <option value=""></option>
                            <option *ngFor="let mcc of mccList" [value]="mcc.value">{{mcc.name}}</option>
                        </select>
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required full-text-width" *ngIf="isInvalid(f['taxId'])">
                        {{getError(f['taxId'].errors, 'TAX ID/EIN')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Tax ID/EIN
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control large-text" formControlName="taxId" placeholder="tax id/EIN" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="pml-row">
                    <div class="required full-text-width" *ngIf="isInvalid(f['ssn'])">
                        {{getError(f['ssn'].errors, 'SOCIAL SECURITY NUMBER')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Social Security No
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control large-text" formControlName="ssn" placeholder="xxx-xx-xxxx" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div> -->

                <div class="pml-row submit-form pml-actions">
                    <button type="button" class="btn btn-outline-primary cancel" (click)="goBack()">
                        Back
                    </button>
                    <button type="submit" class="btn btn-primary save"
                        [disabled]="saving || !(merchantForm.valid && merchantForm.dirty)">
                        <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                        <span *ngIf="!saving" class="">Continue</span>
                        <span *ngIf="saving" class="">Saving...</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>