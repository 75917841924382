export enum Status {
    Pending = 'Pending',
    Active = 'Active',
    Inactive = 'Inactive',
    Suspended = 'Suspended',
    Migrating = 'Migrating',
    Closed = 'Closed'
}

export const statusToLabelMapping: Record<Status, string> = {
    [Status.Pending]: 'Pending',
    [Status.Active]: 'Active',
    [Status.Inactive]: 'Inactive',
    [Status.Suspended]: 'Suspended',
    [Status.Migrating]: 'Migrating',
    [Status.Closed]: 'Closed'
};
