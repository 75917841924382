import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '../enums/order-status';

@Pipe({
  name: 'filterOrderStatus'
})
export class FilterOrderStatusPipe implements PipeTransform {

/**
 * Statuses:
 *   NEW
 *   - Status changes allowed:
 *      - Cancelled
 *   - Allow capture of payment, allow the admin to take payment on a specific order
 *
 *   PENDING
 *   - Status changes allowed:
 *      - Cancelled
 *   - Shouldn't stay in this status for longer than capturing and processing payments.  Eventually should end up as Incomplete or Finalize if the payments were handled with an error or successful respectively.
 *
 *   FINALIZE
 *   - Status changes allowed:
 *      - Cancelled
 *   - Should have a way to commit the order.  Finalize is the status saying that the order is in balance and ready to be committed.
 *
 *   RECEIVED
 *   - Status changes allowed:
 *      - Pick Up
 *      - Shipped
 *      - Complete
 *      - Cancelled
 *
 *   PICK UP
 *   - Status changes allowed:
 *      - Complete
 *      - Cancelled
 *
 *   SHIPPED
 *   - Status changes allowed:
 *      - Complete
 *      - Cancelled
 *
 *   COMPLETE
 *   - Status changes allowed:
 *      - Cancelled
 *
 *   INCOMPLETE
 *   - This is where we would need to inspect the order to see if we can resolve payments or whatnot
 *
 *   CANCELLED
 *   - No changes allowed at this point.  Create new order if you want to restart it.
 *
 */
  transform(statuses: { key: string; value: string; }[], selectedOrderStatus: string): { key: string; value: string; }[] {
    if (!statuses) {
      return null;
    }

    let returnStatuses: { key: string; value: string; }[];

    switch (selectedOrderStatus.toLocaleUpperCase()) {
      case OrderStatus.NEW.toLocaleUpperCase(): {
        returnStatuses = statuses.filter(x =>
          x.key === OrderStatus.CANCELLED.toLocaleUpperCase() ||
          x.key === OrderStatus.NEW.toLocaleUpperCase());
        break;
      }
      case OrderStatus.PENDING.toLocaleUpperCase(): {
        returnStatuses = statuses.filter(x =>
          x.key === OrderStatus.CANCELLED.toLocaleUpperCase() ||
          x.key === OrderStatus.PENDING.toLocaleUpperCase());
        break;
      }
      case OrderStatus.FINALIZE.toLocaleUpperCase(): {
        returnStatuses = statuses.filter(x =>
          x.key === OrderStatus.CANCELLED.toLocaleUpperCase() ||
          x.key === OrderStatus.FINALIZE.toLocaleUpperCase());
        break;
      }
      case OrderStatus.RECEIVED.toLocaleUpperCase(): {
        returnStatuses = statuses.filter(x =>
          x.key === OrderStatus.PICKUP.toLocaleUpperCase() ||
          x.key === OrderStatus.COMPLETE.toLocaleUpperCase() ||
          x.key === OrderStatus.CANCELLED.toLocaleUpperCase() ||
          x.key === OrderStatus.RECEIVED.toLocaleUpperCase());
        break;
      }
      case OrderStatus.PICKUP.toLocaleUpperCase().replace(' ', ''): {
        returnStatuses = statuses.filter(x =>
          x.key === OrderStatus.COMPLETE.toLocaleUpperCase() ||
          x.key === OrderStatus.CANCELLED.toLocaleUpperCase() ||
          x.key === OrderStatus.PICKUP.toLocaleUpperCase().replace(' ', ''));
        break;
      }
      case OrderStatus.PROCESSING.toLocaleUpperCase(): {
        returnStatuses = statuses.filter(x => x.key === OrderStatus.CANCELLED.toLocaleUpperCase());
        break;
      }
      default: {
        // INCOMPLETE
        // CANCELLED
        // In both cases, no menu should be shown
        returnStatuses = null;
        break;
      }
    }



    return returnStatuses;
  }

}
