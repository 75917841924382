<form [formGroup]="editMilestoneForm" class="edit-form" (ngSubmit)="editMilestoneForm.valid && updateMilestoneInPlan()" autocomplete="off">
    <div class="pml-row add-item-row first" *ngIf="!workingResources">
        <div class="pml-col add-badge">
            <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                context: {section: 'levels', selectedBadge: selectedMilestoneBadge, showBadgeSelection: showMilestoneBadges}"></ng-container>    
        </div>
        <div class="pml-col">
            <label>Task</label>
            <span class="required" *ngIf="isInvalid(f['levelTask'])">{{getError(f['levelTask'].errors)}}</span>
            <select class="form-select bonus-type-dd" formControlName="levelTask" (change)="getTaskName($event)">
                <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
                <option *ngFor="let task of templateTasks" [ngValue]="task.key">{{task.name}}</option>
            </select>
        </div>
        <div class="pml-col actions">
            <input type="hidden" formControlName="levelInterval" value="Ever" />
            <input type="hidden" formControlName="levelOccurrence" value="1" />
            <input type="hidden" formControlName="levelRequiresReview" value="true" />

            <button type="submit" class="btn btn-primary nested-submit" [disabled]="working || !(editMilestoneForm.valid && editMilestoneForm.dirty)">
                Modify
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="cancelMilestoneEdit()">Cancel</button>
        </div>
    </div>
    <div class="pml-row add-item-row add-item-icon" *ngIf="!workingResources">
        <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
            context: {form: editMilestoneForm, badgeControlName:'levelBadgeName', formGet: f, selectedBadge: selectedMilestoneBadge, showBadgeSelection: showMilestoneBadges}"></ng-container>
    </div>
</form>


<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet">
    <ng-container [formGroup]="form">
        
    </ng-container>
</ng-template>

<ng-template #badgeSelectionTemplate 
    let-form="form" 
    let-badgeControlName="badgeControlName" 
    let-formGet="formGet" 
    let-selectedBadge="selectedBadge"
    let-showBadgeSelection="showBadgeSelection">
    <ng-container [formGroup]="form">
        <div class="pml-col">
            <input type="hidden" [formControlName]="badgeControlName" value="" />

            <app-choose-badge [selectedBadge]="selectedBadge" 
                (closeBadgeEvent)="showMilestoneBadges = !showMilestoneBadges"
                (selectBadgeEvent)="updateItemWithBadge($event, formGet[badgeControlName])" 
                *ngIf="showBadgeSelection"></app-choose-badge>
        </div>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionBtnTemplate let-selectedBadge="selectedBadge" let-showBadgeSelection="showBadgeSelection">
    <span class="fa-stack fa-xs {{showBadgeSelection ? 'active' : ''}}" (click)="showMilestoneBadges = !showMilestoneBadges" 
          title="{{!selectedBadge ? 'Add Icon' : 'Change Icon'}}" 
          *ngIf="!selectedBadge">
        <i class="fa-light fa-image fa-stack-2x"></i>
        <i class="fa-regular fa-circle-plus fa-stack-1x"></i>
    </span>
    <div class="img" 
        style.backgroundColor="{{selectedBadge.color ? selectedBadge.color : 'rgba(0,0,0,0.08)'}}"
        *ngIf="selectedBadge">
        <img src="{{imageModified ? BADGE_PATH : ''}}{{selectedBadge?.fileName}}" 
            title="{{selectedBadge?.name}}" 
            class="selected-badge {{showBadgeSelection ? 'active' : ''}}" 
            (click)="showMilestoneBadges = !showMilestoneBadges" />
    </div>
</ng-template>

