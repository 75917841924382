import { APP_INITIALIZER } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { RewardsService } from '../services/rewards.service';

export const appInitializer = (
    authService: AuthService,
    localStorageService: LocalStorageService ): any =>
    () => new Promise((resolve: any) => {
        // check to see if a token is present in the localstore.  If not, proceed to login (the
        // app-routing will handle that automagically).  If there is a token, need to check to see
        // if it is valid (could simply be present from the last use if the user didn't log out).  To
        // check, we call the tryToken function which will perform a simple GET.  If the token is not
        // valid an error will be thrown and handled appropriately.
        if (localStorageService.authTokenExists()) {
            // if a token exists, need to see if it is an actual valid token.
            authService.tryToken()
                .subscribe()
                .add(resolve);
        } else {
            // Promise.resolve('success');
            resolve('No token');
        }
    });

