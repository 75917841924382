import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { AppUser } from 'src/app/core/models/app-user';
import { PaymentLinkResponse } from 'src/app/core/models/registration/payment-link-response';
import { Tenant } from 'src/app/core/models/tenant';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { TenantService } from 'src/app/core/services/tenant.service';

const ACCESS_TOKEN = 'access_token';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['../register.component.scss', './register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  companyForm: UntypedFormGroup;
  user: AppUser;

  constructor(
    private router: Router,
    private authService: AuthService,
    private tenantService: TenantService,
    private paymentService: PaymentService,
    private localStorageService: LocalStorageService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;

    this.getUser();

    this.createForm();
  }

  getUser(): void {
    this.authService.getRegisterUser().subscribe({
      next: (response: any) => {
        this.user = response.data;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.companyForm.controls;
  }

  createForm(): void {
    this.companyForm = this.fb.group({
      companyName: ['', Validators.required],
      companyType: ['DENTAL']
    });

  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    return 'Error';
  }

  registerCompany(): void {
    this.saving = true;
    //const newUser: AppUser = this.populateNewUserFromFormInput();
    const companyName: string = this.companyForm.get('companyName').value;
    const companyType: string = this.companyForm.get('companyType').value;

    this.tenantService.createNewTenant(companyName, companyType).pipe(
      concatMap((response: any) => {

        const newTenantKey = response.key;
        const newTenant: Tenant = response;

        // Save new tenant info to local storage
        this.tenantService.setCurrentTenant(newTenant);

        return this.authService.switchTenantKey(newTenantKey);
      }),
      concatMap((response: any) => {
        const newTenantToken: string = response.token;
        //const newTenant: Tenant = response.data.tenant;

        // Save new token to local storage
        this.localStorageService.saveToken(ACCESS_TOKEN, newTenantToken);  // This is the updated tenant token

        // Create the return and refresh urls
        const urlBase = location.origin;  // e.g. https://localhost:4200 or https://uat.pummelo.io
        const returnUrl = `${urlBase}/register/register-plan`;
        const refreshUrl = `${urlBase}/register/register-plan`;

        return this.paymentService.getStripeOnboardingUrl(returnUrl, refreshUrl);
      })
    ).subscribe({
      next: (response: any) => {
        this.saving = false;

        // Get URL for STRIPE onboarding
        const stripeResponse: PaymentLinkResponse = response;
        if (stripeResponse && stripeResponse.link && stripeResponse.link.length > 0) {
          window.location.href = stripeResponse.link;
        } else {
          this.errorMessage = 'Error: No Stripe onboarding URL returned';
        }
      },
      error: (err: any) => {
        this.saving = false;
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  goBack(): void {
    // Back to REGISTRATION page
    this.router.navigateByUrl('/register');
  }

}
