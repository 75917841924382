<div class="container-fluid overflow-hidden">
  <div class="row vh-100 overflow-auto">
    <div class="col-auto px-0 d-flex sticky-top vh-100" *ngIf="isLoggedIn && !isRegistrationFlow">

      <app-sidebar></app-sidebar>

    </div>
    <div class="col px-0 body-col">

      <app-header *ngIf="isLoggedIn && !isRegistrationFlow"></app-header>

      <div class="body-container container">
        <router-outlet></router-outlet>
      </div>

      <app-footer></app-footer>

    </div>

  </div>
</div>