import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceType } from '../models/reward/resource-type';
import { TemplatePlan } from '../models/reward/template-plan';
import { TemplateTask } from '../models/reward/template-task';
import { TemplateTest } from '../models/reward/template-test';
import { Rule } from '../models/reward/rule';
import { RuleResource } from '../models/reward/rule-resource';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';
import { TemplatePlansResponse } from '../models/reward/template-plans-response';
import { UserPlanResponse } from '../models/reward/user-plan-response';
import { TemplateTaskResponse } from '../models/reward/template-task-response';
import { TemplateTestResponse } from '../models/reward/template-test-response';
import { UserPlanActivitySummary } from '../models/reward/user-plan-activity-summary';
import { DatePipe } from '@angular/common';
import { query } from '@angular/animations';
import { PlanItemRequest } from '../models/reward/plan-item-request';
import { TenantService } from './tenant.service';
import { Tenant } from '../models/tenant';
import { UserPlanCreateRequest } from '../models/reward/user-plan-create-request';
import { UserPlanCreateRequestPatch } from '../models/reward/user-plan-create-request-patch';

@Injectable({
  providedIn: 'root'
})
export class RewardsService extends BaseService {

  endpointReward = environment.rewardUrl;
  headersReward = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.rewardSubscriptionKey);

  public bonusTypeResources: Observable<ResourceType[]>;
  private bonusTypeResourcesSubject: BehaviorSubject<ResourceType[]>;

  public resultTypeResources: Observable<ResourceType[]>;
  private resultTypeResourcesSubject: BehaviorSubject<ResourceType[]>;

  constructor(
    http: HttpClient,
    tenantService: TenantService,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe) {

    super(http, tenantService);

    this.loadResources();
  }

  // RULE RESOURCES
  loadResources(): void {
    this.bonusTypeResourcesSubject = new BehaviorSubject<ResourceType[]>(null);
    this.bonusTypeResources = this.bonusTypeResourcesSubject.asObservable();
    this.resultTypeResourcesSubject = new BehaviorSubject<ResourceType[]>(null);
    this.resultTypeResources = this.resultTypeResourcesSubject.asObservable();

    this.getBonusTypeResources().subscribe({
      next: (response: ResourceType[] | any) => {
        this.bonusTypeResourcesSubject.next(response);
        this.bonusTypeResources = this.bonusTypeResourcesSubject?.asObservable();
      },
      error: (err: any) => {
        // TODO: Do something with the error
        console.log(err);
      }
    });

    this.getResultTypeResources().subscribe({
      next: (response: ResourceType[] | any) => {
        this.resultTypeResourcesSubject.next(response);
        this.resultTypeResources = this.resultTypeResourcesSubject?.asObservable();
      },
      error: (err: any) => {
        // TODO: Do something with the error
        console.log(err);
      }
    });
  }

  getRuleResources(): Observable<RuleResource> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/rule/resources`;

    return this.executeApi<RuleResource>('get', apiUrl, this.headersReward);
  }

  getBonusTypeResources(): Observable<ResourceType> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/resources/bonusTypes`;

    return this.executeApi<ResourceType>('get', apiUrl, this.headersReward);
  }

  getResultTypeResources(): Observable<ResourceType> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/resources/resultTypes`;

    return this.executeApi<ResourceType>('get', apiUrl, this.headersReward);
  }

  // RULES
  getRules(): Observable<Rule> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/rules`;

    return this.executeApi<Rule>('get', apiUrl, this.headersReward);
  }

  createNewRule(newRule: Rule): Observable<Rule> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/rule`;

    return this.executeApiPost<Rule>(apiUrl, this.headersReward, newRule);
  }

  updateRule(ruleToUpdate: Rule): Observable<Rule> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/rule/${ruleToUpdate.key}`;

    return this.executeApiPatch<Rule>(apiUrl, this.headersReward, ruleToUpdate);
  }

  deleteRule(key: string): Observable<Rule> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/rule/${key}`;

    return this.executeApi<ResourceType>('delete', apiUrl, this.headersReward);
  }

  // TEMPLATE PLANS
  getTemplatePlans(): Observable<TemplatePlansResponse> {
    const key = this.tenantService.currentTenant.pipe(map(tenant => { return tenant.key }));
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/plans`;

    return this.executeApi<TemplatePlansResponse>('get', apiUrl, this.headersReward);
  }

  createNewTemplatePlan(newPlan: TemplatePlan): Observable<TemplatePlan> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/plan`;

    return this.executeApiPost<TemplatePlan>(apiUrl, this.headersReward, newPlan);
  }

  updateTemplatePlan(planToUpdate: TemplatePlan): Observable<TemplatePlan> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/plan/${planToUpdate.key}`;

    return this.executeApiPatch<TemplatePlan>(apiUrl, this.headersReward, planToUpdate);
  }

  deleteTemplatePlan(key: string): Observable<TemplatePlan> {  // TODO:  Get the right return object
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/plan/${key}`;

    return this.executeApi<TemplatePlan>('delete', apiUrl, this.headersReward);
  }

  // TEMPLATE TESTS
  getTemplateTests(): Observable<TemplateTestResponse> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/test/rules`;

    return this.executeApi<TemplateTestResponse>('get', apiUrl, this.headersReward);
  }

  // TEMPLATE TASKS
  getTemplateTasks(): Observable<TemplateTaskResponse> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/tasks`;

    return this.executeApi<TemplateTaskResponse>('get', apiUrl, this.headersReward);
  }

  createNewTemplateTask(newTask: TemplateTask): Observable<TemplateTask> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/task`;

    return this.executeApiPost<TemplateTask>(apiUrl, this.headersReward, newTask);
  }

  updateTemplateTask(taskToUpdate: TemplateTask): Observable<TemplateTask> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/task/${taskToUpdate.key}`;

    return this.executeApiPatch<TemplateTask>(apiUrl, this.headersReward, taskToUpdate);
  }

  deleteTemplateTask(key: string): Observable<TemplateTask> {  // TODO:  Get the right return object, probably a void?
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/template/task/${key}`;

    return this.executeApi<TemplateTask>('delete', apiUrl, this.headersReward);
  }

  // USER PLANS
  getUserPlanByKey(userKey: string, taskDate: string, planDate: string): Observable<UserPlanResponse> {
    const querystring = `taskDate=${taskDate}&planDate=${planDate}`;
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan?${querystring}`;

    return this.executeApi<UserPlanResponse>('get', apiUrl, this.headersReward);
  }

  enrollUserInPlan(userKey: string, templatePlanKeys: string[]): Observable<UserPlanResponse> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/plan`;

    // REFERENCE: http://reward-dev.workscale.io/api/swagger/ui#/Manage%20-%20Tenant%20-%20App/TenantAppManageUserPlanPost
    // Create object to submit
    const bodyData: UserPlanCreateRequest = {
      userKey: userKey,
      templatePlanKeys: templatePlanKeys.map(key => { return { templatePlanKey: key } })
    };

    return this.executeApiPost<any>(apiUrl, this.headersReward, bodyData);
  }

  updateUserPlanByTemplateKeys(userKey: string, templatePlanKeys: string[]): Observable<UserPlanResponse> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan`;

    // TODO:  Talk to Darin, the POST and PATCH templatePlanKeys have different structures
    const bodyData: UserPlanCreateRequestPatch = {
      userKey: userKey,
      templatePlanKeys: templatePlanKeys
    };

    return this.executeApiPatch<UserPlanResponse>(apiUrl, this.headersReward, bodyData);
  }

  updateUserPlan(userKey: string, planItemToAdd: PlanItemRequest): Observable<UserPlanResponse> {
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan`;

    return this.executeApiPatch<UserPlanResponse>(apiUrl, this.headersReward, planItemToAdd);
  }

  updateUserPlanWithDetailResponse(userKey: string, planItemToAdd: PlanItemRequest, taskDate: string, planDate: string): Observable<UserPlanResponse> {
    const querystring = `taskDate=${taskDate}&planDate=${planDate}`;
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan/detailed?${querystring}`;

    return this.executeApiPatch<UserPlanResponse>(apiUrl, this.headersReward, planItemToAdd);
  }

  getUserPlanActivityByDateRange(
    userKey: string,
    startDate: Date,
    endDate: Date,
    evaluationType: string = 'ByOccurrence',
    timeframeType: string = 'Month'): Observable<UserPlanActivitySummary[]> {

    const startDateFormatted: string = this.datePipe.transform(startDate, 'MM-dd-yyyy');
    const endDateFormatted: string = this.datePipe.transform(endDate, 'MM-dd-yyyy');
    const querystring = `startDate=${startDateFormatted}&endDate=${endDateFormatted}&dataPointTimeframe=${timeframeType}&dataPointEvaluation=${evaluationType}`;
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan/activity/summary?${querystring}`;

    return this.executeApi<UserPlanActivitySummary[]>('get', apiUrl, this.headersReward);
  }

  updateUserPlanActivityByKey(userKey: string, userPlanKey: string, activityDate: string, planDate: string = ''): Observable<any> {
    const querystring = planDate !== '' ? `?planDate=${planDate}` : '';
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan/activity/delta${querystring}`;

    const bodyData: any = {};
    bodyData.userPlanTaskKey = userPlanKey;
    bodyData.completedDate = activityDate;  // Must be MM-dd-yyyy

    const myHeadersReward = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.rewardSubscriptionKey)
      .set('observe', 'response');

    return this.executeApiPost<any>(apiUrl, myHeadersReward, bodyData);
  }

  deleteUserPlanActivityByKey(userKey: string, userPlanCompletionKey: string, planDate: string = ''): Observable<any> {
    const querystring = planDate !== '' ? `?planDate=${planDate}` : '';
    const apiUrl = `${this.endpointReward}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/plan/activity/delta/${userPlanCompletionKey}${querystring}`;

    const myHeadersReward = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.rewardSubscriptionKey)
      .set('observe', 'response');

    return this.executeApi<any>('delete', apiUrl, this.headersReward);
  }

}
