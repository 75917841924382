<div class="container-fluid register">
    
    <app-registration-header stepNumber="1"></app-registration-header>

    <div class="row">
        <div class="col welcome-col">
            Great!  Let's get your company signed up.  You'll have a chance to review and 
            select your plan before you're asked to pay.  The first step is register a new
            account.
        </div>
    </div>
    <div class="row">
        <div class="col form-col">

            <h1>Registration</h1>

            <div class="error alert alert-danger" *ngIf="errorMessage">
                <span class="far fa-exclamation-circle fa-lg icon"></span>
                {{errorMessage}}
            </div>

            <form class="pml-form" [formGroup]="registrationForm" (ngSubmit)="registrationForm.valid" (ngSubmit)="registerUser()" autocomplete="off">

                <div class="pml-row">
                    <span class="required" *ngIf="isInvalid(f['firstName']) && isInvalid(f['lastName'])">{{getError(f['firstName'].errors, 'FIRST & LAST name')}}</span>
                    <span class="required" *ngIf="isInvalid(f['firstName']) && !isInvalid(f['lastName'])">{{getError(f['firstName'].errors, 'FIRST name')}}</span>
                    <span class="required" *ngIf="isInvalid(f['lastName']) && !isInvalid(f['firstName'])">{{getError(f['lastName'].errors, 'LAST name')}}</span>

                    <div class="input-row">
                        <label class="name not-required">
                            name
                        </label>
                        <div class="input-data name">
                            <input type="text" class="form-control med-text first" formControlName="firstName" placeholder="first name" />
                            <input type="text" class="form-control middle" formControlName="middleName" placeholder="middle" />
                            <input type="text" class="form-control last-name" formControlName="lastName" placeholder="last name" />
                        </div>
                    </div>
                    <div class="input-row suffix-row {{toggleSuffix ? 'show' : ''}}">
                        <a [routerLink]="[]" class="add-sfx" (click)="toggleSuffix = !toggleSuffix">
                            <span class="far fa-{{toggleSuffix ? 'minus' : 'plus'}} fa-sm fa-fw icon"></span>
                            {{toggleSuffix ? 'hide' : 'add'}} suffix
                        </a>
                        <input type="text" class="form-control short-text suffix" formControlName="suffix" placeholder="suffix" *ngIf="toggleSuffix" />
                    </div>
                </div>
                <div class="pml-row email-row">
                    <div class="required" *ngIf="isInvalid(f['emailAddress'])">
                        {{getError(f['emailAddress'].errors, 'Email address')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Choose a user name
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control email" formControlName="emailAddress" placeholder="email" />
                    </div>
                </div>
                <div class="pml-row w-fit-content">
                    <div class="input-row">
                        <label>
                            Choose a password
                            <span class="required-icon">*</span>
                        </label>
                        <input type="password" class="form-control password" formControlName="password" placeholder="password" />
                        <div class="required {{f['password'].errors?.['pattern'] ? 'password' : ''}}" *ngIf="isInvalid(f['password'])">
                            <span class="required-text">
                                {{getError(f['password'].errors, 'password')}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="pml-row w-fit-content">
                    <div class="input-row">
                        <label>
                            confirm password
                            <span class="required-icon">*</span>
                        </label>
                        <input type="password" class="form-control password" formControlName="confirmPassword" placeholder="confirm password" />
                        <div class="required password confirm" *ngIf="isInvalid(f['confirmPassword'])">
                            {{getError(f['confirmPassword'].errors, 'Confirm Password')}}
                        </div>
                    </div>
                </div>
                <div class="pml-row submit-form pml-actions">
                    <button type="button" class="btn btn-outline-primary cancel" (click)="returnToLogin()">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary save"
                        [disabled]="saving || !(registrationForm.valid && registrationForm.dirty)">
                        <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                        <span *ngIf="!saving" class="">Register</span>
                        <span *ngIf="saving" class="">Registering...</span>
                    </button>
                </div>

            </form>

        </div>
    </div>

    <div class="row temp-row">
        <a [routerLink]="[]" (click)="tempLogin()">Temp Login</a>
    </div>


</div>