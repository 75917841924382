<h1>Tasks</h1>

<ng-container *ngTemplateOutlet="contentHeaderTemplate"></ng-container>

<app-loading loadingText="loading active tasks..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<ng-container *ngTemplateOutlet="itemGridTemplate; context: {tasks: tasks}"></ng-container>

<ng-template #contentHeaderTemplate>
    <div class="content-header">
        <div class="customer-actions" *ngIf="!working">
            <a [routerLink]="[]" class="new action-item first" (click)="showEditForm = -2">
                <span class="fal fa-plus icon"></span>
                new
            </a>

            <div class="new-task" *ngIf="showEditForm === -2">
                <app-task [task]=""
                    [showLabel]="true"
                    [isNewTask]="true" 
                    (taskSaveEvent)="saveTask($event)" 
                    (taskCancelEvent)="closeTask()">
                </app-task>
            </div>
    
        </div>
        <div class="item-count" *ngIf="!working && errorMessage.length === 0 && tasks.length > 0">
            <span class="count">{{tasks.length}}</span> {{tasks.length === 1 ? 'task' : 'tasks'}} found
        </div>
    </div>
</ng-template>

<ng-template #itemGridTemplate let-tasks="tasks">
    <div class="items" *ngIf="!working && tasks.length > 0">
        <div class="item {{i === 0 ? 'first' : ''}}" *ngFor="let task of tasks; index as i">
            <div class="item-detail" (click)="showEditForm = i" *ngIf="showEditForm !== i">
                <div class="name">{{task.name}}</div>
                <div class="actions">
                    <span class="action-item edit">
                        <span class="text">edit</span>
                        <span class="fad fa-edit icon"></span>
                    </span>
                </div>
            </div>
            
            <div class="item-detail" *ngIf="showEditForm === i">
                <app-task [task]="task"
                    [showLabel]="false"
                    [isNewTask]="false" 
                    (taskSaveEvent)="saveTask($event)" 
                    (taskCancelEvent)="closeTask()">
                </app-task>
            </div>

            <div class="actions">
                <span class="action-item delete" (click)="openConfirmTaskDeleteDialog($event, task)">
                    <span class="text">delete</span>
                    <span class="far fa-trash-alt icon"></span>
                </span>
            </div>
            <div class="item-delete-overlay">
                <div class="fa-duotone fa-spinner fa-pulse fa-lg icon"></div>
                <div class="text">deleting...</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #editTaskTemplate let-task="task" let-showLabel="showLabel" let-isNewTask="isNewTask">
    
</ng-template>