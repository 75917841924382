import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataAreYouSure } from 'src/app/core/models/dialog-data-are-you-sure';

@Component({
  selector: 'app-dialog-are-you-sure',
  templateUrl: './dialog-are-you-sure.component.html',
  styleUrls: ['./dialog-are-you-sure.component.scss']
})
export class DialogAreYouSureComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogAreYouSureComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAreYouSure) { }

  ngOnInit(): void {
  }

  confirmDelete(): void {
    this.dialogRef.close({
      data: {
        confirmDelete: true
      }
    });
  }

}
