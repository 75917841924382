import { TemplatePlan } from './template-plan';

export interface PlanSaveInfo {
    plan: TemplatePlan;
    saveType: PlanSaveType
}

export enum PlanSaveType {
    newPlan = 'NewPlan',
    updatedPlan = 'UpdatedPlan'
}
