import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  qrHtml: SafeHtml;
  companyName: string;

  constructor(
    private tenantService: TenantService,
    private parseErrorService: ParseErrorService,
    private sanitizer: DomSanitizer
) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    // Get the tenant name
    const currentTenant = this.tenantService.getCurrentTenant();
    this.companyName = currentTenant.name;

    this.getQrCode();
  }

  getQrCode(): void {
    this.working = true;

    this.tenantService.getTenantQrCode().subscribe({
      next: (response: string | any) => {
        const qrCodeString = response;

        const parser = new DOMParser();
        const doc = parser.parseFromString(qrCodeString, 'text/html');
        this.qrHtml = this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

}
