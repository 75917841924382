export const AVATAR_COLORS: string[] = [
    '#889194',
    '#ACD91C',
    '#FCD139',
    '#DC9C62',
    '#7ED9A7',
    '#7674E2',
    '#FECB5B',
    '#FF6D6D',
    '#D8A5F0',
    '#489BE8',
    '#71B945',
    '#F181BF',
    '#FF8F9D',
];
