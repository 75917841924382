<footer class="container">
    <div class="footer">
        &copy; {{currentYear}}
        <span class="name">
            <span class="pummelo">Pummelo</span>, a work<span class="scale">scale</span>.io product
        </span>

        <div class="right-content">
            <a href="#">privacy policy</a>
            <a href="#">user agreement</a>
        </div>
    </div>
</footer>