import { Badge } from '../models/badge';

export const BADGES: Badge[] = [
    //Potential groups (might change):
    //Motivational, Medical, Food, Action, Athletic, Numbers, Objects, Electronics, Nature, Social Media, Whimsical,

    { name: 'Number 1', fileName: 'badge-1.png', color: '#7dcc3c', group: 'Numbers' },
    { name: 'Number 2', fileName: 'badge-2.png', color: '#489be8', group: 'Numbers' },
    { name: 'Number 3', fileName: 'badge-3.png', color: '#fcd139', group: 'Numbers' },
    { name: 'Number 4', fileName: 'badge-4.png', color: '#ed6666', group: 'Numbers' },
    { name: 'Number 5', fileName: 'badge-5.png', color: '#f37bca', group: 'Numbers' },
    { name: 'Number 6', fileName: 'badge-6.png', color: '#add921', group: 'Numbers' },
    { name: 'Number 7', fileName: 'badge-7.png', color: '#5a81e5', group: 'Numbers' },
    { name: 'Number 10', fileName: 'badge-10.png', color: '#9a6ee2', group: 'Numbers' },
    { name: 'Number 14', fileName: 'badge-14.png', color: '#7dcc3c', group: 'Numbers' },
    { name: 'Number 15', fileName: 'badge-15.png', color: '#fe7c44', group: 'Numbers' },
    { name: 'Number 20', fileName: 'badge-20.png', color: '#ff7070', group: 'Numbers' },
    { name: 'Number 21', fileName: 'badge-21.png', color: '#ffa843', group: 'Numbers' },
    { name: 'Number 25', fileName: 'badge-25.png', color: '#56c9d9', group: 'Numbers' },
    { name: 'Number 28', fileName: 'badge-28.png', color: '#489be8', group: 'Numbers' },
    { name: 'Number 30', fileName: 'badge-30.png', color: '#ff8f9d', group: 'Numbers' },
    { name: 'Add', fileName: 'badge-add.png', color: '#9a6ee2', group: 'Numbers' },
    { name: 'Unknown', fileName: 'badge-unknown.png', color: '#7dcc3c', group: 'Numbers' },
    { name: 'Facebook', fileName: 'badge-facebook.png', color: '#489be8', group: 'Social Media' },
    { name: 'Instagram', fileName: 'badge-instagram.png', color: '#ed6666', group: 'Social Media' },
    { name: 'Twitter', fileName: 'badge-twitter.png', color: '#fcd139', group: 'Social Media' },
    { name: 'Strawberry', fileName: 'badge-strawberry.png', color: '#9a6ee2', group: 'Food' },
    { name: 'Cucumber', fileName: 'badge-cucumber.png', color: '#7dcc3c', group: 'Food' },
    { name: 'Carrot', fileName: 'badge-carrot.png', color: '#5a81e5', group: 'Food' },
    { name: 'Coffee', fileName: 'badge-coffee.png', color: '#dc9c62', group: 'Food' },
    { name: 'Breakfast', fileName: 'badge-breakfast.png', color: '#489be8', group: 'Food' },
    { name: 'Steak', fileName: 'badge-steak.png', color: '#fcd139', group: 'Food' },
    { name: 'Watermelon', fileName: 'badge-watermelon.png', color: '#489be8', group: 'Food' },
    { name: 'Ice Cream Sunday', fileName: 'badge-ice-cream.png', color: '#ce8aee', group: 'Food' },
    { name: 'Ice Cream Cone', fileName: 'badge-ice-cream-cone.png', color: '#489be8', group: 'Food' },
    { name: 'Candy 1', fileName: 'badge-candy1.png', color: '#ce8aee', group: 'Food' },
    { name: 'Candy 2', fileName: 'badge-candy2.png', color: '#7ed9a7', group: 'Food' },
    { name: 'Candy 3', fileName: 'badge-candy3.png', color: '#7dcc3c', group: 'Food' },
    { name: 'Apple', fileName: 'badge-apple.png', color: '#add921', group: 'Food' },
    { name: 'Banana', fileName: 'badge-banana.png', color: '#9a6ee2', group: 'Food' },
    { name: 'Baby Bottle', fileName: 'badge-baby-bottle.png', color: '#7ed9a7', group: 'Food' },
    { name: 'Trophy 1', fileName: 'badge-trophy1.png', color: '#489be8', group: 'Motivational' },
    { name: 'Trophy 2', fileName: 'badge-trophy2.png', color: '#ed6666', group: 'Motivational' },
    { name: 'Trophy 3', fileName: 'badge-trophy3.png', color: '#ffa843', group: 'Motivational' },
    { name: 'Trophy 4', fileName: 'badge-trophy4.png', color: '#5a81e5', group: 'Motivational' },
    { name: 'Medal 1', fileName: 'badge-medal1.png', color: '#ed6666', group: 'Motivational' },
    { name: 'Medal 2', fileName: 'badge-medal2.png', color: '#add921', group: 'Motivational' },
    { name: 'Medal 3', fileName: 'badge-medal3.png', color: '#ce8aee', group: 'Motivational' },
    { name: 'Medal 4', fileName: 'badge-medal4.png', color: '#7dcc3c', group: 'Motivational' },
    { name: 'Medal 5', fileName: 'badge-medal5.png', color: '#add921', group: 'Motivational' },
    { name: 'Medal 6', fileName: 'badge-medal6.png', color: '#fcd139', group: 'Motivational' },
    { name: 'Medal 7', fileName: 'badge-medal7.png', color: '#ffa843', group: 'Motivational' },
    { name: 'Medal 8', fileName: 'badge-medal8.png', color: '#56c9d9', group: 'Motivational' },
    { name: 'Medal 9', fileName: 'badge-medal9.png', color: '#7ed9a7', group: 'Motivational' },
    { name: 'Medal 10', fileName: 'badge-medal10.png', color: '#489be8', group: 'Motivational' },
    { name: 'Star Gold', fileName: 'badge-star-gold.png', color: '#ed6666', group: 'Motivational' },
    { name: 'Star Silver', fileName: 'badge-star-silver.png', color: '#9a6ee2', group: 'Motivational' },
    { name: 'Star Bronze', fileName: 'badge-star-bronze.png', color: '#7dcc3c', group: 'Motivational' },
    { name: 'Bullseye', fileName: 'badge-bullseye.png', color: '#ff7070', group: 'Motivational' },
    { name: 'Spell Red', fileName: 'badge-spell-red.png', color: '#ff8f9d', group: 'Whimsical' },
    { name: 'Spell Purple', fileName: 'badge-spell-purple.png', color: '#9a6ee2', group: 'Whimsical' },
    { name: 'Spell Blue', fileName: 'badge-spell-blue.png', color: '#489be8', group: 'Whimsical' },
    { name: 'Spell Green', fileName: 'badge-spell-green.png', color: '#add921', group: 'Whimsical' },
    { name: 'Spell Orange', fileName: 'badge-spell-orange.png', color: '#ffa843', group: 'Whimsical' },
    { name: 'Face Cards', fileName: 'badge-cards.png', color: '#56c9d9', group: 'Whimsical' },
    { name: 'Face Card', fileName: 'badge-card.png', color: '#ce8aee', group: 'Whimsical' },
    { name: 'Puzzle', fileName: 'badge-puzzle.png', color: '#489be8', group: 'Whimsical' },
    { name: 'Gem White', fileName: 'badge-gem-white.png', color: '#7ed9a7', group: 'Whimsical' },
    { name: 'Gem Pink', fileName: 'badge-gem-pink.png', color: '#f37bca', group: 'Whimsical' },
    { name: 'Gem Purple', fileName: 'badge-gem-purple.png', color: '#ce8aee', group: 'Whimsical' },
    { name: 'Gem Red', fileName: 'badge-gem-red.png', color: '#ff8f9d', group: 'Whimsical' },
    { name: 'Gem Orange', fileName: 'badge-gem-orange.png', color: '#ffa843', group: 'Whimsical' },
    { name: 'Gem Green', fileName: 'badge-gem-green.png', color: '#7dcc3c', group: 'Whimsical' },
    { name: 'Gem Blue', fileName: 'badge-gem-blue.png', color: '#5a81e5', group: 'Whimsical' },
    { name: 'Balloon Bunch', fileName: 'badge-balloon-bunch.png', color: '#7ed9a7', group: 'Whimsical' },
    { name: 'Balloon Orange', fileName: 'badge-balloon-orange.png', color: '#ffa843', group: 'Whimsical' },
    { name: 'Balloon Red', fileName: 'badge-balloon-red.png', color: '#ff8f9d', group: 'Whimsical' },
    { name: 'Balloon Purple', fileName: 'badge-balloon-purple.png', color: '#ce8aee', group: 'Whimsical' },
    { name: 'Balloon Blue', fileName: 'badge-balloon-blue.png', color: '#489be8', group: 'Whimsical' },
    { name: 'Balloon Green', fileName: 'badge-balloon-green.png', color: '#7dcc3c', group: 'Whimsical' },
    { name: 'Alarm Clock', fileName: 'badge-alarm-clock.png', color: '#56c9d9', group: 'Objects' },
    { name: 'Wood Blocks', fileName: 'badge-wood-blocks.png', color: '#add921', group: 'Objects' },
    { name: 'Treasure Chest', fileName: 'badge-treasure-chest.png', color: '#56c9d9', group: 'Objects' },
    { name: 'Crown', fileName: 'badge-crown.png', color: '#9a6ee2', group: 'Objects' },
    { name: 'Heart', fileName: 'badge-heart.png', color: '#ff8f9d', group: 'Objects' },
    { name: 'Hourglass', fileName: 'badge-timer.png', color: '#489be8', group: 'Objects' },
    { name: 'Present Red', fileName: 'badge-present-red.png', color: '#ff7070', group: 'Objects' },
    { name: 'Present Yellow', fileName: 'badge-present-yellow.png', color: '#ce8aee', group: 'Objects' },
    { name: 'Present Blue', fileName: 'badge-present-blue.png', color: '#fcd139', group: 'Objects' },
    { name: 'Present Purple', fileName: 'badge-present-purple.png', color: '#add921', group: 'Objects' },
    { name: 'Present Green', fileName: 'badge-present-green.png', color: '#ffa843', group: 'Objects' },
    { name: 'Toilet Paper', fileName: 'badge-toilet-paper.png', color: '#7dcc3c', group: 'Objects' },
    { name: 'Book', fileName: 'badge-book.png', color: '#fcd139', group: 'Objects' },
    { name: 'Umbrella', fileName: 'badge-umbrella.png', color: '#9a6ee2', group: 'Objects' },
    { name: 'Briefcase', fileName: 'badge-briefcase.png', color: '#ffa843', group: 'Objects' },
    { name: 'Pencil Sharpener', fileName: 'badge-pencil-sharpener.png', color: '#ce8aee', group: 'Objects' },
    { name: 'Glasses 3D', fileName: 'badge-glasses-3d.png', color: '#7ed9a7', group: 'Objects' },
    { name: 'House', fileName: 'badge-house.png', color: '#ce8aee', group: 'Objects' },
    { name: 'Envelope', fileName: 'badge-email-envelope.png', color: '#add921', group: 'Objects' },
    { name: 'Key', fileName: 'badge-key.png', color: '#56c9d9', group: 'Objects' },
    { name: 'Coin', fileName: 'badge-coin.png', color: '#56c9d9', group: 'Objects' },
    { name: 'Pummelo Logo', fileName: 'badge-logo.png', color: '#489be8', group: 'Objects' },
    { name: 'Bone', fileName: 'badge-bone.png', color: '#489be8', group: 'Medical' },
    { name: 'Medication 1', fileName: 'badge-medication.png', color: '#fcd139', group: 'Medical' },
    { name: 'Medication 2', fileName: 'badge-pill-strip.png', color: '#9a6ee2', group: 'Medical' },
    { name: 'Dental Floss', fileName: 'badge-floss.png', color: '#ff8f9d', group: 'Medical' },
    { name: 'Tooth', fileName: 'badge-tooth.png', color: '#56c9d9', group: 'Medical' },
    { name: 'Soap', fileName: 'badge-soap.png', color: '#ce8aee', group: 'Medical' },
    { name: 'Medical Bag', fileName: 'badge-medical-bag.png', color: '#ed6666', group: 'Medical' },
    { name: 'Toothbrush 1', fileName: 'badge-toothbrush-with-paste.png', color: '#add921', group: 'Medical' },
    { name: 'Toothbrush 2', fileName: 'badge-toothbrush-head.png', color: '#56c9d9', group: 'Medical' },
    { name: 'Toothbrush 3', fileName: 'badge-toothbrush-and-paste.png', color: '#ff8f9d', group: 'Medical' },
    { name: 'Dentist', fileName: 'badge-dentist.png', color: '#489be8', group: 'Medical' },
    { name: 'Hypodermic Needle', fileName: 'badge-hypodermic-needle.png', color: '#f37bca', group: 'Medical' },
    { name: 'Mouthwash', fileName: 'badge-mouthwash.png', color: '#5a81e5', group: 'Medical' },
    { name: 'Mouth with Braces', fileName: 'badge-mouth-braces.png', color: '#56c9d9', group: 'Medical' },
    { name: 'Mouth', fileName: 'badge-mouth.png', color: '#489be8', group: 'Medical' },
    { name: 'Brain', fileName: 'badge-brain.png', color: '#f37bca', group: 'Medical' },
    { name: 'Heartbeat', fileName: 'badge-heartbeat.png', color: '#489be8', group: 'Medical' },
    { name: 'Dentist Chair', fileName: 'badge-dentist-chair.png', color: '#fcd139', group: 'Medical' },
    { name: 'Virus', fileName: 'badge-virus.png', color: '#489be8', group: 'Medical' },
    { name: 'Mountain Bike', fileName: 'badge-mtn-bike.png', color: '#ff7070', group: 'Athletic' },
    { name: 'Barbell', fileName: 'badge-barbell.png', color: '#7ed9a7', group: 'Athletic' },
    { name: 'Weight Scale', fileName: 'badge-scale.png', color: '#5a81e5', group: 'Athletic' },
    { name: 'Sneakers', fileName: 'badge-sneakers.png', color: '#5a81e5', group: 'Athletic' },
    { name: 'Flag Red', fileName: 'badge-flag-red.png', color: '#ff8f9d', group: 'Action' },
    { name: 'Flag Orange', fileName: 'badge-flag-orange.png', color: '#ffa843', group: 'Action' },
    { name: 'Flag Yellow', fileName: 'badge-flag-yellow.png', color: '#fcd139', group: 'Action' },
    { name: 'Flag Green', fileName: 'badge-flag-green.png', color: '#7dcc3c', group: 'Action' },
    { name: 'Flag Blue', fileName: 'badge-flag-blue.png', color: '#489be8', group: 'Action' },
    { name: 'Flag White', fileName: 'badge-flag-white.png', color: '#add921', group: 'Action' },
    { name: 'Flag Purple', fileName: 'badge-flag-purple.png', color: '#ce8aee', group: 'Action' },
    { name: 'Rocket Launch', fileName: 'badge-rocket-vertical.png', color: '#5a81e5', group: 'Action' },
    { name: 'Flame', fileName: 'badge-flame.png', color: '#ffa843', group: 'Action' },
    { name: 'Paper Plane', fileName: 'badge-paper-plane.png', color: '#f37bca', group: 'Action' },
    { name: 'Musical Notes', fileName: 'badge-music-notes.png', color: '#7ed9a7', group: 'Action' },
    { name: 'Bullhorn 1', fileName: 'badge-megaphone.png', color: '#fe7c44', group: 'Action' },
    { name: 'Bullhorn 2', fileName: 'badge-bullhorn.png', color: '#ed6666', group: 'Action' },
    { name: 'Lightning Bolt', fileName: 'badge-lightning-bolt.png', color: '#9a6ee2', group: 'Action' },
    { name: 'Moon Yellow', fileName: 'badge-moon-yellow.png', color: '#5a81e5', group: 'Nature' },
    { name: 'Moon Blue', fileName: 'badge-moon-blue.png', color: '#9a6ee2', group: 'Nature' },
    { name: 'Earth', fileName: 'badge-earth.png', color: '#ed6666', group: 'Nature' },
    { name: 'Tube TV', fileName: 'badge-tube-tv.png', color: '#489be8', group: 'Electronics' },
    { name: 'Camera', fileName: 'badge-camera.png', color: '#489be8', group: 'Electronics' },
    { name: 'Computer Monitor', fileName: 'badge-monitor.png', color: '#ce8aee', group: 'Electronics' },
    { name: 'Laptop', fileName: 'badge-laptop.png', color: '#ff7070', group: 'Electronics' },
    { name: 'Tablet', fileName: 'badge-tablet.png', color: '#fcd139', group: 'Electronics' },
    { name: 'Mobile Phone', fileName: 'badge-mobile.png', color: '#add921', group: 'Electronics' },
    { name: 'Devices', fileName: 'badge-devices.png', color: '#ffa843', group: 'Electronics' },
    { name: 'Smartphone', fileName: 'badge-smartphone.png', color: '#add921', group: 'Electronics' },
    { name: 'Backpack', fileName: 'badge-backpack.png', color: '#56c9d9', group: 'Nature' },
    { name: 'Campfire', fileName: 'badge-campfire.png', color: '#ffa843', group: 'Nature' },
    { name: 'Map', fileName: 'badge-map.png', color: '#7dcc3c', group: 'Nature' },
    { name: 'Sapling', fileName: 'badge-sapling.png', color: '#add921', group: 'Nature' },
    { name: 'Sprout', fileName: 'badge-plant-tube.png', color: '#ffa843', group: 'Nature' },
    { name: 'Compass', fileName: 'badge-compass.png', color: '#add921', group: 'Nature' }
];
