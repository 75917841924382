<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container with-customer-footer">
    <div class="order pml-dialog-header-footer">
        <div class="order-header pml-dialog-header">
            <h1>
                <label class="order-label">Order ID</label>
                <span class="order-id">{{formatOrderId(order.id)}}</span>
            </h1>
        </div>
        
        <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

        <div class="order-dialog-closing alert alert-danger pml-header-msg" *ngIf="showOrderUpdatedMessage">
            Your order has been {{orderStatus}}.
            
            This will dialog will close in {{timeRemaining$ | async | date:'s' }} seconds.
        </div>

        <div class="order-content-container">

            <div class="left-container">

                <div class="order-card order-items">
                    <table class="order-items-table" *ngIf="order.breakdown && order.breakdown[0].items">
                        <thead>
                            <tr>
                                <th class="title">Items Summary</th>
                                <th>QTY</th>
                                <th class="price-th">Price</th>
                                <th class="price-th">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of order.breakdown[0].items">
                                <td>
                                    <div class="td-content">
                                        <div class="img-container">
                                            <img [src]="item.details[0].images[0].url" alt="item image" />
                                        </div>
                                        <div class="text">
                                            {{item.details[0].name}}
                                        </div>
                                    </div>
                                </td>
                                <td>x{{item.quantity}}</td>
                                <td class="price">
                                    {{item.details[0].amount | dynamicCurrency: order.breakdown[0].currencyCode}}
                                </td>
                                <td class="price">
                                    {{(item.details[0].amount * item.quantity) | dynamicCurrency: order.breakdown[0].currencyCode ?? 'USD'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="order-card summary fulfillment">
                    <h5>
                        <div class="text">Fulfillment Information</div>
                    </h5>

                    <ng-container *ngIf="orderStatus === 'complete'">
                        <!-- Item already delivered -->
                        <div class="complete">
                            {{order?.breakdown[0]?.items?.length > 1 ? 'Items have' : order?.breakdown[0]?.items[0]?.quantity > 1 ? 'Items have' : 'Item has'}} been 
                            <span class="status delivered">delivered</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="orderStatus === 'cancelled'">
                        <!-- Order cancelled, so n/a -->
                        <div class="complete">
                            <span class="status cancelled">n/a</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="fulfillmentGroups.length === 1 && order?.breakdown[0]?.items.length === 1 && orderStatus !== 'complete' && orderStatus !== 'cancelled'">
                        <!-- Single item, single fulfillment group (and only for in-office fulfillers) -->
                        <div class="single-item-fulfillment" *ngIf="!fulfillmentGroups[0][0]?.details[0]?.fulfilledBy">
                            <div class="mark-fulfilled">
                                Update order status to
                            </div>
                            <button type="button" class="btn btn-primary save" (click)="updateAllOrderItemStatus(fulfillmentGroups[0], getNextStepStatusText(fulfillmentGroups[0][0]), order.breakdown[0]?.currencyCode)" *ngIf="!officeFulfillAdvanced">
                                <span *ngIf="workingAllItems" class="far fa-spinner fa-pulse spinner"></span>
                                <span *ngIf="!workingAllItems" class="">{{getNextStepStatusText(fulfillmentGroups[0][0])}}</span>
                                <span *ngIf="workingAllItems" class="">updating...</span>
                            </button>

                            <div class="select">
                                <div class="saving" *ngIf="workingItem === -2">
                                    <span class="far fa-spinner fa-pulse spinner"></span>
                                    <span class="text">saving...</span>
                                </div>
                                <select class="form-select" [(ngModel)]="order?.breakdown[0]?.items[0].status" (change)="updateOrderItemStatus(order?.breakdown[0]?.items[0], order?.breakdown[0]?.items[0].status, -2, order.breakdown[0].currencyCode)" title="change {{order?.breakdown[0]?.items[0].details[0]?.name}} status" *ngIf="officeFulfillAdvanced">
                                    <option *ngFor="let status of fulfillmentStatuses" [value]="status.key">{{status.name}}</option>
                                </select>
                            </div>

                            <a [href]="" class="advanced" (click)="officeFulfillAdvanced = !officeFulfillAdvanced">
                                <span class="hide" *ngIf="officeFulfillAdvanced">hide</span>
                                <span class="hide" *ngIf="!officeFulfillAdvanced">advanced</span>
                            </a>
                        </div>

                        <div class="single-item-fulfillment non-office" *ngIf="fulfillmentGroups[0][0]?.details[0]?.fulfilledBy">
                            current status:
                            <span class="status">{{fulfillmentGroups[0][0].status}}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="orderStatus !== 'complete' && orderStatus !== 'cancelled' && order?.breakdown[0]?.items.length > 1">
                        <!-- Multiple items and/or multiple fulfillment group -->
                        <div class="fulfilled-items">
                            <ng-container *ngFor="let group of fulfillmentGroups; index as g">

                                <div class="{{g === 0 ? 'first' : ''}} section-header">
                                    <div class="section-title">
                                        <label class="vendor-label">Vendor</label>
                                        <span class="vendor">{{group[0].details[0].fulfilledBy ?? 'Office'}}</span>
                                    </div>
                                    <div class="right-content">
                                        <div class="action-all" *ngIf="!group[0].details[0].fulfilledBy && allItemsSameStatus(group) && !officeFulfillAdvanced">
                                            <button type="button" class="action btn btn-outline-primary btn-xs save" (click)="updateAllOrderItemStatus(group, getNextStepStatusText(group[0]), order.breakdown[0]?.currencyCode)">
                                                <span *ngIf="workingAllItems" class="far fa-spinner fa-pulse spinner"></span>
                                                <span *ngIf="!workingAllItems" class="">{{getNextStepStatusText(group[0])}} <span class="all">(all)</span></span>
                                                <span *ngIf="workingAllItems" class="">updating...</span>
                                            </button>
                                        </div>
                                        <a [href]="" class="advanced" (click)="officeFulfillAdvanced = !officeFulfillAdvanced" *ngIf="!group[0].details[0].fulfilledBy">
                                            <span class="hide" *ngIf="officeFulfillAdvanced">hide&nbsp;</span> advanced
                                        </a>
                                        <div class="status-label" *ngIf="g === 0">status</div>
                                    </div>
                                </div>

                                <ng-container *ngIf="group[0].details[0].fulfilledBy">
                                    <!-- Fulfilled by 3rd party -->
                                    <div class="fulfilled-item" *ngFor="let item of group">
                                        <div class="name">{{item.details[0]?.name}}</div>
                                        <div class="status">
                                            {{item.status}}
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!group[0].details[0].fulfilledBy">
                                    <!-- Fulfilled in-office -->
                                    <div class="fulfilled-item" *ngFor="let item of group; index as ig">
                                        <div class="name">{{item.details[0]?.name}}</div>
                                        <div class="status {{officeFulfillAdvanced ? 'advanced-status' : ''}}">
                                            <ng-container *ngIf="!officeFulfillAdvanced">
                                                <a class="action btn btn-outline-info btn-xs" (click)="updateOrderItemStatus(item, getNextStepStatusText(item, true), ig, order.breakdown[0].currencyCode)" *ngIf="!officeFulfillAdvanced && item.status.toLocaleLowerCase() !== 'complete' && item.status.toLocaleLowerCase() !== 'cancelled'">
                                                    <span *ngIf="workingItem === ig" class="far fa-spinner fa-pulse spinner"></span>
                                                    <span *ngIf="workingItem !== ig" class="">{{getNextStepStatusText(item)}}</span>
                                                    <span *ngIf="workingItem === ig" class="">updating...</span>
                                                </a>
                                                <span class="text">{{item.status}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="officeFulfillAdvanced">
                                                <div class="saving" *ngIf="workingItem === ig">
                                                    <span class="far fa-spinner fa-pulse spinner"></span>
                                                    <span class="saving-text">saving...</span>
                                                </div>
                                                <select class="form-select" [(ngModel)]="item.status" (change)="updateOrderItemStatus(item, item.status, ig, order.breakdown[0].currencyCode)" title="change {{item.details[0]?.name}} status">
                                                    <option *ngFor="let status of fulfillmentStatuses" [value]="status.key">{{status.name}}</option>
                                                </select>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </ng-container>

                    <div class="fulfilled-by" *ngIf="fulfillmentGroups.length < 3 && fulfillmentGroups.length > 0 && orderStatus !== 'cancelled'">
                        <div class="text">
                            fulfilled by
                            <span class="fulfiller" *ngFor="let group of fulfillmentGroups; index as g">
                                <!-- {{order.breakdown[0].items[0].details[0].fulfilledBy ?? 'Office'}} -->
                                {{group[0].details[0].fulfilledBy ?? 'Office'}}
                                <span class="divider" *ngIf="g < fulfillmentGroups.length - 1">and</span>
                            </span>
                        </div>
                    </div>
                    <div class="fulfilled-by" *ngIf="fulfillmentGroups.length > 2 && orderStatus !== 'cancelled'">
                        <div class="text">
                            fulfilled by
                            <span class="fulfiller">multiple vendors</span>
                        </div>
                    </div>

                </div>                

            </div>
            
            <div class="right-container">

                <div class="order-card summary customer">
                    <h5>
                        <div class="text">Customer</div>
                    </h5>
                    <div class="customer">
                        <div class="text">{{order.info?.customerInformation?.name}}</div>
                    </div>
                </div>

                <div class="order-card summary">
                    <h5>
                        <div class="text">Order Summary</div>
                        <div class="status {{order.info.status.toLowerCase()}}">{{order.info.status}}</div>
                    </h5>
                    <div class="summary-line">
                        <div class="text">Order Created</div>
                        <div class="detail">{{formatOrderTimestampToLocaleDateTime(order.info.createdUtc, 'EEE, MMM d, yyyy')}}</div>
                    </div>
                    <div class="summary-line small-gap">
                        <div class="text">Order Time</div>
                        <div class="detail">{{formatOrderTimestampToLocaleDateTime(order.info.createdUtc, 'hh:mm a')}}
                        </div>
                    </div>
                    <div class="summary-line gap">
                        <div class="text">Subtotal</div>
                        <div class="detail">{{getOrderSubtotal() | dynamicCurrency: order.breakdown[0].currencyCode ?? 'USD'}}</div>
                    </div>
                    <div class="summary-line" *ngFor="let surcharge of order.breakdown[0].surcharges">
                        <div class="text">{{surcharge.description}}</div>
                        <div class="detail">{{surcharge.amount | dynamicCurrency:  order.breakdown[0].currencyCode ?? 'USD'}}</div>
                    </div>
                </div>

                <div class="order-card summary total">
                    <div class="summary-line">
                        <div class="text">Total</div>
                        <div class="detail">{{order.breakdown[0].originalAmountDue | dynamicCurrency: order.breakdown[0].currencyCode ?? 'USD'}}</div>
                    </div>
                </div>

                <div class="order-card summary payment">
                    <h5>
                        <div class="text">Payment Info</div>
                        <div class="status paid" *ngIf="order.breakdown[0].amountDue === 0 && hasPaymentInfo && !hasRefundInfo">
                            PAID IN FULL
                        </div>
                        <div class="status refunded" *ngIf="order.breakdown[0].amountDue === 0 && hasRefundInfo">
                            REFUNDED
                        </div>
                    </h5>
                    <ng-container *ngIf="hasPaymentInfo">
                        <div class="summary-line">
                            <div class="text">Amount Due</div>
                            <div class="detail deemphisized">
                                {{order.breakdown[0].amountDue | dynamicCurrency: order.breakdown[0].currencyCode ?? 'USD'}}
                            </div>
                        </div>
                        <div class="summary-line">
                            <div class="text">Amount Paid</div>
                            <div class="detail">{{paymentTransaction.amount| dynamicCurrency: paymentTransaction.currencyCode ?? 'USD'}}</div>
                        </div>
                        <div class="summary-line">
                            <div class="text">Date Paid</div>
                            <div class="detail">{{formatOrderTimestampToLocaleDateTime(paymentTransaction.timestamp, 'MMM dd, yyyy h:mm a')}}</div>
                        </div>
                        <div class="summary-line" *ngIf="paymentTransaction.receiptUrl">
                            <div class="text">Reciept URL</div>
                            <div class="detail">
                                <a [href]="paymentTransaction.receiptUrl" target="_blank" title="payment receipt">View
                                    Receipt</a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="hasPaymentInfo && hasRefundInfo">
                        <div class="payment-divider"></div>
                    </ng-container>
                    <ng-container *ngIf="hasRefundInfo">
                        <div class="summary-line">
                            <div class="text">Amount Refunded</div>
                            <div class="detail">{{refundTransaction.amount| currency: refundTransaction.currencyCode}}</div>
                        </div>
                        <div class="summary-line">
                            <div class="text">Date refunded</div>
                            <div class="detail">{{formatOrderTimestampToLocaleDateTime(refundTransaction.timestamp, 'MMM dd, yyyy h:mm a')}}</div>
                        </div>
                    </ng-container>
                </div>

            </div>

        </div>


        <div class="pml-dialog-footer" *ngIf="orderStatus !== 'processing' && orderStatus !== 'complete' && orderStatus !== 'cancelled'">
            <button type="button" class="btn btn-outline-danger cancel" (click)="openConfirmProductDeleteDialog($event)">
                <span *ngIf="cancelling" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!cancelling" class="fa-regular fa-ban cancel-icon"></span>
                <span *ngIf="!cancelling" class="text">cancel order</span>
                <span *ngIf="cancelling" class="text">cancelling...</span>
            </button>
        </div>

    </div>
</div>