<div class="dialog">

    <h3 class="secondary">
        Do you really want to delete <span class="user">{{data.deleteObjectName}}</span>?
    </h3>

    <div mat-dialog-content class="mat-typography content">

        <div class="meme">
            <img src="/assets/images/are-you-sure.jpg">
        </div>

        <p>
            {{data.message}}
        </p>

    </div>
    <div mat-dialog-actions class="actions">
        <button class="btn btn-link" mat-dialog-close>No, don't delete</button>
        <button type="submit" class="btn btn-danger" (click)="confirmDelete()">
            Yes, delete this {{data.deleteObjectTypeName}}
        </button>
    </div>

</div>