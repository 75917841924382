
<form class="pml-form flex-col-no-overflow" [formGroup]="planForm" (ngSubmit)="planForm.valid && savePlanForm()" autocomplete="off">

    <div class="plan pml-dialog-header-footer">
        <div class="plan-header pml-dialog-header">
            <div class="first-line">
                <h1>{{selectedPlan?.name ?? 'New Plan'}}</h1>

                <div class="save-changes" *ngIf="planForm.dirty">
                    <span class="fa-regular fa-message-exclamation fa-flip-horizontal icon"></span>
                    <!-- <span class="fa-regular fa-floppy-disk fa-lg icon"></span> -->
                    Don't forget to save your changes.
                </div>
            </div>
            
            <app-error-display [errorMessage]="errorMessage" class="pml-header-msg plan-header-msg"></app-error-display>
        </div>

        <div class="pml-dialog-content">

            <div class="pml-row">
                <div class="pml-col title">
                    <label>
                        Plan Name
                        <span class="required" *ngIf="isInvalid(f['planName'])">{{getError(f['planName'].errors)}}</span>
                    </label>
                    <input type="textbox" class="form-control" formControlName="planName" placeholder="plan name" value="" />
                    <input type="hidden" formControlName="key" />
                </div>
                <div class="pml-col default-plan form-check">
                    <input class="form-check-input" type="checkbox" value="" formControlName="isDefault" id="isDefault" />
                    <label class="form-check-label" for="isDefault">default plan</label>
                </div>
            </div>

            <ng-container *ngTemplateOutlet="tasksTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="testsTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="levelsTemplate"></ng-container>

        </div>
        
        <div class="pml-dialog-footer form-row submit-form pml-dialog-actions">
            <button type="submit" class="btn btn-primary save" [disabled]="isSaving() || !(planForm.valid && planForm.dirty)">
                <span *ngIf="isSaving()" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!isSaving()" class="">save</span>
                <span *ngIf="isSaving()" class="">saving...</span>
            </button>
            <input type="button" class="btn btn-outline-dark" value="cancel" (click)="cancelPlanForm()">
        </div>
    </div>

</form>

<ng-template #tasksTemplate>
        <div class="tests plan-section first">
            <div class="section-header">
                <h3>Daily Tasks</h3>

                <div class="add-item">
                    <a [routerLink]="[]" class="new action-item" (click)="showAddTask = !showAddTask">
                        <span class="fa-light {{showAddTask ? 'fa-minus' : 'fa-plus'}} icon"></span>
                        add daily task
                    </a>
                </div>
            </div>

            <form [formGroup]="newTaskForm" class="new-form" (ngSubmit)="newTaskForm.valid && addTaskToPlan()" autocomplete="off">
                <div class="pml-row add-item-row" *ngIf="showAddTask && !workingResources">
                    <div class="pml-col">
                        <ng-container *ngTemplateOutlet="taskDropdownTemplate; 
                            context: {form: newTaskForm, 
                                controlName: 'taskTask', 
                                label: 'Task', 
                                formGet: k, 
                                taskArray: filterAvailableTasks(selectedPlan.tasks, null)}"></ng-container>
                    </div>
                    <div class="pml-col number">
                        <label>Occurrence</label>
                        <span class="required" *ngIf="isInvalid(k['taskOccurrence'])">{{getError(k['taskOccurrence'].errors)}}</span>
                        <input type="number" class="form-control number occurrence" formControlName="taskOccurrence" placeholder="times/day" value="" />
                    </div>
                    <div class="pml-col actions">
                        <input type="hidden" value="daily" formControlName="taskInterval" />
                        <input type="hidden" value="false" formControlName="taskRequiresReview" />
                        <button type="submit" class="btn btn-primary nested-submit">Add</button>
                    </div>
                </div>
            </form>

            <div class="working add-item-row" *ngIf="showAddTask && workingResources">
                <app-loading loadingText="loading new task info..." alignText="left"></app-loading>
            </div>
            <div class="none-found" *ngIf="!selectedPlan?.tasks || selectedPlan?.tasks?.length === 0">
                <span class="fa-light fa-face-unamused icon fa-lg"></span>
                No daily tasks found for this plan
            </div>
            <div *ngFor="let task of selectedPlan?.tasks; index as i">
                <div class="pml-row plan-item {{showEditPlanTask === i ? 'plan-item-edit' : ''}}" *ngIf="!task.deleted">
                    <div class="item-detail no-img" *ngIf="showEditPlanTask !== i">
                        <span class="item dark">{{task.name}}</span>
                        <span class="item light">-</span>
                        <span class="item light timeframe">{{task.interval}}</span>
                        <span class="item light">(repeats</span>
                        <span class="item light quantity">{{task.occurrence}}</span>
                        <span class="item light">{{task.occurrence === 1 ? 'time' : 'times'}})</span>
    
                        <a [routerLink]="[]" class="edit-item" (click)="showEditPlanTask = i">
                            modify
                            <span class="fa-light fa-pencil fa-lg icon"></span>
                        </a>
                    </div>
                    <div class="item-detail no-img edit-task" *ngIf="showEditPlanTask === i">
                        <app-edit-plan-task 
                            [task]="task"
                            [availableTasks]="filterAvailableTasks(selectedPlan.tasks, task)"
                            (taskSaveEvent)="saveEditTask($event)" 
                            (taskCancelEvent)="closeEditTask()" class="d-flex flex-fill"></app-edit-plan-task>
                    </div>
                    <a [routerLink]="[]" class="remove-item" (click)="removeTaskFromPlan(task)" *ngIf="showEditPlanTask !== i">
                        remove
                        <span class="fa-light fa-trash-xmark fa-lg icon"></span>
                    </a>
                </div>
            </div>
        </div>
</ng-template>

<ng-template #testsTemplate>
    <div class="tests plan-section">
        <div class="section-header">
            <h3>Challenges</h3>
            
            <div class="add-item">
                <a [routerLink]="[]" class="new action-item" (click)="showAddTest = !showAddTest">
                    <span class="fal  {{showAddTest ? 'fa-minus' : 'fa-plus'}} icon"></span>
                    add challenge
                </a>
            </div>
        </div>

        <form [formGroup]="newTestForm" class="new-form" (ngSubmit)="newTestForm.valid && addTestToPlan()" autocomplete="off">
            <div class="pml-row add-item-row first" *ngIf="showAddTest && !workingResources">
                <div class="pml-col add-badge">
                    <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                        context: {section: 'tests', selectedBadge: selectedTestBadge, showBadgeSelection: showTestBadges}"></ng-container>    
                </div>
                <div class="pml-col">
                    <ng-container *ngTemplateOutlet="taskDropdownTemplate; 
                        context: {form: newTestForm, controlName: 'testTask', label: 'Task', formGet: t, taskArray: templateTasks}"></ng-container>
                </div>
                <div class="pml-col">
                    <label>Rule</label>
                    <span class="required" *ngIf="isInvalid(t['testRule'])">{{getError(t['testRule'].errors)}}</span>
                    <select class="form-select bonus-type-dd" formControlName="testRule">
                        <option [ngValue]="null" [disabled]="true" [selected]="true" >Rule</option>
                        <option *ngFor="let test of templateTests" [ngValue]="test.key">{{test.name}}</option>
                    </select>
                </div>
                <div class="pml-col">
                    <label>Duration Type</label>
                    <span class="required" *ngIf="isInvalid(t['testTimeframe'])">{{getError(t['testTimeframe'].errors)}}</span>
                    <select class="form-select bonus-type-dd timeframe" formControlName="testTimeframe">
                        <option [ngValue]="null" [disabled]="true" [selected]="true" >Interval</option>
                        <option *ngFor="let tf of timeframeTypes" [ngValue]="timeframeValues[tf]">{{timeframeToLabelMapping[tf]}}</option>
                    </select>
                </div>
                <div class="pml-col number">
                    <label>duration</label>
                    <span class="required" *ngIf="isInvalid(t['testTimeframeQuantity'])">{{getError(t['testTimeframeQuantity'].errors)}}</span>
                    <input type="number" class="form-control number" formControlName="testTimeframeQuantity" placeholder="duration" value="" />
                </div>
                <div class="pml-col actions">
                    <button type="submit" class="btn btn-primary nested-submit">Add</button>
                </div>
            </div>
            <div class="pml-row add-item-row add-item-icon" *ngIf="showAddTest && !workingResources">
                <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
                    context: {form: newTestForm, badgeControlName:'testBadgeName', formGet: t, section: 'tests', selectedBadge: selectedTestBadge, showBadgeSelection: showTestBadges}"></ng-container>
            </div>
        </form>
        <div class="working add-item-row" *ngIf="showAddTest && workingResources">
            <app-loading loadingText="loading new test info..." alignText="left"></app-loading>
        </div>
        <div class="none-found" *ngIf="!selectedPlan?.tests || selectedPlan?.tests?.length === 0">
            <span class="fa-light fa-face-unamused icon fa-lg"></span>
            No challenges found for this plan
        </div>
        <div *ngFor="let test of selectedPlan?.tests; index as i">
            <div class="pml-row plan-item {{showEditPlanChallenge === i ? 'plan-item-edit' : ''}}" *ngIf="!test.deleted">
                <div class="item-detail" *ngIf="showEditPlanChallenge !== i">
                    <div class="img" style.backgroundColor="{{test.additionalInfo && test.additionalInfo['badgeColor'] ? test.additionalInfo['badgeColor'] : 'rgba(0,0,0,0.08)'}}">
                        <img src="{{test.imageUrl}}" alt="{{test.name}}" *ngIf="test.imageUrl" />
                    </div>
                    <span class="item dark">{{test.task}}</span>
                    <span class="item light">- achieve</span>
                    <span class="item dark">{{test.name}}</span>
                    <span class="item light">- within</span>
                    <span class="item timeframe quantity">{{test.timeframeQuantity}}</span>
                    <span class="item light timeframe">{{test.timeframe}}{{test.timeframeQuantity !== 1 ? 's' : ''}}</span>

                    <a [routerLink]="[]" class="edit-item" (click)="showEditPlanChallenge = i">
                        modify
                        <span class="fa-light fa-pencil fa-lg icon"></span>
                    </a>
                </div>
                <div class="item-detail no-img edit-task" *ngIf="showEditPlanChallenge === i">
                    <app-edit-plan-challenge 
                        [test]="test"
                        [templateTasks]="templateTasks"
                        [templateTests]="templateTests"
                        [timeframeTypes]="timeframeTypes"
                        (challengeSaveEvent)="saveEditChallenge($event)" 
                        (challengeCancelEvent)="closeEditChallenge()" class="d-flex flex-fill"></app-edit-plan-challenge>
                </div>
                <a [routerLink]="[]" class="remove-item" (click)="removeTestFromPlan(test)" *ngIf="showEditPlanChallenge !== i">
                    remove
                    <span class="fa-light fa-trash-xmark fa-lg icon"></span>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #levelsTemplate>
    <div class="tests plan-section">
        <div class="section-header">
            <h3>Milestones</h3>

            <div class="add-item">
                <a [routerLink]="[]" class="new action-item" (click)="showAddLevel = !showAddLevel">
                    <span class="fal  {{showAddLevel ? 'fa-minus' : 'fa-plus'}} icon"></span>
                    add milestone
                </a>
            </div>
        </div>

        <form [formGroup]="newLevelForm" class="new-form" (ngSubmit)="newLevelForm.valid && addLevelToPlan()" autocomplete="off">
            <div class="pml-row add-item-row first" *ngIf="showAddLevel && !workingResources">
                <div class="pml-col add-badge">
                    <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                        context: {section: 'levels', selectedBadge: selectedLevelBadge, showBadgeSelection: showLevelBadges}"></ng-container>    
                </div>
                <div class="pml-col">
                    <ng-container *ngTemplateOutlet="taskDropdownTemplate; 
                        context: {form: newLevelForm, 
                            controlName: 'levelTask', 
                            label: 'Task', 
                            formGet: l, 
                            taskArray: filterAvailableTasksByLevel(selectedPlan.levels, null)}"></ng-container>
                </div>
                <div class="pml-col actions">
                    <input type="hidden" formControlName="levelInterval" value="Ever" />
                    <input type="hidden" formControlName="levelOccurrence" value="1" />
                    <input type="hidden" formControlName="levelRequiresReview" value="true" />

                    <button type="submit" class="btn btn-primary nested-submit">Add</button>
                </div>
            </div>
            <div class="pml-row add-item-row add-item-icon" *ngIf="showAddLevel && !workingResources">
                <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
                    context: {form: newLevelForm, badgeControlName:'levelBadgeName', formGet: l, section: 'levels', selectedBadge: selectedLevelBadge, showBadgeSelection: showLevelBadges}"></ng-container>
            </div>
        </form>

        <div class="working add-item-row" *ngIf="showAddLevel && workingResources">
            <app-loading loadingText="loading new level info..." alignText="left"></app-loading>
        </div>
        <div class="none-found" *ngIf="!selectedPlan?.levels || selectedPlan?.levels?.length === 0">
            <span class="fa-light fa-face-unamused icon fa-lg"></span>
            No milestones for this plan
        </div>
        <div *ngFor="let level of selectedPlan?.levels; index as i">
            <div class="pml-row plan-item {{showEditPlanMilestone === i ? 'plan-item-edit' : ''}}" *ngIf="!level.deleted">
                <div class="item-detail" *ngIf="showEditPlanMilestone !== i">
                    <span class="img" style.backgroundColor="{{level.additionalInfo && level.additionalInfo['badgeColor'] ? level.additionalInfo['badgeColor'] : 'rgba(0,0,0,0.08)'}}">
                        <img src="{{level.imageUrl}}" alt="{{level.name}}" *ngIf="level.imageUrl" />
                    </span>
                    <span class="item dark">{{level.name}}</span>
                    <!-- <ng-container *ngIf="level.requiresReview">
                        <span class="item light">-</span>
                        <span class="item review">requires review</span>
                    </ng-container> -->
                    
                    <a [routerLink]="[]" class="edit-item" (click)="showEditPlanMilestone = i">
                        modify
                        <span class="fa-light fa-pencil fa-lg icon"></span>
                    </a>
                </div>
                <div class="item-detail no-img edit-task" *ngIf="showEditPlanMilestone === i">
                    <app-edit-plan-milestone 
                        [level]="level"
                        [templateTasks]="filterAvailableTasksByLevel(selectedPlan.levels, level)"
                        (milestoneSaveEvent)="saveEditMilestone($event)" 
                        (milestoneCancelEvent)="closeEditMilestone()" class="d-flex flex-fill"></app-edit-plan-milestone>
                </div>
                <a [routerLink]="[]" class="remove-item" (click)="removeLevelFromPlan(level)" *ngIf="showEditPlanMilestone !== i">
                    remove
                    <span class="fa-light fa-trash-xmark fa-lg icon"></span>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet" let-taskArray="taskArray">
    <ng-container [formGroup]="form">
        <label>{{label}}</label>
        <span class="required" *ngIf="isInvalid(formGet[controlName])">{{getError(formGet[controlName].errors)}}</span>
        <select class="form-select bonus-type-dd new-task" formControlName="{{controlName}}">
            <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
            <option *ngFor="let task of taskArray" [ngValue]="task.key">{{task.name}}</option>
        </select>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionTemplate 
    let-form="form" 
    let-badgeControlName="badgeControlName"
    let-formGet="formGet" 
    let-section="section"
    let-selectedBadge="selectedBadge"
    let-showBadgeSelection="showBadgeSelection">
    <ng-container [formGroup]="form">
        <div class="pml-col">
            <input type="hidden" [formControlName]="badgeControlName" value="" />
    
            <app-choose-badge [selectedBadge]="selectedBadge" 
                (closeBadgeEvent)="toggleShowBadges(section)"
                (selectBadgeEvent)="updateItemWithBadge($event, formGet[badgeControlName], section)" 
                *ngIf="showBadgeSelection"></app-choose-badge>
        </div>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionBtnTemplate let-section="section" let-selectedBadge="selectedBadge" let-showBadgeSelection="showBadgeSelection">
    <span class="fa-stack fa-xs {{showBadgeSelection ? 'active' : ''}}" (click)="toggleShowBadges(section)" 
          title="{{!selectedBadge ? 'Add Icon' : 'Change Icon'}}" 
          *ngIf="!selectedBadge">
        <i class="fa-light fa-image fa-stack-2x"></i>
        <i class="fa-regular fa-circle-plus fa-stack-1x"></i>
    </span>
    <div class="img" 
        style.backgroundColor="{{selectedBadge.color ? selectedBadge.color : 'rgba(0,0,0,0.08)'}}"
        *ngIf="selectedBadge">
        <img src="{{BADGE_PATH}}{{selectedBadge?.fileName}}" 
            title="{{selectedBadge?.name}}" 
            class="selected-badge {{showBadgeSelection ? 'active' : ''}}" 
            (click)="toggleShowBadges(section)" />
    </div>
</ng-template>
