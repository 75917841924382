import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppUser } from 'src/app/core/models/app-user';
import { Name } from 'src/app/core/models/name';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { DateOfBirth } from 'src/app/core/models/date-of-birth';
import { HelperDobService } from 'src/app/core/helpers/helper-dob.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';
import { ValidationErrors } from '@angular/forms';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userKey: string;
  user: AppUser;
  childUsers: AppUser[];
  working: boolean;
  workingChildren: boolean;
  errorMessage: string;
  months: Array<string>;
  days: Array<number>;

  activeTab: string;
  ACTIVITY_TAB = 'activityTab';  // Setting as CONST to avoid spelling errors
  ACCOUNT_TAB = 'accountTab';

  // edit toggle bools
  editAccountData: boolean;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private nameHelper: HelperNameService,
    private dobHelper: HelperDobService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = true;
    this.workingChildren = true;
    this.activeTab = this.ACCOUNT_TAB;
    this.editAccountData = false;
    this.months = Array(12).fill(0).map((i, idx) => this.getMonth(idx + 1));

    // get the user key from the querystring
    this.userKey = this.route.snapshot.paramMap.get('key');

    this.getUser(this.userKey);
    this.getChildUsers(this.userKey);

    this.route.fragment.subscribe((fragment: string) => {
      // eg. /user#account will show the payment tab
      this.activeTab = fragment === 'account' || fragment === 'a' ? this.ACCOUNT_TAB : this.ACTIVITY_TAB;
    });
  }

  getUser(userKey: string): void {
    this.working = true;

    this.userService.getAppUserByKey(userKey).subscribe({
      next: (response: AppUser | any) => {
        this.user = response;

        this.working = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      }
    });

  }

  getChildUsers(parentKey: string): void {
    this.workingChildren = true;

    this.userService.getLinkedUsersByParentKey(parentKey).subscribe({
      next: (response: AppUser[] | any) => {
        this.childUsers = response;

        this.workingChildren = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      }
    });

    // this.childUsers = [
    //   {
    //     'key': '1234abcd1',
    //     'displayName': 'girluser',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd2',
    //     'displayName': 'uncle',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd3',
    //     'displayName': 'tester',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd4',
    //     'displayName': 'aunt',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd5',
    //     'displayName': 'girl',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd6',
    //     'displayName': 'boy',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   },
    //   {
    //     'key': '1234abcd7',
    //     'displayName': 'teenage',
    //     'linkInfo': {
    //       'parentUserKey': 'MDRGODk5NkRBNzYzQjdBOTY5QjEwMjhFRTMwMDc1NjlFQUYzQTYzNTQ4NkREQUIyMTFENTEyQzg1QjlERjhGQn4xZjhjOTdlMS04MzYxLTQ1YmEtODJkZC01OTdlMjNlYzE4YzM-'
    //     }
    //   }
    // ];
  }

  setActiveTab(tabName: string): void {
    this.errorMessage = '';
    this.activeTab = tabName;
  }

  fullNamePageTitleDisplay(name: Name, displayName: string): string {
    const fullName = name ? this.fullNameDisplay(name, '--') : '--';
    return fullName !== '--' ? fullName : displayName;
  }

  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name) ?? nullPlaceholder;
  }

  dobDisplay(dob: DateOfBirth): string {
    return this.dobHelper.formatDOB(dob, 'MMMM dd, yyyy') ?? '--';
  }

  public getMonth(index: number) {
    const myDate = new Date();
    myDate.setDate(1);
    myDate.setMonth(index - 1);

    const locale = 'en-us';
    const month = myDate.toLocaleString(locale, { month: 'long' });

    return month;
  }

  public getDays(thisForm: any): number[] {
    const dayCount = !thisForm.year && !thisForm.month ?
      31 :
      this.getDaysInMonth(thisForm.year, thisForm.month);
    return Array(dayCount).fill(0).map((i, idx) => idx + 1);
  }

  public getDaysInMonth(year: number, month: number): number {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  getErrorText(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }


    return '';
  }

  onClickSubmit(formData: any, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    console.log('form.displayName: ' + formData.displayName);
  }

  tempGetChildAvatar(child: AppUser, i: number): string {
    let iconName: string;
    if (child.displayName.indexOf('girl') > -1 ||
      child.displayName.indexOf('aunt') > -1 ||
      child.displayName.indexOf('mom') > -1) {
      iconName = i % 2 === 0 ? 'mom' : 'sally';
    } else {
      iconName = i % 2 === 0 ? 'jimmy' : 'joe';
    }
    return `assets/images/avatar-${iconName}.png`;
  }

}
