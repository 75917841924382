import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '../models/state';
import { Observable } from 'rxjs';
import { MCC } from '../models/registration/MCC';


@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private http: HttpClient) { }

  getStates(): Observable<State> {
    const statesLocaction = 'assets/data/states.json';
    return this.http.get<State>(statesLocaction);
  }

  getMCCs(): Observable<MCC> {
    const statesLocaction = 'assets/data/mcc.json';
    return this.http.get<MCC>(statesLocaction);
  }

}
