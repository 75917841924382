import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BADGES } from 'src/app/core/constants/badges';
import { Badge } from 'src/app/core/models/badge';

@Component({
  selector: 'app-choose-badge',
  templateUrl: './choose-badge.component.html',
  styleUrls: ['./choose-badge.component.scss']
})
export class ChooseBadgeComponent implements OnInit {

  IMG_PATH = 'assets/images/badges/';

  badges: Badge[];

  working: boolean;

  @Input() public selectedBadge: Badge;
  @Output() selectBadgeEvent = new EventEmitter<Badge>();
  @Output() closeBadgeEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.working = false;

    this.badges = BADGES;
  }

  selectBadge(badge: Badge): void {
    this.selectBadgeEvent.emit(badge);
  }

  closeBadge(): void {
    this.closeBadgeEvent.emit();
  }

}
