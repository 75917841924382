import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Product } from 'src/app/core/models/offer/product';
import { ProductSaveInfo, ProductSaveType } from 'src/app/core/models/offer/product-save-info';
import { StoreType } from 'src/app/core/models/offer/store-type';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { DialogAreYouSureComponent } from '../_shared/dialog-are-you-sure/dialog-are-you-sure.component';
import { DialogProductComponent } from '../_shared/dialog-product/dialog-product.component';

@Component({
  selector: 'app-gift-catalog',
  templateUrl: './gift-catalog.component.html',
  styleUrls: ['./gift-catalog.component.scss']
})
export class GiftCatalogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
