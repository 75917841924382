<div class="container-fluid register company-contact">

    <app-registration-header stepNumber="3"></app-registration-header>

    <div class="row">
        <div class="col welcome-col">
            Now let's find out a little more about you, the company administrator.  This information will be used to 
            manage your account.  It will also be used to set up your company's financial profile.
        </div>
    </div>
    <div class="row">
        <div class="col form-col">

            <div class="error alert alert-danger" *ngIf="errorMessage">
                <span class="far fa-exclamation-circle fa-lg icon"></span>
                {{errorMessage}}
            </div>

            <h1>Who will be the primary contact?</h1>

            <form class="pml-form" [formGroup]="contactForm" (ngSubmit)="contactForm.valid" (ngSubmit)="saveCompanyContact()" autocomplete="off">

                <div class="pml-row">
                    <span class="required" *ngIf="isInvalid(f['contactFirstName']) && isInvalid(f['contactLastName'])">{{getError(f['contactFirstName'].errors, 'FIRST & LAST name')}}</span>
                    <span class="required" *ngIf="isInvalid(f['contactFirstName']) && !isInvalid(f['contactLastName'])">{{getError(f['contactFirstName'].errors, 'FIRST name')}}</span>
                    <span class="required" *ngIf="isInvalid(f['contactLastName']) && !isInvalid(f['contactFirstName'])">{{getError(f['contactLastName'].errors, 'LAST name')}}</span>
                    <div class="input-row">
                        <label>
                            Name of contact
                            <span class="required-icon">*</span>
                        </label>
                        <div class="multi-input">
                            <input type="text" class="form-control med-text first" formControlName="contactFirstName" placeholder="first name" />
                            <input type="text" class="form-control last-name" formControlName="contactLastName" placeholder="last name" />
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required" *ngIf="isInvalid(f['contactRole'])">
                        {{getError(f['contactRole'].errors, 'COMPANY ROLE')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Role with the company
                            <span class="required-icon">*</span>
                        </label>
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="contactRole" formControlName="contactRole" id="contactRole1" value="owner" autocomplete="off">
                            <label class="btn btn-outline-primary no-right-border" for="contactRole1">Owner</label>
                        
                            <input type="radio" class="btn-check" name="contactRole" formControlName="contactRole" id="contactRole2" value="representative" autocomplete="off">
                            <label class="btn btn-outline-primary no-right-border" for="contactRole2">Representative</label>
                        
                            <input type="radio" class="btn-check" name="contactRole" formControlName="contactRole" id="contactRole3" value="executive" autocomplete="off">
                            <label class="btn btn-outline-primary" for="contactRole3">Executive</label>
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="input-row">
                        <label>
                            Title
                        </label>
                        <input type="text" class="form-control med-text" formControlName="contactTitle" placeholder="title" />
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required" *ngIf="isInvalid(f['contactEmail'])">
                        {{getError(f['contactEmail'].errors, 'EMAIL ADDRESS')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Email address
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control full-width" formControlName="contactEmail" placeholder="email address" />
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required med-text-width" *ngIf="isInvalid(f['contactPhone'])">
                        {{getError(f['contactPhone'].errors, 'PHONE')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Primary phone
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control med-text" formControlName="contactPhone" placeholder="phone number" />
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required date-width has-help-icon" *ngIf="isInvalid(f['dob'])">
                        {{getError(f['dob'].errors, 'DATE OF BIRTH')}}
                    </div>
                    <div class="input-row">
                        <label>
                            date of birth
                            <span class="required-icon">*</span>
                        </label>
                        <input type="date" class="form-control" formControlName="dob" />
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <div class="pml-row">
                    <div class="required has-help-icon full-width-max poi-required" *ngIf="isInvalid(f['proofOfIdType']) || isInvalid(f['proofOfIdImage'])">
                        <span *ngIf="isInvalid(f['proofOfIdType']) && isInvalid(f['proofOfIdImage'])">
                            {{getError(f['proofOfIdType'].errors, 'PROOF OF ID')}}
                        </span>
                        <span *ngIf="isInvalid(f['proofOfIdType']) && !isInvalid(f['proofOfIdImage'])">
                            {{getError(f['proofOfIdType'].errors, 'PROOF OF ID', 'You must select a PROOF OF IDENTIFICATION type')}}
                        </span>
                        <span *ngIf="!isInvalid(f['proofOfIdType']) && isInvalid(f['proofOfIdImage'])">
                            {{getError(f['proofOfIdImage'].errors, 'PROOF OF ID', 'You must upload a PROOF OF IDENTIFICATION image')}}
                        </span>
                    </div>
                    <div class="input-row">
                        <label class="proof">
                            Proof of identification
                            <span class="required-icon">*</span>
                        </label>
                        <select class="form-select full-width" formControlName="proofOfIdType" (change)="updateProofOfIdImage($event)">
                            <option value=""></option>
                            <option *ngFor="let proofItem of proofOfIdTypes" [value]="proofItem">{{proofItem}}</option>
                        </select>
                        <input type="text" class="d-none" formControlName="proofOfIdImage" />
                        <button class="btn btn-primary upload-proof" type="button" (click)="uploadIdImage()">BROWSE...</button>
                        <div class="help">
                            <span class="fa-regular fa-circle-question icon"></span>
                        </div>
                    </div>
                </div>
                <div class="pml-row" *ngIf="proofOfIdImage">
                    <div class="input-row">
                        <label></label>
                        <div class="proof-of-id-image">
                            <span class="id-image-name">{{proofOfIdImage.name}}</span>
                            <span class="fa-regular fa-circle-xmark delete-image" (click)="removeIdImage()"></span>
                        </div>
                    </div>
                </div>

                <div class="new-section">
                    <h1>Company Address</h1>
                </div>
                <div class="pml-row">
                    <div class="required" *ngIf="isInvalid(f['addressLine1'])">
                        {{getError(f['addressLine1'].errors, 'ADDRESS')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Address Line 1
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control full-width" formControlName="addressLine1" placeholder="address" />
                    </div>
                </div>
                <div class="pml-row">
                    <div class="input-row">
                        <label class="not-required">
                            Address Line 2
                        </label>
                        <input type="text" class="form-control full-width" formControlName="addressLine2" placeholder="address" />
                    </div>
                </div>
                <div class="pml-row multi-cols">
                
                    <div class="multi-col take-space">
                        <div class="required" *ngIf="isInvalid(f['city'])">
                            {{getError(f['city'].errors, 'CITY')}}
                        </div>
                        <div class="input-row">
                            <label>
                                City
                                <span class="required-icon">*</span>
                            </label>
                            <input type="text" class="form-control" formControlName="city" placeholder="city" />
                        </div>
                    </div>
                    <div class="multi-col">
                        <div class="required" *ngIf="isInvalid(f['state'])">
                            {{getError(f['state'].errors, 'STATE/PROVINCE')}}
                        </div>
                        <div class="input-row">
                            <label class="multi-col-label">
                                State/Province
                                <span class="required-icon">*</span>
                            </label>
                            <select class="form-select" formControlName="state">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.code">{{state.name}}</option>
                            </select>
                        </div>
                    </div>
                
                </div>
                <div class="pml-row">
                    <div class="required med-text-width" *ngIf="isInvalid(f['postalCode'])">
                        {{getError(f['postalCode'].errors, 'POSTAL CODE')}}
                    </div>
                    <div class="input-row">
                        <label>
                            Postal Code
                            <span class="required-icon">*</span>
                        </label>
                        <input type="text" class="form-control med-text" formControlName="postalCode" placeholder="postal code/zip code" />
                    </div>
                </div>

                <div class="pml-row submit-form pml-actions">
                    <button type="button" class="btn btn-outline-primary cancel" (click)="goBack()">
                        Back
                    </button>
                    <button type="submit" class="btn btn-primary save"
                        [disabled]="saving || !(contactForm.valid && contactForm.dirty)">
                        <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                        <span *ngIf="!saving" class="">Continue</span>
                        <span *ngIf="saving" class="">Saving...</span>
                    </button>
                </div>
            </form>