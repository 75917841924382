<div class="container-fluid forgot">
    <div class="row">
        <div class="col branding-col">

            <div class="branding">
                <a routerLink="[]" class="brand">
                    <!-- <img src="assets/images/logo-horizontal.png" title="Pummelo" /> -->
                    <img src="assets/images/logo-horizontal.svg" title="Pummelo" />
                </a>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col">

            <div class="card">
                <div class="card-body">

                    <ng-container *ngIf="showEnterEmailOrPhone">
                        <ng-container *ngTemplateOutlet="forgotPwTemplate">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showEnterNewPw">
                        <ng-container *ngTemplateOutlet="newPwTemplate"></ng-container>
                    </ng-container>

                </div>
            </div>

        </div>
    </div>
</div>


<ng-template #forgotPwTemplate>
    <h1>Forgot Password</h1>
    <p class="{{errorMessage ? 'with-error' : ''}}">
        Don't worry, we can help you set a new password. Enter the email address or
        phone number associated with your account. We'll send you a code that will allow
        you to reset your password.
    </p>

    <div class="error alert alert-danger" *ngIf="errorMessage">
        <span class="far fa-exclamation-circle fa-lg icon"></span>
        {{errorMessage}}
    </div>

    <form class="login" [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()" autocomplete="off">
        <div class="mb-3 form-row">
            <label class="form-label">
                e-mail or phone number
                <span class="required" *ngIf="(f['email'].invalid && f['email'].touched)">required</span>
            </label>
            <input type="text" formControlName="email" class="form-control" placeholder="email address or phone number"
                required>
        </div>
        <div class="mb-3 submit-row">
            <button type="submit" class="form-control btn btn-primary btn-search"
                [disabled]="!forgotForm.valid || working">
                <span *ngIf="working" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!working" class="">Send code</span>
                <span *ngIf="working" class="">Sending...</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #newPwTemplate>
    <h1>New password</h1>
    <p class="{{errorMessage ? 'with-error' : ''}}">
        We've sent a confirmation code to <span class="bold">{{emailOrPhoneNumber}}</span>. Please enter
        the code below and create a new password.
    </p>

    <div class="error alert alert-danger" *ngIf="errorMessage">
        <span class="far fa-exclamation-circle fa-lg icon"></span>
        {{errorMessage}}
    </div>

    <form class="login" [formGroup]="newPwForm" (ngSubmit)="newPwSubmit()" autocomplete="off">
        <div class="mb-3 form-row">
            <label class="form-label">
                Confirmation Code
                <span class="required" *ngIf="(p['confirmationToken'].invalid && p['confirmationToken'].touched)">required</span>
            </label>
            <input type="text" formControlName="confirmationToken" class="form-control" placeholder="Confirmation Code" required>
            <div class="new-code">
                Didn't get a code? <a routerLink="[]" class="link" (click)="requestNewCode()">Click here</a> to get another one sent to you.
                
                <div class="sending-new-code" *ngIf="newCodeRequestWorking">
                    <span class="fa-duotone fa-spinner fa-pulse spinner"></span>
                    Sending new code...
                </div>
            </div>
        </div>
        <div class="mb-3 form-row">
            <label class="form-label">
                New password
                <div class="required" *ngIf="(p['password'].invalid && p['password'].touched)">required</div>
            </label>
            <input type="password" formControlName="password" class="form-control" placeholder="New password" required>
            <div class="pw-rules">
                <div class="rule">
                    <span class="fa-regular {{isMatch('.{8,}', p['password'].value) ? 'fa-check' : 'fa-xmark'}} fa-fw icon"></span>
                    Minimum of 8 characters in length
                </div>
                <div class="rule">
                    <span class="fa-regular {{isMatch('[A-Z]', p['password'].value) ? 'fa-check' : 'fa-xmark'}} fa-fw icon"></span>
                    At least 1 uppercase character
                </div>
                <div class="rule">
                    <span class="fa-regular {{isMatch('[a-z]', p['password'].value) ? 'fa-check' : 'fa-xmark'}} fa-fw icon"></span>
                    At least 1 lowercase character
                </div>
                <div class="rule">
                    <span class="fa-regular {{isMatch('[0-9]', p['password'].value) ? 'fa-check' : 'fa-xmark'}} fa-fw icon"></span>
                    At least 1 number
                </div>
                <div class="rule">
                    <span class="fa-regular {{isMatch('.*?[#?!@$%^&*-]', p['password'].value) ? 'fa-check' : 'fa-xmark'}} fa-fw icon"></span>
                    At least 1 special character
                </div>
            </div>
        </div>
        <div class="mb-3 submit-row submit-form pml-actions">
            <button type="button" class="btn btn-outline-primary btn-cancel" (click)="cancelNewPw()">
                Cancel
            </button>
            <button type="submit" class="form-control btn btn-primary btn-search" [disabled]="!newPwForm.valid || working">
                <span *ngIf="working" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!working" class="">Save</span>
                <span *ngIf="working" class="">Saving...</span>
            </button>
        </div>
    </form>
</ng-template>