import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { AppUser } from '../models/app-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn: Observable<boolean>;
  private loggedInSubject: BehaviorSubject<boolean>;

  private endpointAuth = environment.authenticationUrl;
  private headersAuth = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.authenticationSubscriptionKey);

  private appKey: string;

  constructor(
    private http: HttpClient,
    public router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserService) {

    this.loggedInSubject = new BehaviorSubject<boolean>(this.isLoggedIn);
    this.loggedIn = this.loggedInSubject.asObservable();

    this.appKey = environment.appKey;
  }

  /**
   * Gets isLogged flag
   *    This isn't the best way to check isLoggedIn.  However, the token itself
   *    does not contain an EXP flag (although there is a session live item, which
   *    is defaulted at 15 mins), so we can't check that.  Additionally, with each
   *    API call the token expiration is refreshed, so it is a rolling exp time.
   *    So, if a token is present, then this flag is set to true.  Then when the application
   *    actually makes the HTTP call with this token, the API will validate the token.
   *    If the token has become stale and is no longer valid, that call will return
   *    an error, which will be caught by this app and the access_token will be removed.
   */
  get isLoggedIn(): boolean {
    const authToken = this.localStorageService.getToken('access_token');
    const user = this.localStorageService.getToken('user');

    //return (authToken !== null) ? true : false;
    return (authToken !== null && user !== null) ? true : false;
  }

  set isLoggedIn(value: boolean) {
    this.loggedInSubject.next(value);
  }

  login(user: AppUser, includeAppKey: boolean = true): Observable<any> {

    if (includeAppKey) {
      // Add the appKey to the user/login object
      user.appKey = environment.appKey;
    }

    return this.http.post<any>(`${this.endpointAuth}/token`, user, { headers: this.headersAuth })
      .pipe(
        tap((response: any) => {
          // Add the user object into the userSubject so that we can hold on to the PW
          // so the user doesn't have to re-login if they need MFA auth
          this.userService.userValue = user;

          if (response.data?.token) {
            this.localStorageService.saveToken('access_token', response.data.token);
            // this.loggedInSubject.next(true);  // Set this on the login page so it doesn't show sidebar until all login is complete

            // After login, clear out the PW so that it isn't just sitting in local storage in clear text
            if (this.userService.userValue && this.userService.userValue.password) {
              this.userService.userValue.password = '';
            }
          }
        }),
        catchError(err => throwError(() => err))
      );
  }

  /**
   * Logs the user out of the WorkScale application
   *    Clears all application localStorageService, which is what is used for active sessions.  Also optionally
   *    deletes the user session from the WorkScale system.  This is optional, because in some cases
   *    it may already be deleted from server (e.g., token validation scenario) and a logout api call
   *    would be redundant.
   *    Lastly, upon successful logout, redirects to LOGIN page.
   *
   * @param deleteOnServer: bool to indicate whether or not to call "logout" api
   */
  logout(deleteOnServer: boolean = true, logoutMessageCode: string = '', tokenOnly: boolean = false): void {
    if (deleteOnServer) {
      this.http.delete<any>(`${this.endpointAuth}/token`, { headers: this.headersAuth }).subscribe();
    }

    // For now, just deleting all tokens, causes issues when logging back in under another tenant
    tokenOnly = false;
    this.localStorageService.logOut(tokenOnly);
    this.userService.userValue = null;
    this.loggedInSubject.next(false);

    if (logoutMessageCode !== '') {
      this.router.navigate(['/login'], { queryParams: { code: logoutMessageCode } });
    } else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Observable method used mostly by the app.initializer to check to see if a token is valid
   *     For now we are calling the get token api.  However, an error is thrown when it is not valid
   *     ideally we'd like a dedicated API that returns true or false if token is valid.
   *
   * @returns token
   */
  tryToken(): Observable<any> {
    return this.http.get<any>(`${this.endpointAuth}/token`, { headers: this.headersAuth })
      .pipe(
        tap((res) => {
          this.loggedInSubject.next(true);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 440) {
            this.logout(false, '', true);
          } else if (error.status === 0) {
            this.logout(false);
          }
          return of(0);
        })
      );
  }

  getAnonToken(): Observable<string> {
    const apiUrl = `${this.endpointAuth}/token`;

    return this.http.post<any>(apiUrl, null, { headers: this.headersAuth })
      .pipe(
        map((resp: any) => {
          if (resp.data) {
            return resp.data;
          }
          return of(resp);
        })
      );
  }

  switchTenantKey(tenantKey: string): Observable<any> {
    const apiUrl = `${this.endpointAuth}/token/switch`;
    const body = {
      tenantKey: tenantKey
    };

    return this.http.post<any>(apiUrl, body, { headers: this.headersAuth }).pipe(
      map((resp: any) => {
        if (resp.data) {
          return resp.data;
        }
        return of(resp);
      }),
      catchError(err => throwError(() => err))
    );
  }

  registerUser(user: AppUser): Observable<AppUser | any> {
    const apiUrl = `${this.endpointAuth}/user`;

    return this.http.post<any>(apiUrl, user, { headers: this.headersAuth });
  }

  forgotPassword(emailOrPhoneNumber: string): Observable<any> {
    const apiUrl = `${this.endpointAuth}/app/${this.appKey}/user/password/forgot`;

    // response.data should be a boolean
    //return this.executeApiPost<any>(apiUrl, myHeaders, { "emailAddressOrPhoneNumber": emailOrPhoneNumber });
    return this.http.post<any>(apiUrl, { 'emailAddressOrPhoneNumber': emailOrPhoneNumber }, { headers: this.headersAuth })
      .pipe(
        map((resp: any) => {
          if (resp.data) {
            return resp.data;
          }
          return of(resp);
        })
      );
  }

  forgotPasswordReset(confirmationToken: string, newPassword: string): Observable<any> {
    const apiUrl = `${this.endpointAuth}/user/password/reset`;
    const data: any = { 'token': confirmationToken, 'newPassword': newPassword, 'appKey': this.appKey };

    return this.http.post<any>(apiUrl, data, { headers: this.headersAuth });
  }

  completeInviteManagementUser(inviteCode: string, password: string): Observable<any> {
    const apiUrl = `${this.endpointAuth}/user/invite/${inviteCode}`;
    const requestBody = {
      'password': password,
      'appKey': this.appKey
    };

    return this.http.patch<any>(apiUrl, requestBody, { headers: this.headersAuth });
  }

  getRegisterUser(): Observable<any> {
    const apiUrl = `${this.endpointAuth}/user`;

    return this.http.get<any>(apiUrl, { headers: this.headersAuth });
  }

}

