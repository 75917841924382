import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { SaveInfo, SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-edit-plan-task',
  templateUrl: './edit-plan-task.component.html',
  styleUrls: ['./edit-plan-task.component.scss']
})
export class EditPlanTaskComponent implements OnInit {

  @Input() public task: TemplateTask;
  @Input() public availableTasks: TemplateTask[];
  @Output() taskCancelEvent = new EventEmitter();
  @Output() taskSaveEvent = new EventEmitter<SaveInfo<TemplateTask>>();

  editTaskForm: UntypedFormGroup;
  taskName: string;

  working: boolean;
  workingResources: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.editTaskForm = this.fb.group({
      taskTask: [this.task.templateTaskKey ?? '', Validators.required],
      taskInterval: ['daily'],                // hidden field with default value DAILY
      taskOccurrence: [this.task.occurrence, Validators.required],
      taskRequiresReview: [false],            // hidden field with default value FALSE
      taskBadgeName: ['']
    });

    this.formChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.editTaskForm.controls;
  }

  formChanges(): void {
    // Cute little item to make title reflect user changes
    this.f['taskTask'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && this.task.templateTaskKey) {
          if (val === this.task.templateTaskKey) {
            this.f['taskTask'].markAsPristine();
          }
        }
      }
    });

  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  getTaskName(e: Event): void {
    const taskElement: HTMLSelectElement = <HTMLSelectElement>e.currentTarget;
    this.taskName = taskElement[taskElement.options.selectedIndex].textContent;
  }

  updateTaskInPlan(): void {
    // Make sure there are changes to save, if not, close the form
    if (!this.editTaskForm.dirty) {
      this.taskCancelEvent.emit();
      return;
    }

    // Update task items from form
    if (this.f['taskTask'].value !== this.task.templateTaskKey) {
      this.task.templateTaskKey = this.f['taskTask'].value;
      this.task.name = this.taskName;
    }

    if (this.f['taskOccurrence'].value !== this.task.occurrence) {
      this.task.occurrence = this.f['taskOccurrence'].value;
    }

    // Save to parent form
    this.taskSaveEvent.emit({result: this.task, saveType: SaveType.updated});
  }

  cancelTaskEdit(): void {
    this.taskCancelEvent.emit();
  }

}
