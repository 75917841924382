import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { AppUser } from '../models/app-user';

@Pipe({
  name: 'parentUsers',
  pure: false  // needed to be able to update/refresh array without reload
})
export class ParentUsersPipe implements PipeTransform {

  //
  // Only show parent users, child users will be shown UNDER the parent users
  //
  transform(appUsers: Array<AppUser>): Array<AppUser> {
    if (!appUsers) {
      return null;
    }

    // Check the user's APP section to see if there are any linked user info present
    const appKey  = environment.appKey;

    return appUsers.filter(x => x.linkInfo === null || x.linkInfo?.parentUserKey === null);
  }

}
