import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { MfaType } from 'src/app/core/enums/mfa-type';
import { AppUser } from 'src/app/core/models/app-user';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.scss', './register.component.scss']
})
export class RegisterComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  registrationForm: UntypedFormGroup;
  toggleSuffix: boolean;

  IS_REGISTRATION_FLOW: string = 'isRegistrationFlow';

  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder ) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;

    this.createForm();

    // Get an anon token for use when saving
    this.getAnonToken();

    sessionStorage.setItem(this.IS_REGISTRATION_FLOW, 'true');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registrationForm.controls;
  }

  getAnonToken(): void {
    this.authService.getAnonToken().subscribe({
      next: (response: any) => {
        const token = response;
        this.localStorageService.saveToken('access_token', token.token);
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  createForm(): void {
    this.registrationForm = this.fb.group({
      firstName: ['', Validators.maxLength(50)],
      middleName: [''],
      lastName: ['', Validators.maxLength(50)],
      suffix: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirmPassword: ['', this.passwordsMatch.bind(this)]
    });

  }

  passwordsMatch(control: FormControl) : any {
    const pass = this.registrationForm?.get('password')?.value;
    const confirmPass = control.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['maxlength']) {
      return labelName !== '' ? `${labelName} is too long (${errors['maxlength']?.requiredLength} chars max)` : `too long (${errors['maxlength']?.requiredLength} chars max)`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern'] && labelName === 'password') {
      return 'Password must be 8 characters in length and contain at least 1 digit, 1 lowercase character, 1 uppercase character, and 1 special character ';
    }

    if (errors['notSame']) {
      return 'Passwords do not match';
    }

    return 'Error';
  }

  returnToLogin(): void {
    this.router.navigateByUrl('/');
  }

  registerUser(): void {
    this.saving = true;
    const newUser: AppUser = this.populateNewUserFromFormInput();

    this.authService.registerUser(newUser).pipe(
      concatMap((response: any) => {

        const user = response;  // retuns the new user object (type AppUser)

        const userToLogin: AppUser = { username: newUser.emailAddress, password: newUser.password };

        return this.authService.login(userToLogin, false);
      })
    )
    .subscribe({
      next: (response: any) => {
        this.saving = false;

        this.router.navigateByUrl('/register/register-company');
      },
      error: (err: any) => {
        this.saving = false;
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  populateNewUserFromFormInput(): AppUser {
    let user: AppUser = {};

    if (this.f['firstName'] && this.f['firstName'].value.length > 0) {
      user.info = {};
      user.info.name = {};
      user.info.name.first = this.f['firstName'].value;
    }

    if (this.f['middleName'] && this.f['middleName'].value.length > 0) {
      if (!user.info) {
        user.info = {};
      }
      if (!user.info.name) {
        user.info.name = {};
      }
      user.info.name.middle = this.f['middleName'].value;
    }

    if (this.f['lastName'] && this.f['lastName'].value.length > 0) {
      if (!user.info) {
        user.info = {};
      }
      if (!user.info.name) {
        user.info.name = {};
      }
      user.info.name.last = this.f['lastName'].value;
    }

    if (this.f['suffix'] && this.f['suffix'].value.length > 0) {
      if (user.info && user.info.name) {
        // Only create the suffix object if the name object exists
        user.info.name.suffix = this.f['suffix'].value;
      }
    }

    if (this.f['emailAddress'] && this.f['emailAddress'].value.length > 0) {
      user.emailAddress = this.f['emailAddress'].value;
    }

    if (this.f['password'] && this.f['password'].value.length > 0) {
      user.password = this.f['password'].value;
    }

    user.mfaType = MfaType.none;
    user.status = 'Active';

    return user;
  }

  tempLogin(): void {
    // Login with a temp user for testing purposes
    const userToLogin: AppUser = { username: 'ryanJ@testregister.com', password: 'P@ssword1' };

    this.authService.login(userToLogin, false).subscribe({
      next: (response: any) => {
        this.router.navigateByUrl('/register/register-company');
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

}
