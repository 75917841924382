<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">

    <h1>{{newRule ? 'Create New Rule' : 'Edit Rule'}}</h1>

    <ng-container *ngIf="newRule && ruleResources && !evaluator">
        <ng-container *ngTemplateOutlet="evalTypesTemplate; context: {ruleResources: ruleResources}"></ng-container>
    </ng-container>

    <a [routerLink]="[]" class="back-to-eval" (click)="backToEvalSelect()" *ngIf="newRule && ruleResources && evaluator">
        <span class="fa-light fa-left-from-line icon"></span>
        <span class="text">Back to evaluation/rule type selection...</span>
    </a>

    <app-evaluator 
        [evaluator]="evaluator" 
        [selectedRule]="selectedRule" 
        [evaluatorTypes]="evaluatorTypes"
        [bonusResources]="bonusTypeResources" 
        [resultResources]="resultTypeResources"
        (evaluatorSaveEvent)="saveDialog($event)" 
        (evaluatorCancelEvent)="closeDialog()" *ngIf="evaluator">
    </app-evaluator>

</div>

<ng-template #evalTypesTemplate let-ruleResources="ruleResources">
    <h3>Select an evaluation/rule type...</h3>
    <div class="eval-types">
        <a [routerLink]="[]" class="plan-name" (click)="evaluator = resource" *ngFor="let resource of ruleResources">
            <div class="details">
                <div class="name">{{resource.name.replace('Test', 'Challenge')}}</div>
                <ng-container *ngTemplateOutlet="evalTypeInfoTemplate; context: {evalInfo: getEvalTypeInfo(resource.evaluatorType)}"></ng-container>
            </div>
            <div class="next">
                <span class="fa-regular fa-arrow-right fa-lg icon"></span>
            </div>
        </a>
    </div>
</ng-template>

<ng-template #evalTypeInfoTemplate let-evalInfo="evalInfo">
    <div class="info">
        <div class="description">{{evalInfo.description}}</div>
        <div class="example">{{evalInfo.example}}</div>
    </div>
</ng-template>