import { Injectable } from '@angular/core';
import { ResultsPaging } from '../models/results-paging';
import { PageCount } from '../models/page-count';

@Injectable()
export class HelperPaging {

  PREV = 'prev';
  NEXT = 'next';

  // DEPRECATED
  resultsPaging: ResultsPaging[];
  // DEPRECATED
  pageCount: PageCount;

  /**
   *
   * @returns ResultsPaging[]:  Initial value for new RESULTS PAGING object
   */
  resultsPagingInit(): ResultsPaging[] {
    return [{ continuationToken: '', pageNumber: 1 }];
  }

  /**
   *
   * @returns PageCount:  Initial value for new PAGE COUNT object
   */
  pageCountInit(): PageCount {
    return {
      showResultsPaging: false,
      countStart: 0,
      countEnd: 0,
      countTotal: '',
      currentPageCount: 0
    };
  }

  /**
   *
   * @param response The response from the query that contains continuation token
   * @param currentPageCount current page count
   * @returns boolean to determine whether or not to show paging
   */
  resultsPagingNeeded(response: any, currentPageCount: number): boolean {
    if (currentPageCount === 0 && response?.continuationToken === null) {
      // No paging needed
      return false;
    } else {
      return true;
    }
  }

  isPagingNeeded(response: any, currentPageCount: number): boolean {
    if (currentPageCount === 0 && response?.continuationToken === null) {
      // No paging needed
      return false;
    } else {
      return true;
    }
  }

  /**
   * DEPRECATED
   * @param pageDirection page direction
   * @param response collection response
   * @param continuationToken continuation token
   */
  calculatePagingValue(pageDirection: string, response: any, continuationToken: string): void {
    if (this.pageCount.showResultsPaging) {
      if (pageDirection === this.PREV) {
        this.pageCount.currentPageCount--;

        // Calculate page counts
        this.pageCount.countStart = this.pageCount.currentPageCount === 1 ? 1 : this.pageCount.countStart - parseInt(response.items.length);
        this.pageCount.countEnd = this.pageCount.countStart + response?.items.length - 1;
        this.pageCount.countTotal = 'many';

        if (response?.continuationToken !== null) {
          this.pageCount.nextContinuationToken = response?.continuationToken;
        }

        // remove the last item off the array
        // TODO:  Before popping off the last item in the array, make sure the
        //        pageCount.currentPageCount and the array length match, if not
        //        don't pop
        if (this.pageCount.currentPageCount > this.resultsPaging.length) {
          this.resultsPaging.pop();
        }

      } else {
        this.pageCount.currentPageCount++;

        // Calculate page counts
        this.pageCount.countStart = continuationToken === '' && response?.items.length > 0 ? 1 : this.pageCount.countEnd + 1;
        this.pageCount.countEnd = continuationToken === '' && response?.items.length > 0 ? response?.items.length : this.pageCount.countEnd += response?.items.length;
        this.pageCount.countTotal = response?.continuationToken === null ? this.pageCount.countEnd.toString() : 'many';

        if (response?.continuationToken !== null) {
          // Add the continuation and page number to the paging results object
          this.resultsPaging.push({
            continuationToken: response.continuationToken,
            pageNumber: this.pageCount.currentPageCount
          });
        }
      }

      if (this.pageCount.currentPageCount === 1) {
        this.pageCount.prevContinuationToken = '';
      } else if (this.pageCount.currentPageCount === 2) {
        this.pageCount.prevContinuationToken = 'FIRST';
      } else {
        this.pageCount.prevContinuationToken = this.resultsPaging[this.pageCount.currentPageCount - 2].continuationToken;
      }

      // TODO:  Need logic here for when user hits PREV button, we are not saving the NEXT token to go back
      if (pageDirection !== this.PREV) {
        this.pageCount.nextContinuationToken = this.resultsPaging[this.pageCount.currentPageCount]?.continuationToken;
      }

    } else {
      this.pageCount.countTotal = response && response.items ? response.items.length : '0';
    }

  }

  /**
   * Calculates all paging information needed and updates the
   * page count and results paging objects
   *
   * @param showResultsPaging boolean to determine if paging is needed
   * @param pageDirection direction of the page
   * @param continuationToken continuation token
   * @param apiResponse response object from the api
   * @param pageCount page count object (this object is updated in this method for use on the referencing class - by reference)
   * @param resultsPaging results paging array object (this object is updated in this method for use on the referencing class - by reference)
   */
  getPagingInfo(
    pageDirection: string,
    continuationToken: string,
    apiResponse: any,
    pageCount: PageCount,
    resultsPaging: ResultsPaging[]): void {

    if (pageCount === null) {
      pageCount = this.pageCountInit();
    }

    if (resultsPaging === null) {
      resultsPaging = this.resultsPagingInit();
    }

    if (pageCount.showResultsPaging) {
      if (pageDirection === this.PREV) {
        // Prev button clicked
        pageCount.currentPageCount--;

        // Calculate page counts
        pageCount.countStart = pageCount.currentPageCount === 1 ? 1 : pageCount.countStart - parseInt(apiResponse.items.length);
        pageCount.countEnd = pageCount.countStart + apiResponse?.items.length - 1;
        pageCount.countTotal = 'many';

        if (apiResponse?.continuationToken !== null) {
          pageCount.nextContinuationToken = apiResponse?.continuationToken;
        }

        // remove the last item off the array
        // TODO:  Before popping off the last item in the array, make sure the
        //        pageCount.currentPageCount and the array length match, if not
        //        don't pop
        if (pageCount.currentPageCount > resultsPaging.length) {
          resultsPaging.pop();
        }
      }
      else {
        // Next buttom clicked
        pageCount.currentPageCount++;

        // Calculate page counts
        pageCount.countStart = continuationToken === '' && apiResponse?.items.length > 0 ? 1 : pageCount.countEnd + 1;
        pageCount.countEnd = continuationToken === '' && apiResponse?.items.length > 0 ? apiResponse?.items.length : pageCount.countEnd += apiResponse?.items.length;
        pageCount.countTotal = apiResponse?.continuationToken === null ? pageCount.countEnd.toString() : 'many';

        if (apiResponse?.continuationToken !== null) {
          // Add the continuation and page number to the paging results object
          resultsPaging.push({
            continuationToken: apiResponse.continuationToken,
            pageNumber: pageCount.currentPageCount
          });
        }
      }

      if (pageCount.currentPageCount === 1) {
        pageCount.prevContinuationToken = '';
      } else if (pageCount.currentPageCount === 2) {
        pageCount.prevContinuationToken = 'FIRST';
      } else {
        pageCount.prevContinuationToken = resultsPaging[pageCount.currentPageCount - 2].continuationToken;
      }

      // TODO:  Need logic here for when user hits PREV button, we are not saving the NEXT token to go back
      if (pageDirection !== this.PREV) {
        pageCount.nextContinuationToken = resultsPaging[pageCount.currentPageCount]?.continuationToken;
      }
    }
    else {
      // Calculate page counts
      pageCount.countTotal = apiResponse && apiResponse.items ? apiResponse.items.length : '0';
    }

  }

}
