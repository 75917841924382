import { Component, OnInit } from '@angular/core';
import { AppUser } from 'src/app/core/models/app-user';
import { Page } from 'src/app/core/models/pages';
import { TenantAppSettings } from 'src/app/core/models/tenant-app-settings';
import { OfferService } from 'src/app/core/services/offer.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sideNavState: boolean;
  defaultPages: Page[];
  rewardPages: Page[];
  shoppingPages: Page[];
  adminPages: Page[];
  //officePages: Page[];

  user: AppUser;

  storeFrontEnabled: boolean;
  managementSectionEnabled: boolean;

  constructor(
    private offerService: OfferService,
    private userService: UserService) {
    this.defaultPages = [
      { name: 'Customers', link: '', icon: 'user-friends' },
      { name: 'QR Link', link: 'qr-code', icon: 'qrcode', iconType: 'fa-light' }
      // { name: 'Customers ALT', link: 'users-alt', icon: 'users-crown' }
      // { name: 'Rewards', link: 'rewards', icon: 'gift', iconType: 'fal' },
      // { name: 'Tasks', link: 'rewards/tasks', icon: 'tasks' },
      // { name: 'Plans', link: 'rewards/plans', icon: 'clipboard-list' }
    ];

    this.rewardPages = [
      // TODO:  Add role based security to these pages
      { name: 'Tasks', link: 'rewards/tasks', icon: 'tasks' },
      { name: 'Rules', link: 'rewards/rules', icon: 'clipboard-list' },
      { name: 'Template Plans', link: 'rewards/plans', icon: 'map-signs', iconType: 'fa-light' },
    ];

    this.shoppingPages = [
      { name: 'Orders', link: 'orders', icon: 'basket-shopping-simple', iconType: 'fa-solid' },
      // { name: 'Inventory', link: 'inventory', icon: 'box-taped', iconType: 'fa-light'},
      // { name: 'Store Catalog', link: 'store-catalog', icon: 'store', iconType: 'fa-regular'},
      // { name: 'Gift Catalog', link: 'gift-catalog', icon: 'gift', iconType: 'fa-regular'}
    ]

    // this.officePages = [
    //   { name: 'consultation', link: 'some-link', icon: 'chair-office', iconType: 'fal' },
    //   { name: 'brushing', link: 'some-link', icon: 'toothbrush', iconType: 'fal' },
    //   { name: 'award points', link: 'some-link', icon: 'ticket-alt', iconType: 'fal', class: 'ticket' }
    // ];

  }

  ngOnInit(): void {
    this.sideNavState = true;  // TRUE: sidenav open, FALSE: sidenav closed
    this.managementSectionEnabled = false;

    this.userService.user.subscribe((user: AppUser) => {
      this.user = user;

      if (user) {
        this.getStoreFrontEnabled();

        this.getAdminPagesEnabled();
      }
    });

  }

  getStoreFrontEnabled(): void {
    this.offerService.storeFrontEnabled.subscribe({
      next: (response: boolean | any) => {
        this.storeFrontEnabled = response;

        if (response !== null) {

          if (this.storeFrontEnabled) {
            this.shoppingPages.push({ name: 'Store Catalog', link: 'store-catalog', icon: 'store', iconType: 'fa-regular' });
            this.shoppingPages.push({ name: 'Gift Catalog', link: 'gift-catalog', icon: 'gift', iconType: 'fa-regular' });
          }
        } else {
          // Will hit this code if the user hits refresh in the browser.
          // Need to call the api to get the value for "store enabled"
          this.offerService.getTenantSettings().subscribe({
            next: (response: TenantAppSettings) => {
              const enableStoreFront: boolean = response['STORE_FRONT:ENABLED'] ?? false;
              this.offerService.storeFrontEnabledValue = enableStoreFront;
              this.storeFrontEnabled = enableStoreFront;
            }
          });
        }
      },
      error: (err: any) => {
        this.storeFrontEnabled = true;
        // TODO:  Fix this
        // this.errorDetail = this.parseErrorService.getFullApiError(err);
        // this.errorMessage = this.errorDetail.errorMessage;
      }
    })
  }

  getAdminPagesEnabled(): void {

    if (this.user && this.user.roleType && (this.user.roleType === 'Administrator' || this.user.roleType === 'PowerUser')) {
      this.managementSectionEnabled = true;

      this.adminPages = [
        // TODO:  Add role based security to these pages
        { name: 'Users', link: 'admin-users', icon: 'user-lock', additionalIconClass: 'fa-swap-opacity management-user' },
        //   { name: 'Users ALT', link: 'users-alt', icon: 'users-crown' },
        //   { name: 'Invite User', link: 'some-link', icon: 'paper-plane', iconType: 'fal' },
        //   { name: 'Store', link: 'some-link', icon: 'store', iconType: 'fal' },
        //   { name: 'Goals Setup', link: 'some-link', icon: 'bullseye-pointer' }
      ];
    }
  }

  onSidenavToggle(e: any): void {
    e.preventDefault();

    //this.sideNavState = !this.sideNavState;
    const toggleButton: HTMLLinkElement = e.target.classList.contains('icon') ? e.target.parentElement : e.target;

    this.toggleSideNavIcon(toggleButton);
    //this.sidenavService.sideNavState$.next(this.sideNavState);
  }

  toggleSideNavIcon(toggleButton: HTMLLinkElement): void {
    if (!toggleButton) {
      return;
    }

    // Get the .sidebar element
    const sideBarElement = toggleButton.parentElement?.parentElement;

    // make sure it is the sidebar element
    if (sideBarElement) {
      if (!sideBarElement.classList.contains('sidebar')) {
        return;
      }
    }

    const expandableElement = sideBarElement ? sideBarElement.getElementsByClassName('expandable') : undefined;

    setTimeout(() => {
      if (this.sideNavState) {
        toggleButton.getElementsByClassName('icon')[0].classList.remove('expanded');
        if (expandableElement) {
          for (var i = 0; i < expandableElement.length; i++) {
            expandableElement?.item(i)?.classList.remove('d-lg-inline');
          }
        }
        sideBarElement?.getElementsByClassName('mark-logo')[0].classList.remove('d-lg-none');
        sideBarElement?.classList.add('sidebar-manual-collapse');
      } else {
        toggleButton.getElementsByClassName('icon')[0].classList.add('expanded');
        if (expandableElement) {
          for (var i = 0; i < expandableElement.length; i++) {
            expandableElement?.item(i)?.classList.add('d-lg-inline');
          }
        }
        sideBarElement?.getElementsByClassName('mark-logo')[0].classList.add('d-lg-none');
        sideBarElement?.classList.remove('sidebar-manual-collapse');
      }

      this.sideNavState = !this.sideNavState;
    }, 200);
  }

}
