import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ResourceType } from 'src/app/core/models/reward/resource-type';
import { RuleActionable } from 'src/app/core/models/reward/rule-actionable';
import { RuleExpressionable } from 'src/app/core/models/reward/rule-expressionable';
import { RuleResource } from 'src/app/core/models/reward/rule-resource';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-rule-types',
  templateUrl: './rule-types.component.html',
  styleUrls: ['./rule-types.component.scss']
})
export class RuleTypesComponent implements OnInit {

  working: boolean;
  ruleResources: RuleResource[] = [];
  bonusTypeResources: ResourceType[] = [];
  resultTypeResources: ResourceType[] = [];
  errorMessage: string;
  errorDetail: WsApiError;
  activeRule: RuleResource;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.getRuleResources();
  }

  getRuleResources(): void {
    this.working = true;

    // Using combineLatest because need resources and rules to load at the same time (or sequentially)
    this.working = true;
    var bonusResources = this.rewardsService.bonusTypeResources;
    var resultResources = this.rewardsService.resultTypeResources;
    var ruleResources = this.rewardsService.getRuleResources();

    combineLatest([bonusResources, resultResources, ruleResources]).subscribe({
      next: (response: any) => {
        this.bonusTypeResources = response[0];
        this.resultTypeResources = response[1];
        this.ruleResources = response[2];

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  resultType(code: string): string {
    return this.resultTypeResources?.find(x => x.code === code)?.name ?? code;
  }

  bonusType(code: string): string {
    return this.bonusTypeResources?.find(x => x.code === code)?.name ?? code;
  }

  evaluationType(code: string): string {
    // TODO:  Get this list from a RESOURCE?
    const evalTypes: {[key: string]: string} = {
      'Equals': '=',
      'GreaterThan': '>',
      'GreaterThanOrEquals': '>=',
      'LessThan': '<',
      'LessThanOrEquals': '<=',
      'NotEquals': '!='
    };
    return evalTypes[code] ?? code;
  }

  getExpressionable(ruleResource: RuleResource, methodName: string): RuleExpressionable {
    return ruleResource?.expressionables?.find(x => x.propertyOrMethod === methodName) ?? null;
  }

  getActionable(ruleResource: RuleResource, methodName: string): RuleActionable {
    return ruleResource?.actionables?.find(x => x.methodName === methodName) ?? null;
  }

}
