export enum MfaType {
    none = 0,
    phoneNumber = 1,
    email = 2
}

export const mfaTypeToLabelMapping: Record<MfaType, string> = {
    [MfaType.none]: 'None',
    [MfaType.phoneNumber]: 'Phone/Text',
    [MfaType.email]: 'Email'
};
