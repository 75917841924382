import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlanSaveType } from 'src/app/core/models/reward/plan-save-info';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplatePlan } from 'src/app/core/models/reward/template-plan';
import { TemplatePlansResponse } from 'src/app/core/models/reward/template-plans-response';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { DialogAreYouSureComponent } from '../../_shared/dialog-are-you-sure/dialog-are-you-sure.component';
import { DialogPlanComponent } from '../../_shared/dialog-plan/dialog-plan.component';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  working: boolean;
  plans: TemplatePlan[] = [];
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.getAllPlans();
  }

  getAllPlans(): void {
    this.working = true;

    this.rewardsService.getTemplatePlans().subscribe({
      next: (response: TemplatePlansResponse | any) => {
        this.plans = response?.items;

        // this.plans.forEach((plan: TemplatePlan) => {
        //   this.initializeTasksAndLevels(plan);
        // });

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  openPlanDialog(selectedPlan: TemplatePlan, newPlan: boolean = false): void {
    const dialogRef = this.dialog.open(DialogPlanComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '802px',
      position: { top: '45px' },
      panelClass: 'pml-modal-plan',
      data: {
        selectedPlan: selectedPlan,
        newPlan: newPlan,
        message: ''
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {  // result === '' means cancel button was clicked
        if (result.saveType === PlanSaveType.newPlan) {
          // Add the plan to the array
          //this.plans.push(result.plan);
          this.plans.unshift(result.plan);
        } else {
          // Update the existing plan
          const planIndexToUpdate: number = this.plans.findIndex(x => x.key === result.plan.key);
          if (planIndexToUpdate > -1) {
            this.initializeTasksAndLevels(result.plan);
            // TODO:  Finish this up once we complete the design
            this.plans[planIndexToUpdate] = result.plan;
          }
        }
      }
    });
  }

  initializeTasksAndLevels(plan: TemplatePlan): void {
    if (!plan) {
      return;
    }

    plan.tasks.forEach((task: TemplateTask) => {
      task.templateTaskKey = task.key;
    })

    plan.levels.forEach((level: TemplateLevel) => {
      level.templateTaskKey = level.key;
    })
  }

  openConfirmPlanDeleteDialog(e: Event, planToDelete: TemplatePlan): void {
    const deleteElement = e.currentTarget as HTMLSpanElement;
    const deleteDiv: HTMLDivElement = deleteElement.parentElement.parentElement.querySelector('.item-delete-overlay');

    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {
      autoFocus: false,
      position: { top: '10vh' },
      maxWidth: '430px',
      width: '430px',
      panelClass: 'are-you-sure',
      data: {
        deleteObjectName: planToDelete.name,
        deleteObjectTypeName: 'plan',
        message: 'This plan will be deleted from the system, including all data associated with the plan.  Are you sure you want to proceed? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data && result.data.confirmDelete) {  // result === '' means cancel button was clicked

        deleteDiv.classList.add('show-deleting');

        // Call reward service here to delete the plan
        this.rewardsService.deleteTemplatePlan(planToDelete.key).subscribe({
          next: (response: any) => {
            // return the user to the main team page for display
            if (response) {
              // Update the list of rules for display on the page
              this.plans = this.plans.filter(x => x.key !== planToDelete.key);
            } else {
              // There is was a problem, display an error
              this.errorMessage = 'There was a problem with the request.';
              deleteDiv.classList.remove('show-deleting');
            }
          },
          error: (err: any) => {
            // Display the error
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            deleteDiv.classList.remove('show-deleting');
          }
        });
      }
    });
  }

}
