<div class="avatar user-avatar {{defaultAvatar ? 'default' : ''}}" [style.background-color]="avatarBgColor"
    (click)="updateAvatar = !updateAvatar" *ngIf="!isNewUser">
    <img src="{{avatarImg}}" class="img" title="user" />
</div>

<span class="avatar new-user" *ngIf="isNewUser">
    <span class="fal fa-plus fa-3x icon"></span>
</span>

<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div class="nav nav-tabs">
    <a [routerLink]="[]" class="nav-item {{activeTab === PROFILE_TAB ? 'active' : ''}}"
        (click)="setActiveTab(PROFILE_TAB)">Profile</a>
    <a [routerLink]="[]" class="nav-item {{activeTab === PLAN_TAB ? 'active' : ''}}"
        (click)="setActiveTab(PLAN_TAB)">Plan</a>
</div>

<div mat-dialog-content class="dialog-container">
    <ng-container *ngTemplateOutlet="userNameDisplay; context: {user: user}"></ng-container>

    <app-choose-avatar [user]="user" [isNewUser]="false"
        (closeAvatarEvent)="closeAvatarWindow()"
        (previewAvatarEvent)="previewAvatar($event)" 
        (resetAvatarToOriginalEvent)="resetAvatarToOriginal()"
        (updateAvatarEvent)="updateUserWithAvatar($event)" 
        *ngIf="updateAvatar">
    </app-choose-avatar>

    <ng-container *ngIf="activeTab === PROFILE_TAB">
        <ng-container *ngTemplateOutlet="userTabTemplate; context: {user: user}"></ng-container>
    </ng-container>

    <ng-container *ngIf="activeTab === PLAN_TAB">
        <ng-container *ngTemplateOutlet="planTabTemplate; context: {user: user}"></ng-container>
    </ng-container>

</div>

<ng-template #userNameDisplay let-user="user">
    <div class="dialog-username-display">
        <ng-container *ngIf="user && user.info && user.info.name && user.info.name.first && user.info.name.last; else elseBlock">
            {{user.info.name.first}} <ng-container *ngIf="user.info.name.middle">{{user.info.name.middle}}</ng-container> {{user.info.name.last}}
        </ng-container>
    </div>
</ng-template>
<ng-template #elseBlock let-user="user">
    <ng-container *ngIf="user?.displayName">
        {{user?.displayName}}
    </ng-container>
</ng-template>

<ng-template #userTabTemplate let-user="user">
    <div class="user-content">
        <div class="user-info-row">
            <ng-container *ngTemplateOutlet="userTemplate; context: {user: user}"></ng-container>

            <div class="user-info first-row balance">
                <app-user-balance [user]="user"></app-user-balance>
            </div>
        </div>

        <div class="user-info task">
            <app-loading loadingText="loading user plan..." loadingSize="lg" alignText="left" *ngIf="workingPlan"></app-loading>
            
            <app-user-activity-summary [user]="user" 
                [childComponentActivitySummary]="childComponentActivitySummary"
                (updateParentUserActivitySummary)="updateUserActivitySummary($event)"
                *ngIf="!workingPlan && userPlan"></app-user-activity-summary>

            <app-user-plan-add [user]="user" 
                (enrollUserInPlanEvent)="enrollUserInPlan($event)" 
                *ngIf="!workingPlan && !userPlan" class="app"></app-user-plan-add>
        </div>

        <div class="user-info last family">
            <h4>{{hasParent ? 'Parent' : 'Family Members'}}</h4>
            <app-linked-users [user]="user" 
                (viewLinkedUserEvent)="viewLinkedUser($event)"
                (newLinkedUserSaveEvent)="newLinkedUserSave($event)" ></app-linked-users>
        </div>
    </div>
</ng-template>

<ng-template #planTabTemplate let-user="user">
    <div class="user-content">
        <div class="user-info plan-info">
            <app-user-tasks [user]="user" 
                [userPlan]="userPlan" 
                [childComponentTemplateTasks]="childComponentTemplateTasks"
                [working]="workingTask"
                [savingTask]="savingTask"
                (updatePlanTaskInfoByTaskDate)="updatePlanTaskInfoByTaskDate($event)"
                (updatePlanByTaskDate)="updatePlanByTaskDate($event)"
                (updateParentTemplateTasks)="updateTemplateTasks($event)"
                (updateParentPlan)="updateParentPlan($event)"></app-user-tasks>
        </div>

        <div class="user-info plan-info">
            <app-user-challenge [user]="user" 
                [userPlan]="userPlan" 
                [childComponentTemplateTasks]="childComponentTemplateTasks"
                [childComponentTemplateTests]="childComponentTemplateTests"
                [working]="workingPlan"
                (updateParentTemplateTasks)="updateTemplateTasks($event)"
                (updateParentTemplateTests)="updateTemplateTests($event)"
                (updateParentPlan)="updateParentPlan($event)"></app-user-challenge>
        </div>

        <div class="user-info plan-info">
            <app-user-milestones [user]="user" 
                [userPlan]="userPlan" 
                [childComponentTemplateTasks]="childComponentTemplateTasks"
                [working]="workingPlan"
                [savingMilestone]="savingMilestone"
                (updatePlanMilestoneInfoByTaskDate)="updatePlanMilestoneInfoByTaskDate($event)"
                (updateParentTemplateTasks)="updateTemplateTasks($event)"
                (updateParentPlan)="updateParentPlan($event)"></app-user-milestones>
        </div>

        <div class="add-additional-plan {{userHasAvailablePlans ? '' : 'd-none'}}">
            <a [routerLink]="[]" class="add-plan-link" (click)="showAdditionalPlanContent = !showAdditionalPlanContent">
                <span class="fa-regular fa-caret-right fa-fw icon {{showAdditionalPlanContent ? 'fa-rotate-90' : ''}}"></span>
                Add additional plan
            </a>

            <div class="add-plan-content {{showAdditionalPlanContent ? '' : 'd-none'}}">
                <app-user-plan-add-to-existing  [user]="user" 
                    [templatePlanKeys]="userPlan.templatePlanKeys" 
                    (hasAvailablePlans)="updateUserHasAvailablePlans($event)"
                    (addPlanToUserPlan)="enrollUserInPlan($event)"></app-user-plan-add-to-existing>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #userTemplate let-user="user">
    <div class="user-info first-row first user-account">
        <app-user-info [user]="user" [isNewUser]="false"></app-user-info>
    </div>
</ng-template>