
<ng-container *ngTemplateOutlet="pageHeaderTemplate"></ng-container>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<app-loading loadingText="loading catalog..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<div class="items" *ngIf="!working && products?.length > 0">
    <div class="item {{i === 0 ? 'first' : ''}}" *ngFor="let item of products; index as i">
        <div class="item-container" (click)="openProductDialog(item, false)">
            <div class="item-img">
                <img src="{{item.images[0]?.url}}" title="{{item.name}}" *ngIf="item.images[0]?.url" />
            </div>
            <div class="item-detail">
                <div class="name">{{item.name}}</div>

                <div class="info">
                    <div class="product-info">
                        <div class="info-line">
                            <span class="data-item" *ngIf="item?.productCategoryCode">{{item?.productCategoryCode}}</span>
                            <span class="separator" *ngIf="item?.productCategoryCode && item?.manufacturerCode">|</span>
                            <span class="data-item" *ngIf="item?.manufacturerCode">{{item?.manufacturerCode}}</span>
                        </div>
                        <div class="info-line price">
                            Offer price {{getAmountDisplay(item.amount, item.currencyCode)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <span class="action-item delete" (click)="openConfirmProductDeleteDialog($event, item)">
                <span class="text">delete</span>
                <span class="far fa-trash-alt icon"></span>
            </span>
        </div>
        <div class="item-delete-overlay">
            <div class="fa-duotone fa-spinner fa-pulse fa-lg icon"></div>
            <div class="text">deleting...</div>
        </div>
    </div>

    <div class="bottom-paging  paging-container">
        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
    </div>
</div>

<div class="none-found" *ngIf="!working && (!products || products.length === 0)">
    <span class="fa-light fa-face-unamused icon fa-lg"></span>
    No products found
</div>

<ng-template #pageHeaderTemplate>
    <div class="content-header paging-container">
        <div class="customer-actions">
            <a [routerLink]="[]" class="new action-item first" (click)="openProductDialog(null, true)">
                <span class="fal fa-plus icon"></span>
                new
            </a>

            <a [routerLink]="[]" class="new action-item" (click)="openFulfillableProductDialog()" *ngIf="storeTypeCode === 'GIF'">
                <span class="fal fa-plus icon"></span>
                Import Fulfillable Product
            </a>
        </div>

        <div class="search action-item input-group">
            <span class="far fa-search icon input-group-text"></span>
            <input type="text" class="form-control with-trailing-button" name="search" placeholder="Search by Product Name" [(ngModel)]="productSearchValue" (keyup)="searchValidator($event)" />
            <button type="button" class="btn btn-light" (click)="searchProducts($event)" [disabled]="isSearchButtonDisabled()">search</button>
        </div>
        <div class="clear-search-results" *ngIf="areResultsFromSearch">
            <a [routerLink]="[]" (click)="clearSearchResults()">
                <span class="fa-regular fa-xmark fa-sm icon"></span>
                Clear search results
            </a>
        </div>

        <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>

        <div class="item-count" *ngIf="!working && errorMessage.length === 0 && products.length > 0 && !pagingCount.showResultsPaging">
            <span class="count">{{products.length}}</span> {{products.length === 1 ? 'product' : 'products'}} found
        </div>
    </div>
</ng-template>

<ng-template #pagingTemplate>
    <div class="paging" *ngIf="!working && pagingCount.showResultsPaging">
        <ng-container *ngTemplateOutlet="pagePrevButtonTemplate; context: {continuationToken: pagingCount.prevContinuationToken}"></ng-container>
        <div class="count">
            {{pagingCount.countStart}} to {{pagingCount.countEnd}} of {{pagingCount.countTotal}}
        </div>
        <ng-container *ngTemplateOutlet="pageNextButtonTemplate; context: {continuationToken: pagingCount.nextContinuationToken}"></ng-container>
    </div>
</ng-template>

<ng-template #pagePrevButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page prev" (click)="pageProducts(continuationToken, PREV)">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || continuationToken === ''">
        <div class="page prev disabled">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #pageNextButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page next" (click)="pageProducts(continuationToken, NEXT)">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || (continuationToken && continuationToken === '')">
        <div class="page next disabled">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>
</ng-template>