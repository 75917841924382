import { Avatar } from '../models/avatar';

export const AVATARS: Avatar[] = [
    { name: 'avatar-astronaut-flying', fileName: 'avatar-astronaut-flying.png', color: '#489BE8' },
    { name: 'avatar-bulldog-skating', fileName: 'avatar-bulldog-skating.png', color: '#7ED9A7' },
    { name: 'avatar-carrot', fileName: 'avatar-carrot.png', color: '#FECB5B' },
    { name: 'avatar-cat', fileName: 'avatar-cat.png', color: '#FF8F9D' },
    { name: 'avatar-coffee', fileName: 'avatar-coffee.png', color: '#F181BF' },
    { name: 'avatar-cow', fileName: 'avatar-cow.png', color: '#F181BF' },
    { name: 'avatar-donut', fileName: 'avatar-donut.png', color: '#FCD139' },
    { name: 'avatar-earth', fileName: 'avatar-earth.png', color: '#FF6D6D' },
    { name: 'avatar-giraffe', fileName: 'avatar-giraffe.png', color: '#DC9C62' },
    { name: 'avatar-godzilla', fileName: 'avatar-godzilla.png', color: '#FF6D6D' },
    { name: 'avatar-hamburger', fileName: 'avatar-hamburger.png', color: '#FECB5B' },
    { name: 'avatar-headphones', fileName: 'avatar-headphones.png', color: '#D8A5F0' },
    { name: 'avatar-hedgehog', fileName: 'avatar-hedgehog.png', color: '#7ED9A7' },
    { name: 'avatar-hotdog', fileName: 'avatar-hotdog.png', color: '#FCD139' },
    { name: 'avatar-husky', fileName: 'avatar-husky.png', color: '#D8A5F0' },
    { name: 'avatar-jet', fileName: 'avatar-jet.png', color: '#ACD91C' },
    { name: 'avatar-joystick', fileName: 'avatar-joystick.png', color: '#FF6D6D' },
    { name: 'avatar-logo', fileName: 'avatar-logo.png', color: '#FF6D6D' },
    { name: 'avatar-octopus', fileName: 'avatar-octopus.png', color: '#ACD91C' },
    { name: 'avatar-paint', fileName: 'avatar-paint.png', color: '#D8A5F0' },
    { name: 'avatar-plant', fileName: 'avatar-plant.png', color: '#56C9D9' },
    { name: 'avatar-pliers', fileName: 'avatar-pliers.png', color: '#889194' },
    { name: 'avatar-popcorn', fileName: 'avatar-popcorn.png', color: '#FF6D6D' },
    { name: 'avatar-pug', fileName: 'avatar-pug.png', color: '#FECB5B' },
    { name: 'avatar-pummelo', fileName: 'avatar-pummelo.png', color: '#7674E2' },
    { name: 'avatar-pumpkin', fileName: 'avatar-pumpkin.png', color: '#7674E2' },
    { name: 'avatar-radio', fileName: 'avatar-radio.png', color: '#7674E2' },
    { name: 'avatar-rubberduck', fileName: 'avatar-rubberduck.png', color: '#71B945' },
    { name: 'avatar-shoe', fileName: 'avatar-shoe.png', color: '#56C9D9' },
    { name: 'avatar-submarine', fileName: 'avatar-submarine.png', color: '#489BE8' },
    { name: 'avatar-tractor', fileName: 'avatar-tractor.png', color: '#489BE8' },
    { name: 'avatar-unicorn', fileName: 'avatar-unicorn.png', color: '#F181BF' }
];
