import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-catalog',
  templateUrl: './store-catalog.component.html',
  styleUrls: ['./store-catalog.component.scss']
})
export class StoreCatalogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
