import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { AppUserNewLinked } from 'src/app/core/models/app-user-new-linked';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-linked-users-add',
  templateUrl: './linked-users-add.component.html',
  styleUrls: ['./linked-users-add.component.scss']
})
export class LinkedUsersAddComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  toggleSuffix: boolean;
  linkedUserForm: UntypedFormGroup;
  isMinor: boolean;
  months: string[];
  days: number[];

  @Input() public parentUser: AppUser;
  @Output() userSaveEvent = new EventEmitter<AppUser>();
  @Output() userCancelEvent = new EventEmitter();


  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.toggleSuffix = false;

    this.isMinor = false;
    this.months = Array(12).fill(0).map((i, idx) => this.getMonth(idx + 1));

    this.createForm();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.linkedUserForm.controls;
  }

  createForm(): void {
    this.linkedUserForm = this.fb.group({
      //displayName: [this.user?.displayName ?? ''],
      first: ['', Validators.required],
      middle: [''],
      last: [''],
      suffix: [''],
      emailAddress: ['', Validators.email],
      year: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)]],
      month: [0],
      day: [0],
      isMinor: [false]
    });

  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && name === 'dob') {
      return true;
    } else if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    if (errors['invalidDob']) {
      return 'Date of Birth is not a valid date';
    }

    if (errors['min']) {
      return `${labelName} is an invalid year (too short)`;
    }

    if (errors['max']) {
      return `${labelName} is an invalid year (too long)`;
    }

    return 'Error';
  }

  /**
   * TODO:  Probably extract this to a static helper
   * @param index index number
   * @returns month
   */
  public getMonth(index: number) {
    const myDate = new Date();
    myDate.setDate(1);
    myDate.setMonth(index - 1);

    const locale = 'en-us';
    const month = myDate.toLocaleString(locale, { month: 'long' });

    return month;
  }

  public getDays(thisForm: any): number[] {
    const dayCount = !thisForm.year && !thisForm.month ?
      31 :
      this.getDaysInMonth(thisForm.year, thisForm.month);
    return Array(dayCount).fill(0).map((i, idx) => idx + 1);
  }

  public getDaysInMonth(year: number, month: number): number {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  isMinorToggle(e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    if (currentCheckbox.id === 'userIsMinor') {
      this.isMinor = !currentCheckbox.checked;  // Mark it as the opposite, because "checked" is true for adult
    } else {
      this.isMinor = !currentCheckbox.checked;
    }

    // TODO:  Possible save this without hitting save button
  }

  validateDOBInput(): void {
    if (!this.isInputDOBValidDate()) {
      this.f['month'].setErrors({ invalidDob: true });
      this.f['day'].setErrors({ invalidDob: true });
      this.f['year'].setErrors({ invalidDob: true });
    } else {
      this.f['month'].setErrors(null);
      this.f['month'].updateValueAndValidity();
      this.f['day'].setErrors(null);
      this.f['day'].updateValueAndValidity();
      this.f['year'].setErrors(null);
      this.f['year'].updateValueAndValidity();
    }
  }

  isInputDOBValidDate(): boolean {
    if (!this.f['year'].value || !this.f['month'].value || !this.f['day'].value) {
      return false;
    }

    var dob: Date = new Date(`${this.f['year'].value}-${this.f['month'].value}-${this.f['day'].value}`);

    // TODO:  If DOB puts the user younger than 18, toggle isMinor

    if (isNaN(dob.getTime())) {
      return false;
    }

    return true;
  }

  saveLinkedUser(): void {
    this.saving = true;
    const newUser: AppUserNewLinked = this.populateUserDataFromFormInput();

    this.userService.createAppUser(newUser).subscribe({
      next: (response: AppUser | any) => {
        //this.user = response;

        // Emit the user back to the parent page to update both the linked user list and the CUSTOMERS list with the
        // new user and the linked information

        this.userSaveEvent.emit(response);
        this.linkedUserForm.reset();

        this.saving = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      }
    });
  }

  populateUserDataFromFormInput(): AppUserNewLinked {
    const newUser: AppUserNewLinked = { info: { name: {}} };

    newUser.parentUserKey = this.parentUser.key;
    newUser.info.name.first = this.f['first'].value;

    if (this.f['middle'] && this.f['middle'].value.length > 0) {
      newUser.info.name.middle = this.f['middle'].value;
    }

    if (this.f['last'] && this.f['last'].value.length > 0) {
      newUser.info.name.last = this.f['last'].value;
    }

    if (this.f['suffix'] && this.f['suffix'].value.length > 0) {
      newUser.info.name.suffix = this.f['suffix'].value;
    }

    if (this.f['emailAddress'] && this.f['emailAddress'].value.length > 0) {
      newUser.emailAddress = this.f['emailAddress'].value;
      newUser.username = this.f['emailAddress'].value;
      newUser.displayName = this.f['emailAddress'].value.substring(0, this.f['emailAddress'].value.lastIndexOf('@'));
    }

    if (this.isInputDOBValidDate()) {
      if (!newUser.info.dateOfBirth) {
        newUser.info.dateOfBirth = {};
      }

      newUser.info.dateOfBirth.year = this.f['year'].value;
      newUser.info.dateOfBirth.month = this.f['month'].value;
      newUser.info.dateOfBirth.day = this.f['day'].value;
    }

    newUser.info.isMinor = this.isMinor;
    newUser.status = 'Active';
    newUser.roleType = 'Customer';

    return newUser;
  }

  cancelLinkedUser(): void {
    this.linkedUserForm.reset();
    this.userCancelEvent.emit();
  }

}
