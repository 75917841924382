import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { MfaRequirement } from 'src/app/core/enums/mfa-requirement';
import { AppUser } from 'src/app/core/models/app-user';
import { Tenant } from 'src/app/core/models/tenant';
import { TenantAppSettings } from 'src/app/core/models/tenant-app-settings';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  errorMessage: string;
  working: boolean;
  newCodeRequestWorking: boolean;

  forgotForm: UntypedFormGroup;
  newPwForm: UntypedFormGroup;

  emailOrPhoneNumber: string;
  showEnterEmailOrPhone: boolean;
  showEnterNewPw: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private offerService: OfferService,
    private localStorageService: LocalStorageService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.errorMessage = '';
    this.working = false;
    this.newCodeRequestWorking = false;

    this.emailOrPhoneNumber = '';
    this.showEnterEmailOrPhone = true;
    this.showEnterNewPw = false;

    this.createForms();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgotForm.controls;
  }

  get p(): { [key: string]: AbstractControl } {
    return this.newPwForm.controls;
  }

  createForms(): void {
    this.forgotForm = this.fb.group({
      email: ['', Validators.required]
    });

    this.newPwForm = this.fb.group({
      confirmationToken: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]
    });
  }

  isMatch(regExPattern: string, stringToTest: string): boolean {
    const regex = new RegExp(regExPattern);
    if (regex) {
      return regex.test(stringToTest);
    }
    return false;
  }

  requestNewCode(): void {
    this.newCodeRequestWorking = true;

    if (this.emailOrPhoneNumber !== '') {
      this.forgotPasswordRequest(this.emailOrPhoneNumber);
    }
  }

  forgotFormSubmit(): void {
    const emailOrPhoneNumber: string = this.f['email'].value;
    this.forgotPasswordRequest(emailOrPhoneNumber);
  }

  forgotPasswordRequest(emailOrPhoneNumber: string, showWorking: boolean = true): void {
    this.working = showWorking;

    // get email/phone from form
    this.emailOrPhoneNumber = emailOrPhoneNumber;

    this.authService.getAnonToken()
      .pipe(
        concatMap((response: any) => {
          const token = response;
          this.localStorageService.saveToken('access_token', token.token);
          return this.authService.forgotPassword(this.emailOrPhoneNumber);
        })
      )
      .subscribe({
        next: (response: boolean | any) => {
          // Show the actual change PW screen with code field if response is true
          if (response) {
            this.showEnterEmailOrPhone = false;
            this.showEnterNewPw = true;
          }

          this.working = false;
          this.newCodeRequestWorking = false;
        },
        error: (error: any) => {
          this.errorMessage = error;
          this.working = false;
          this.newCodeRequestWorking = false;
        }
      });
  }

  cancelNewPw(): void {
    // TODO: Not sure if I want to redirect back to login page or just back to forgot PW normal page

    // Back to forgot PW page
    this.errorMessage = '';
    this.emailOrPhoneNumber = '';
    this.showEnterEmailOrPhone = true;
    this.showEnterNewPw = false;
  }

  newPwSubmit(): void {
    this.working = true;
    this.errorMessage = '';
    let token: string;
    let tenant: Tenant;

    const newPw: string = this.p['password'].value;
    const confirmationToken: string = this.p['confirmationToken'].value;

    // TODO:  This code is essentially duplplicated in the login.component.ts file.  Think about refactoring.
    this.authService.forgotPasswordReset(confirmationToken, newPw).pipe(
      concatMap((response: any) => {
        // Check to see if MFA needed, if not, then return response
        if (response.data?.mfaRequirement === MfaRequirement.needsAuthorization ||
          response.data?.mfaRequirement === MfaRequirement.pendingAuthorization) {
          // TODO:  Test this out
          this.router.navigateByUrl('/verify');
          return null;
        }

        token = response.data?.token;
        this.localStorageService.saveToken('access_token', token);
        return this.tenantService.getUserTenants();
      }),
      concatMap((response: Array<Tenant>) => {

        // Get the tenant
        if (response && response.length > 0) {
          tenant = this.tenantService.setAppTenant(response, token);
        }

        return this.tenantService.updateToken(tenant);
      }),
      concatMap((response: Tenant) => {

        return this.offerService.getTenantSettings();
      }),
      concatMap((response: TenantAppSettings) => {
        // used for sidebar to determine whether or not to show store front
        this.offerService.storeFrontEnabledValue = response['STORE_FRONT:ENABLED'] ?? false;

        // Now call get AppUser
        return this.userService.getAppUser(tenant.key);
      })
    ).subscribe({
      next: (response: AppUser) => {
        // TODO:  Check to make sure that there is a TENANT key in the token
        this.userService.updateUser(response);

        this.authService.isLoggedIn = true;
        this.router.navigateByUrl('/dashboard');
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      },
      complete: () => {
        this.working = false;
      }
    });
  }

}
