<span class="fas fa-times-circle fa-lg fa-fw icon close new-user-close" style="--dialog-width: {{dialogWidth}}" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">

    <div class="pml-dialog-header-footer">
        <div class="user-header pml-dialog-header">
            <h3>Invite New Management User</h3>
            <div class="info">
                Enter the name and email address of the user you would like to invite as a management user.  Select the role 
                below that you would like to assign to the user. 
            </div>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="newUserFormTemplate"></ng-container>
</div>

<ng-template #newUserFormTemplate>
    <div class="new-user-form pml-form">
        <app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

        <form class="pml-form horiz-layout" [formGroup]="userForm" (ngSubmit)="userForm.valid" (ngSubmit)="saveUser()" autocomplete="off">
            <div class="pml-row w-fit-content">
                <label>name</label>
                <span class="required" *ngIf="isInvalid(f['first'])">{{getError(f['first'].errors, 'First name')}}</span>
                <div class="input-data name">
                    <input type="text" class="form-control med-text first admin-first" formControlName="first" placeholder="first name" />
                    <input type="text" class="form-control large-text admin-last" formControlName="last" placeholder="last name" />
                </div>
            </div>
            <div class="pml-row email w-fit-content">
                <label>email</label>
                <span class="required" *ngIf="isInvalid(f['emailAddress'])">{{getError(f['emailAddress'].errors, 'Email address')}}</span>
                <div class="input-data">
                    <input type="text" class="form-control" formControlName="emailAddress" placeholder="email" />
                </div>
            </div>
            <div class="pml-row w-fit-content">
                <label>role</label>
                <span class="required" *ngIf="isInvalid(f['roleType'])">{{getError(f['roleType'].errors)}}</span>
                <select class="form-select input-data" formControlName="roleType">
                    <option [ngValue]="null" disabled selected hidden></option>
                    <option *ngFor="let item of roleTypes" [ngValue]="item.key">
                        {{item.value}}
                    </option>
                </select>
            </div>
            <div class="pml-row submit-form pml-dialog-actions">
                <input type="button" class="btn btn-outline-primary btn-cancel" value="cancel" (click)="resetFormToDefault()">
                <button type="submit" class="btn btn-primary save"
                    [disabled]="saving || !(userForm.valid && userForm.dirty)">
                    <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                    <span *ngIf="!saving" class="">invite user</span>
                    <span *ngIf="saving" class="">inviting...</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>