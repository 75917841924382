import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperImageCompression } from 'src/app/core/helpers/helper-image-compression';
import { Address } from 'src/app/core/models/Address';
import { AppUser } from 'src/app/core/models/app-user';
import { ImageRequest } from 'src/app/core/models/image-request';
import { MCC } from 'src/app/core/models/registration/MCC';
import { ExternalAccount } from 'src/app/core/models/registration/external-account';
import { RegisterBusiness } from 'src/app/core/models/registration/register-business';
import { RegisterPerson } from 'src/app/core/models/registration/register-person';
import { TenantAccount } from 'src/app/core/models/registration/tenant-account';
import { TenantAccountRequest } from 'src/app/core/models/registration/tenant-account-request';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { JsonService } from 'src/app/core/services/json.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['../register.component.scss', './merchant-details.component.scss']
})
export class MerchantDetailsComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  merchantForm: UntypedFormGroup;
  user: AppUser;
  tenantAccount: TenantAccount;

  mccList: MCC[];

  REGISTER_ADDRESS: string = 'REGISTER-ADDRESS';

  constructor(
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentService,
    private jsonService: JsonService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;

    this.getUser();
    this.getTenantAccount();

    this.getMccList();

    this.createForm();
  }

  getUser(): void {
    this.authService.getRegisterUser().subscribe({
      next: (response: any) => {
        this.user = response.data;

        //update the accountHolderName with the user's name
        if (this.user && this.user.info && this.user.info.name && this.user.info.name.first && this.user.info.name.last) {
          let fullName: string;

          if (this.user.info.name.first.length > 0) {
            fullName = this.user.info.name.first;
          }
          if (this.user.info.name.middle && this.user.info.name.middle.length > 0) {
            if (fullName.length > 0) {
              fullName += ' ';
            }
            fullName += this.user.info.name.middle;
          }
          if (this.user.info.name.last.length > 0) {
            if (fullName.length > 0) {
              fullName += ' ';
            }
            fullName += this.user.info.name.last;
          }
          if (fullName.length > 0) {
            this.merchantForm.patchValue({
              accountHolderName: fullName
            });
          }
        }
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  getTenantAccount(): void {
    this.paymentService.getTenantAccount().subscribe({
      next: (response: TenantAccount | any) => {
        this.tenantAccount = response.data;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.merchantForm.controls;
  }

  getMccList(): void {
    this.jsonService.getMCCs().subscribe({
      next: (response: MCC[] | any) => {
        this.mccList = response;

        //this.stateList.forEach((state: State) => {
        //  console.log(`state: ${state.name} - ${state.code}`);
        //});
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  createForm(): void {
    this.merchantForm = this.fb.group({
      routingNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      accountNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      accountHolderName: ['', Validators.required],
      merchantCode: ['8021', Validators.required],
      taxId: ['', Validators.required]
    });

  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = '', overrideLabel: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      if (overrideLabel !== '') {
        return overrideLabel;
      } else {
        return labelName !== '' ? `${labelName} is required` : 'required';
      }
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['maxlength']) {
      return labelName !== '' ? `${labelName} is too long (${errors['maxlength']?.requiredLength} chars max)` : `too long (${errors['maxlength']?.requiredLength} chars max)`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern'] && (labelName === 'ACCOUNT NUMBER' || labelName === 'ROUTING NUMBER')) {
      return 'Only numbers are allowed in this field';
    }

    if (errors['notSame']) {
      return 'Passwords do not match';
    }

    return 'Error';
  }

  updateProofOfIdImage(event: any): void {
    this.f['proofOfIdImage'].markAsTouched();
    this.f['proofOfIdImage'].markAsDirty();
  }

  saveMerchantInfo(): void {
    this.saving = true;

    const accountBusinessRequest: TenantAccountRequest = this.getBusinessInfo();

    this.paymentService.patchTenantAccount(accountBusinessRequest).subscribe({
        next: (response: TenantAccount | any) => {
          this.saving = false;

          // delete the address from sessionStorage
          sessionStorage.removeItem(this.REGISTER_ADDRESS);

          this.router.navigateByUrl('/register/register-complete');
        },
        error: (err: any) => {
          this.saving = false;
          this.errorMessage = this.parseErrorService.parseApiError(err);
        }
      });

  }

  getBusinessInfo(): TenantAccountRequest {
    let accountRequest: TenantAccountRequest = {};

    // Get the address from sessionStorage so that we can use it as business address
    const companyAddressJson: string = sessionStorage.getItem(this.REGISTER_ADDRESS);
    if (companyAddressJson) {
      const companyAddress: Address = JSON.parse(companyAddressJson);
      accountRequest.address = companyAddress;
    }

    // From form
    let externalAccount: ExternalAccount = {
      currencyCode: 'USD',
      countryCode: 'US'
    };
    if (this.f['routingNumber'].value && this.f['routingNumber'].value !== '') {
      externalAccount.routingNumber = this.f['routingNumber'].value;
    }
    if (this.f['accountNumber'].value && this.f['accountNumber'].value !== '') {
      externalAccount.accountNumber = this.f['accountNumber'].value;
    }
    if (this.f['accountHolderName'].value && this.f['accountHolderName'].value !== '') {
      externalAccount.accountHolderName = this.f['accountHolderName'].value;
    }
    accountRequest.externalAccount = [];
    accountRequest.externalAccount.push(externalAccount);

    if (this.f['merchantCode'].value && this.f['merchantCode'].value !== '') {
      accountRequest.mcc = this.f['merchantCode'].value;
    }

    if (this.f['taxId'].value && this.f['taxId'].value !== '') {
      accountRequest.taxId = this.f['taxId'].value;
    }

    return accountRequest;
  }

  goBack(): void {
    // Back to SELECXT PLAN page
    this.router.navigateByUrl('/register/register-plan');
  }


}
