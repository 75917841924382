import { Injectable } from '@angular/core';
import { AppUser } from '../models/app-user';
import { Tenant } from '../models/tenant';
import { RegisterPerson } from '../models/registration/register-person';

const ACCESS_TOKEN = 'access_token';
const USER = 'user';
const TENANT = 'tenant';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  saveToken(tokenKey: string, tokenValue: string): void {
    localStorage.removeItem(tokenKey);
    localStorage.setItem(tokenKey, tokenValue);
  }

  setLocalStorageItem(key: string, value: any): void {
    localStorage.removeItem(key);
    localStorage.setItem(key, value);
  }

  getLocalStorageItem(key: string): any {
    return localStorage.getItem(key);
  }

  removeLocalStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  getToken(tokenKey: string): string | null {
    return localStorage.getItem(tokenKey);
  }

  setSessionStorageItem(key: string, value: any): void {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, value);
  }

  getSessionStorageItem(key: string): any {
    return sessionStorage.getItem(key);
  }

  getRegisterPersonFromSessionStorage(): RegisterPerson | null {
    return JSON.parse(sessionStorage.getItem('registerPerson'));
  }

  getSessionStorageItemParsed<T>(key: string) : T | null {
    return JSON.parse(sessionStorage.getItem(key));
  }

  /**
   * Gets current user from local storage
   *
   * @returns AppUser
   */
  getUser(): AppUser | null {
    const userFromStorage: AppUser = JSON.parse(localStorage.getItem(USER));
    return userFromStorage;
  }

  saveUser(value: AppUser): void {
    localStorage.removeItem(USER);
    localStorage.setItem(USER, JSON.stringify(value));
  }

  getAuthToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  authTokenExists(): boolean {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      return true;
    } else {
      return false;
    }
  }

  logOut(tokenOnly: boolean = false): void {

    if (tokenOnly) {
      // Don't clear out everything, just the token - which will allow login process
      // to use existing tenant and other items stored in local storage
      localStorage.removeItem(ACCESS_TOKEN);
    } else {
      localStorage.clear();
    }
  }

  // getTenantUsers(): Array<TenantUser> | null {
  //   return JSON.parse(localStorage.getItem('tenant_users'));
  // }

  // getTenantUserByKey(userKey: string): TenantUser | null {
  //   const users: Array<TenantUser> = JSON.parse(localStorage.getItem('tenant_users'));
  //   const user: TenantUser = users.filter(x => x.key === userKey)[0];
  //   return user;
  // }

}
