import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // TODO:  Need to implement role based
    //        https://jasonwatmore.com/post/2022/12/22/angular-14-role-based-authorization-tutorial-with-example
    //        https://dev.to/chintanonweb/enabling-multiple-roles-within-role-based-authentication-in-angular-2dcd

    const user = this.userService.userValue;
    const { roles } = route.data;

    if (roles && roles.length > 0) {
      // This route requires a role, so check if the user has the role
      if (user && !roles.includes(user?.roleType)) {
        // role is unauthorized so return false
        this.router.navigate(['/?error=Unauthorized']);  // TODO: This doesn't work
        console.log('Unauthorized access requested by user: ' + user?.username);
        return false;
      }

      // role is authorized so return true
      return true;

    } else {
      // no roles present, so just check if user is logged in
      if (!this.authService.isLoggedIn) {
        this.router.navigate(['/login']);
        return false;
      }

      return true;

    }

  }
}
