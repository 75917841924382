import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppUser } from '../models/app-user';
import { UserInfo } from '../models/user-info';
import { AppUsersResponse } from '../models/app-users-response';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';
import { TenantService } from './tenant.service';
import { AppUserNewLinked } from '../models/app-user-new-linked';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  endpointAuth = environment.authenticationUrl;
  headersAuth = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.authenticationSubscriptionKey);

  public user: Observable<AppUser>;
  private userSubject: BehaviorSubject<AppUser>;

  public currentUserAvatar: Observable<string>;
  private currentUserAvatarSubject: BehaviorSubject<string>;
  public currentUserAvatarBg: Observable<string>;
  private currentUserAvatarBgSubject: BehaviorSubject<string>;

  constructor(
    http: HttpClient,
    tenantService: TenantService,
    private localStorageService: LocalStorageService) {

    super(http, tenantService);

    this.userSubject = new BehaviorSubject<AppUser>(this.localStorageService.getUser() ?? null);
    this.user = this.userSubject?.asObservable();

    this.currentUserAvatarSubject = new BehaviorSubject<string>('');
    this.currentUserAvatar = this.currentUserAvatarSubject?.asObservable();

    this.currentUserAvatarBgSubject = new BehaviorSubject<string>('');
    this.currentUserAvatarBg = this.currentUserAvatarBgSubject?.asObservable();
  }

  /**
   * Gets user value
   */
  get userValue(): AppUser {
    if (this.userSubject.value) {
      return this.userSubject.value;
    } else if (this.localStorageService.getUser()) {
      this.userSubject.next(this.localStorageService.getUser());
      this.user = this.userSubject.asObservable();
    }
    return null;
  }

  set userValue(value: AppUser) {
    if (value) {
      this.userSubject.next(value);
      this.user = this.userSubject.asObservable();
    }
  }

  set currentUserAvatarValue(value: string) {
    if (value) {
      this.currentUserAvatarSubject.next(value);
      this.currentUserAvatar = this.currentUserAvatarSubject.asObservable();
    }
  }

  set currentUserAvatarBgValue(value: string) {
    if (value) {
      this.currentUserAvatarBgSubject.next(value);
      this.currentUserAvatarBg = this.currentUserAvatarBgSubject.asObservable();
    }
  }

  /**
   * Retrieves and persists (localStorageService) user data
   *    Gets user data, including key, from the WorkScale system.  Then persists that data on the
   *    localStorageService for use through the user session.
   *
   * @returns User information
   */
  getUser(): Observable<AppUser> {
    return this.executeApi<AppUser>('get', `${this.endpointAuth}/user`, this.headersAuth);
  }

  getAppUser(tenantKey?: string): Observable<AppUser> {
    // if (this.tenantKey) {
    //   this.tenantKey = tenantKey;
    // } else {
    //   this.tenantKey ?? this.getCurrentTenantKey();
    // }
    const apiUrl = `${this.endpointAuth}/tenant/${tenantKey}/app/${this.appKey}/user`;

    return this.executeApi<AppUser>('get', apiUrl, this.headersAuth);
  }

  createAppUser(newUser: AppUser | AppUserNewLinked): Observable<AppUser> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user`;

    return this.executeApiPost<AppUser>(apiUrl, this.headersAuth, newUser);
  }

  inviteManagementUser(emailAddress: string, roleType: string, userInfo: UserInfo): Observable<any> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/user/invite`;
    const requestBody = {
      'emailAddress': emailAddress,
      'appKey': this.appKey,
      'roleType': roleType,
      'info': userInfo  // this portion contains the user's name
    };

    return this.executeApiPost<any>(apiUrl, this.headersAuth, requestBody);
    //return this.executeApiWithResponseStatus<any>('post', apiUrl, requestBody);
  }

  updateUser(user: AppUser): void {
    //this.userSubject.value.key = user.key;
    this.userSubject.next(user);

    // Filter out a few items before saving
    if (user.info && user.info.dateOfBirth) {
      delete user.info.dateOfBirth;
    }

    if (user.apps) {
      delete user['apps'];
    }

    // if (user.roleType) {
    //   delete user['roleType'];
    // }

    this.localStorageService.saveUser(user);
  }

  //
  // USERS (get, linked, update)
  //
  getAppUsersManage(continuationToken: string, sortCode: string, roleType: string, query: string = ''): Observable<AppUsersResponse> {
    let apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/users?RoleType=${roleType}`;
    if (continuationToken !== '') {
      apiUrl += '&continuationToken=' + continuationToken;
    }
    if (sortCode !== '') {
      apiUrl += `&SortCode=${sortCode}`;
    }
    if (query !== '') {
      apiUrl += query;
    }

    return this.executeApi<AppUsersResponse>('get', apiUrl, this.headersAuth);
  }

  getAppUserByKey(userKey: string): Observable<AppUser> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}`;

    return this.executeApi<AppUser>('get', apiUrl, this.headersAuth);
  }

  getLinkedUsersByParentKey(parentKey: string): Observable<AppUser[]> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${parentKey}/links`;

    return this.executeApi<AppUser[]>('get', apiUrl, this.headersAuth);
  }

  getLinkedUserByUserKey(userKey: string): Observable<AppUser[]> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/links`;

    return this.executeApi<AppUser[]>('get', apiUrl, this.headersAuth);
  }

  getUsernameSuggestion(proposedUsername: string): Observable<string[]> {
    const apiUrl = `${this.endpointAuth}/user/suggest?username=${proposedUsername}`;

    return this.executeApi<string[]>('get', apiUrl, this.headersAuth);
  }

  updateUserByKey(userKey: string, appUser: AppUser): Observable<AppUser> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/user/${userKey}`;  // TODO:  This should be app user, not tenant user

    return this.executeApiPatch<AppUser>(apiUrl, this.headersAuth, appUser);
  }

  updateUserInfoByKey(userKey: string, userInfo: UserInfo): Observable<UserInfo> {
    const apiUrl = `${this.endpointAuth}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/info`;

    return this.executeApiPatch<UserInfo>(apiUrl, this.headersAuth, userInfo);
  }

}
