import { Pipe, PipeTransform } from '@angular/core';
import { AppUser } from '../models/app-user';

@Pipe({
  name: 'filterSelf'
})
export class FilterSelfPipe implements PipeTransform {

  transform(appUsers: AppUser[], selfKey: string): AppUser[] {
    if (!appUsers) {
      return null;
    }

    return appUsers.filter(x => x.key !== selfKey);
  }

}
