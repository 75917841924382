import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BitColorHelper } from 'src/app/core/helpers/bit-color';
import { HelperDobService } from 'src/app/core/helpers/helper-dob.service';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { Avatar } from 'src/app/core/models/avatar';
import { DateOfBirth } from 'src/app/core/models/date-of-birth';
import { DialogDataUser } from 'src/app/core/models/dialog-data-user';
import { LinkInfo } from 'src/app/core/models/link-info';
import { Name } from 'src/app/core/models/name';
import { RGBA } from 'src/app/core/models/rgba';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user_OLD.component.html',
  styleUrls: ['./dialog-user_OLD.component.scss']
})
export class DialogUserOLDComponent implements OnInit {

  PROFILE_TAB = 'profileTab';  // Setting as CONST to avoid spelling errors
  CHALLENGES_TAB = 'challengesTab';
  APP_KEY = environment.appKey;

  tempAvatar: string;

  working: boolean;
  workingChildren: boolean;
  workingParent: boolean;
  workingUsername: boolean;
  workingProfile: boolean;
  editAccount: boolean;
  editHouseholdMember: boolean;
  user: AppUser;
  linkedUsers: AppUser[];
  parentUser: AppUser;
  errorMessage: string;
  activeTab: string;
  accountForm: UntypedFormGroup;
  linkedUserForm: UntypedFormGroup;
  months: string[];
  days: number[];
  toggleSuffix: boolean;
  isMinor: boolean;
  linkedUserIsMinor: boolean;
  isNewUser: boolean;
  hasParent: boolean;
  availableNames: string;
  avatarBgColor: string;
  avatarImg: string;
  defaultAvatar: boolean;
  updateAvatar: boolean;

  @Output() userSaveEvent = new EventEmitter<AppUser>();

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private nameHelper: HelperNameService,
    private dobHelper: HelperDobService,
    private bitColorHelper: BitColorHelper,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogUserOLDComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUser) { }

  get a(): { [key: string]: AbstractControl } {
    return this.accountForm.controls;
  }

  get b(): { [key: string]: AbstractControl } {
    return this.linkedUserForm.controls;
  }

  ngOnInit(): void {
    this.working = false;
    this.workingChildren = false;
    this.workingParent = false;
    this.workingUsername = false;
    this.workingProfile = false;
    this.errorMessage = '';
    this.availableNames = '';
    this.activeTab = this.PROFILE_TAB;
    this.toggleSuffix = false;
    this.isMinor = false;
    this.linkedUserIsMinor = true;
    this.editHouseholdMember = false;
    this.updateAvatar = false;
    this.user = this.data?.user !== null ? this.data?.user : {} as AppUser;
    this.isNewUser = this.data?.isNewUser;
    this.hasParent = this.data?.parentUserKey || this.data?.parentUser ? true : false;
    this.months = Array(12).fill(0).map((i, idx) => this.getMonth(idx + 1));

    this.createForms();

    if (this.userHasLinkedUsers()) {
      this.getLinkedUsers(this.user.key);
    }

    if (!this.data?.parentUser && this.data?.parentUserKey) {
      this.getParentUser(this.data.parentUserKey);
    }

    if (this.data?.parentUser) {
      this.parentUser = this.data.parentUser;
    }

    if (this.data?.isNewUser) {
      this.editAccount = true;
    }

    // default avatar values
    // this.avatarBgColor = '#ff6d6d';
    // this.avatarImg = 'assets/images/avatars/nacho-black-white.png';
    this.avatarBgColor = '#fff';
    this.avatarImg = 'assets/images/avatars/default.png';
    this.defaultAvatar = true;
    this.checkForAvatar();
  }

  checkForAvatar(): void {

    if (this.user?.info?.additionalInfo) {
      // Get the avatar color and convert to a usable RGBA format
      const color = this.user.info.additionalInfo['avatarColor'];
      if (!isNaN(parseInt(color))) {
        this.avatarBgColor = this.getAvatarColor(parseInt(color));
      } else {
        this.avatarBgColor = color;
      }

      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = `assets/images/avatars/${this.user.info.additionalInfo['avatar']}`;
        this.defaultAvatar = false;
      }
    }
  }

  getAvatarColor(avatarColorNumericValue: number): string {
    console.log('color: ' + avatarColorNumericValue);

    const bgColorRgba = this.bitColorHelper.numberToRgba(avatarColorNumericValue);
    // this.bgColorHex = this.bitColorHelper.rgbaToHex(bgColorRgba.red, bgColorRgba.green, bgColorRgba.blue, bgColorRgba.alpha);

    console.log('R: ' + bgColorRgba.red);
    console.log('G: ' + bgColorRgba.green);
    console.log('B: ' + bgColorRgba.blue);
    console.log('A: ' + bgColorRgba.alpha);

    return bgColorRgba?.rgba;
  }

  createForms(): void {
    // Set up the ACCOUNT INFO form
    this.accountForm = this.fb.group({
      displayName: [this.user.displayName, [Validators.required]],
      title: [this.user.info?.name?.title],
      first: [this.user.info?.name?.first],
      middle: [this.user.info?.name?.middle],
      last: [this.user.info?.name?.last],
      suffix: [this.user.info?.name?.suffix],
      year: [this.user.info?.dateOfBirth?.year, [Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)]],
      month: [this.user.info?.dateOfBirth?.month, [Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(2)]],
      day: [this.user.info?.dateOfBirth?.day, [Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(2)]],
      emailAddress: ['', Validators.email],
      phoneNumber: ['']
    });

    this.linkedUserForm = this.fb.group({
      first: ['', [Validators.required]],
      middle: [''],
      last: [''],
      emailAddress: ['', Validators.email],
      phoneNumber: ['']
    });

    if (!this.user.info ||
      !this.user.info.dateOfBirth) {
      this.accountForm.controls['month'].setValue(0);
      this.accountForm.controls['day'].setValue(0);
    }
    this.user.info?.dateOfBirth?.day ?? this.accountForm.controls['day'].setValue(0);
    this.user.info?.dateOfBirth?.month ?? this.accountForm.controls['month'].setValue(0);
  }

  getLinkedUsers(parentKey: string): void {
    this.workingChildren = true;

    this.userService.getLinkedUsersByParentKey(parentKey).subscribe({
      next: (response: AppUser[] | any) => {
        this.linkedUsers = response;

        this.workingChildren = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      }
    });
  }

  getParentUser(parentKey: string): void {
    this.workingParent = true;

    this.userService.getAppUserByKey(parentKey).subscribe({
      next: (response: AppUser | any) => {
        this.parentUser = response;

        this.workingParent = false;
      },
      error: (err: any) => {
        //this.errorMessage = this.parseErrorService.parseApiError(err);
        this.workingParent = false;
      }
    });
  }

  /**
   *
   * Calls name display helper service
   *
   * @param name NAME object used to create display name
   * @returns string, formatted name
   */
  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name) ?? nullPlaceholder;
  }

  dobDisplay(dob: DateOfBirth): string {
    return this.dobHelper.formatDOB(dob, 'MMMM dd, yyyy') ?? '--';
  }

  setActiveTab(tabName: string): void {
    this.errorMessage = '';
    this.activeTab = tabName;
  }

  checkUsername(e: Event): void {
    this.availableNames = '';
    const inputElement = e.currentTarget as HTMLInputElement;
    const usernameValue = inputElement.value;

    if (usernameValue.length > 0 && usernameValue !== this.user.displayName) {
      this.workingUsername = true;

      // Call the API and check to see if the username is available
      //const suggestedValues: string[] = this.userService.getUsernameSuggestion(usernameValue);
      this.userService.getUsernameSuggestion(usernameValue).subscribe({
        next: (response: string[] | any) => {
          if (!response.includes(usernameValue)) {
            // The name is already taken
            // TODO
            inputElement.classList.remove('ng-valid');
            inputElement.classList.add('ng-invalid');

            this.a['displayName'].setErrors({ nameInUse: true });
            if (response.length >= 0) {
              this.availableNames = `${response[0]}, ${response[1]}`;
            }
          }
          this.workingUsername = false;
        },
        error: (err: any) => {
          // this.errorMessage = this.parseErrorService.parseApiError(err);
          this.availableNames = '';
          this.workingUsername = false;
        }
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  isMinorToggle(e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    if (currentCheckbox.id === 'userIsMinor') {
      this.isMinor = !currentCheckbox.checked;  // Mark it as the opposite, because "checked" is true for adult
    } else {
      this.linkedUserIsMinor = !currentCheckbox.checked;
    }

    // TODO:  Possible save this without hitting save button
  }

  getErrorText(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['nameInUse']) {
      return 'That username is taken'
    }

    return '';
  }

  savePersonalForm(): void {
    this.workingProfile = true;
    const updatedUser: AppUser = this.buildUserDataFromFormInput();

    // Call the API and check to see if the username is available
    //const suggestedValues: string[] = this.userService.getUsernameSuggestion(usernameValue);
    this.userService.updateUserByKey(this.user.key, updatedUser).subscribe({
      next: (response: AppUser | any) => {
        this.user = response;

        // Update the parent page (USERS) rather than do a reload of that page
        this.data.user = this.user;
        this.userSaveEvent.emit(this.user);

        this.workingProfile = false;
        this.editAccount = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.workingProfile = false;
      }
    });

  }

  saveLinkedUserForm(): void {
    // this.savingUserData = true;
    const linkedUser: AppUser = this.buildLinkedUserFromFormInput();

    // this.savingUserData = false;
  }

  buildUserDataFromFormInput(): AppUser {
    const user: AppUser = {} as AppUser;
    const f = this.a;  // get an abbreviated ref to the form control

    // TODO:  GET THIS WORKING
    if ((f['displayName'].value || this.user?.displayName) &&
      f['displayName'].value !== this.user?.displayName) {
      user.displayName = f['displayName'].value;
    }

    return user;
  }

  buildLinkedUserFromFormInput(): AppUser {
    const linkedUser: AppUser = {} as AppUser;
    const f = this.b;  // get an abbreviated ref to the form control

    // TODO:  GET THIS WORKING
    if ((f['first'].value || this.user?.info?.name?.first) &&
      f['first'].value !== this.user?.info?.name?.first) {
      linkedUser.info.name.first = f['first'].value;
    }

    return linkedUser;
  }

  viewLinkedUser(linkedUser: AppUser, parentUser: AppUser = null): void {
    this.data = {
      user: linkedUser,
      isNewUser: linkedUser === null ? true : false,
      message: '',
      parentUser: parentUser
    };
    this.ngOnInit();
  }

  addLinkedUser(parentUser: AppUser): void {
    this.editHouseholdMember = true;

    setTimeout(() => {
      document.querySelector('.dialog-container').scrollTo(0, document.querySelector('.dialog-container').scrollHeight);
    }, 100);
  }

  closeAvatarWindow(): void {
    this.updateAvatar = !this.updateAvatar;
  }

  previewAvatar(avatar: Avatar): void {
    if (avatar?.color) {
      this.avatarBgColor = avatar.color;
    }

    // Get the avatar image
    if (avatar?.fileName) {
      this.avatarImg = `assets/images/avatars/${avatar.fileName}`;
      this.defaultAvatar = false;
    }
  }

  resetAvatarToOriginal(): void {
    this.avatarBgColor = '#fff';
    this.avatarImg = 'assets/images/avatars/default.png';
    this.defaultAvatar = true;

    this.checkForAvatar();
  }

  updateUserWithAvatar(avatar: Avatar): void {
    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          'avatar': avatar.fileName,
          'avatarColor': avatar.color
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        'avatar': avatar.fileName,
        'avatarColor': avatar.color
      };
    } else {
      this.user.info.additionalInfo['avatar'] = avatar.fileName;
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
    }
    this.avatarBgColor = avatar.color;
    this.avatarImg = `assets/images/avatars/${avatar.fileName}`;
    this.defaultAvatar = false;
  }

  updateUserWithAvatarBg(avatar: Avatar): void {
    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          'avatarColor': avatar.color
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        'avatarColor': avatar.color
      };
    } else {
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
    }

    this.avatarBgColor = avatar.color;
    this.avatarImg = `assets/images/avatars/default.png`;
    this.defaultAvatar = false;
  }

  challengeMenuFormmating(e: any): void {
    const rowElement = e.currentTarget as HTMLElement;
    rowElement.classList.add('hover');
  }

  menuChallengeClose(): void {
    const rowElements = document.querySelectorAll('.hover') as NodeListOf<HTMLElement>;
    rowElements.forEach(element => {
      element.classList.remove('hover');
    });
  }

  isSavingProfile(): boolean {
    if (this.workingProfile === true) {
      return true;
    } else {
      return false;
    }
  }

  //
  // TODO:  Probably extract this to a static helper
  //
  public getMonth(index: number) {
    const myDate = new Date();
    myDate.setDate(1);
    myDate.setMonth(index - 1);

    const locale = 'en-us';
    const month = myDate.toLocaleString(locale, { month: 'long' });

    return month;
  }

  public getDays(thisForm: any): number[] {
    const dayCount = !thisForm.year && !thisForm.month ?
      31 :
      this.getDaysInMonth(thisForm.year, thisForm.month);
    return Array(dayCount).fill(0).map((i, idx) => idx + 1);
  }

  public getDaysInMonth(year: number, month: number): number {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  tempGetChildAvatar(child: AppUser, i: number): string {
    let iconName: string;
    if (child.displayName.indexOf('girl') > -1 ||
      child.displayName.indexOf('aunt') > -1 ||
      child.displayName.indexOf('mom') > -1) {
      iconName = i % 2 === 0 ? 'mom' : 'sally';
    } else {
      iconName = i % 2 === 0 ? 'jimmy' : 'joe';
    }
    return `assets/images/avatar-${iconName}.png`;
  }

  userHasLinkedUsers(): boolean {
    let hasParent = false;

    hasParent = this.user && this.user.linkInfo && this.user.linkInfo.parentUserKey && this.user.linkInfo.parentUserKey.length > 0 ? true : false;

    return hasParent;
  }

}
