<ng-container
    *ngIf="orderStatus === orderStatusEnum.NEW || orderStatus === orderStatusEnum.PENDING || orderStatus === orderStatusEnum.RECEIVED">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.2108 1.10495L12.2108 7.73669C12.2106 8.3101 11.7528 8.79999 11.1807 8.83907C10.5258 8.88381 10.0432 8.36545 10 7.73669L10 1.10495C10.0002 0.531548 10.458 0.0416502 11.03 0.00257164C11.6849 -0.0421672 12.1676 0.476191 12.2108 1.10495ZM20.7296 3.27132C21.1726 3.7152 21.1234 4.37857 20.7281 4.83346L15.5696 9.99148C15.1654 10.3925 14.501 10.4159 14.0696 10.0443C13.5716 9.61527 13.5931 8.90598 14.0074 8.42934L19.1658 3.27132C19.5914 2.84626 20.3025 2.84626 20.7281 3.27132L20.7296 3.27132ZM24 12.8947C24 13.5212 23.496 13.9587 22.8946 14L16.2623 14C15.689 13.9998 15.199 13.5421 15.1599 12.97C15.1151 12.3151 15.6336 11.8326 16.2623 11.7894L22.8946 11.7894C23.4966 11.7894 24 12.2927 24 12.8947Z"
            fill="#415261" />
        <path
            d="M11 23H4C2.366 23 1 21.634 1 20V13C1 11.366 2.366 10 4 10H11C12.634 10 14 11.366 14 13V20C14 21.634 12.634 23 11 23ZM4 12C3.45533 12 3 12.4553 3 13V20C3 20.5447 3.45533 21 4 21H11C11.5447 21 12 20.5447 12 20V13C12 12.4553 11.5447 12 11 12H4Z"
            fill="#415261" />
    </svg>
</ng-container>

<ng-container *ngIf="orderStatus === orderStatusEnum.PICKUP">
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.1433 5.9031L11.8356 0.708635C11.3274 0.430455 10.6832 0.430455 10.175 0.708635L0.867387 5.8822C0.327377 6.18367 -0.00605407 6.71555 8.32933e-05 7.34543V16.6507C0.00115285 17.2466 0.341965 17.8216 0.867387 18.114L10.175 23.2875C10.6857 23.5628 11.324 23.5637 11.8356 23.2875L21.1433 18.114C21.6633 17.8173 21.9989 17.2441 22 16.6507V7.34543C22.0033 6.72674 21.6758 6.21349 21.1433 5.9031ZM11 2.17186L19.4404 6.8542L16.1933 8.67278L7.66832 4.02181L11 2.17186ZM11.0952 11.5365L2.58084 6.8542L5.93371 4.99381L14.4692 9.64479L11.0952 11.5365ZM1.69238 8.27562L10.2491 12.9893L10.1645 21.3644L1.69238 16.6507V8.27562ZM11.8568 21.354L11.9414 12.9893L15.3366 11.0767V15.0587L17.0289 14.0587V10.1256L20.3077 8.28608V16.6507L11.8568 21.354Z"
            fill="#415261" />
    </svg>
</ng-container>

<ng-container *ngIf="orderStatus === orderStatusEnum.COMPLETE">
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.4699 3.63C21.1289 4.28898 21.1347 4.36525 20.4699 5.03C19.8052 5.69475 9.75759 15.7424 9.12995 16.37C8.50231 16.9976 8.35429 16.9943 7.72995 16.37C7.10561 15.7457 3.52995 12.17 3.52995 12.17C2.88389 11.5239 2.86333 11.4366 3.52995 10.77C4.19658 10.1034 4.29494 10.135 4.92995 10.77L8.42995 14.27C8.42995 14.27 18.4228 4.27718 19.07 3.63C19.7171 2.98282 19.811 2.97101 20.4699 3.63ZM18.36 1.51L8.42995 11.44L5.63995 8.65C4.85995 7.87 3.58995 7.87 2.80995 8.65L1.40995 10.05C0.629951 10.83 0.629951 12.1 1.40995 12.88L7.00995 18.48C7.78995 19.26 9.05995 19.26 9.83995 18.48L22.59 5.74C23.37 4.96 23.37 3.69 22.59 2.91L21.19 1.51C20.4 0.729999 19.14 0.729999 18.36 1.51Z"
            fill="#415261" />
    </svg>
</ng-container>

<ng-container *ngIf="orderStatus === orderStatusEnum.CANCELLED">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.46445 10.7071L7.17155 10L6.46445 9.29289L1.86825 4.6967C1.28247 4.11091 1.28247 3.16116 1.86825 2.57538L2.57536 1.86827C3.16115 1.28249 4.11089 1.28249 4.69668 1.86827L9.29287 6.46447L9.99998 7.17157L10.7071 6.46447L15.3033 1.86827C15.8891 1.28248 16.8388 1.28249 17.4246 1.86827L18.1317 2.57538C18.7175 3.16117 18.7175 4.11091 18.1317 4.6967L13.5355 9.29289L12.8284 10L13.5355 10.7071L18.1317 15.3033C18.7175 15.8891 18.7175 16.8388 18.1317 17.4246L17.4246 18.1317C16.8388 18.7175 15.8891 18.7175 15.3033 18.1317L10.7071 13.5355L9.99998 12.8284L9.29287 13.5355L4.69668 18.1317C4.11089 18.7175 3.16115 18.7175 2.57536 18.1317L1.86825 17.4246C1.28247 16.8388 1.28247 15.8891 1.86825 15.3033L6.46445 10.7071Z"
            stroke="#ED6666" stroke-width="2" />
    </svg>
</ng-container>