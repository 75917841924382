import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EVALUATOR_TYPES } from 'src/app/core/constants/evaluator-types';
import { ErrorDetail } from 'src/app/core/models/error-detail';
import { EvaluatorType } from 'src/app/core/models/reward/evaluator-type';
import { ResourceType } from 'src/app/core/models/reward/resource-type';
import { Rule } from 'src/app/core/models/reward/rule';
import { RuleAction } from 'src/app/core/models/reward/rule-action';
import { RuleActionable } from 'src/app/core/models/reward/rule-actionable';
import { RuleExpression } from 'src/app/core/models/reward/rule-expression';
import { RuleExpressionable } from 'src/app/core/models/reward/rule-expressionable';
import { RuleResource } from 'src/app/core/models/reward/rule-resource';
import { RuleSaveInfo, RuleSaveType } from 'src/app/core/models/reward/rule-save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-evaluator',
  templateUrl: './evaluator.component.html',
  styleUrls: ['./evaluator.component.scss']
})
export class EvaluatorComponent implements OnInit, OnChanges {

  @Input() public evaluator: RuleResource;
  @Input() public selectedRule: Rule;
  @Input() public evaluatorTypes: EvaluatorType[];
  @Input() public bonusResources: ResourceType[];
  @Input() public resultResources: ResourceType[];
  @Output() evaluatorCancelEvent = new EventEmitter();
  @Output() evaluatorSaveEvent = new EventEmitter<RuleSaveInfo>();

  EVAL_TYPES = EVALUATOR_TYPES;
  evalForm: UntypedFormGroup;
  errorMessage: string;
  errorDetail: WsApiError;
  working: boolean;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.errorMessage = '';
    this.working = false;

    // Validate input
    if (!this.evaluator) {
      this.errorMessage = 'Missing evaluator.';
    }

    if (!this.resultResources) {
      this.errorMessage = 'Missing result resources.';
    }

    if (!this.bonusResources) {
      this.errorMessage = 'Missing bonus resources.';
    }

    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.evalForm) {
      this.createForm();
    }

    this.evalForm.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.evalForm.controls;
  }

  createForm(): void {
    this.evalForm = this.fb.group({
      key: [this.selectedRule?.key],
      ruleName: [this.selectedRule?.name ?? '', Validators.required],
      resultTypeCode: [this.getExpressionableParamValue(this.selectedRule, 'EvaluateResultType', 'resultTypeCode')],
      evaluationType: [this.getExpressionableParamValue(this.selectedRule, 'EvaluateResultType', 'evaluationType')],
      amount: [this.getExpressionableParamValue(this.selectedRule, 'EvaluateResultType', 'amount')],
      numberOfAwards: [this.getExpressionableParamValue(this.selectedRule, 'EvaluateResultType', 'numberOfAwards')],
      streakLength: [this.getExpressionableParamValue(this.selectedRule, 'HasStreak', 'streakLength')],
      percentage: [this.getExpressionableParamValue(this.selectedRule, 'HasPercentage', 'percentage')],
      bonusTypeCode: [this.getActionableParamValue(this.selectedRule, 'SetResult', 'bonusTypeCode')],
      bonusAmount: [this.getActionableParamValue(this.selectedRule, 'SetResult', 'bonusAmount')],
      resultTypeCodeActionable: [this.getActionableParamValue(this.selectedRule, 'SetResult', 'resultTypeCode')],
      resultAmount: [this.getActionableParamValue(this.selectedRule, 'SetResult', 'resultAmount')]
    });

    this.updateFormValidation();
  }

  updateFormValidation(): void {
    this.evalForm.clearValidators();

    if (this.evaluator.evaluatorType === EVALUATOR_TYPES.results) {
      this.f['resultTypeCode'].setValidators(Validators.required);
      this.f['resultTypeCode'].updateValueAndValidity();
      this.f['evaluationType'].setValidators(Validators.required);
      this.f['evaluationType'].updateValueAndValidity();
      this.f['amount'].setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1)]);
      this.f['amount'].updateValueAndValidity();
    }

    if (this.evaluator.evaluatorType === EVALUATOR_TYPES.activities) {
      // No additional fields needed to validate for this type
    }

    if (this.evaluator.evaluatorType === EVALUATOR_TYPES.awards) {
      // TODO:  Set an either/or custom
    }

    // Add validation for rewards
    this.f['bonusTypeCode'].setValidators(Validators.required);
    this.f['bonusTypeCode'].updateValueAndValidity();
    this.f['bonusAmount'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
    this.f['bonusAmount'].updateValueAndValidity();
    this.f['resultTypeCodeActionable'].setValidators(Validators.required);
    this.f['resultTypeCodeActionable'].updateValueAndValidity();
    this.f['resultAmount'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
    this.f['resultAmount'].updateValueAndValidity();
  }

  isInvalid(formElement: string): boolean {
    if (this.f[formElement].invalid && (this.f[formElement].dirty || this.f[formElement].touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  resultType(code: string): string {
    return this.resultResources?.find(x => x.code === code)?.name ?? code;
  }

  bonusType(code: string): string {
    return this.bonusResources?.find(x => x.code === code)?.name ?? code;
  }

  evaluationType(code: string): string {
    // TODO:  Get this list from a RESOURCE?
    const evalTypes: { [key: string]: string } = {
      'Equals': '=',
      'GreaterThan': '>',
      'GreaterThanOrEquals': '>=',
      'LessThan': '<',
      'LessThanOrEquals': '<=',
      'NotEquals': '!='
    };
    return evalTypes[code] ?? code;
  }

  getExpressionableParamValue(rule: Rule, propertyOrMethodName: string, paramName: string): string {
    return rule?.expressions?.find(x => x.propertyOrMethod === propertyOrMethodName)?.parameters?.find(x => x.parameterName === paramName)?.value ?? '';
  }

  getActionableParamValue(rule: Rule, methodName: string, paramName: string): string {
    return rule?.actions?.find(x => x.methodName === methodName)?.parameters?.find(x => x.parameterName === paramName)?.value ?? '';
  }

  isSaving(): boolean {
    if (this.working === true) {
      return true;
    } else {
      return false;
    }
  }

  saveEvalForm(): void {
    this.working = true;

    // Determine if this is new or existing rule, which will tell us
    // whether we need to create an API POST or PATCH
    if (this.selectedRule) {
      // Update an existing RULE - this will be an API PATCH
      const rulePatch: Rule = { ...this.selectedRule };  // Clone the existing rule and then make changes
      this.cleanRulePatchForApi(rulePatch);  // Remove unneeded items for Patch API call
      let ruleChanged = false;

      ruleChanged = this.buildPatchEvalObject(rulePatch, 'ruleName', 'name') ? true : ruleChanged;

      // Step 1:  Determine if any of the RESULT (EXPRESSION) params have changed
      if (this.selectedRule.evaluatorType === EVALUATOR_TYPES.results) {
        ruleChanged = this.updateExpressionalbesRESULTS(rulePatch) ? true : ruleChanged;
      }

      // Step 2:  Determine if any of the ACTIVITY (EXPRESSION) params have changed
      if (this.selectedRule.evaluatorType === EVALUATOR_TYPES.activities) {
        // Actually there aren't any expressionables that a user edits, but this is here for
        // consistency and in case that changes in the future.  The only thing that can be
        // changed for an ACTIVITY-based rule is the name and actionables (both handled) separately
      }

      // Step 3:  Determine if any of the AWARDS (EXPRESSION) params have changed
      if (this.selectedRule.evaluatorType === EVALUATOR_TYPES.awards) {
        ruleChanged = this.updateExpressionablesAWARDS(rulePatch) ? true : ruleChanged;
      }

      // Step LAST:  Determine if any of the rewards/results (ACTIONS) params have changed
      //             This applies to all rule types, so it is run last.
      ruleChanged = this.updateActionables(rulePatch) ? true : ruleChanged;

      // Create or Update the rule
      if (ruleChanged) {
        // Update the rule, which is an API PATCH
        this.rewardsService.updateRule(rulePatch).subscribe({
          next: (response: Rule | any) => {
            this.working = false;

            this.evaluatorSaveEvent.emit({ rule: response, saveType: RuleSaveType.updatedRule });
          },
          error: (err: any) => {
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            this.working = false;
          }
        });
      }
    } else {
      // Create a new RULE - this will be an API POST
      // Create the object with default values for the appropriate EVALUATOR type
      const rulePost: Rule = this.createNewRuleWithFormValues(this.evaluator);
      this.rewardsService.createNewRule(rulePost).subscribe({
        next: (response: Rule | any) => {
          this.working = false;
          this.evaluatorSaveEvent.emit({ rule: response, saveType: RuleSaveType.newRule });
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.working = false;
        }
      });
    }

  }

  cleanRulePatchForApi(rulePatch: Rule): void {
    rulePatch.evaluatorType = null;
    rulePatch.type = null;

    rulePatch.expressions?.forEach(expression => {
      expression.key = null;
      expression.expressionType = null;

      expression.parameters?.forEach(param => {
        param.key = null;
      });
    });

    rulePatch.actions?.forEach(action => {
      action.key = null;

      action.parameters?.forEach(param => {
        param.key = null;
      })
    });

  }

  buildPatchEvalObject(inEval: RuleResource, formElementName: string, evalPropertyName: string): boolean {
    if (this.f[formElementName] && !this.f[formElementName].pristine) {
      inEval[evalPropertyName] = this.f[formElementName].value;
      return true;
    }
    return false;
  }

  updateExpressionalbesRESULTS(rulePatch: Rule): boolean {
    if (this.f['resultTypeCode'] &&
      this.f['evaluationType'] &&
      this.f['amount'] && (
        !this.f['resultTypeCode'].pristine ||
        !this.f['evaluationType'].pristine ||
        !this.f['amount'].pristine)) {
      // Check each EXPRESSION form element to see if the user has changed the value.  If it has changed
      // update the rule patch object
      this.getUpdateValueFromFormByElementEXPRESSION(rulePatch, 'resultTypeCode');
      this.getUpdateValueFromFormByElementEXPRESSION(rulePatch, 'evaluationTtype');
      this.getUpdateValueFromFormByElementEXPRESSION(rulePatch, 'amount');

      return true;
    }

    return false;
  }

  updateExpressionablesAWARDS(rulePatch: Rule): boolean {
    if (this.f['streakLength'] && this.f['percentage'] && (
      !this.f['streakLength'].pristine || !this.f['percentage'].pristine)) {
      // Check each EXPRESSION form element to see if the user has changed the value.  If it has changed
      // update the rule patch object
      this.getUpdateValueFromFormByElementEXPRESSION(rulePatch, 'streakLength');
      this.getUpdateValueFromFormByElementEXPRESSION(rulePatch, 'percentage');

      return true;
    }
    return false;
  }

  getUpdateValueFromFormByElementEXPRESSION(
    rulePatch: Rule,
    paramName: string,
    formElementName: string = paramName): void {

    if (this.f[formElementName] && !this.f[formElementName].pristine) {
      // Update the value that has changed
      rulePatch.expressions[0].parameters.find(x => x.parameterName === paramName).value = this.f[formElementName].value;
    }
  }

  updateActionables(rulePatch: Rule): boolean {
    if (this.f['bonusTypeCode'] &&
      this.f['bonusAmount'] &&
      this.f['resultTypeCodeActionable'] &&
      this.f['resultAmount'] && (
        !this.f['bonusTypeCode'].pristine ||
        !this.f['bonusAmount'].pristine ||
        !this.f['resultTypeCodeActionable'].pristine ||
        !this.f['resultAmount'].pristine)) {
      // Check each ACTION form element to see if the user has changed the value.  If it has changed
      // update the rule patch object
      this.getUpdateValueFromFormByElementACTION(rulePatch, 'Success', 'bonusTypeCode');
      this.getUpdateValueFromFormByElementACTION(rulePatch, 'Success', 'bonusAmount');
      this.getUpdateValueFromFormByElementACTION(rulePatch, 'Success', 'resultTypeCode', 'resultTypeCodeActionable', 'Failure');
      this.getUpdateValueFromFormByElementACTION(rulePatch, 'Success', 'resultAmount', 'resultAmount', 'Failure');

      return true;
    }

    return false;
  }

  getUpdateValueFromFormByElementACTION(
    rulePatch: Rule,
    actionTypeSuccess: string,
    paramName: string,
    formElementName: string = paramName,
    actionTypeFailure: string = ''): void {

    if (this.f[formElementName] && !this.f[formElementName].pristine) {
      // Update the value that has changed
      rulePatch.actions.find(x => x.type === actionTypeSuccess).parameters.find(x => x.parameterName === paramName).value = this.f[formElementName].value;

      if (actionTypeFailure !== '') {
        // Update the faulure value that has changed
        rulePatch.actions.find(x => x.type === actionTypeFailure).parameters.find(x => x.parameterName === paramName).value = this.f[formElementName].value;
      }
    }
  }

  createNewRuleWithFormValues(evaluator: RuleResource): Rule {
    const rule: Rule = { name: this.f['ruleName'].value };

    switch (evaluator.evaluatorType) {
      case EVALUATOR_TYPES.results: {
        rule.evaluatorType = EVALUATOR_TYPES.results;
        rule.type = null;
        rule.expressions = this.createNewExpressionsWithFormValuesRESULTS();
        break;
      }
      case EVALUATOR_TYPES.activities: {
        rule.evaluatorType = EVALUATOR_TYPES.activities;
        rule.type = null;
        rule.expressions = this.createNewExpressionsWithFormValuesACTIVITIES();
        break;
      }
      case EVALUATOR_TYPES.awards: {
        rule.evaluatorType = EVALUATOR_TYPES.awards;
        rule.type = 'REC';
        rule.expressions = this.createNewExpressionsWithFormValuesAWARDS();
        break;
      }
    }
    rule.actions = this.createNewActions();

    return rule;
  }

  createNewExpressionsWithFormValuesRESULTS(): RuleExpression[] {
    const expressions: RuleExpression[] = [
      {
        operator: null,
        propertyOrMethod: 'EvaluateResultType',
        expressionType: 'Method',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'True',
        parameters: [
          {
            parameterName: 'resultTypeCode',
            valueType: 'System.String',
            value: this.f['resultTypeCode'].value
          },
          {
            parameterName: 'evaluationType',
            valueType: 'System.String',
            value: this.f['evaluationType'].value
          },
          {
            parameterName: 'amount',
            valueType: 'System.Double',
            value: this.f['amount'].value
          }
        ]
      },
      {  // TODO:  This is always the same, refactor to individual method
        operator: 1,
        propertyOrMethod: 'ContextAsType.Awarded',
        expressionType: 'Property',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'False'
      }
    ];

    return expressions;
  }

  createNewExpressionsWithFormValuesACTIVITIES(): RuleExpression[] {
    const expressions: RuleExpression[] = [
      {
        operator: null,
        propertyOrMethod: 'ContextAsType.Completed',
        expressionType: 'Property',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'True'
      },
      {
        operator: 1,
        propertyOrMethod: 'ContextAsType.Awarded',
        expressionType: 'Property',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'False'
      }
    ];

    return expressions;
  }

  createNewExpressionsWithFormValuesAWARDS(): RuleExpression[] {
    const expressions: RuleExpression[] = [
      {
        operator: null,
        propertyOrMethod: 'HasStreak',
        expressionType: 'Method',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'True',
        parameters: [
          {
            parameterName: 'streakLength',
            valueType: 'System.Int32',
            value: this.f['streakLength'].value
          }
        ]
      },
      {
        operator: 1,
        propertyOrMethod: 'ContextAsType.Awarded',
        expressionType: 'Property',
        evaluationType: 'Equals',
        valueType: 'System.Boolean',
        value: 'False'
      }
    ];

    return expressions;
  }

  createNewActions(): RuleAction[] {
    const actions: RuleAction[] = [
      {
        name: 'OutputExpression',
        type: 'Success',
        methodName: 'SetResult',
        parameters: [
          {
            parameterName: 'bonusTypeCode',
            valueType: 'System.String',
            value: this.f['bonusTypeCode'].value
          },
          {
            parameterName: 'bonusAmount',
            valueType: 'System.Double',
            value: this.f['bonusAmount'].value
          },
          {
            parameterName: 'resultTypeCode',
            valueType: 'System.String',
            value: this.f['resultTypeCodeActionable'].value
          },
          {
            parameterName: 'resultAmount',
            valueType: 'System.Double',
            value: this.f['resultAmount'].value
          }
        ]
      },
      {
        name: 'OutputExpression',
        type: 'Failure',
        methodName: 'ReportProgress',
        parameters: [
          {
            parameterName: 'resultTypeCode',
            valueType: 'System.String',
            value: this.f['resultTypeCode'].value
          },
          {
            parameterName: 'resultAmount',
            valueType: 'System.Double',
            value: this.f['resultAmount'].value
          }
        ]
      }
    ];

    return actions;
  }

  getActionParamValueFromRule(rule: Rule, paramName: string): string {
    return rule.actions.find(x => x.type === 'Success')?.parameters?.find(x => x.parameterName === paramName)?.value ?? '';
  }

  cancelEvaluatorForm(): void {
    this.evaluatorCancelEvent.emit();
  }

}
