<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div mat-dialog-title class="dialog-title paging-container">
    <div class="title">Import Fulfillable Product</div>

    <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>

    <div class="items-found" *ngIf="!working && errorMessage.length === 0 && productCount > 0 && !pagingCount.showResultsPaging">
        <span class="count">{{productCount}}</span> {{productCount === 1 ? 'item' : 'items'}} found
    </div>
</div>

<div mat-dialog-content class="dialog-container dialog-container-no-footer">
    <div class="product">

        <app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

        <app-loading loadingText="loading catalog..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

        <div class="items" *ngIf="!working && products.length > 0">
            <ng-container *ngFor="let item of products; index as i">
                <div class="item {{i === 0 ? 'first' : ''}} {{showProductDetail === i ? 'active' : ''}}">
                    
                    <div class="item-container">
                        <div class="item-img">
                            <img src="{{item.images[0]?.url}}" title="{{item.name}}" *ngIf="item.images[0]?.url" />
                            <span class="fa-light fa-image-slash icon fa-3x" *ngIf="!item.images[0]?.url"></span>
                        </div>
                        <div class="item-detail">
                            <div class="name" *ngIf="showProductDetail !== i">{{item.name}}</div>

                            <div class="info" *ngIf="showProductDetail !== i">
                                <div class="product-info">
                                    <div class="info-line">
                                        <span class="data-item" *ngIf="item?.productCategoryCode">{{item?.productCategoryCode}}</span>
                                        <span class="separator" *ngIf="item?.productCategoryCode && item?.manufacturerCode">|</span>
                                        <span class="data-item" *ngIf="item?.manufacturerCode">{{item?.manufacturerCode}}</span>
                                    </div>
                                    <div class="info-line price">
                                        <span class="label">your cost</span>
                                        <span class="amount">{{item.amount | currency: item.currencyCode}}</span>
                                        <!-- {{item.currencyCode}} -->
                                    </div>
                                </div>
                            </div>

                            <app-fulfillable-product-form *ngIf="showProductDetail === i"
                                style="display: flex; width: 100%;"
                                [product]="item" 
                                [storeTypeKey]="storeTypeKey"
                                [allowedCurrencies]="allowedCurrencies"
                                (productAddEvent)="addFulfillableProduct($event)"
                                (productCancelEvent)="cancelAddProduct()"></app-fulfillable-product-form>
                        </div>

                    </div>

                    <div class="more-details">
                        <a [routerLink]="[]" class="more-detail-link" (click)="toggleProductActive(i)">
                            Click for more detail
                        </a>
                    </div>

                </div>
            </ng-container>

            <div class="bottom-paging paging-container">
                <ng-container *ngTemplateOutlet="pagingTemplate"></ng-container>
            </div>
        </div>

        <div class="none-found" *ngIf="!working && (!products || products.length === 0)">
            <span class="fa-light fa-face-unamused icon fa-lg"></span>
            <div class="message">
                No fulfillable products found for the <span class="store-type">{{storeTypeName}}</span>
            </div>
        </div>

        
    </div>
</div>

<ng-template #pagingTemplate>
    <div class="paging" *ngIf="!working && pagingCount.showResultsPaging">
        <ng-container *ngTemplateOutlet="pagePrevButtonTemplate; context: {continuationToken: pagingCount.prevContinuationToken}"></ng-container>
        <div class="count">
            {{pagingCount.countStart}} to {{pagingCount.countEnd}} of {{pagingCount.countTotal}}
        </div>
        <ng-container *ngTemplateOutlet="pageNextButtonTemplate; context: {continuationToken: pagingCount.nextContinuationToken}"></ng-container>
    </div>
</ng-template>

<ng-template #pagePrevButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page prev" (click)="pageProducts(continuationToken, PREV)">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || continuationToken === ''">
        <div class="page prev disabled">
            <span class="far fa-arrow-left-long fa-fw icon"></span>
            <span class="text">prev</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #pageNextButtonTemplate let-continuationToken="continuationToken"> 
    <ng-container *ngIf="continuationToken && continuationToken !== ''">
        <div class="page next" (click)="pageProducts(continuationToken, NEXT)">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="!continuationToken || (continuationToken && continuationToken === '')">
        <div class="page next disabled">
            <span class="text">next</span>
            <span class="far fa-arrow-right-long fa-fw icon"></span>
        </div>
    </ng-container>
</ng-template>