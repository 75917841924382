<ng-container *ngTemplateOutlet="linkedUsersTemplate; context: {user: user, linkedUsers: linkedUsers, allowAddLinkedUser: !hasParent}">
</ng-container>

<ng-container *ngTemplateOutlet="parentUserTemplate; context: {parentUser: parentUser, showParentUserInfo: hasParent, siblingUsers: siblingUsers}">
</ng-container>

<!-- <ng-container *ngTemplateOutlet="addLinkedUserTemplate; context: {parentUser: user}"></ng-container> -->



<ng-template #linkedUsersTemplate let-user="user" let-linkedUsers="linkedUsers" let-allowAddLinkedUser="allowAddLinkedUser">
    <div class="linked-users" *ngIf="allowAddLinkedUser">
        <app-loading loadingText="loading household members" alignText="left" *ngIf="working"></app-loading>

        <div class="users" *ngIf="!working">
            <ng-container *ngFor="let linkedUser of linkedUsers | childUsers: user.key; index as i">
                <a [routerLink]="[]" class="linked-user {{i === 0 ? 'first' : ''}}" (click)="viewLinkedUser(linkedUser, user)">
                    <div class="avatar user-avatar {{isDefaultAvatar(linkedUser) ? 'default' : ''}}" [style.background-color]="getAvatarBg(linkedUser)">
                        <img src="{{getUserAvatar(linkedUser)}}" class="img" title="view {{linkedUser.displayName}} detail" />
                    </div>
                    <div class="linked-user-text">{{fullNameDisplay(linkedUser.info?.name, linkedUser.displayName)}}</div>
                </a>
            </ng-container>
            <a [routerLink]="[]" class="linked-user add-linked-user {{editLinkedUser ? 'active' : ''}}" (click)="showAddLinkedUser()">
                <div class="add-icon">
                    <span class="fal fa-plus fa-lg icon"></span>
                </div>
                <span class="add-text">Add a person</span>
            </a>
        </div>

        <!-- LINKED USERS {{user.linkInfo?.linkedCount ?? 0}} -->
        <ng-container *ngTemplateOutlet="addLinkedUserTemplate; context: {linkedUser: user}"></ng-container>

    </div>
</ng-template>

<ng-template #parentUserTemplate let-parentUser="parentUser" let-showParentUserInfo="showParentUserInfo" let-siblingUser="siblingUsers">
    <div class="linked-users" *ngIf="showParentUserInfo">
        <app-loading loadingText="loading parent user" alignText="left" *ngIf="working"></app-loading>

        <div class="users" *ngIf="!working">
            <a [routerLink]="[]" class="linked-user" (click)="viewLinkedUser(parentUser)">
                <div class="avatar user-avatar {{isDefaultAvatar(parentUser) ? 'default' : ''}}" [style.background-color]="getAvatarBg(parentUser)">
                    <img src="{{getUserAvatar(parentUser)}}" class="img" title="view {{parentUser.displayName}} detail" />
                </div>
                <div class="linked-user-text">{{fullNameDisplay(parentUser.info?.name, parentUser.displayName)}}</div>
            </a>
        </div>

        <h4 class="sibling-header" *ngIf="!working && siblingUsers && siblingUsers.length > 0">Siblings</h4>
        <div class="users" *ngIf="!working && siblingUsers && siblingUsers.length > 0">
            <div class="users" *ngFor="let siblingUser of siblingUsers">
                <a [routerLink]="[]" class="linked-user" (click)="viewLinkedUser(siblingUser, user)">
                    <div class="avatar user-avatar {{isDefaultAvatar(siblingUser) ? 'default' : ''}}" [style.background-color]="getAvatarBg(siblingUser)">
                        <img src="{{getUserAvatar(siblingUser)}}" class="img" title="view {{siblingUser.displayName}} detail" />
                    </div>
                    <div class="linked-user-text">{{fullNameDisplay(siblingUser.info?.name, siblingUser.displayName)}}</div>
                </a>
            </div>
        </div>
        

    </div>
</ng-template>

<ng-template #addLinkedUserTemplate let-linkedUser="linkedUser">
    <div class="linked-user-form-container" *ngIf="editLinkedUser">
        <app-linked-users-add [parentUser]="linkedUser" 
            (userSaveEvent)="saveLinkedUser($event)" 
            (userCancelEvent)="cancelLinkedUser()"></app-linked-users-add>
    </div>
</ng-template>
