import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, mergeMap, of, concatMap, switchMap, concat } from 'rxjs';
import { MfaRequirement } from 'src/app/core/enums/mfa-requirement';
import { AppUser } from 'src/app/core/models/app-user';
import { Tenant } from 'src/app/core/models/tenant';
import { TenantAppSettings } from 'src/app/core/models/tenant-app-settings';
import { AuthService } from 'src/app/core/services/auth.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  errorMessage: string;
  working: boolean;

  // Working from https://www.bezkoder.com/angular-13-jwt-auth/

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private offerService: OfferService,
    private parseErrorService: ParseErrorService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.working = false;

    // Reset registration flow
    sessionStorage.removeItem('isRegistrationFlow');
  }

  isWorking(): boolean {
    if (this.working === true) {
      return true;
    } else {
      return false;
    }
  }

  loginUser(): void {
    this.working = true;
    this.errorMessage = '';
    let token: string;
    let tenant: Tenant;

    const user: AppUser = { username: this.f['username'].value, password: this.f['password'].value };

    // TODO:  This code is essentially duplicated in the forgot-password.component.ts file.  Think about refactoring.
    //        This code is also mostly duplicated in the complete-registration.component.ts file.  Think about refactoring.
    this.authService.login(user).pipe(
      concatMap((response: any) => {
        // Check to see if MFA needed, if not, then return response
        if (response.data?.mfaRequirement === MfaRequirement.needsAuthorization ||
            response.data?.mfaRequirement === MfaRequirement.pendingAuthorization) {
          // TODO:  Test this out
          this.router.navigateByUrl('/verify');
          return null;
        }

        // TODO:  This doesn't need to be in place for first beta, but is new functionality for management
        //        Get system users and check roles?  Can maybe call get
        //        get app user (not get app tenant user), if user is a customer, then
        //        continue with logic below.
        //        if (standard, power user, or admin), they may or may not have
        //        tenants associated with their own user, but that role allows them
        //        to log in to any tenant, so let them search and select which tenant
        //        they want to manage in this app

        token = response.data?.token;
        return this.tenantService.getUserTenants();
      }),
      concatMap((response: Array<Tenant>) => {

        // Get the tenant
        if (response && response.length > 0) {
          tenant = this.tenantService.setAppTenant(response, token);
        }

        return this.tenantService.updateToken(tenant);
      }),
      concatMap((response: Tenant) => {

        return this.offerService.getTenantSettings();
      }),
      concatMap((response: TenantAppSettings) => {
        // used for sidebar to determine whether or not to show store front
        this.offerService.storeFrontEnabledValue = response['STORE_FRONT:ENABLED'] ?? false;

        // Now call get AppUser
        return this.userService.getAppUser(tenant.key);
      })
    ).subscribe({
      next: (response: AppUser) => {
        // TODO:  Check to make sure that there is a TENANT key in the token
        this.userService.updateUser(response);

        this.authService.isLoggedIn = true;
        this.router.navigateByUrl('/dashboard');
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      },
      complete: () => {
        this.working = false;
      }
    });
  }

}
