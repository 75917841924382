import { Injectable } from '@angular/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ProductImageRequest } from '../models/offer/product-image-request';
import { ImageCompressionValues } from '../models/image-compression-values';
import { ImageDimensions } from '../models/image-dimensions';


@Injectable()
export class HelperImageCompression {

    MAX_BYTES = 800000;

    constructor(private imageCompress: NgxImageCompressService) { }

    async uploadAndCompressImage(): Promise<ProductImageRequest | any> {
        let compressionNeeded = false;
        let dimensions: ImageDimensions;
        let vals: ImageCompressionValues;
        let compressionByteSize: number;
        let compressedImage: string;
        let imageString: string;
        let imageFileName: string;
        let imageRequest: ProductImageRequest;

        await this.imageCompress.uploadFileOrReject()
            .then(async ({ image, fileName, orientation }) => {
                //this.imgResultBeforeCompression = image;
                imageFileName = fileName;
                compressionByteSize = this.imageCompress.byteCount(image);

                console.log('File name: ' + fileName);
                console.log('Size in bytes of the uploaded image was:', this.imageCompress.byteCount(image));

                // For best results we want the image to be AT LEAST 1600px on the longest side, depending on orientation
                // So first we need to check the size of the document
                //const dimensions: ImageDimensions = await this.getImageDimensions(image);
                dimensions = await this.getImageDimensions(image);
                console.log(`Image HEIGHT before compression: ${dimensions.height}`);
                console.log(`Image WIDTH before compression: ${dimensions.width}`);

                // Set default CompressionValues
                vals = {
                    ratio: 100,
                    quality: 100,
                    maxHeight: dimensions.height ?? 1600,
                    maxWidth: dimensions.width ?? 1066
                };

                // Check to see if dimension changed is needed
                if (this.dimensionChangedNeeded(dimensions)) {
                    // Get the compression values needed
                    vals = this.getCompressionValues(dimensions);

                    compressionNeeded = true;
                }

                // Check the image size (in bytes)
                if (compressionByteSize > this.MAX_BYTES) {
                    vals.quality = vals.quality - 5;
                    compressionNeeded = true;
                }

                while (compressionNeeded) {
                    try {
                        compressedImage = await this.compressUploadedImage(image, orientation, vals.ratio, vals.quality, vals.maxWidth, vals.maxHeight);
                    } catch (error) {
                        compressionNeeded = false;
                    }
                    compressionNeeded = false;
                    vals.ratio = 100;  // Reset this to default

                    console.log('Size in bytes after compression is now:', this.imageCompress.byteCount(compressedImage));

                    dimensions = await this.getImageDimensions(compressedImage);
                    console.log(`Image HEIGHT after compression: ${dimensions.height}`);
                    console.log(`Image WIDTH after compression: ${dimensions.width}`);

                    // Double check compression image dimensions, just to be safe
                    if (this.dimensionChangedNeeded(dimensions)) {
                        // Get the compression values needed
                        vals = this.getCompressionValues(dimensions);

                        compressionNeeded = true;
                    }

                    // Check the image size (in bytes)
                    compressionByteSize = this.imageCompress.byteCount(compressedImage);
                    if (compressionByteSize > this.MAX_BYTES) {
                        vals.quality = vals.quality - 5;
                        compressionNeeded = true;
                    }

                    image = compressedImage;
                }

                imageString = image;

                if (imageString.length > 6) {
                    const imageData: string = imageString.length > 0 ? imageString.split(',')[1] : '';
                    const mimeType: string = imageString.split(',')[0]?.replace('data:', '')?.replace(';base64', '');
                    imageRequest = {
                        name: imageFileName,
                        mimeType: mimeType,
                        data: imageData
                    };
                }

            });

        return imageRequest ?? null;
    }

    private async compressUploadedImage(
        image: string,
        orientation: DOC_ORIENTATION,
        compressionRatio: number,
        compressionQuality: number,
        compressionMaxWidth: number,
        compressionMaxHeight: number): Promise<string | any> {
        return new Promise((resolve) => {
            this.imageCompress
                .compressFile(image, orientation, compressionRatio, compressionQuality, compressionMaxWidth, compressionMaxHeight)
                .then(async compressedImage => {
                    await resolve(compressedImage);
                }).catch(error => {
                    resolve(error);
                });
        });
    }

    private async getImageDimensions(image: string): Promise<ImageDimensions> {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            }
        });
    }

    private dimensionChangedNeeded(dimensions: ImageDimensions): boolean {
        if (dimensions && dimensions.height && dimensions.height > 0 && dimensions.width && dimensions.width > 0) {

            if (dimensions.height > dimensions.width) {
                // Portrait image
                if (dimensions.height > 1600) {
                    return true;
                }
            } else {
                // Landscape image
                if (dimensions.width > 1600) {
                    return true;
                }
            }
        }

        return false;
    }

    private getCompressionValues(dimensions: ImageDimensions): ImageCompressionValues {
        const comp: ImageCompressionValues = {
            quality: 100,
            ratio: 100,
            maxWidth: 0,
            maxHeight: 0
        };

        if (!dimensions || !dimensions.height || !dimensions.width) return comp;

        if (dimensions.height > dimensions.width) {
            // Portrait image
            if (dimensions.height > 1600) {
                // Compress down to max height (1600) - get ratio
                comp.maxHeight = 1600;
                comp.ratio = Math.round((comp.maxHeight / dimensions.height) * 100);
                comp.maxWidth = Math.round(dimensions.width * (comp.ratio / 100));
            }
        } else {
            // Landscape image
            if (dimensions.width > 1600) {
                // Compress down to max width (1600) - get ratio
                comp.maxWidth = 1600;
                comp.ratio = Math.round((comp.maxWidth / dimensions.width) * 100);
                comp.maxHeight = Math.round(dimensions.height * (comp.ratio / 100));
            }
        }

        return comp;
    }

}
