<h3>
    <span class="fa-light fa-face-unamused error-icon"></span>
    No user plan selected
</h3>

<p>
    <b>{{getUserDisplayName(user)}}</b>
    has not been enrolled in a <b>user plan(s)</b>.
    To add them to a plan select one or more plans below and click the 'Enroll' button.
</p>

<ng-container *ngTemplateOutlet="contentHeaderTemplate"></ng-container>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<app-loading loadingText="loading active plans..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<ng-container *ngTemplateOutlet="itemGridTemplate; context: {plans: plans}"></ng-container>


<ng-template #contentHeaderTemplate>
    <div class="content-header">
        <div class="item-count" *ngIf="!working && errorMessage.length === 0 && plans.length > 0">
            <span class="count">{{plans.length}}</span> {{plans.length === 1 ? 'plan' : 'plans'}} found
        </div>
    </div>
</ng-template>

<ng-template #itemGridTemplate let-plans="plans">
    <div class="items" *ngIf="!working && plans.length > 0">
        <div class="item {{i === 0 ? 'first' : ''}}" *ngFor="let plan of plans; index as i">
            <div class="checkbox-container">
                <input type="checkbox" [checked]="plan.isSelected" (change)="updatePlanIsSelected(plan, $event)" />
            </div>
            <div class="name">{{plan.name}}</div>
            <div class="view-detail">
                <a [routerLink]="[]" title="view details" (click)="showPlanDetails === i ? showPlanDetails = -1 : showPlanDetails = i">
                    {{showPlanDetails === i ? 'Hide' : 'Show'}} plan details
                </a>
            </div>

            <div class="plan-details {{showPlanDetails === i ? 'show' : ''}}">
                <ng-container *ngTemplateOutlet="planDetailsTemplate; context: {plan: plan}"></ng-container>
            </div>
        </div>

        <div class="actions">
            <button type="submit" class="btn btn-primary save" [disabled]="saving" (click)="enrollUserInPlan(user.key, plans)">
                <span *ngIf="saving" class="far fa-spinner fa-pulse spinner fa-xs"></span>
                <span *ngIf="saving" class="">Enrolling...</span>
                <span *ngIf="!saving" class="">Enroll</span>
            </button>
        </div>

    </div>
</ng-template>

<ng-template #planDetailsTemplate let-plan="plan">
    <div class="plan-item task-items">
        <h5>Tasks</h5>
        <div class="tasks">
            <div class="task" [style.background-color]="task.imageBgColor" *ngFor="let task of plan.tasks; index as i">
                <span class="name">{{task.name}}</span>
                <span class="occurrence">{{task.occurrence}} {{task.occurrence === 1 ? 'time' : 'times'}} {{task.interval}}</span>
            </div>
        </div>
    </div>
    <div class="plan-item challenge-items">
        <h5>Challenges</h5>
        <div class="challenges">
            <div class="challenge" *ngFor="let challenge of plan.tests; index as i">
                <div class="img-container" [style.background-color]="challenge.imageBgColor">
                    <img [src]="challenge.imageUrl" [alt]="challenge.name" />
                </div>
                <span class="name">{{challenge.name}}</span>
                <span class="task-name">{{challenge.task}}</span>
            </div>
        </div>
    </div>
    <div class="plan-item milestone-items">
        <h5>Milestones</h5>
        <div class="milestones">
            <div class="milestone" *ngFor="let milestone of plan.levels; index as i">
                <div class="img-container" [style.background-color]="milestone.imageBgColor">
                    <img [src]="milestone.imageUrl" [alt]="milestone.name" />
                </div>
                <span class="name">{{milestone.name}}</span>
            </div>
        </div>
    </div>
</ng-template>