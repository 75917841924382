import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDetail } from './error-detail';

export class WsApiError {
    ok: boolean;
    status: number;
    statusText: string;
    errorCode: string;
    errorMessage: string;
    errorDetail?: ErrorDetail[];
    fullError: HttpErrorResponse;
}
