import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BONUS_TYPES } from 'src/app/core/constants/bonus-types';
import { AppUser } from 'src/app/core/models/app-user';
import { AwardType } from 'src/app/core/models/reward/award-type';
import { PlanItemRequest } from 'src/app/core/models/reward/plan-item-request';
import { PlanSaveType } from 'src/app/core/models/reward/plan-save-info';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { UpdatePlan } from 'src/app/core/models/reward/update-plan';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';

@Component({
  selector: 'app-user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss']
})
export class UserTasksComponent implements OnInit {

  errorMessage: string;
  errorDetail: WsApiError;

  workingResources: boolean;
  savingAddTask: boolean;

  templateTasks: TemplateTask[] = [];
  taskDate: string;
  taskDateDisplay: string;
  today: string;

  newTaskForm: UntypedFormGroup;
  showAddTask: boolean;

  @Input() public user: AppUser;
  @Input() public userPlan: UserPlanResponse;
  @Input() public working: boolean;
  @Input() public savingTask: number;
  @Input() public childComponentTemplateTasks: TemplateTask[];
  @Output() updatePlanByTaskDate = new EventEmitter<UpdatePlan>();
  @Output() updatePlanTaskInfoByTaskDate = new EventEmitter<UpdatePlan>();
  @Output() updateParentTemplateTasks = new EventEmitter<TemplateTask[]>();
  @Output() updateParentPlan = new EventEmitter<UserPlanResponse>();

  constructor(
    private rewardService: RewardsService,
    private parseErrorService: ParseErrorService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.savingTask = -1;
    this.workingResources = false;
    this.savingAddTask = false;
    this.showAddTask = false;

    this.taskDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.taskDateDisplay = this.datePipe.transform(new Date(), 'EEEE, MMMM dd');
    this.today = this.datePipe.transform(new Date(), 'MM-dd-yyyy');

    this.createForm();

    if (!this.childComponentTemplateTasks) {
      this.getTemplatePlanResources();
    } else {
      this.templateTasks = this.childComponentTemplateTasks;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newTaskForm.controls;
  }

  getTemplatePlanResources(): void {
    this.workingResources = true;

    var taskService = this.rewardService.getTemplateTasks();

    taskService.subscribe({
      next: (resp: any) => {
        this.templateTasks = resp.tasks;
        this.updateParentTemplateTasks.emit(this.templateTasks);

        this.workingResources = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingResources = false;
      }
    });
  }

  createForm(): void {
    this.newTaskForm = this.fb.group({
      taskTask: [null, Validators.required],
      taskInterval: ['daily', Validators.required],      // hidden field with default value DAILY
      taskOccurrence: [null, Validators.required],
      taskRequiresReview: [false, Validators.required],  // hidden field with default value FALSE
      taskBadgeName: ['']
    });
  }

  toggleDate(direction: number): void {
    const dateTaskDate = new Date(this.taskDate);
    dateTaskDate.setDate(dateTaskDate.getDate() + direction);
    this.taskDate = this.datePipe.transform(dateTaskDate, 'MM-dd-yyyy');
    this.taskDateDisplay = this.datePipe.transform(dateTaskDate, 'EEEE, MMMM dd');

    // TODO:  I'm not sure why I had this in place, but need to do more research.
    //        For now, just it fixed the bug.
    // if (this.taskDate === this.today) {
    //   return;
    // }

    this.updatePlanByTaskDate.emit({ taskDate: this.taskDate, workingTask: true, workingPlan: false });
  }

  getAlternateName(user: AppUser): string {
    // Note:  won't pass unit test without pulling this into a method.  Not sure why.
    if (user?.displayName && user.displayName.length > 0) {
      return user.displayName;
    } else {
      return 'this user';
    }
  }

  buildTaskStatus(task: TemplateTask): string {
    const bonusTypes: AwardType[] = BONUS_TYPES;
    const bonusType: AwardType = task.potentialResults && task.potentialResults.success ?
      bonusTypes.find(x => x.code === task.potentialResults?.success?.bonusTypeCode) :
      { code: 'TICK', name: 'Tickets' };

    // if (task.interval.toString().toLocaleLowerCase() === 'daily') {}
    const completedCount: number = task.completions ? task.completions.length : 0;

    if (task.completed) {  // && task.awarded????
      return `Done!  You have earned ${task.potentialResults?.success?.bonusAmount} ${bonusType?.name}`;
    }

    if (task.occurrence > completedCount && task.occurrence > 1) {
      return `${completedCount} of ${task.occurrence} times today`;
    } else if (task.occurrence > completedCount && task.occurrence === 1) {
      return '1 time today';
    }

    return '';
  }

  markUserTaskAsComplete(taskKey: string, userKey: string, e: Event, i: number): void {
    this.savingTask = i;

    this.rewardService.updateUserPlanActivityByKey(userKey, taskKey, this.taskDate, this.today).subscribe({
      next: (resp: any) => {
        // If successful, mark the item as completed so we don't have to reload the tasks
        // const targetTask: TemplateTask = this.userPlan.tasks.find(x => x.key === taskKey);
        const updatedUserPlan: UserPlanResponse = resp;

        this.updatePlanTaskInfoByTaskDate.emit({ updatedUserPlan: updatedUserPlan, taskDate: this.taskDate, workingTask: false, workingPlan: false, savingTask: this.savingTask });

        this.savingTask = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingTask = -1;
      }
    });
  }

  markUserTaskAsNotComplete(userKey: string, taskKey: string, taskCompletionKey: string, e: Event, i: number): void {
    this.savingTask = i;

    this.rewardService.deleteUserPlanActivityByKey(userKey, taskCompletionKey, this.today).subscribe({
      next: (resp: any) => {
        // If successful, mark the item as completed so we don't have to reload the tasks
        //const targetTask: TemplateTask = this.userPlan.levels.find(x => x.key === taskKey);
        const updatedUserPlan: UserPlanResponse = resp;

        this.updatePlanTaskInfoByTaskDate.emit({ updatedUserPlan: updatedUserPlan, taskDate: this.taskDate, workingTask: false, workingPlan: false, savingTask: this.savingTask });

        this.savingTask = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingTask = -1;
      }
    });
  }

  deleteUserTask(userKey: string, taskKeyToDelete: string, e: Event, i: number): void {
    this.savingTask = i;

    if (this.userPlan.tasks.findIndex(t => t.key === taskKeyToDelete) >= 0) {
      const index = this.userPlan.tasks.findIndex(t => t.key === taskKeyToDelete);
      if (index > -1) {
        this.userPlan.tasks[index].deleted = true;
        this.userPlan.tasks[index].entityOperation = 3;

        const planItemRequest: PlanItemRequest = {
          tasks: []
        };
        planItemRequest.tasks.push(this.userPlan.tasks[index]);

        this.savePlan(planItemRequest);

      }
    }
  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  addTaskToPlan(): void {
    this.savingAddTask = true;

    const newTask: TemplateTask = {
      templateTaskKey: this.f['taskTask'].value,
      interval: this.f['taskInterval'].value,
      occurrence: this.f['taskOccurrence'].value,
      requiresReview: this.f['taskRequiresReview'].value

      // Temp code
      ,
      name: this.templateTasks.filter(t => t.key === this.f['taskTask'].value)[0]?.name
    };

    const newPlanItemRequest: PlanItemRequest = {
      tasks: []
    };
    newPlanItemRequest.tasks.push(newTask);

    this.savePlan(newPlanItemRequest);
  }

  savePlan(planToUpdate: PlanItemRequest): void {
    const taskPlanDate: string = this.datePipe.transform(new Date(), 'MM-dd-yyyy');  // Plan date (for challenges)

    this.rewardService.updateUserPlanWithDetailResponse(this.user.key, planToUpdate, taskPlanDate, taskPlanDate).subscribe({
      next: (response: UserPlanResponse | any) => {
        this.initializeTasksAndLevels(response);

        this.userPlan = response;
        this.updateParentPlan.emit(this.userPlan);

        this.savingAddTask = false;
        this.showAddTask = false;
        this.savingTask = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingAddTask = false;
        this.savingTask = -1;
      }
    });
  }

  initializeTasksAndLevels(plan: UserPlanResponse): void {
    if (!plan) {
      return;
    }

    plan.tasks.forEach((task: TemplateTask) => {
      task.templateTaskKey = task.key;
    })

    plan.levels.forEach((level: TemplateLevel) => {
      level.templateTaskKey = level.key;
    })
  }
}
