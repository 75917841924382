import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppUser } from 'src/app/core/models/app-user';
import { UserPlanResponse } from 'src/app/core/models/reward/user-plan-response';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { TemplateLevel } from 'src/app/core/models/reward/template-level';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { UpdatePlan } from 'src/app/core/models/reward/update-plan';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TemplateTask } from 'src/app/core/models/reward/template-task';
import { Badge } from 'src/app/core/models/badge';
import { Image } from 'src/app/core/models/image';
import { PlanItemRequest } from 'src/app/core/models/reward/plan-item-request';

@Component({
  selector: 'app-user-milestones',
  templateUrl: './user-milestones.component.html',
  styleUrls: ['./user-milestones.component.scss']
})
export class UserMilestonesComponent implements OnInit {

  BADGE_PATH = 'assets/images/badges/';

  errorMessage: string;
  errorDetail: WsApiError;

  templateTasks: TemplateTask[] = [];

  newMilestoneForm: UntypedFormGroup;
  showAddMilestone: boolean;
  workingResources: boolean;
  savingAddMilestone: boolean;

  showMilestoneBadges: boolean;
  selectedMilestoneBadge: Badge;

  @Input() public user: AppUser;
  @Input() public userPlan: UserPlanResponse;
  @Input() public working: boolean;
  @Input() public savingMilestone: number;
  @Input() public childComponentTemplateTasks: TemplateTask[];
  @Output() updatePlanMilestoneInfoByTaskDate = new EventEmitter<UpdatePlan>();
  @Output() updateParentTemplateTasks = new EventEmitter<TemplateTask[]>();
  @Output() updateParentPlan = new EventEmitter<UserPlanResponse>();

  constructor(private rewardService: RewardsService,
    private parseErrorService: ParseErrorService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.savingMilestone = -1;
    this.workingResources = false;
    this.savingAddMilestone = false;
    this.showAddMilestone = false;
    this.showMilestoneBadges = false;

    this.createForm();

    if (!this.childComponentTemplateTasks) {
      this.getTemplatePlanResources();
    } else {
      this.templateTasks = this.childComponentTemplateTasks;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newMilestoneForm.controls;
  }

  getTemplatePlanResources(): void {
    this.workingResources = true;

    var taskService = this.rewardService.getTemplateTasks();

    taskService.subscribe({
      next: (resp: any) => {
        this.templateTasks = resp.tasks;
        this.updateParentTemplateTasks.emit(this.templateTasks);

        this.workingResources = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.workingResources = false;
      }
    });
  }

  createForm(): void {
    this.newMilestoneForm = this.fb.group({
      levelTask: [null, Validators.required],
      levelInterval: ['Ever'],    // hidden field with default value EVER
      levelOccurrence: ['1'],      // hidden field with default value 1
      levelRequiresReview: [true, Validators.required], // hidden field with default value TRUE
      levelBadgeName: ['']
    });
  }

  markUserMilestoneAsComplete(levelKey: string, userKey: string, e: Event, i: number): void {
    this.savingMilestone = i;
    const taskDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');

    this.rewardService.updateUserPlanActivityByKey(userKey, levelKey, taskDate).subscribe({
      next: (resp: any) => {
        // If successful, mark the item as completed so we don't have to reload the tasks
        // const targetLevel: TemplateLevel = this.userPlan.levels.find(x => x.key === levelKey);
        // targetLevel.completed = true;
        // this.savingLevel = -1;

        const taskDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
        const updatedUserPlan: UserPlanResponse = resp;

        this.updatePlanMilestoneInfoByTaskDate.emit({updatedUserPlan: updatedUserPlan, taskDate: taskDate, workingTask: false, workingPlan: false, savingTask: this.savingMilestone});

        this.savingMilestone = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingMilestone = -1;
      }
    });
  }

  markUserMilestoneAsNotComplete(userKey: string, levelKey: string, levelCompletionKey: string, e: Event, i: number): void {
    this.savingMilestone = i;

    this.rewardService.deleteUserPlanActivityByKey(userKey, levelCompletionKey).subscribe({
      next: (resp: any) => {
        // If successful, mark the item as completed so we don't have to reload the tasks
        //const targetLevel: TemplateLevel = this.userPlan.levels.find(x => x.key === levelKey);
        //targetLevel.completed = false;
        //this.savingLevel = -1;

        const taskDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
        const updatedUserPlan: UserPlanResponse = resp;
        this.updatePlanMilestoneInfoByTaskDate.emit({updatedUserPlan: updatedUserPlan, taskDate: taskDate, workingTask: false, workingPlan: false, savingTask: this.savingMilestone});

        this.savingMilestone = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingMilestone = -1;
      }
    });
  }

  deleteUserMilestone(userKey: string, levelKeyToDelete: string, e: Event, i: number): void {
    this.savingMilestone = i;

    if (this.userPlan.levels.findIndex(l => l.key === levelKeyToDelete) >= 0) {
      const index = this.userPlan.levels.findIndex(l => l.key === levelKeyToDelete);
      if (index > -1) {
        const levelToDelete: TemplateLevel = {
          entityOperation: 3,
          key: levelKeyToDelete,
          templateTaskKey: this.userPlan.levels[index].templateTaskKey
        }

        const planItemRequest: PlanItemRequest = {
          levels: []
        };
        planItemRequest.levels.push(levelToDelete);

        this.savePlan(planItemRequest);
      }
    }

  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return '';
  }

  async addMilestoneToPlan(): Promise<void> {
    this.savingAddMilestone = true;

    const newLevel: TemplateLevel = {
      templateTaskKey: this.f['levelTask'].value,
      interval: this.f['levelInterval'].value,
      occurrence: this.f['levelOccurrence'].value,
      requiresReview: this.f['levelRequiresReview'].value

      // Temp code
      ,
      name: this.templateTasks.filter(t => t.key === this.f['levelTask'].value)[0]?.name
    };

    if (this.selectedMilestoneBadge) {
      const levelImage = await this.updatePlanSectionWithBadgeInfo(this.selectedMilestoneBadge);
      newLevel.image = levelImage ?? null;
      newLevel.imageUrl = this.BADGE_PATH + this.selectedMilestoneBadge.fileName;
    }

    const newPlanItemRequest: PlanItemRequest = {
      levels: []
    };
    newPlanItemRequest.levels.push(newLevel);

    this.savePlan(newPlanItemRequest);
  }

  savePlan(planToUpdate: PlanItemRequest): void {
    const taskPlanDate: string = this.datePipe.transform(new Date(), 'MM-dd-yyyy');  // Plan date (for challenges)

    this.rewardService.updateUserPlanWithDetailResponse(this.user.key, planToUpdate, taskPlanDate, taskPlanDate).subscribe({
      next: (response: UserPlanResponse | any) => {
        this.initializeTasksAndLevels(response);

        this.userPlan = response;
        this.updateParentPlan.emit(this.userPlan);

        this.savingAddMilestone = false;
        this.showAddMilestone = false;
        this.savingMilestone = -1;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.savingAddMilestone = false;
        this.savingMilestone = -1;
      }
    });
  }

  initializeTasksAndLevels(plan: UserPlanResponse): void {
    if (!plan) {
      return;
    }

    plan.tasks.forEach((task: TemplateTask) => {
      task.templateTaskKey = task.key;
    })

    plan.levels.forEach((level: TemplateLevel) => {
      level.templateTaskKey = level.key;
    })
  }

  updateItemWithBadge(badge: Badge, formItem: AbstractControl, section: string): void {
    this.selectedMilestoneBadge = badge;
    formItem.setValue(badge.name);
    this.showMilestoneBadges = false;
  }

  async updatePlanSectionWithBadgeInfo(badge: Badge): Promise<Image> {
    let imageToUpdate: Image;
    const imageBase64String = await this.getBase64FromBadgeImage(badge);
    if (imageBase64String !== '') {
      imageToUpdate = {
        mimeType: 'image/png',
        data: imageBase64String
      };
    }
    return imageToUpdate;
  }

  async getBase64FromBadgeImage(badge: Badge): Promise<string | any> {
    const imageResponse = await fetch(this.BADGE_PATH + badge.fileName);
    if (imageResponse.status === 200) {
      const imageBlob = await imageResponse.blob();

      const resultBase64 = await new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          const result: string = fileReader.result.toString().length > 0 ? fileReader.result.toString().replace('data:image/png;base64,', '') : '';
          resolve(result);
        };
        fileReader.onerror = () => resolve('');
        fileReader.readAsDataURL(imageBlob);
      });

      return resultBase64;
    } else {
      return new Promise((resolve) => { resolve(''); });
    }
  }

}
