import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from 'src/app/core/enums/order-status';

@Component({
  selector: 'app-status-icons',
  templateUrl: './status-icons.component.html',
  styleUrls: ['./status-icons.component.scss']
})
export class StatusIconsComponent implements OnInit {

  @Input() public orderStatus: string;
  orderStatusEnum = OrderStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
