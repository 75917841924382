<div class="error alert alert-danger {{errorSize}} {{errorWidth && errorWidth === 'auto' ? 'auto-width' : '' }}" *ngIf="errorMessage">
    <div class="err-row">
        <span class="far fa-exclamation-circle fa-lg icon" (click)="showDetail = !showDetail"></span>
        {{errorMessage}}
    </div>

    <div class="err-row error-detail" *ngIf="showDetail">
        <div class="error-item" *ngFor="let err of errorDetail?.errorDetail">
            <div class="item-row">
                <label>code</label>
                <span class="text">{{err?.code}}</span>
            </div>
            <div class="item-row">
                <label>description</label>
                <span class="text">{{err?.error}}</span>
            </div>
        </div>
    </div>
</div>