<div class="container-fluid forgot">
    <div class="row">
        <div class="col branding-col">

            <div class="branding">
                <a routerLink="[]" class="brand">
                    <!-- <img src="assets/images/logo-horizontal.png" title="Pummelo" /> -->
                    <img src="assets/images/logo-horizontal.svg" title="Pummelo" />
                </a>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col">

            <div class="card">
                <div class="card-body">

                    <h1>Complete Registration</h1>
                    <p class="{{errorMessage ? 'with-error' : ''}}" *ngIf="inviteCodePresent">
                        To complete your registration, please create a password below.
                    </p>

                    <div class="error alert alert-danger" *ngIf="errorMessage">
                        <span class="far fa-exclamation-circle fa-lg icon"></span>
                        {{errorMessage}}
                    </div>

                    <ng-container *ngIf="inviteCodePresent">
                        <ng-container *ngTemplateOutlet="completeRegistrationTemplate">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!inviteCodePresent">
                        <ng-container *ngTemplateOutlet="askForInviteCodeTemplate"></ng-container>
                    </ng-container>

                </div>
            </div>

        </div>
    </div>
</div>


<ng-template #completeRegistrationTemplate>

    <form class="pml-form" [formGroup]="completeRegistrationForm" (ngSubmit)="completeRegistrationForm.valid" (ngSubmit)="saveUser()" autocomplete="off">

        <div class="pml-row w-fit-content">
            <label>password</label>
            <span class="required {{f['password'].errors?.['pattern'] ? 'password' : ''}}" *ngIf="isInvalid(f['password'])">{{getError(f['password'].errors, 'password')}}</span>
            <div class="input-data">
                <input type="password" class="form-control password" formControlName="password" placeholder="password" />
            </div>
        </div>
        <div class="pml-row w-fit-content">
            <label>confirm password</label>
            <span class="required password v-center" *ngIf="isInvalid(f['confirmPassword'])">{{getError(f['confirmPassword'].errors, 'Confirm Password')}}</span>
            <div class="input-data">
                <input type="password" class="form-control password" formControlName="confirmPassword" placeholder="confirm password" />
            </div>
        </div>
        <div class="pml-row submit-form pml-dialog-actions">
            <button type="button" class="btn btn-outline-primary cancel" (click)="returnToLogin()">
                Cancel
            </button>
            <button type="submit" class="btn btn-primary save"
                [disabled]="saving || !(completeRegistrationForm.valid && completeRegistrationForm.dirty)">
                <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!saving" class="">complete registration</span>
                <span *ngIf="saving" class="">completing registration...</span>
            </button>
        </div>

    </form>

</ng-template>

<ng-template #askForInviteCodeTemplate>
    <form class="pml-form" [formGroup]="inviteForm" (ngSubmit)="inviteForm.valid" (ngSubmit)="useInviteCode()" autocomplete="off">

        <div class="pml-row w-fit-content">
        <label>invite code</label>
        <span class="required" *ngIf="isInvalid(c['inviteCode'])">{{getError(c['inviteCode'].errors, 'invite code')}}</span>
        <div class="input-data">
            <input type="text" class="form-control" formControlName="inviteCode" placeholder="invite code" />
        </div>
    </div>
    <div class="pml-row submit-form pml-dialog-actions">
        <button type="button" class="btn btn-outline-primary cancel" (click)="returnToLogin()">
            Cancel
        </button>
        <button type="submit" class="btn btn-primary save"
            [disabled]="saving || !(inviteForm.valid && inviteForm.dirty)">
            <span *ngIf="saving" class="far fa-spinner fa-pulse spinner"></span>
            <span *ngIf="!saving" class="">use invite code</span>
            <span *ngIf="saving" class="">adding invite code...</span>
        </button>
    </div>

    </form>
</ng-template>