import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-helper',
  templateUrl: './svg-helper.component.html',
  styleUrls: ['./svg-helper.component.scss']
})
export class SvgHelperComponent implements OnInit {

  imageColor: string;

  @Input() public svgName: string;
  @Input() public svgColor: string;

  constructor() { }

  ngOnInit(): void {
    this.imageColor = this.svgColor ? this.svgColor : 'none';
  }

}
