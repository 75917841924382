<h1>Rule Resources</h1>
<p>Temporary place holder for rule resources</p>

<app-error-display [errorMessage]="errorMessage" [errorDetail]="errorDetail" style="display: flex;"></app-error-display>

<app-loading loadingText="loading rule resources..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<div class="rule-resources">
    <a [routerLink]="[]" class="plan-name" (click)="activeRule = resource" *ngFor="let resource of ruleResources">
        {{resource.name}}
    </a>
</div>

<div class="evals">
    <ng-container *ngTemplateOutlet="activeRuleTemplate; context: {ruleResource: activeRule}"></ng-container>

    <div class="eval">
        <app-evaluator [evaluator]="activeRule" [bonusResources]="bonusTypeResources" [resultResources]="resultTypeResources" *ngIf="activeRule"></app-evaluator>
    </div>
</div>


<ng-template #activeRuleTemplate let-ruleResource="ruleResource">
    <div class="user-plan-result" *ngIf="ruleResource">

        <h3>{{activeRule.name}}</h3>
        <ng-container *ngIf="activeRule.name === 'User Plan Result'">
            <ng-container *ngTemplateOutlet="userPlanEvalTemplate; context: {expressionable: getExpressionable(ruleResource, 'EvaluateResultType')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userPlanAllowedNumberTemplate; context: {expressionable: getExpressionable(ruleResource, 'AllowedNumberOfAwards')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userRuleRewardsTemplate; context: {actionable: getActionable(ruleResource, 'SetResult')}"></ng-container>
        </ng-container>

        <ng-container *ngIf="activeRule.name === 'User Plan Activity'">
            <ng-container *ngTemplateOutlet="userPlanCompletedTemplate; context: {expressionable: getExpressionable(ruleResource, 'ContextAsType.Completed')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userPlanCompletedTemplate; context: {expressionable: getExpressionable(ruleResource, 'ContextAsType.Awarded')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userRuleRewardsTemplate; context: {actionable: getActionable(ruleResource, 'SetResult')}"></ng-container>

            <ng-container *ngTemplateOutlet="userRuleRewardsTemplate; context: {actionable: getActionable(ruleResource, 'ReportProgress')}"></ng-container>
        </ng-container>

        <ng-container *ngIf="activeRule.name === 'User Plan Test Award' || activeRule.name === 'User Plan Challenge Award'">
            <ng-container *ngTemplateOutlet="userStreakTemplate; context: {expressionable: getExpressionable(ruleResource, 'HasStreak')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userPercentageTemplate; context: {expressionable: getExpressionable(ruleResource, 'HasPercentage')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userPlanCompletedTemplate; context: {expressionable: getExpressionable(ruleResource, 'ContextAsType.Completed')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userPlanCompletedTemplate; context: {expressionable: getExpressionable(ruleResource, 'ContextAsType.Awarded')}">
            </ng-container>

            <ng-container *ngTemplateOutlet="userRuleRewardsTemplate; context: {actionable: getActionable(ruleResource, 'SetResult')}"></ng-container>

            <ng-container *ngTemplateOutlet="userRuleRewardsTemplate; context: {actionable: getActionable(ruleResource, 'ReportProgress')}"></ng-container>
        </ng-container>

    </div>
</ng-template>

<ng-template #userPlanEvalTemplate let-expressionable="expressionable">
    <div class="rule-result eval-result">
        <label>{{expressionable.displayName}}</label>
        <div class="params">
            <ng-container *ngFor="let param of expressionable?.parameters">
                <div class="param-item result-type" *ngIf="param.parameterName === 'resultTypeCode'">
                    <select class="result-type-dd" name="type">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of param.validValues" [ngValue]="type">{{resultType(type)}}</option>
                    </select>
                </div>
                <div class="param-item eval-type" *ngIf="param.parameterName === 'evaluationType'">
                    <select class="eval-type-dd" name="type">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of param.validValues" [ngValue]="type">{{evaluationType(type)}}</option>
                    </select>
                </div>
                <div class="param-item eval-amt" *ngIf="param.parameterName === 'amount'">
                    <input type="textbox" name="amount" placeholder="amount" value="" />
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #userPlanAllowedNumberTemplate let-expressionable="expressionable">
    <div class="rule-result eval-result">
        <label>{{expressionable.displayName}}</label>
        <div class="params">
            <ng-container *ngFor="let param of expressionable?.parameters">
                <input type="textbox" name="numberOfAwards" placeholder="Allowed number of awards" value="" />
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #userRuleRewardsTemplate let-actionable="actionable">
    <div class="rule-result reward">
        <label>{{actionable.displayName}}</label>
        <div class="params">
            <ng-container *ngFor="let param of actionable?.parameters">
                <div class="param-item" *ngIf="param.parameterName === 'bonusTypeCode'">
                    <select class="bonus-type-dd">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of param.validValues" [ngValue]="type">{{bonusType(type)}}</option>
                    </select>
                </div>
                <div class="param-item" *ngIf="param.parameterName === 'bonusAmount'">
                    <input type="textbox" name="bonusAmount" placeholder="amount" value="" class="text-amount" />
                </div>
                <div class="param-item" *ngIf="param.parameterName === 'resultTypeCode'">
                    <select class="result-type-dd">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of param.validValues" [ngValue]="type">{{resultType(type)}}</option>
                    </select>
                </div>
                <div class="param-item" *ngIf="param.parameterName === 'resultAmount'">
                    <input type="textbox" name="resultAmount" placeholder="amount" value="" class="text-amount" />
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #userPlanCompletedTemplate let-expressionable="expressionable">
    <div class="rule-result">
        <label>{{expressionable.displayName}}</label>
        <div class="boolean">Yes / No</div>
    </div>
</ng-template>

<ng-template #userStreakTemplate let-expressionable="expressionable">
    <div class="rule-result eval-result">
        <label>{{expressionable.displayName}}</label>
        <div class="params">
            <ng-container *ngFor="let param of expressionable?.parameters">
                <input type="textbox" name="{{param.parameterName}}" placeholder="streak length" class="text-amount" value="" />
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #userPercentageTemplate let-expressionable="expressionable">
    <div class="rule-result eval-result">
        <label>{{expressionable.displayName}}</label>
        <div class="params">
            <ng-container *ngFor="let param of expressionable?.parameters">
                <input type="textbox" name="{{param.parameterName}}" placeholder="streak length" class="text-amount" value="" />
            </ng-container>
        </div>
    </div>
</ng-template>