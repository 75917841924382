<h1>New User QR Code</h1>

<app-error-display errorMessage="{{errorMessage}}" [errorDetail]="errorDetail"></app-error-display>

<app-loading loadingText="loading QR code..." loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

<p class="header-text" *ngIf="!working">
    Use the QR code below to automatically add a new customer to <span class="company-name">{{companyName}}</span> company/reward program as part of the
    registration process in the <b>Pummelo</b> mobile app.<br />
</p>

<div class="qr-code-container" *ngIf="!working">
    <div class="qr-code" [innerHTML]="qrHtml"></div>

    <div class="instructions">
        <h3>Instructions</h3>
        <div class="item">
            <img src="/assets/images/screens/application-screenshot01a.png" class="first" alt="get started" />
            First time users should download the <b>Pummelo</b> mobile app from Apple's App Store or Google Play.  Open the
            application and select the <b>GET STARTED</b> button.
        </div>

        <div class="item">
            <img src="/assets/images/screens/application-screenshot02a.png" class="second" alt="get started" />
            The user should select their age and click the <b>CONTINUE</b> button.
        </div>

        <div class="item">
            <img src="/assets/images/screens/application-screenshot03a.png" class="last" alt="get started" />
            On the <b>Which company rewards program are you joining?</b> screen, click the
            <span class="scan">SCAN PUMMELO QR CODE</span> link, which will open the camera on the 
            mobile device.  Point the camera at the QR code. on this page and it will auto select <b>{{companyName}}</b>.
            <br/><br />
            The <b>Pummelo</b> mobile app will recognize the QR code and automatically add <b>{{companyName}}</b>
            as the new user's company/reward program and take the user to the next step in the registration process.
        </div>
    </div>
</div>

