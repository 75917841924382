import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { HelperImageCompression } from 'src/app/core/helpers/helper-image-compression';
import { Address } from 'src/app/core/models/Address';
import { AppUser } from 'src/app/core/models/app-user';
import { ImageRequest } from 'src/app/core/models/image-request';
import { ImageResponse } from 'src/app/core/models/image-response';
import { Name } from 'src/app/core/models/name';
import { RegisterPerson } from 'src/app/core/models/registration/register-person';
import { TenantAccount } from 'src/app/core/models/registration/tenant-account';
import { State } from 'src/app/core/models/state';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { JsonService } from 'src/app/core/services/json.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-company-contact',
  templateUrl: './company-contact.component.html',
  styleUrls: ['../register.component.scss', './company-contact.component.scss']
})
export class CompanyContactComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;
  loadingImage: boolean;

  contactForm: UntypedFormGroup;
  user: AppUser;
  tenantAccount: TenantAccount;

  stateList: State[];
  proofOfIdTypes: string[] = ['Driver License', 'Passport'];
  proofOfIdImage: ImageRequest;

  REGISTER_ADDRESS: string = 'REGISTER-ADDRESS';

  constructor(
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentService,
    private jsonService: JsonService,
    private imgCompressionHelper: HelperImageCompression,
    private parseErrorService: ParseErrorService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.loadingImage = false;

    this.getStates();

    this.getUser();
    this.getTenantAccount();

    this.createForm();
  }

  getUser(): void {
    this.authService.getRegisterUser().subscribe({
      next: (response: any) => {
        this.user = response.data;

        this.contactForm.patchValue({
          contactFirstName: this.user?.info?.name?.first,
          contactLastName: this.user?.info?.name?.last,
          contactEmail: this.user?.emailAddress
        });
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  getTenantAccount(): void {
    this.working = true;

    this.paymentService.getTenantAccount().subscribe({
      next: (response: TenantAccount | any) => {
        this.tenantAccount = response.data;

        this.working = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  getStates(): void {
    this.jsonService.getStates().subscribe({
      next: (response: State[] | any) => {
        this.stateList = response;

        //this.stateList.forEach((state: State) => {
        //  console.log(`state: ${state.name} - ${state.code}`);
        //});
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  createForm(): void {
    this.contactForm = this.fb.group({

      contactFirstName: [this.user?.info?.name?.first ?? '', [Validators.required, Validators.maxLength(50)]],
      contactLastName: [this.user?.info?.name?.last ?? '', [Validators.required, Validators.maxLength(50)]],
      contactRole: ['owner', Validators.required],
      contactTitle: [''],
      contactEmail: [this.user?.emailAddress, [Validators.required, Validators.email]],
      contactPhone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      dob: ['', Validators.required],
      proofOfIdType: ['', Validators.required],
      proofOfIdImage: ['', Validators.required],

      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],

    });

  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = '', overrideLabel: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      if (overrideLabel !== '') {
        return overrideLabel;
      } else {
        return labelName !== '' ? `${labelName} is required` : 'required';
      }
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['maxlength']) {
      return labelName !== '' ? `${labelName} is too long (${errors['maxlength']?.requiredLength} chars max)` : `too long (${errors['maxlength']?.requiredLength} chars max)`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern'] && labelName === 'password') {
      return 'Password must be 8 characters in length and contain at least 1 digit, 1 lowercase character, 1 uppercase character, and 1 special character ';
    }

    if (errors['notSame']) {
      return 'Passwords do not match';
    }

    return 'Error';
  }

  updateProofOfIdImage(event: any): void {
    this.f['proofOfIdImage'].markAsTouched();
    this.f['proofOfIdImage'].markAsDirty();
  }

  async uploadIdImage(): Promise<void> {
    this.loadingImage = true;

    // Upload and compress image
    try {
      this.proofOfIdImage = await this.imgCompressionHelper.uploadAndCompressImage();

      //proofOfIdImage
      this.contactForm.patchValue({ proofOfIdImage: this.proofOfIdImage.data });
      this.contactForm.markAsDirty();
      //console.log(`${this.proofOfIdImage.name} added to page`);
    } catch (e: any) {
      if (e.message === 'file upload on blur - no file selected') {
        const error: any = e;
      } else {
        this.errorMessage = e.message;
      }
      this.loadingImage = false;
      return;
    }
  }

  removeIdImage(): void {
    this.proofOfIdImage = null;
    this.contactForm.patchValue({ proofOfIdImage: null });
    this.contactForm.markAsDirty();
  }

  saveCompanyContact(): void {
    this.saving = true;

    let accountPerson: RegisterPerson = this.getAccountPersonInfoFromForm();

    this.paymentService.tenantAccountDocument(this.proofOfIdImage.data)
      .pipe(
        concatMap((response: ImageResponse | any) => {
          // The response should be a document key, which will be used
          // to post the rest of the company contact information
          const savedDocument: ImageResponse = response;

          // Create the request body for the tenantAccountPerson method,
          // which will include the information from the form and the
          // document key from the previous response.
          accountPerson.verificationDocumentKey = savedDocument.key;

          return this.paymentService.tenantAccountPerson(accountPerson);
        })
      ).subscribe({
        next: (response: RegisterPerson | any) => {
          this.saving = false;

          this.router.navigateByUrl('/register/register-plan');
        },
        error: (err: any) => {
          this.saving = false;
          this.errorMessage = this.parseErrorService.parseApiError(err);
        }
      });

  }

  getAccountPersonInfoFromForm(): RegisterPerson {
    // Get the form data and add to user info
    let contactName: Name = {
      first: this.f['contactFirstName'].value ?? '',
      last: this.f['contactLastName'].value ?? ''
    }
    if (this.f['contactTitle'].value && this.f['contactTitle'].value !== '') {
      contactName.title = this.f['contactTitle'].value;
    }

    const personInfo: RegisterPerson = {
      name: contactName,
      emailAddress: this.f['contactEmail'].value ?? '',
      phoneNumber: this.f['contactPhone'].value ?? '',
      isOwner: false,             // Default to false, will get value next
      isRepresentative: false,    // Default to false, will get value next
      isExecutive: false          // Default to false, will get value next
    };

    if (this.f['contactRole'].value) {
      if (this.f['contactRole'].value === 'owner') {
        personInfo.isOwner = true;
        personInfo.role = 'owner';
      }
      if (this.f['contactRole'].value === 'representative') {
        personInfo.isRepresentative = true;
        personInfo.role = 'representative';
      }
      if (this.f['contactRole'].value === 'executive') {
        personInfo.isExecutive = true;
        personInfo.role = 'executive';
      }
    }

    // Get the DOB
    if (this.f['dob'].value && this.f['dob'].value !== '') {
      //const dob: Date = new Date(Date.parse(this.f['dob'].value));  // This converts from server time to UTC which makes the values off by 1 day
      var dob = this.f['dob'].value.split('-');
      // format of dob is yyyy-mm-dd
      if (dob.length === 3) {
        personInfo.dateOfBirth = {
          month: dob[1],
          day: dob[2],
          year: dob[0]
        }
      }
    }

    // Get the address
    if (this.f['addressLine1'].value && this.f['addressLine1'].value !== '') {
      let address: Address = {
        addressLine1: this.f['addressLine1'].value,
        lineOne: this.f['addressLine1'].value,
        country: 'US'
      };

      if (this.f['addressLine2'].value && this.f['addressLine2'].value !== '') {
        address.addressLine2 = this.f['addressLine2'].value;
        address.lineTwo = this.f['addressLine1'].value;
      }

      if (this.f['city'].value && this.f['city'].value !== '') {
        address.city = this.f['city'].value;
      }

      if (this.f['state'].value && this.f['state'].value !== '') {
        address.state = this.f['state'].value;
        address.provinceState = this.f['state'].value;
      }

      if (this.f['postalCode'].value && this.f['postalCode'].value !== '') {
        address.postalCode = this.f['postalCode'].value;
      }

      personInfo.address = address;

      // Add the address to sessionStorage so that we can use it in the next step as well
      sessionStorage.setItem(this.REGISTER_ADDRESS, JSON.stringify(address));
    }

    return personInfo;
  }

  goBack(): void {
    // Back to REGISTRATION page
    this.router.navigateByUrl('/register-company');
  }

}
