import { Component, OnInit } from '@angular/core';
import { ResourceType } from 'src/app/core/models/reward/resource-type';
import { Rule } from 'src/app/core/models/reward/rule';
import { RuleResource } from 'src/app/core/models/reward/rule-resource';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { combineLatestWith, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RuleParameter } from 'src/app/core/models/reward/rule-parameter';
import { RuleSaveInfo, RuleSaveType } from 'src/app/core/models/reward/rule-save-info';
import { MatDialog } from '@angular/material/dialog';
import { DialogRuleComponent } from '../../_shared/dialog-rule/dialog-rule.component';
import { EvaluatorType } from 'src/app/core/models/reward/evaluator-type';
import { DialogAreYouSureComponent } from '../../_shared/dialog-are-you-sure/dialog-are-you-sure.component';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  working: boolean;
  rules: Rule[] = [];
  ruleResources: RuleResource[] = [];
  bonusTypeResources: ResourceType[] = [];
  resultTypeResources: ResourceType[] = [];
  evaluatorTypes: EvaluatorType[] = [];
  errorMessage: string;
  errorDetail: WsApiError;

  constructor(
    private rewardsService: RewardsService,
    private parseErrorService: ParseErrorService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.getRuleDataAndResources();
  }

  getRuleDataAndResources(): void {
    // Using combineLatest because need resources and rules to load at the same time (or sequentially)
    this.working = true;
    var bonusResources = this.rewardsService.bonusTypeResources;
    var resultResources = this.rewardsService.resultTypeResources;
    var rules = this.rewardsService.getRules();
    var ruleResources = this.rewardsService.getRuleResources();

    combineLatest([bonusResources, resultResources, rules, ruleResources]).subscribe({
      next: (response: any) => {
        this.bonusTypeResources = response[0];
        this.resultTypeResources = response[1];
        this.ruleResources = response[3];
        this.rules = response[2].items;

        // Get the evalTypes
        this.evaluatorTypes = Array.from(this.ruleResources).map(x => { return { name: x.name, evaluatorType: x.evaluatorType }; });

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });
  }

  // TODO:  Extract these to common helper
  resultType(code: string): string {
    return this.resultTypeResources?.find(x => x.code === code)?.name ?? code;
  }

  bonusType(code: string): string {
    return this.bonusTypeResources?.find(x => x.code === code)?.name ?? code;
  }

  evaluationType(code: string): string {
    // TODO:  Get this list from a RESOURCE?
    const evalTypes: { [key: string]: string } = {
      'Equals': '=',
      'GreaterThan': '>',
      'GreaterThanOrEquals': '>=',
      'LessThan': '<',
      'LessThanOrEquals': '<=',
      'NotEquals': '!='
    };

    return evalTypes[code] ?? code;
  }

  getActionParamValueFromRule(rule: Rule, paramName: string): string {
    return rule.actions.find(x => x.type === 'Success')?.parameters?.find(x => x.parameterName === paramName)?.value ?? '';
  }

  buildConditionParamDisplay(params: RuleParameter[]): string {
    if (!params) {
      return '';
    }

    const parts: string[] = [];
    params.forEach(item => {
      switch (item.parameterName) {
        case 'resultTypeCode':
          parts.push(this.resultType(item.value));
          break;
        case 'bonusTypeCode':
          parts.push(this.bonusType(item.value));
          break;
        case 'evaluationType':
          parts.push(this.evaluationType(item.value));
          break;
        case 'streakLength':
          parts.push(`streak of ${item.value}`);
          break;
        default:
          parts.push(item.value);
          break;
      }
    });

    return parts.join(' ');
  }

  buildActionParamDisplay(params: RuleParameter[]): string {
    if (!params) {
      return '';
    }

    let bonusType: string = this.bonusType(params.find(x => x.parameterName === 'bonusTypeCode')?.value);
    const bonusAmount: string = params.find(x => x.parameterName === 'bonusAmount')?.value;
    let resultType: string = this.resultType(params.find(x => x.parameterName === 'resultTypeCode')?.value);
    const resultAmount: string = params.find(x => x.parameterName === 'resultAmount')?.value;

    bonusType = bonusAmount === '1' ? bonusType.slice(0, -1) : bonusType;
    resultType = resultAmount === '1' ? resultType.slice(0, -1) : resultType;

    let response = `${resultAmount} ${resultType}`;
    if (bonusType) {
      response += ` - ${bonusAmount} ${bonusType}`;
    }
    return response;
  }

  todo(): void {
    window.alert('TODO');
  }

  openRuleDialog(selectedRule: Rule, newRule: boolean): void {
    const dialogRef = this.dialog.open(DialogRuleComponent, {
      autoFocus: false,
      height: 'calc(100vh - 80px)',
      maxWidth: '96vw',
      width: '802px',
      position: { top: '45px' },
      panelClass: 'pummelo-modal',
      data: {
        evaluator: selectedRule ? this.ruleResources.find(x => x.evaluatorType === selectedRule.evaluatorType) : null,
        evaluatorTypes: this.evaluatorTypes,
        selectedRule: selectedRule,
        bonusTypeResources: this.bonusTypeResources,
        resultTypeResources: this.resultTypeResources,
        newRule: newRule,
        ruleResources: newRule ? this.ruleResources : null,
        message: 'SAMPLE MESSAGE'
      }
    });

    dialogRef.afterClosed().subscribe((result: RuleSaveInfo | any) => {
      if (result) {  // result === '' means cancel button was clicked
        if (result.saveType === RuleSaveType.newRule) {
          // Add the rule to the array
          this.rules.unshift(result.rule);
        } else {
          // Update the existing rule
          const ruleToUpdate: Rule = this.rules.find(x => x.key === result.rule.key);
          if (ruleToUpdate) {
            ruleToUpdate.name = result.rule.name;
            ruleToUpdate.expressions = result.rule.expressions;
            ruleToUpdate.actions = result.rule.actions;
          }
        }
      }
    });
  }

  openConfirmTeamDeleteDialog(e: Event, ruleToDelete: Rule): void {
    const deleteElement = e.currentTarget as HTMLSpanElement;
    const deleteDiv: HTMLDivElement = deleteElement.parentElement.parentElement.querySelector('.rule-delete-overlay');

    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {
      autoFocus: false,
      position: { top: '10vh' },
      maxWidth: '430px',
      width: '430px',
      panelClass: 'are-you-sure',
      data: {
        deleteObjectName: ruleToDelete.name,
        deleteObjectTypeName: 'rule',
        message: 'This rule will be deleted from the system, including any user data associated with this rule.  Are you sure you want to proceed? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== '' && result.data && result.data.confirmDelete) {  // result === '' means cancel button was clicked

        deleteDiv.classList.add('show-deleting');

        // Call team service here to delete team
        this.rewardsService.deleteRule(ruleToDelete.key).subscribe({
          next: (response: any) => {
            // return the user to the main team page for display
            if (response) {
              // Update the list of rules for display on the page
              this.rules = this.rules.filter(x => x.key !== ruleToDelete.key);
            } else {
              // There is was a problem, display an error
              this.errorMessage = 'There was a problem with the request.';
              deleteDiv.classList.remove('show-deleting');
            }
          },
          error: (err: any) => {
            // Display the error
            this.errorDetail = this.parseErrorService.getFullApiError(err);
            this.errorMessage = this.errorDetail.errorMessage;
            deleteDiv.classList.remove('show-deleting');
          }
        });
      }
    });
  }

}
