import { Injectable } from '@angular/core';
import { RGBA } from '../models/rgba';

@Injectable()
export class BitColorHelper {
  numberToRgba(colorNumber: number): RGBA {
    var base2 = (colorNumber).toString(2);
    const parseArray = arr => {
      return arr.reduce((acc, val) => {
        return (acc << 1) | val;
      });
    };

    const arrRed = [base2[16], base2[17], base2[18], base2[19], base2[20], base2[21], base2[22], base2[23]];
    const arrGreen = [base2[8], base2[9], base2[10], base2[11], base2[12], base2[13], base2[14], base2[15]];
    const arrBlue = [base2[0], base2[1], base2[2], base2[3], base2[4], base2[5], base2[6], base2[7]];
    const arrAlpha = [base2[24], base2[25], base2[26], base2[27], base2[28], base2[29], base2[30], base2[31]];

    const rgba: RGBA = { red: parseArray(arrRed),
                         green: parseArray(arrGreen),
                         blue: parseArray(arrBlue),
                         alpha: parseArray(arrAlpha),     // I'm not sure the ALPHA value is accurate
                         //rgba: `rgba(${parseArray(arrRed)}, ${parseArray(arrGreen)}, ${parseArray(arrBlue)}, ${(parseArray(arrAlpha)/100)}`};
                         rgba: `rgba(${parseArray(arrRed)}, ${parseArray(arrGreen)}, ${parseArray(arrBlue)}, 1.0}`};
    // rgba.rgba = `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${(rgba.alpha/100)})`;

    return rgba;
    // console.log('R: ' + parseArray(arrRed));
    // console.log('G: ' + parseArray(arrGreen));
    // console.log('B: ' + parseArray(arrBlue));
    // console.log('A: ' + parseArray(arrAlpha));
  }

  rgbaToHex(red: number, green: number, blue: number, alpha: number): string {
    var outParts = [
      red.toString(16),
      green.toString(16),
      blue.toString(16),
      Math.round(alpha * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    })

    return ('#' + outParts.join(''));
  }
}
