<app-error-display [errorMessage]="errorMessage" style="display: flex;"></app-error-display>

<div class="evaluator" *ngIf="evaluator">
    <div class="eval-header">
        <h3>{{evaluator.name.replace('Test', 'Challenge')}}</h3>

        <div class="rule-display">
            <h4>{{f['ruleName'].value === '' ? 'Rule output display' : f['ruleName'].value}}</h4>

            <div class="rule-details">
                <div class="evaluator">
                    <ng-container *ngTemplateOutlet="evalDisplayTemplate; context: {evaluator: evaluator}"></ng-container>
                </div>
                <div class="equals">
                    <span class="fa-light fa-right-long fa-lg icon"></span>
                </div>
                <div class="award">
                    <span class="bonus-amt">
                        {{f['bonusAmount'].value === '' ? '00' : f['bonusAmount'].value | number:'1.0':'en-US'}}
                    </span>
                    <span class="bonus-type">
                        {{bonusType(f['bonusTypeCode'].value === '' ? '[reward type]' : f['bonusTypeCode'].value)}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <form class="eval-form" [formGroup]="evalForm" (ngSubmit)="evalForm.valid && saveEvalForm()" autocomplete="off">

        <div class="rule-row">
            <div class="rule-col title">
                <label>
                    Rule Name
                    <span class="required" *ngIf="isInvalid('ruleName')">{{getError(f['ruleName'].errors)}}</span>
                </label>            
                <input type="textbox" class="form-control" formControlName="ruleName" placeholder="rule name" value="" />
                <input type="hidden" formControlName="key" />
            </div>
        </div>

        <ng-container *ngFor="let expressionable of evaluator.expressionables">
            <div class="eval-type" *ngIf="expressionable.propertyOrMethod === 'EvaluateResultType'">
                <ng-container *ngTemplateOutlet="evalResultTemplate; context: {params: expressionable.parameters, form: evalForm, name: expressionable.displayName}"></ng-container>
            </div>

            <div class="eval-type" *ngIf="expressionable.propertyOrMethod === 'AllowedNumberOfAwards'">
                <ng-container *ngTemplateOutlet="evalAwardNumTemplate; context: {expressionable: expressionable, form: evalForm}"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngFor="let expressionable of evaluator.expressionables">
            <div class="eval-type" *ngIf="expressionable.propertyOrMethod === 'HasStreak'">
                <ng-container *ngTemplateOutlet="evalStreakTemplate; context: {expressionable: expressionable, form: evalForm}"></ng-container>
            </div>

            <div class="eval-type" *ngIf="expressionable.propertyOrMethod === 'HasPercentage'">
                <ng-container *ngTemplateOutlet="evalPercentageTemplate; context: {expressionable: expressionable, form: evalForm}"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngFor="let actionable of evaluator.actionables">
            <div class="eval-type" *ngIf="actionable.methodName === 'SetResult'">
                <ng-container *ngTemplateOutlet="rewardTemplate; context: {params: actionable.parameters, form: evalForm, name: actionable.displayName}"></ng-container>
            </div>
        </ng-container>

        <div class="form-row submit-form">
            <button type="submit" class="btn btn-primary save"
                [disabled]="isSaving()">
                <span *ngIf="isSaving()" class="far fa-spinner fa-pulse spinner"></span>
                <span *ngIf="!isSaving()" class="">save</span>
                <span *ngIf="isSaving()" class="">saving...</span>
            </button>
            <input type="button" class="btn btn-outline-dark" value="cancel" (click)="cancelEvaluatorForm()">
        </div>
    </form>
</div>

<ng-template #evalResultTemplate let-params="params" let-form="form" let-name="name">
    <ng-container [formGroup]="form">
        <div class="rule-row">
            <div class="label">
                <label>{{name}}</label>
            </div>
            <div class="input">
                <div class="rule-col rule-select">
                    <span class="required" *ngIf="isInvalid('resultTypeCode')">{{getError(f['resultTypeCode'].errors)}}</span>
                    <select class="form-select result-type-dd" formControlName="resultTypeCode">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of params[0].validValues" [ngValue]="type">{{resultType(type)}}</option>
                    </select>
                </div>
                <div class="rule-col rule-select eval-type">
                    <span class="required" *ngIf="isInvalid('evaluationType')">{{getError(f['evaluationType'].errors)}}</span>
                    <select class="form-select eval-type" formControlName="evaluationType">
                        <option [ngValue]="null" disabled selected hidden></option>
                        <option *ngFor="let type of params[1].validValues" [ngValue]="type">{{evaluationType(type)}}</option>
                    </select>
                </div>
                <div class="rule-col number last">
                    <span class="required" *ngIf="isInvalid('amount')">{{getError(f['amount'].errors)}}</span>
                    <input type="number" class="form-control number" formControlName="amount" placeholder="amount" value="" />
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #evalAwardNumTemplate let-expressionable="expressionable" let-form="form">
    <div class="rule-row" [formGroup]="form">
        <ng-container *ngFor="let param of expressionable?.parameters">
            <div class="rule-col number" *ngIf="param.parameterName === 'numberOfAwards'">
                <label>{{expressionable.displayName}}</label>
                <input type="number" class="form-control number" formControlName="numberOfAwards" placeholder="# of awards" value="" />
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #evalStreakTemplate let-expressionable="expressionable" let-form="form">
    <div class="rule-row" [formGroup]="form">
        <div class="rule-col number">
            <label>
                {{expressionable.displayName}}
                <span class="required" *ngIf="isInvalid('streakLength')">{{getError(f['streakLength'].errors)}}</span>
            </label>
            <ng-container *ngFor="let param of expressionable?.parameters">
                <ng-container *ngIf="param.parameterName === 'streakLength'">
                    <input type="number" class="form-control number" formControlName="streakLength" placeholder="streak length" value="" />
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #evalPercentageTemplate let-expressionable="expressionable" let-form="form">
    <div class="rule-row" [formGroup]="form">
        <div class="rule-col number">
            <label>
                {{expressionable.displayName}}
                <span class="required" *ngIf="isInvalid('percentage')">{{getError(f['percentage'].errors)}}</span>
            </label>
            <ng-container *ngFor="let param of expressionable?.parameters">
                <ng-container class="param-item eval-amt" *ngIf="param.parameterName === 'percentage'">
                    <input type="number" class="form-control number" formControlName="percentage" placeholder="%" value="" />
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #rewardTemplate let-params="params" let-form="form" let-name="name">
    <div class="rule-row" [formGroup]="form">
        <div class="label">
            <label>{{name}}</label>
        </div>
        <div class="input">
            <div class="rule-col number">
                <label>award amount/type</label>
                <input type="number" class="form-control number" formControlName="bonusAmount" placeholder="amount" value="" />
                <span class="required" *ngIf="isInvalid('bonusAmount')">{{getError(f['bonusAmount'].errors)}}</span>
            </div>
            <div class="rule-col rule-select">
                <label>&nbsp;</label>
                <span class="required" *ngIf="isInvalid('bonusTypeCode')">{{getError(f['bonusTypeCode'].errors)}}</span>
                <select class="form-select bonus-type-dd" formControlName="bonusTypeCode">
                    <option [ngValue]="null" disabled selected hidden></option>
                    <option *ngFor="let type of params[0].validValues" [ngValue]="type">{{bonusType(type)}}</option>
                </select>
            </div>

            <div class="rule-col number new-group">
                <label>completions</label>
                <span class="required" *ngIf="isInvalid('resultAmount')">{{getError(f['resultAmount'].errors)}}</span>
                <input type="number" class="form-control number" formControlName="resultAmount" placeholder="amount" value="" />
            </div>
            <div class="rule-col rule-select last">
                <label>&nbsp;</label>
                <span class="required" *ngIf="isInvalid('resultTypeCodeActionable')">{{getError(f['resultTypeCodeActionable'].errors)}}</span>
                <select class="form-select result-type-dd" formControlName="resultTypeCodeActionable">
                    <option [ngValue]="null" disabled selected hidden></option>
                    <option *ngFor="let type of params[2].validValues" [ngValue]="type">{{resultType(type)}}</option>
                </select>
            </div>
        </div>
    </div>
        
</ng-template>

<ng-template #evalDisplayTemplate let-evaluator="evaluator">
    <ng-container *ngIf="evaluator.evaluatorType === EVAL_TYPES.results">
            <span class="eval-result-type">
                {{resultType(f['resultTypeCode'].value === '' ? '[challenge type]' : f['resultTypeCode'].value)}}
            </span>
            <span class="eval-type">
                {{evaluationType(f['evaluationType'].value === '' ? '??' : f['evaluationType'].value)}}
            </span>
            <span class="eval-amt">
                {{f['amount'].value === '' ? '00' : f['amount'].value}}
            </span>
    </ng-container>

    <ng-container *ngIf="evaluator.evaluatorType === EVAL_TYPES.activities">
        <span class="activity-completed">completed</span>
    </ng-container>

    <ng-container *ngIf="evaluator.evaluatorType === EVAL_TYPES.awards && f['streakLength'].value !== ''">
        <span class="eval-text">streak of</span>
        <span class="eval-amt">{{f['streakLength'].value}}</span>
    </ng-container>

    <ng-container *ngIf="evaluator.evaluatorType === EVAL_TYPES.awards && f['percentage'].value !== ''">
        <span class="eval-amt">{{f['percentage'].value}}%</span>
        <span class="eval-text">complete</span>
    </ng-container>

    <ng-container *ngIf="evaluator.evaluatorType === EVAL_TYPES.awards && f['streakLength'].value === '' && f['percentage'].value === ''">
        <span class="eval-text">streak of</span>
        <span class="eval-amt">00</span>
    </ng-container>
</ng-template>

