import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartComponentLike, ChartConfiguration, ChartOptions } from 'chart.js';
import { AppUser } from 'src/app/core/models/app-user';
import { UserPlanActivitySummary } from 'src/app/core/models/reward/user-plan-activity-summary';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';

@Component({
  selector: 'app-user-activity-summary',
  templateUrl: './user-activity-summary.component.html',
  styleUrls: ['./user-activity-summary.component.scss']
})
export class UserActivitySummaryComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;

  activitySummary: UserPlanActivitySummary[];

  // Chart components
  lineChartData: ChartConfiguration<'line'>['data'] = { datasets: [] };
  lineChartOptions: ChartOptions<'line'> = {};
  lineChartLegend = false;
  lineChartPlugins: ChartComponentLike[] = [];

  // Chart params
  today: Date;
  endDate: Date;

  chartHeaderColors: string[];

  @Input() public user: AppUser;
  @Input() public childComponentActivitySummary: UserPlanActivitySummary[];
  @Output() updateParentUserActivitySummary = new EventEmitter<UserPlanActivitySummary[]>();

  constructor(
    private rewardService: RewardsService,
    private datePipe: DatePipe,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;

    this.chartHeaderColors = AVATAR_COLORS;
    this.loadDefaultChartConfigurations();

    // Default date range is the past 6 months
    this.today = new Date();
    this.endDate = new Date();
    this.today.setMonth(this.today.getMonth() - 6);

    if (!this.childComponentActivitySummary) {
      this.getActivitySummary(this.today, this.endDate);
    } else {
      this.activitySummary = this.childComponentActivitySummary;
      this.convertActivityToChart();
    }

  }

  getActivitySummary(startDate: Date, endDate: Date, evaluationType: string = 'ByOccurrence', timeframeType: string = 'Month'): void {
    this.working = true;

    this.rewardService.getUserPlanActivityByDateRange(this.user.key, startDate, endDate, evaluationType, timeframeType).subscribe({
      next: (response: UserPlanActivitySummary[] | any) => {
        this.activitySummary = response;

        this.activitySummary.forEach(item => {
          // API returns data points newest to oldest, need to reverse the array so it
          // appears correctly on the chart
          // However, only reverse this on the initial load from the API
          item.entries.reverse();
        });

        this.updateParentUserActivitySummary.emit(this.activitySummary);

        this.convertActivityToChart();

        this.working = false;
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });

  }

  convertActivityToChart(): void {
    this.translateActivitySummaryToChartData();

    // Set the BG color
    let iBg = 1;
    this.activitySummary?.forEach((summary: UserPlanActivitySummary) => {
      summary.bgColor = this.chartHeaderColors[iBg];
      iBg++;
      if (iBg === this.chartHeaderColors.length) {
        iBg = 0;
      }
    });
  }

  loadDefaultChartConfigurations(): void {
    const labelFontColor = 'rgba(30, 30, 30, 0.4)';
    const labelFontSize = 14;  // in pixels
    const labelFontFamily = 'Raleway';

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          ticks: {
            // callback: function (val, index) {
            //   // Hide every 2nd tick label
            //   return index % 2 === 0 ? '' : this.getLabelForValue(parseInt(val.toString()));
            // },
            autoSkip: true,
            color: labelFontColor,
            font: {
              size: labelFontSize,
              family: labelFontFamily
            },
            maxRotation: 0,
            minRotation: 0
          }
        },
        y: {
          beginAtZero: true,
          grace: '5%',
          max: 100,
          min: 0,
          stacked: false,
          suggestedMax: 100,
          suggestedMin: 0,
          ticks: {
            autoSkip: true,
            callback: function (value) { return value + '%' },
            color: labelFontColor,
            font: {
              size: labelFontSize,
              family: labelFontFamily
            }
          }
        }
      }
    };

    this.lineChartLegend = false;

  }

  loadChartData(dataLabels: string[], dataPoints: any[]): ChartConfiguration<'line'>['data'] {
    const chartLineColor = '#489BE8';
    const chartLineWidth = 5;
    const pointLineBgColor = '#fff';
    const pointLineBorderColorHover = '#418cd1';
    const pointLineBgColorHover = '#9bed4c';  // This pink also looks good #fa949e

    return {
      labels: dataLabels,
      datasets: [
        {
          data: dataPoints,
          // label: 'Series A',
          fill: 'origin',
          borderColor: chartLineColor,
          borderCapStyle: 'butt',
          borderWidth: chartLineWidth,
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: chartLineColor,
          pointBackgroundColor: pointLineBgColor,
          pointBorderWidth: 2,
          pointRadius: 5,
          pointHoverRadius: 5,
          pointHoverBorderColor: pointLineBorderColorHover,
          pointHoverBorderWidth: 2,
          pointHoverBackgroundColor: pointLineBgColorHover,
          pointHitRadius: 10
        }
      ]
    };

  }

  chartPlugins(chartId: string, dataPoints: any[]): ChartComponentLike[] {
    // const maxArrayItem: any = Math.max(...dataPoints);
    // const maxValue: number = !isNaN(parseFloat(maxArrayItem)) ? parseFloat(maxArrayItem) + 10 : 110;
    // NOTE:  The gradient fill createLinearGradient(x0, y0, x1, y1).  Starting point (0, 0) is top left
    //        of the entire canvas, which is 340x130 for this page.  The graph itself goes from (47, 11)
    //        from the top left (x,y) to (267, 89).  So the gradient goes from top to bottom.  In this case
    //        it starts at (0,40) and ends at (0, 130) from the top left.

    // Add gradient fill for chart
    return [{
      id: chartId,
      afterLayout: chart => {
        var ctx = chart.ctx;
        // var gradient = ctx.createLinearGradient(0, 0, 0, maxValue);
        var gradient = ctx.createLinearGradient(0, 40, 0, 130);
        gradient.addColorStop(0, '#489BE8');
        //gradient.addColorStop(0.45, 'rgba(72, 155, 232, 0.5)');
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0.1');
        var dataset = chart.data.datasets[0];
        dataset.backgroundColor = gradient;
      }
    }];
  }

  translateActivitySummaryToChartData(): void {
    if (!this.activitySummary) {
      return;
    }

    this.activitySummary.forEach(item => {
      const labels: string[] = [];
      const dataPoints: any[] = [];

      item.entries.forEach(entry => {
        labels.push(this.datePipe.transform(entry.date, 'MMM-yy').toUpperCase());
        dataPoints.push(entry.dataPoint);
      });
      item.chartLabels = labels;
      item.chartDataPoints = dataPoints;
      item.dataPointAverage = this.getAverageFromArray(dataPoints);
    });
  }

  getAverageFromArray(chartDataPoints: any[]): number {
    const sum = chartDataPoints.reduce((a, c) => a + c, 0);

    if (isNaN(sum)) {
      return null;
    }
    const avg = Math.round(sum / chartDataPoints.length);

    return avg;
  }

  changeEvaluationType(evaluationType: string): void {
    this.getActivitySummary(this.today, this.endDate, evaluationType);
  }

}
