import { Injectable } from '@angular/core';
import { Badge } from '../models/badge';
import { Image } from '../models/image';

@Injectable()
export class HelperImage {

    async createImageObject(badge: Badge, badgePath: string): Promise<Image> {
        let imageToUpdate: Image;
        const imageBase64String = await this.getBase64FromBadgeImage(badge, badgePath);
        if (imageBase64String !== '') {
            imageToUpdate = {
                mimeType: 'image/png',
                data: imageBase64String
            };
        }
        return imageToUpdate;
    }

    async getBase64FromBadgeImage(badge: Badge, badgePath: string): Promise<string | any> {
        const imageResponse = await fetch(badgePath + badge.fileName);
        if (imageResponse.status === 200) {
            const imageBlob = await imageResponse.blob();

            const resultBase64 = await new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.onloadend = (e) => {
                    const result: string = fileReader.result.toString().length > 0 ? fileReader.result.toString().replace('data:image/png;base64,', '') : '';
                    resolve(result);
                };
                fileReader.onerror = () => resolve('');
                fileReader.readAsDataURL(imageBlob);
            });

            return resultBase64;
        } else {
            return new Promise((resolve) => { resolve(''); });
        }
    }

}
