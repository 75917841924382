import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { AppUser } from 'src/app/core/models/app-user';
import { SubscriptionPlan } from 'src/app/core/models/registration/subscription-plan';
import { SubscriptionProduct } from 'src/app/core/models/registration/subscription-product';
import { TenantAccount } from 'src/app/core/models/registration/tenant-account';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-register-plan',
  templateUrl: './register-plan.component.html',
  styleUrls: ['../register.component.scss', './register-plan.component.scss']
})
export class RegisterPlanComponent implements OnInit {
  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: number;

  user: AppUser;
  tenantAccount: TenantAccount;
  appPlans: SubscriptionPlan[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentService,
    private subscriptionService: SubscriptionService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = -1;

    this.getUser();
    this.getTenantAccountAndPlans();

    // FOR TESTING ONLY
    // this.subscriptionService.getPlansTEMP().subscribe({
    //   next: (response: SubscriptionPlan[] | any) => {
    //     this.appPlans = response;
    //     this.addUiProperties();
    //   },
    //   error: (err: any) => {
    //     this.errorMessage = this.parseErrorService.parseApiError(err);
    //   }
    // });

  }

  getUser(): void {
    this.authService.getRegisterUser().subscribe({
      next: (response: any) => {
        this.user = response.data;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  getTenantAccountAndPlans(): void {

    this.paymentService.getTenantAccount().pipe(
      concatMap((response: TenantAccount | any) => {
        this.tenantAccount = response;

        return this.subscriptionService.getPlans();
      })
    ).subscribe({
      next: (response: SubscriptionPlan | any) => {
        this.appPlans = response;

        this.addUiProperties();
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  addUiProperties(): void {
    if (this.appPlans && this.appPlans.length > 0) {
      this.appPlans.forEach(plan => {

        if (plan && plan.name) {
          if (plan.name.toLocaleLowerCase().includes('basic')) {
            plan.headerBackgroundColor = '#70C5EA';
            plan.descriptionText = 'A good choice for smaller businesses who don’t wish to provide in-app sales to customers';
          };
          if (plan.name.toLocaleLowerCase().includes('standard')) {
            plan.headerBackgroundColor = '#489BE8';
            plan.descriptionText = '';
          };
          if (plan.name.toLocaleLowerCase().includes('premium')) {
            plan.headerBackgroundColor = '#276EAF';
            plan.descriptionText = 'Perfect for large businesses that desire premium support and the ability to monetize direct sales to customers';
          };
        }

      });
    }
  }

  getPlanCost(products: SubscriptionProduct[]) {
    if (products && products.length > 0) {
      const priceNode = products.filter(x => x.code === 'SUBSCRIPTION.APP:ACCESS');
      if (priceNode && priceNode.length > 0) {
        return priceNode[0].amount;
      }
    }
    return 0;
  }

  planHasCode(products: SubscriptionProduct[], code: string) {
    if (products) {
      const itemFound = products.find(x => x.code === code);
      if (itemFound) {
        return true;
      }
    }

    return false;
  }

  getPlanBgColor(headerBgColor: string) {
    if (headerBgColor && headerBgColor.length > 0) {
      return headerBgColor;
    } else {
      return '#489BE8';
    }
  }

  selectPlan(planKey: string, planIndex: number): void {
    this.saving = planIndex;

    this.subscriptionService.selectPlan(planKey).subscribe({
      next: (response: boolean | any) => {
        if (response) {
          //this.router.navigateByUrl('/register/merchant-details');
          this.router.navigateByUrl('/register/register-complete');
        }

        this.errorMessage = 'Something happened. Please try again.';
        this.saving = -1;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = -1;
      }
    });

  }

  goBack(): void {
    // Back to COMPANY DETAILS page
    this.router.navigateByUrl('/register/company-contact');
  }

}
