import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatCurrency, getLocaleCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'dynamicCurrency'
})
export class DynamicCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(
    value: number,
    currencyCode: string,
    display: 'code' | 'symbol' | 'symbol-narrow' = 'symbol',
    digitsInfo?: string): string | null {
      // Customize formatting based on currency code
      if (currencyCode === 'PTS') {
        return `${value.toFixed(0)}pts`; // Custom formatting for pts
      }

      // Default to built-in currency pipe
      return formatCurrency(value, this.localeId, getLocaleCurrencySymbol(this.localeId), currencyCode, digitsInfo);
  }

}
