import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { WsApiError } from 'src/app/core/models/ws-api-error';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {

  @Input() public errorDetail: WsApiError;
  @Input() public errorMessage: string;
  @Input() public errorSize: string;
  @Input() public errorWidth: string;
  // @Input() public title: string;
  isDev: boolean;
  showDetail: boolean;

  constructor() {}

  ngOnInit(): void {
    this.showDetail = false;
    this.isDev = isDevMode();
  }

}
