<div class="milestone-header customer-actions">
    <h3>Milestones</h3>
    
    <div class="sort action-item" *ngIf="!working && (userPlan?.levels || userPlan?.levels?.length > 0)">
        <label>show</label>
        <div class="btn-group">
            <input type="radio" class="btn-check" name="sortby" id="all" checked>
            <label class="btn btn-outline-primary" for="all">All</label>

            <input type="radio" class="btn-check" name="sortby" id="open">
            <label class="btn btn-outline-primary" for="open">Open</label>

            <input type="radio" class="btn-check" name="sortby" id="done">
            <label class="btn btn-outline-primary" for="done">Done</label>
        </div>
    </div>

    <a [routerLink]="[]" class="new action-item first"  
        (click)="showAddMilestone = !showAddMilestone"
        *ngIf="!working && (userPlan?.levels || userPlan?.levels?.length > 0)">
        <span class="fal fa-plus icon"></span>
        add
    </a>
</div>

<div class="add-plan-item add-milestone">
    <ng-container *ngTemplateOutlet="addUserMilestone"></ng-container>
</div>

<app-loading loadingText="loading milestones..." alignText="left" loadingSize="lg" *ngIf="working"></app-loading>
<app-error-display [errorMessage]="errorMessage" class="pml-header-msg justify-left"></app-error-display>

<div class="none-found" *ngIf="!working && (!userPlan?.levels || userPlan?.levels?.length === 0)">
    <span class="fa-light fa-face-unamused icon fa-lg"></span>
    No milestones found for
    <span class="user-name">
        <ng-container *ngTemplateOutlet="userNameDisplay; context: {user: user}"></ng-container>
    </span>
</div>

<div class="milestones" *ngIf="!working && userPlan && userPlan.levels">
    <!-- <div class="plan-name">{{userPlan.name}}</div> -->
    <div class="milestone" *ngFor="let level of userPlan?.levels; let i = index">
        <div class="milestone-content" [matMenuTriggerFor]="menu">
            <div class="icon-container {{level.completed ? '' : 'disabled'}}" [style.background-color]="level.imageBgColor">
                <img src="{{level.imageUrl}}" class="icon" alt="{{level.name}}" />
                <div class="point-coin {{level.completed ? 'complete' : ''}}">
                    <span class="amount-value">{{level.potentialResults?.success?.bonusAmount}}</span>
                </div>
                <span class="fa-duotone fa-loader fa-spin-pulse fa-2x spinner" *ngIf="savingMilestone === i"></span>
            </div>
            <div class="name">
                {{level.name}}
                
                <div class="completed" *ngIf="level.completed">
                    Earned {{level.completions[0]?.applicableDate | date: "dd MMM ''yy"}}
                </div>
            </div>
        </div>

        <mat-menu #menu class="pml-menu milestone-menu">
            <ng-container *ngTemplateOutlet="challengeMenuTemplate; context: {menuName: '#menu', level: level, user: user, i: i, areYouSureMenu: areYouSureMenu}"></ng-container>
        </mat-menu>

        <mat-menu #areYouSureMenu class="are-you-sure-menu wide" yPosition="above">
            <div class="small-are-you-sure">
                <div class="confirm-row">
                    <div class="graphic">
                        <span class="fa-light fa-face-thinking fa-2x icon"></span>
                    </div>
                    <div class="text">
                        Do you really want to <span class="emphasis">permanently delete</span> this milestone?
                    </div>
                </div>
                <div class="actions">
                    <a [routerLink]="[]" class="dont">Don't delete</a>
                    <a [routerLink]="[]" class="btn btn-danger" (click)="deleteUserMilestone(user.key, level.key, $event, i)">Yes, delete milestone</a>
                </div>
            </div>
        </mat-menu>
    </div>
</div>

<ng-template #userNameDisplay let-user="user">
    <ng-container *ngIf="user && user.info && user.info.name && user.info.name.first && user.info.name.last; else elseBlock">
        {{user.info.name.first}} {{user.info.name.last}}
    </ng-container>
</ng-template>
<ng-template #elseBlock let-user="user">
    <ng-container *ngIf="user.displayName">
        {{user.displayName}}
    </ng-container>
    <ng-container *ngIf="!user.displayName">
        <span class="this-user">this user</span>
    </ng-container>
</ng-template>

<ng-template #challengeMenuTemplate let-menuName="menuName" let-level="level" let-user="user" let-i="i" let-areYouSureMenu="areYouSureMenu">
    <button mat-menu-item (click)="markUserMilestoneAsComplete(level.key, user.key, $event, i)" *ngIf="!level.completed">
        <span class="text">Mark complete</span>
        <img src="assets/images/icon-check-mark.svg" class="icon" title="mark as done" />
    </button>
    <button mat-menu-item (click)="markUserMilestoneAsNotComplete(user.key, level.key, level.completions[0]?.key, $event, i)" *ngIf="level.completed">
        <span class="text">Not complete</span>
        <span class="fa-solid fa-arrow-rotate-left fa-lg icon fa-icon"></span>
    </button>
    <!-- <button mat-menu-item>
        <span class="text">Edit</span>
        <img src="assets/images/icon-edit.svg" class="icon" title="Edit" />
    </button>
    <button mat-menu-item>
        <span class="text">Add new</span>
        <img src="assets/images/icon-add.svg" class="icon" title="Add new challenge" />
    </button> -->
    <button mat-menu-item class="last delete" [matMenuTriggerFor]="areYouSureMenu">
        <span class="text">Remove</span>
        <img src="assets/images/icon-delete.svg" class="icon" title="Delete challenge" />
    </button>
</ng-template>

<ng-template #addUserMilestone>
    <form [formGroup]="newMilestoneForm" class="pml-form" (ngSubmit)="newMilestoneForm.valid && addMilestoneToPlan()" autocomplete="off">
        <div class="pml-row add-item-row first" *ngIf="showAddMilestone && !workingResources">
            <div class="pml-col add-badge">
                <ng-container *ngTemplateOutlet="badgeSelectionBtnTemplate; 
                    context: {section: 'levels', selectedBadge: selectedMilestoneBadge, showBadgeSelection: showMilestoneBadges}"></ng-container>    
            </div>
            <div class="pml-col">
                <ng-container *ngTemplateOutlet="taskDropdownTemplate; context: {form: newMilestoneForm, controlName: 'levelTask', label: 'Task', formGet: f}"></ng-container>
            </div>
            <div class="pml-col actions">
                <input type="hidden" formControlName="levelInterval" value="Ever" />
                <input type="hidden" formControlName="levelOccurrence" value="1" />
                <input type="hidden" formControlName="levelRequiresReview" value="true" />

                <button type="submit" class="btn btn-primary nested-submit">
                    <span *ngIf="savingAddMilestone" class="far fa-spinner fa-pulse fa-sm spinner"></span>
                    <span *ngIf="!savingAddMilestone" class="">add</span>
                    <span *ngIf="savingAddMilestone" class="">adding...</span>
                </button>
            </div>
        </div>
        <div class="pml-row add-item-row add-item-icon" *ngIf="showAddMilestone && !workingResources">
            <ng-container *ngTemplateOutlet="badgeSelectionTemplate; 
                context: {form: newMilestoneForm, badgeControlName:'levelBadgeName', formGet: f, section: 'levels', selectedBadge: selectedMilestoneBadge, showBadgeSelection: showMilestoneBadges}"></ng-container>
        </div>
    </form>
</ng-template>

<ng-template #taskDropdownTemplate let-form="form" let-controlName="controlName" let-label="label" let-formGet="formGet">
    <ng-container [formGroup]="form">
        <label>{{label}}</label>
        <span class="required" *ngIf="isInvalid(formGet[controlName])">{{getError(formGet[controlName].errors)}}</span>
        <select class="form-select bonus-type-dd" formControlName="{{controlName}}">
            <option [ngValue]="null" [disabled]="true" [selected]="true" >Select a Task</option>
            <option *ngFor="let task of templateTasks" [ngValue]="task.key">{{task.name}}</option>
        </select>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionTemplate 
    let-form="form" 
    let-badgeControlName="badgeControlName" 
    let-formGet="formGet" 
    let-section="section"
    let-selectedBadge="selectedBadge"
    let-showBadgeSelection="showBadgeSelection">
    <ng-container [formGroup]="form">
        <div class="pml-col">
            <input type="hidden" [formControlName]="badgeControlName" value="" />

            <app-choose-badge [selectedBadge]="selectedBadge" 
                (closeBadgeEvent)="showMilestoneBadges = false"
                (selectBadgeEvent)="updateItemWithBadge($event, formGet[badgeControlName], section)" 
                *ngIf="showBadgeSelection"></app-choose-badge>
        </div>
    </ng-container>
</ng-template>

<ng-template #badgeSelectionBtnTemplate let-section="section" let-selectedBadge="selectedBadge" let-showBadgeSelection="showBadgeSelection">
    <span class="fa-stack fa-xs {{showBadgeSelection ? 'active' : ''}}" (click)="showMilestoneBadges = !showMilestoneBadges" 
          title="{{!selectedBadge ? 'Add Icon' : 'Change Icon'}}" 
          *ngIf="!selectedBadge">
        <i class="fa-light fa-image fa-stack-2x"></i>
        <i class="fa-regular fa-circle-plus fa-stack-1x"></i>
    </span>
    <img src="{{BADGE_PATH}}{{selectedBadge?.fileName}}" 
        title="{{selectedBadge?.name}}" 
        class="selected-badge {{showBadgeSelection ? 'active' : ''}}" 
        (click)="showMilestoneBadges = !showMilestoneBadges"
        *ngIf="selectedBadge" />
</ng-template>