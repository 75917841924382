import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Pummelo';
  isLoggedIn: boolean;
  isRegistrationFlow: boolean;
  IS_REGISTRATION_FLOW: string = 'isRegistrationFlow';

  constructor(private authService: AuthService) {
    this.isLoggedIn = false;
  }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    });

    // Check to see if app is in registration flow
    this.isRegistrationFlow = this.isAppInRegistrationFlow();
  }

  isAppInRegistrationFlow(): boolean {
    return sessionStorage.getItem(this.IS_REGISTRATION_FLOW) && sessionStorage.getItem(this.IS_REGISTRATION_FLOW) === 'true' ? true : false;
  }

}
