import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AVATAR_COLORS } from 'src/app/core/constants/avatar-colors';
import { AVATARS } from 'src/app/core/constants/avatars';
import { BitColorHelper } from 'src/app/core/helpers/bit-color';
import { AdditionalInfo } from 'src/app/core/models/additional-info';
import { AppUser } from 'src/app/core/models/app-user';
import { UserInfo } from 'src/app/core/models/user-info';
import { Avatar } from 'src/app/core/models/avatar';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-choose-avatar',
  templateUrl: './choose-avatar.component.html',
  styleUrls: ['./choose-avatar.component.scss']
})
export class ChooseAvatarComponent implements OnInit {

  AVATAR_TAB = 'avatarTab';  // Setting as CONST to avoid spelling errors
  AVATAR_COLOR_TAB = 'avatarColorTab';
  IMG_PATH = 'assets/images/avatars/';
  DEFAULT_IMG = 'default.png';

  userAvatar: Avatar;
  allAvatars: Avatar[];
  avatarBgColors: string[];
  activeTab: string;

  working: boolean;
  errorMessage: string;

  @Input() public user: AppUser;
  @Input() public isNewUser: boolean;
  @Output() closeAvatarEvent = new EventEmitter();
  @Output() previewAvatarEvent = new EventEmitter<Avatar>();
  @Output() updateAvatarEvent = new EventEmitter<Avatar>();
  @Output() resetAvatarToOriginalEvent = new EventEmitter();

  constructor(
    private userService: UserService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;

    // Get current avatar
    this.userAvatar = this.getCurrentAvatar(this.user);

    this.allAvatars = AVATARS;
    this.avatarBgColors = AVATAR_COLORS;
    this.activeTab = this.AVATAR_TAB;
  }

  getCurrentAvatar(user: AppUser): Avatar {
    let avatarFromUserInfo: Avatar;
    if (this.user?.info?.additionalInfo) {
      if (this.user.info.additionalInfo['avatarColor']) {
        // Get the avatar color and convert to a usable RGBA format
        const color = this.user.info.additionalInfo['avatarColor'];
        avatarFromUserInfo = { color: color };
      }

      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        const fileName: string = this.user.info.additionalInfo['avatar'];
        const name: string = fileName.substring(0, fileName.indexOf('.'));
        avatarFromUserInfo = { name: name, fileName: fileName };
      }
    }

    return avatarFromUserInfo ?? null;
  }


  checkForExistingAvatarColor(): string {
    let existingColor: string;

    if (this.user?.info?.additionalInfo) {
      const color = this.user.info.additionalInfo['avatarColor'];
      existingColor = color;
    }
    return existingColor;
  }

  previewAvatar(avatar: Avatar): void {
    // Have to clone so that if the user has already selected a color and we do the
    // color check, the original object doesn't also get updated (reference pointer)
    const avatarClone: Avatar = { ...avatar };  // clones the avatar object

    // TODO:  Taking this out for now.  Seems to make more sense to show the
    //        default color for a new avatar
    // // Check for color
    // const existingColor = this.checkForExistingAvatarColor();
    // if (existingColor && existingColor.length > 0) {
    //   avatarClone.color = existingColor;
    // }

    this.previewAvatarEvent.emit(avatarClone);
  }

  resetAvatarToOriginal(): void {
    this.resetAvatarToOriginalEvent.emit();
  }

  updateAvatar(avatar: Avatar): void {
    const avatarClone: Avatar = { ...avatar };

    // TODO:  Taking this out for now.  Seems to make more sense to show the
    //        default color for a new avatar
    // // Check for color
    // const existingColor = this.checkForExistingAvatarColor();
    // if (existingColor && existingColor.length > 0) {
    //   avatarClone.color = existingColor;
    // }

    this.updateUserWithAvatar(avatarClone);
    this.updateAvatarEvent.emit(avatarClone);
  }

  previewAvatarBgColor(color: string): void {
    if (!this.userAvatar) {
      this.userAvatar = { color: color };
    } else {
      this.userAvatar.color = color;
    }
    this.previewAvatarEvent.emit(this.userAvatar);
  }

  updateAvatarBg(color: string): void {
    if (!this.userAvatar) {
      this.userAvatar = { color: color, fileName: this.DEFAULT_IMG };
    } else {
      this.userAvatar.color = color;
      if (!this.userAvatar.fileName) {
        this.userAvatar.fileName = this.DEFAULT_IMG;
      }
    }
    this.updateUserWithAvatarBg(this.userAvatar);
    this.updateAvatarEvent.emit(this.userAvatar);
  }

  closeAvatar(): void {
    this.closeAvatarEvent.emit();
  }

  updateUserWithAvatar(avatar: Avatar): void {
    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          avatar: avatar.fileName,
          avatarColor: avatar.color
          // pummelo: {
          //   avatar: avatar.fileName,
          //   avatarColor: avatar.color
          // }
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        avatar: avatar.fileName,
        avatarColor: avatar.color
        // pummelo: {
        //   avatar: avatar.fileName,
        //   avatarColor: avatar.color
        // }
      };
    } else {
      // this.user.info.additionalInfo.pummelo.avatar = avatar.fileName;
      // this.user.info.additionalInfo.pummelo.avatarColor = avatar.color;
      this.user.info.additionalInfo['avatar'] = avatar.fileName;
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
    }

    this.saveAvatar({ additionalInfo: this.user.info.additionalInfo });
  }

  updateUserWithAvatarBg(avatar: Avatar): void {
    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          avatarColor: avatar.color,
          avatar: this.DEFAULT_IMG
          // pummelo: {
          //   avatarColor: avatar.color,
          //   avatar: this.DEFAULT_IMG
          // }
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        avatarColor: avatar.color,
        avatar: this.DEFAULT_IMG
        // pummelo: {
        //   avatarColor: avatar.color,
        //   avatar: this.DEFAULT_IMG
        // }
      };
    } else {
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
      if (!this.user.info.additionalInfo['avatar']) {
        this.user.info.additionalInfo['avatar'] = this.DEFAULT_IMG;
      }
    }

    this.saveAvatar({ additionalInfo: this.user.info.additionalInfo });
  }

  saveAvatar(userInfo: UserInfo): void {
    if (this.isNewUser) {
      return;
    }

    this.working = true;

    this.userService.updateUserInfoByKey(this.user.key, userInfo).subscribe({
      next: (response: UserInfo | any) => {
        this.user.info = response;

        // Set the observable avatar and avatarBg values IF the item updated
        // is for the current logged in user
        if (this.user.key === this.userService.userValue.key) {
          if (userInfo.additionalInfo['avatar'] && userInfo.additionalInfo['avatar'].length > 0) {
            this.userService.currentUserAvatarValue = userInfo.additionalInfo['avatar'];
          }

          if (userInfo.additionalInfo['avatarColor'] && userInfo.additionalInfo['avatarColor'].length > 0) {
            this.userService.currentUserAvatarBgValue = userInfo.additionalInfo['avatarColor'];
          }
        }

        this.working = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.working = false;
      }
    });
  }

}
