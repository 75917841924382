<div class="choose-badge">
    <div class="header-row">
        <h3>Pick a badge</h3>

        <span class="fal fa-times close" (click)="closeBadge()"></span>
    </div>

    <div class="badges">
        <ng-container *ngFor="let badge of badges">
            <div class="badge {{selectedBadge?.name === badge?.name ? 'active' : ''}}" style.backgroundColor="{{badge.color}}" (click)="selectBadge(badge)">
                <img src="{{IMG_PATH}}{{badge.fileName}}" title="{{badge.name}}" />
            </div>
        </ng-container>
    </div>
</div>