import { Product } from './product';

export interface ProductSaveInfo {
    product: Product;
    saveType: ProductSaveType
    existingProductKey?: string;
}

export enum ProductSaveType {
    NEW_PRODUCT = 'NewProduct',
    UPDATED_PRODUCT = 'UpdatedProduct',
    NEW_FULFILLABLE_PRODUCT = 'NewFulfillableProduct',
}
