import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WsApiError } from '../models/ws-api-error';

@Injectable({
  providedIn: 'root'
})
export class ParseErrorService {

  constructor() { }

  parseHttpApiError(err: HttpErrorResponse): WsApiError {
    const wsApiError: WsApiError = new WsApiError();

    if (err !== undefined) {
      wsApiError.ok = err.ok;
      wsApiError.status = err.status;
      wsApiError.statusText = err.statusText;

      if (err.error && err.error.errors && err.error.errors.length > 0) {
        wsApiError.errorCode = err.error.errors[0]?.code;
        wsApiError.errorMessage = err.error.errors[0]?.error;
        wsApiError.errorDetail = err.error.errors;

        // Temp code to get rid of server-speak errors
        //if (wsApiError.errorMessage?.substring(0, 18).toLocaleLowerCase() === 'validation against') {
        if (wsApiError.errorMessage?.indexOf(': -') > -1 ||
            wsApiError.errorMessage?.indexOf(':  -') > -1) {
          const startingPoint: number = wsApiError.errorMessage.indexOf(': -') > -1 ?
                                          wsApiError.errorMessage?.toLocaleLowerCase().indexOf(': -') + 3 :
                                          wsApiError.errorMessage?.toLocaleLowerCase().indexOf(':  -') + 4;
          const endingPoint: number = wsApiError.errorMessage?.length;
          wsApiError.errorMessage = wsApiError.errorMessage?.substring(startingPoint, endingPoint);
        }

      } else if (err.error && err.error.message && err.error.message.length > 0) {
        wsApiError.errorCode = err.error.statusCode;
        wsApiError.errorMessage = err.error.message;
      } else {
        wsApiError.errorCode = err.status.toString();
        wsApiError.errorMessage = err.message;

      }
    }

    return wsApiError;
  }

  parseApiError(err: any): string  {
    let errorMessage: string;

    if (err instanceof HttpErrorResponse) {
      // Parse out our messaging
      const apiErr: WsApiError = this.parseHttpApiError(err);
      errorMessage = apiErr.errorMessage;
    } else {
      // do something else
      errorMessage = err.message;
    }
    return errorMessage;
  }

  getFullApiError(err: any): WsApiError {
    if (err instanceof HttpErrorResponse) {
      // Parse out our messaging
      return this.parseHttpApiError(err);
    } else {
      // do something else
      return err.message;
    }
  }

  isExpiredTokenError(err: any): boolean {
    let isExpired = false;

    if (err !== undefined) {
      if (err.error && err.error.errors && err.error.errors.length > 0) {
        // Check code
        err.error.errors.forEach(itemError => {
          if (itemError?.code === 'workscale:core:token:expired') {
            isExpired = true;
          }

          if (itemError?.error?.toLocaleLowerCase().indexOf('token is expired') > -1) {
            isExpired = true;
          }
        });

      }
    }

    return isExpired;
  }

}
