<span class="fas fa-times-circle fa-lg fa-fw icon close" mat-dialog-close></span>

<div mat-dialog-content class="dialog-container">
    <app-plan class="flex-col-no-overflow"
        [selectedPlan]="selectedPlan" 
        [isNewPlan]="isNewPlan" 
        (planSaveEvent)="saveDialog($event)"
        (planCancelEvent)="closeDialog()">
    </app-plan>
</div>
