import { Component, OnInit, booleanAttribute } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from 'src/app/core/models/app-user';
import { TenantAccount } from 'src/app/core/models/registration/tenant-account';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { AuthService } from 'src/app/core/services/auth.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { PaymentService } from 'src/app/core/services/payment.service';

@Component({
  selector: 'app-register-complete',
  templateUrl: './register-complete.component.html',
  styleUrls: ['../register.component.scss', './register-complete.component.scss']
})
export class RegisterCompleteComponent implements OnInit {
  working: boolean;
  userWorking: boolean;
  accountWorking: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  user: AppUser;
  tenantAccount: TenantAccount;

  tenantName: string;
  hasTenantName: boolean;

  provisioningProgress: number = 0;
  provisioningTimeToWait: number = 360;  // In seconds (6 mins)

  constructor(
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentService,
    private parseErrorService: ParseErrorService) { }

  ngOnInit(): void {
    this.working = false;
    this.userWorking = false;
    this.accountWorking = false;
    this.errorMessage = '';
    this.saving = false;

    this.tenantName = 'your account';
    this.hasTenantName = false;

    this.getUser();

    this.getTenantAccount();
  }

  getUser(): void {
    this.working = true;
    this.userWorking = true;

    this.authService.getRegisterUser().subscribe({
      next: (response: any) => {
        this.user = response.data;

        if (this.user && this.user.tenants && this.user.tenants.length > 0) {
          if (this.user.tenants[0].name) {
            this.tenantName = this.user.tenants[0].name;
            this.hasTenantName = true;
          }
        }

        this.userWorking = false;
        this.working = this.userWorking && this.accountWorking ? true : false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  getTenantAccount(): void {
    this.working = true;
    this.accountWorking = true;

    this.paymentService.getTenantAccount().subscribe({
      next: (response: TenantAccount | any) => {
        this.tenantAccount = response.data;

        this.accountWorking = false;
        this.working = this.userWorking && this.accountWorking ? true : false;

        //this.startProvisioningProgressBar();
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
      }
    });
  }

  async startProvisioningProgressBar(): Promise<void> {
    const default_ms: number = 8.33;    // 1/120th of a second
    this.provisioningProgress = 0;

    while( this.provisioningProgress <= 100 ) {
      this.provisioningProgress += (1 / this.provisioningTimeToWait);
      await this.sleep(default_ms);
    }
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  signOut(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
