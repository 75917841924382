import { CurrencyPipe, KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { Status } from 'src/app/core/enums/status';
import { HelperImageCompression } from 'src/app/core/helpers/helper-image-compression';
import { DialogDataProduct } from 'src/app/core/models/offer/dialog-data-product';
import { Product } from 'src/app/core/models/offer/product';
import { ProductImage } from 'src/app/core/models/offer/product-image';
import { ProductImageRequest } from 'src/app/core/models/offer/product-image-request';
import { ProductSaveInfo, ProductSaveType } from 'src/app/core/models/offer/product-save-info';
import { StoreType } from 'src/app/core/models/offer/store-type';
import { SaveType } from 'src/app/core/models/save-info';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { OfferService } from 'src/app/core/services/offer.service';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { DialogFulfillableProductComponent } from '../dialog-fulfillable-product/dialog-fulfillable-product.component';

@Component({
  selector: 'app-dialog-product',
  templateUrl: './dialog-product.component.html',
  styleUrls: ['./dialog-product.component.scss']
})
export class DialogProductComponent implements OnInit {

  activeTab: string;
  GENERAL_TAB = 'generalTab';  // Setting as CONST to avoid spelling errors
  PHOTOS_TAB = 'photosTab';

  working: boolean;
  loadingAdd: number;
  loadingDelete: number;
  errorMessage: string;
  errorDetail: WsApiError;

  newProduct: boolean;
  selectedProduct: Product = {};
  remainingAddImageElements: number[];
  existingImagesLength: number
  newProductImages: ProductImage[] = []
  storeTypes: StoreType[];
  storeType: string;

  productForm: UntypedFormGroup;
  dataItemsFormValid: boolean;
  statusEnum = Status;

  isContent: boolean;
  isFulfillable: boolean;

  constructor(
    private offerService: OfferService,
    private imgCompressionHelper: HelperImageCompression,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogProductComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProduct,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.loadingAdd = -1;
    this.loadingDelete = -1;
    this.errorMessage = '';

    this.activeTab = this.GENERAL_TAB;
    this.newProduct = this.data?.newProduct;
    this.selectedProduct = this.data?.selectedProduct;
    this.dataItemsFormValid = this.newProduct ? false : true;
    this.storeTypes = this.data?.storeTypes;

    //this.storeType = 'GIF';  // TODO:  Populate from data
    this.storeType = this.data?.currentStoreTypeCode;

    this.isContent = this.isContentProudct();
    console.log(`isContent: ${this.isContent}`);
    this.isFulfillable = this.isFulfillableProduct();
    console.log(`isFulfillable: ${this.isFulfillable}`);

    this.existingImagesLength = this.data?.selectedProduct?.images?.length ?? 0;
    this.remainingAddImageElements = Array(this.existingImagesLength === 0 ? 8 : 8 - this.existingImagesLength).fill(0);

    this.createForm();

    // Little function to make title reflect user changes
    this.f['name'].valueChanges.subscribe({
      next: (val: any) => {
        if (this.selectedProduct) {
          this.selectedProduct.name = val === '' ? null : val;
        } else {
          this.selectedProduct = { name: val === '' ? null : val };
        }
      }
    });

    this.productForm.valueChanges.subscribe({
      next: (val: any) => {
        // See if there are any form errors other than the image count
        if (this.productForm.valid) {
          this.dataItemsFormValid = true;
        } else {
          // See if the only error is the imageLength
          const invalidControls: string[] = this.findInvalidControls();
          if (invalidControls.length === 1 && invalidControls[0] === 'imageLength') {
            this.dataItemsFormValid = true;
          } else {
            this.dataItemsFormValid = false;
          }
        }
      }
    });
  }

  findInvalidControls(): string[] {
    const invalid: string[] = [];
    for (const name in this.productForm.controls) {
      if (this.productForm.controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  isFulfillableProduct(): boolean {
    return this.isNonEditableProduct('fulfillment');
  }

  isContentProudct(): boolean {
    return this.isNonEditableProduct('content');
  }

  isNonEditableProduct(tagIsInProduct: string): boolean {
    let returnNonEditable: boolean = false;

    if (this.data && this.data.selectedProduct && this.data.selectedProduct.tags) {
      if (this.data.selectedProduct.tags.some(x => x.toLocaleLowerCase() === tagIsInProduct.toLocaleLowerCase())) {
        returnNonEditable = true;
      }
    }

    return returnNonEditable;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.productForm.controls;
  }

  createForm(): void {
    this.productForm = this.fb.group({
      key: [this.selectedProduct?.key ?? ''],
      name: [this.selectedProduct?.name ?? '', Validators.required],
      amount: [this.selectedProduct?.amount ?? '', Validators.required],
      productCategoryCode: [this.selectedProduct?.productCategoryCode ?? ''],
      manufacturerCode: [this.selectedProduct?.manufacturerCode ?? ''],
      description: [this.selectedProduct?.description ?? ''],
      status: [this.selectedProduct?.status ?? ''],
      imageLength: [this.existingImagesLength ?? 0, Validators.min(1)]
    });

  }

  isInvalid(formElement: AbstractControl): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['min']) {
      return `Please add at least one image`;
    }

    if (errors['pattern']) {
      return 'Numbers only';
    }

    return 'Error';
  }

  getCurrencyCode(): string {
    //storeType === 'STO' ? 'USD' : 'PTS'
    if (this.newProduct) {
      return this.storeType === 'STO' ? 'USD' : 'PTS';
    } else {
      if (this.selectedProduct.currencyCode && this.selectedProduct.currencyCode !== '') {
        return this.selectedProduct.currencyCode;
      } else {
        return this.storeType === 'STO' ? 'USD' : 'PTS';
      }
    }

  }

  getAmountDisplay(amount: number, currencyCode: string): string {
    const currencyPipe = new CurrencyPipe('en-US');

    if (currencyCode) {
      if (currencyCode === 'USD') {
        return currencyPipe.transform(amount, currencyCode);
      }

      if (currencyCode === 'PTS') {
        return `${(amount).toLocaleString('en-us', {minimumFractionDigits: 0})} PTS`;
        //return currencyPipe.transform(amount, currencyCode);
      }
    }

    return amount.toString();
  }

  setActiveTab(tabName: string): void {
    this.errorMessage = '';
    this.activeTab = tabName;
  }

  saveProductForm(): void {
    const productToSave: Product = this.populateProductFromForm();
    if (!productToSave) {
      // TODO:  Invalid form
      return;
    }

    // save the product
    this.saveProduct(productToSave);
  }

  saveProduct(productToSave: Product): void {
    // show the spinner
    this.working = true;

    // Determine which api method to call
    const saveMethod = this.newProduct ?
      this.offerService.addNewProduct(productToSave) :
      this.offerService.updateProduct(productToSave, this.selectedProduct.key);

    // Call the API to save the data
    saveMethod.subscribe({
      next: (response: Product | any) => {
        // Update the response object and close the dialog box
        this.data.selectedProduct = response;
        const productSaveInfo: ProductSaveInfo = {
          existingProductKey: this.selectedProduct.key,
          product: response,
          saveType: this.newProduct ? ProductSaveType.NEW_PRODUCT : ProductSaveType.UPDATED_PRODUCT
        }

        // If this is a new product, save the new product images
        if (this.newProduct) {
          this.saveNewImages(productSaveInfo.product.key, productSaveInfo);
        } else {
          // hide spinner
          this.working = false;

          // close the dialog
          this.dialogRef.close(productSaveInfo);
        }
      },
      error: (err: any) => {
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.working = false;
      }
    });

  }

  saveNewImages(newKey: string, productSaveInfo: ProductSaveInfo): void {
    if (!this.newProductImages) {
      return;
    }

    const imageSaves: Array<Observable<any>> = [];
    this.newProductImages.forEach((x: ProductImage, index: number) => {
      // NOTE:  Images are returned sorted by name, so we will prefix each image
      //        to make sure the order is maintained.  First image is always
      //        the MAIN image
      x.name = `${index}_${x.name}`;  // prefix image name with index number
      imageSaves.push(this.offerService.addProductImage(newKey, { name: x.name, mimeType: x.mimeType, data: x.data }));
    });

    if (imageSaves.length > 0) {
      forkJoin(imageSaves).subscribe({
        next: (response: ProductImage[] | any) => {
          const ryan: any = response;
          productSaveInfo.product.images = response;

          this.working = false;
          this.dialogRef.close(productSaveInfo);
        }
      })
    }
  }

  populateProductFromForm(): Product {
    const product: Product = {};

    if (this.productForm.valid) {
      product.storeTypeKey = this.storeTypes.find(x => x.code === this.storeType)?.key;

      if (this.newProduct) {
        product.status = 0;
      }
      //this.updateFormValue('status', product);

      this.updateFormValue('name', product);
      //this.updateFormValue('productCategoryCode', product);
      this.updateFormValue('manufacturerCode', product);
      this.updateFormValue('description', product);
      if (this.f['status']) {
        product.status = this.f['status'].value;
      }

      if (this.f['amount'] && this.f['amount'].value) {
        product.amounts = [
          {
            currencyCode: this.storeType === 'STO' ? 'USD' : 'PTS',
            amount: this.f['amount'].value
          }
        ];
      }
    }

    return product;
  }

  updateFormValue(formElementName: string, product: Product): void {
    if (this.f[formElementName] && this.f[formElementName].value) {
      product[formElementName] = this.f[formElementName].value;
    }

    // Check to see if it was populated, but now is not
    if (this.f[formElementName] &&
        this.f[formElementName].value === '' &&
        this.data?.selectedProduct &&
        this.data.selectedProduct[formElementName] &&
        this.data.selectedProduct[formElementName] !== '') {
      product[formElementName] = '';
    }
  }

  cancelProductForm(): void {
    this.dialogRef.close();
  }

  deleteImage(imgToDelete: ProductImage, deletingIndex: number): void {
    // show spinner
    this.loadingDelete = deletingIndex;

    // TODO:  Check to see if this is a new product or not

    // Call the API to delete the image
    if (!this.newProduct) {
      this.offerService.deleteProductImage(imgToDelete.key).subscribe({
        next: (response: ProductImage | any) => {
          // Add image to existing PRODUCT (this.selectedProduct)
          this.selectedProduct.images = this.selectedProduct.images.filter(x => x.key !== imgToDelete.key);

          // Remove the last item from the remainingAddImageElements so that there are still the right
          // amount of "Add Image" elements
          this.remainingAddImageElements.push(0);
          this.existingImagesLength--;
          this.productForm.patchValue({ imageLength: this.existingImagesLength });

          // hide spinner
          this.loadingDelete = -1;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.loadingDelete = -1;
        }
      });
    } else {
      this.selectedProduct.images.splice(deletingIndex, 1);
      this.newProductImages.splice(deletingIndex, 1);
      this.remainingAddImageElements.push(0);
      this.existingImagesLength--;
      this.productForm.patchValue({ imageLength: this.existingImagesLength });

      // hide the spinner
      this.loadingDelete = -1;
    }

    // Update the existing product (this.selectedProduct) and remove the image

    // hide timer
    // this.loadingDelete = -1;
  }

  async addImageToProduct(loadingIndex: number): Promise<void> {
    // show spinner
    this.loadingAdd = loadingIndex;

    // Upload and compress image
    let productImageRequest: ProductImageRequest;
    try {
      productImageRequest = await this.imgCompressionHelper.uploadAndCompressImage();
    } catch (e: any) {
      if (e.message === 'file upload on blur - no file selected') {
        const error: any = e;
      } else {
        this.errorMessage = e.message;
      }
      this.loadingAdd = -1;
      return;
    }

    // Upload file via API
    if (!this.newProduct) {
      this.offerService.addProductImage(this.selectedProduct.key, productImageRequest).subscribe({
        next: (response: ProductImage | any) => {
          // Add image to existing PRODUCT (this.selectedProduct)
          this.selectedProduct.images.push(response);

          // Order the images by name
          this.selectedProduct.images.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          });

          // Remove the last item from the remainingAddImageElements so that there are still the right
          // amount of "Add Image" elements
          this.remainingAddImageElements.pop();
          this.existingImagesLength++;
          this.productForm.patchValue({ imageLength: this.existingImagesLength });

          // hide spinner
          this.loadingAdd = -1;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.loadingAdd = -1;
        }
      });
    } else {
      // Show the uploaded image
      if (!this.selectedProduct) {
        this.selectedProduct = {};
      }
      if (!this.selectedProduct.images) {
        this.selectedProduct.images = [];
      }
      this.selectedProduct.images.push({ name: productImageRequest.name, url: `data:${productImageRequest.mimeType};base64, ${productImageRequest.data}` });

      // Remove the last item from the remainingAddImageElements so that there are still the right amount of "Add Image" elements
      this.remainingAddImageElements.pop();

      // Update the image length and add to the object to be saved when the user hits the save button
      this.existingImagesLength++;
      this.productForm.patchValue({ imageLength: this.existingImagesLength });
      this.newProductImages.push({ name: productImageRequest.name, mimeType: productImageRequest.mimeType, data: productImageRequest.data });

      // hide spinner
      this.loadingAdd = -1;
    }
  }

  /**
   * DEPRECATED
   * @param e click event
   * @param loadingIndex index number
   */
  async addNewImage(e: Event, loadingIndex: number): Promise<void> {
    // show spinner
    this.loadingAdd = loadingIndex;

    // Get the file to be uploaded and convert image to base64 string
    const fileUpload: HTMLInputElement = e.currentTarget as HTMLInputElement;
    const productImageRequest = await this.createImageObject(fileUpload.files[0]);

    // Upload file via API
    if (!this.newProduct) {
      this.offerService.addProductImage(this.selectedProduct.key, productImageRequest).subscribe({
        next: (response: ProductImage | any) => {
          // Add image to existing PRODUCT (this.selectedProduct)
          this.selectedProduct.images.push(response);

          // Order the images by name
          this.selectedProduct.images.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          });

          // Remove the last item from the remainingAddImageElements so that there are still the right
          // amount of "Add Image" elements
          this.remainingAddImageElements.pop();
          this.existingImagesLength++;
          this.productForm.patchValue({ imageLength: this.existingImagesLength });

          // hide spinner
          this.loadingAdd = -1;
        },
        error: (err: any) => {
          this.errorDetail = this.parseErrorService.getFullApiError(err);
          this.errorMessage = this.errorDetail.errorMessage;
          this.loadingAdd = -1;
        }
      });
    } else {
      // Show the uploaded image
      if (!this.selectedProduct) {
        this.selectedProduct = {};
      }
      if (!this.selectedProduct.images) {
        this.selectedProduct.images = [];
      }
      this.selectedProduct.images.push({ name: productImageRequest.name, url: `data:${productImageRequest.mimeType};base64, ${productImageRequest.data}` });

      // Remove the last item from the remainingAddImageElements so that there are still the right amount of "Add Image" elements
      this.remainingAddImageElements.pop();

      // Update the image length and add to the object to be saved when the user hits the save button
      this.existingImagesLength++;
      this.productForm.patchValue({ imageLength: this.existingImagesLength });
      this.newProductImages.push({ name: productImageRequest.name, mimeType: productImageRequest.mimeType, data: productImageRequest.data });

      // hide spinner
      this.loadingAdd = -1;
    }
  }

  async createImageObject(file: File): Promise<ProductImageRequest> {
    const fr: FileReader = new FileReader();
    let imageRequest: ProductImageRequest = {};

    fr.readAsDataURL(file);
    const imgRequestPromise = await new Promise((resolve) => {
      fr.onload = () => {
        // const imageData: string = fr.result.toString().length > 0 ? fr.result.toString().replace('data:image/png;base64,', '') : '';
        const imageData: string = fr.result.toString().length > 0 ? fr.result.toString().split(',')[1] : '';
        const mimeType: string = fr.result.toString().split(',')[0]?.replace('data:', '')?.replace(';base64', '');
        imageRequest = {
          name: file.name.substring(0, file.name.lastIndexOf('.')),
          mimeType: mimeType,
          data: imageData
        };
        resolve(imageRequest);
        console.log(`name: ${imageRequest.name}`);
        console.log(`mimeType: ${imageRequest.mimeType}`);
        //console.log(`data: ${imageRequest.data}`);
      }
    });

    return imgRequestPromise;
  }

}
