import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataPlan } from 'src/app/core/models/dialog-data-plan';
import { PlanSaveInfo } from 'src/app/core/models/reward/plan-save-info';
import { TemplatePlan } from 'src/app/core/models/reward/template-plan';

@Component({
  selector: 'app-dialog-plan',
  templateUrl: './dialog-plan.component.html',
  styleUrls: ['./dialog-plan.component.scss']
})
export class DialogPlanComponent implements OnInit {

  working: boolean;
  errorMessage: string;

  isNewPlan: boolean;
  selectedPlan: TemplatePlan;

  constructor(
    public dialogRef: MatDialogRef<DialogPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPlan) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';

    this.isNewPlan = this.data?.newPlan;
    // this.selectedPlan = this.data?.selectedPlan;
    if (this.data && this.data.selectedPlan) {
      this.selectedPlan = JSON.parse(JSON.stringify(this.data.selectedPlan));
    }
  }

  saveDialog(planEmit: PlanSaveInfo): void {
    // if save was successful, close the dialog and pass the data back to the referring page
    // this.data.newPlan = planEmit.saveType === PlanSaveType.newPlan ? true : false;
    this.dialogRef.close(planEmit);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
