import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Wallet } from '../models/payment/wallet';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';
import { TenantService } from './tenant.service';
import { ImageResponse } from '../models/image-response';
import { RegisterPerson } from '../models/registration/register-person';
import { TenantAccount } from '../models/registration/tenant-account';
import { TenantAccountRequest } from '../models/registration/tenant-account-request';
import { PaymentLinkResponse } from '../models/registration/payment-link-response';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {

  endpointPayment = environment.paymentUrl;
  headersPayment = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.paymentSubscriptionKey);

  constructor(
    http: HttpClient,
    tenantService: TenantService,
    private localStorageService: LocalStorageService) {
    super(http, tenantService);
  }

  // WALLET
  getUserWalletByKey(userKey: string): Observable<Wallet> {
    const apiUrl = `${this.endpointPayment}/manage/tenant/${this.tenantKey}/app/${this.appKey}/user/${userKey}/wallet`;

    return this.executeApi<Wallet>('get', apiUrl, this.headersPayment);
  }

  // COMPANY REGISTRATION METHODS
  tenantAccountDocument(imageDataString: string): Observable<ImageResponse> {
    const apiUrl = `${this.endpointPayment}/tenant/${this.tenantKey}/account/document`;

    var requestBody = { data: imageDataString };

    return this.executeApiPost<ImageResponse>(apiUrl, this.headersPayment, requestBody);
  }

  tenantAccountPerson(person: RegisterPerson): Observable<RegisterPerson> {
    const apiUrl = `${this.endpointPayment}/tenant/${this.tenantKey}/account/person`;

    return this.executeApiPost<RegisterPerson>(apiUrl, this.headersPayment, person);
  }

  getTenantAccount(): Observable<TenantAccount> {
    const apiUrl = `${this.endpointPayment}/tenant/${this.tenantKey}/account`;

    return this.executeApi<TenantAccount>('get', apiUrl, this.headersPayment);
  }

  patchTenantAccount(tenantAccountRequest: TenantAccountRequest): Observable<TenantAccount> {
    const apiUrl = `${this.endpointPayment}/tenant/${this.tenantKey}/account`;

    return this.executeApiPatch<TenantAccount>(apiUrl, this.headersPayment, tenantAccountRequest);
  }

  getStripeOnboardingUrl(refreshUrl: string, returnUrl: string): Observable<PaymentLinkResponse> {
    const apiUrl = `${this.endpointPayment}/tenant/${this.tenantKey}/account/link`;

    var requestBody = {
      'refreshUrl': refreshUrl,
      'returnUrl': returnUrl
    };

    return this.executeApiPost<PaymentLinkResponse>(apiUrl, this.headersPayment, requestBody);
  }


}
