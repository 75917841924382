import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperNameService } from 'src/app/core/helpers/helper-name.service';
import { AppUser } from 'src/app/core/models/app-user';
import { Avatar } from 'src/app/core/models/avatar';
import { DialogDataNewUser } from 'src/app/core/models/dialog-data-new-user';
import { Name } from 'src/app/core/models/name';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dialog-user-new',
  templateUrl: './dialog-user-new.component.html',
  styleUrls: ['./dialog-user-new.component.scss']
})
export class DialogUserNewComponent implements OnInit {
  DEFAULT_AVATAR_COLOR = '#FF6D6D';
  DEFAULT_AVATAR_IMG = 'avatar-logo.png';
  AVATAR_PATH = 'assets/images/avatars/';

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  user: AppUser;
  dialogWidth: string;

  avatarBgColor: string;
  avatarImg: string;
  defaultAvatar: boolean;
  updateAvatar: boolean;

  toggleSuffix: boolean;
  userForm: UntypedFormGroup;
  isMinor: boolean;
  months: string[];
  days: number[];

  constructor(
    private userService: UserService,
    private nameHelper: HelperNameService,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogUserNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNewUser,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.updateAvatar = false;
    this.user = { info: {} };

    this.avatarBgColor = this.DEFAULT_AVATAR_COLOR;
    this.avatarImg = `${this.AVATAR_PATH}${this.DEFAULT_AVATAR_IMG}`;
    this.defaultAvatar = true;
    this.dialogWidth = this.data.dialogWidth;

    this.isMinor = this.user?.info?.isMinor ? this.user.info.isMinor : false;
    this.months = Array(12).fill(0).map((i, idx) => this.getMonth(idx + 1));

    this.createForm();
    this.formChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.fb.group({
      first: [this.user?.info?.name?.first ?? '', this.conditionalValidator('last')],
      middle: [this.user?.info?.name?.middle ?? ''],
      last: [this.user?.info?.name?.last ?? '', this.conditionalValidator('first')],
      suffix: [this.user?.info?.name?.suffix ?? ''],
      emailAddress: [this.user?.emailAddress ?? '', [Validators.required, Validators.email]],
      username: [this.user?.username ?? ''],
      year: [this.user?.info?.dateOfBirth?.year ?? '', [Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)]],
      month: [this.user?.info?.dateOfBirth?.month ?? 0],
      day: [this.user?.info?.dateOfBirth?.day ?? 0],
      isMinor: [this.user?.info?.isMinor ?? false]
      //password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      //confirmPassword: ['', this.passwordsMatch.bind(this)]
    });

  }

  passwordsMatch(control: FormControl) {
    const pass = this.userForm?.get('password')?.value;
    const confirmPass = control.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  conditionalValidator(field: string): ValidatorFn {
    return userForm => {
      if (!userForm.parent) {
        return null;
      }

      const otherControl = userForm.parent.get(field);
      const error: ValidationErrors = userForm.value || otherControl.value ? null : { conditional: { key: 'conditional', error: `this field or ${field} is required` } };

      if ((error && userForm.valid) || (!error && userForm.invalid)) {
        setTimeout(() => {
          otherControl.updateValueAndValidity({ emitEvent: false });
        });
      }
      return error;
    };
  }

  resetFormToDefault(): void {
    this.userForm.reset();
    this.userForm.controls['first'].setValue(this.user?.info?.name?.first ?? '');
    this.userForm.controls['middle'].setValue(this.user?.info?.name?.middle ?? '');
    this.userForm.controls['last'].setValue(this.user?.info?.name?.last ?? '');
    this.userForm.controls['suffix'].setValue(this.user?.info?.name?.suffix ?? '');
    this.userForm.controls['emailAddress'].setValue(this.user?.emailAddress ?? '');
    this.userForm.controls['year'].setValue(this.user?.info?.dateOfBirth?.year ?? '');
    this.userForm.controls['month'].setValue(this.user?.info?.dateOfBirth?.month ?? 0);
    this.userForm.controls['day'].setValue(this.user?.info?.dateOfBirth?.day ?? 0);
    this.userForm.controls['isMinor'].setValue(this.user?.info?.isMinor ?? false);

    this.dialogRef.close();
  }

  formChanges(): void {
    // Cute little item to make title reflect user changes
    this.f['first'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.first = val;
      }
    });

    this.f['middle'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.middle = val;
      }
    });

    this.f['last'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.last = val;
      }
    });

    this.f['suffix'].valueChanges.subscribe({
      next: (val: any) => {
        if (val && !this.userNameObjectExists()) {
          this.createNameObject();
        }
        this.user.info.name.suffix = val;
      }
    });

    this.f['isMinor'].valueChanges.subscribe({
      next: (val: any) => {
        this.isMinor = val;
        if (val && this.user && this.user.info && this.user.info.isMinor) {
          this.user.info.isMinor = val;
        }
      }
    });
  }

  userNameObjectExists(): boolean {
    if (!this.user || !this.user.info || !this.user.info.name) {
      return false;
    }
    return true;
  }

  createNameObject(): void {
    if (!this.user) {
      this.user = {};
    }
    if (!this.user.info) {
      this.user.info = {};
    }
    if (!this.user.info.name) {
      this.user.info.name = {};
    }
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && name === 'dob') {
      return true;
    } else if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['conditional']) {
      return labelName != '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    if (errors['pattern'] && labelName === 'password') {
      return 'Password must be 8 characters in length and contain at least 1 digit, 1 lowercase character, 1 uppercase character, and 1 special character ';
    }

    if (errors['notSame']) {
      return 'Passwords do not match';
    }

    if (errors['invalidDob']) {
      return 'Date of Birth is not a valid date';
    }

    if (errors['min']) {
      return `${labelName} is an invalid year (too short)`;
    }

    if (errors['max']) {
      return `${labelName} is an invalid year (too long)`;
    }

    return 'Error';
  }

  /**
   * TODO:  Probably extract this to a static helper
   * @param index index number
   * @returns month
   */
  public getMonth(index: number) {
    const myDate = new Date();
    myDate.setDate(1);
    myDate.setMonth(index - 1);

    const locale = 'en-us';
    const month = myDate.toLocaleString(locale, { month: 'long' });

    return month;
  }

  public getDays(thisForm: any): number[] {
    const dayCount = !thisForm.year && !thisForm.month ?
      31 :
      this.getDaysInMonth(thisForm.year, thisForm.month);
    return Array(dayCount).fill(0).map((i, idx) => idx + 1);
  }

  public getDaysInMonth(year: number, month: number): number {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  fullNameDisplay(name: Name, nullPlaceholder: string = ''): string {
    return this.nameHelper.nameDisplay(name) ?? nullPlaceholder;
  }

  isMinorToggle(e: Event): void {
    const currentCheckbox = e.currentTarget as HTMLInputElement;

    if (currentCheckbox.id === 'userIsMinor') {
      this.isMinor = !currentCheckbox.checked;  // Mark it as the opposite, because "checked" is true for adult
    } else {
      this.isMinor = !currentCheckbox.checked;
    }

    // Update validation for minor
    if (this.isMinor) {
      this.f['username'].setValidators(Validators.required);
      this.f['username'].updateValueAndValidity();

      this.f['emailAddress'].setValue('');
      this.f['emailAddress'].clearValidators();
      this.f['emailAddress'].updateValueAndValidity();
    } else {
      this.f['username'].setValue('');
      this.f['username'].clearValidators();
      this.f['username'].updateValueAndValidity();

      this.f['emailAddress'].clearValidators();
      this.f['emailAddress'].setValidators([Validators.required, Validators.email]);
      this.f['emailAddress'].updateValueAndValidity();
    }

    // TODO:  Possible save this without hitting save button
  }

  saveUser(): void {
    this.saving = true;
    const newUser: AppUser = this.populateUserDataFromFormInput();

    this.userService.createAppUser(newUser).subscribe({
      next: (response: AppUser | any) => {
        this.user = response;

        // Update the parent page (USERS) rather than do a reload of that page
        this.dialogRef.close({
          data: {
            newUser: response
          }
        });

        this.saving = false;
      },
      error: (err: any) => {
        this.errorMessage = this.parseErrorService.parseApiError(err);
        this.saving = false;
      }
    });
  }

  populateUserDataFromFormInput(): AppUser {
    if (!this.userNameObjectExists) {
      this.createNameObject();
    }

    this.user.info.name.first = this.f['first'].value;

    if (this.f['middle'] && this.f['middle'].value.length > 0) {
      this.user.info.name.middle = this.f['middle'].value;
    }

    if (this.f['last'] && this.f['last'].value.length > 0) {
      this.user.info.name.last = this.f['last'].value;
    }

    if (this.f['suffix'] && this.f['suffix'].value.length > 0) {
      this.user.info.name.suffix = this.f['suffix'].value;
    }

    if (this.f['emailAddress'] && this.f['emailAddress'].value.length > 0) {
      this.user.emailAddress = this.f['emailAddress'].value;
      this.user.username = this.f['emailAddress'].value;
      this.user.displayName = this.f['emailAddress'].value.substring(0, this.f['emailAddress'].value.lastIndexOf('@'));
    }

    if (this.f['username'] && this.f['username'].value.length > 0) {
      this.user.username = this.f['username'].value;
      this.user.displayName = this.f['username'].value;
    }

    if (this.f['password'] && this.f['password'].value.length > 0) {
      this.user.password = this.f['password'].value;
    }

    if (this.isInputDOBValidDate()) {
      if (!this.user.info.dateOfBirth) {
        this.user.info.dateOfBirth = {};
      }

      this.user.info.dateOfBirth.year = this.f['year'].value;
      this.user.info.dateOfBirth.month = this.f['month'].value;
      this.user.info.dateOfBirth.day = this.f['day'].value;
    }

    this.user.info.isMinor = this.isMinor;
    this.user.status = 'Active';
    this.user.roleType = 'Customer';

    return { ...this.user };
  }

  validateDOBInput(): void {
    if (!this.isInputDOBValidDate()) {
      this.f['month'].setErrors({ invalidDob: true });
      this.f['day'].setErrors({ invalidDob: true });
      this.f['year'].setErrors({ invalidDob: true });
    } else {
      this.f['month'].setErrors(null);
      this.f['month'].updateValueAndValidity();
      this.f['day'].setErrors(null);
      this.f['day'].updateValueAndValidity();
      this.f['year'].setErrors(null);
      this.f['year'].updateValueAndValidity();
    }
  }

  isInputDOBValidDate(): boolean {
    if (!this.f['year'].value || !this.f['month'].value || !this.f['day'].value) {
      return false;
    }

    var dob: Date = new Date(`${this.f['year'].value}-${this.f['month'].value}-${this.f['day'].value}`);

    // TODO:  If DOB puts the user younger than 18, toggle isMinor

    if (isNaN(dob.getTime())) {
      return false;
    }

    return true;
  }

  closeAvatarWindow(): void {
    this.updateAvatar = !this.updateAvatar;
  }

  previewAvatar(avatar: Avatar): void {
    if (avatar?.color) {
      this.avatarBgColor = avatar.color;
    }

    // Get the avatar image
    if (avatar?.fileName) {
      this.avatarImg = `${this.AVATAR_PATH}${avatar.fileName}`;
      this.defaultAvatar = false;
    }
  }

  resetAvatarToOriginal(): void {
    if (this.user && this.user.info && this.user.info.additionalInfo) {
      // Get the avatar image
      if (this.user.info.additionalInfo['avatar']) {
        this.avatarImg = `${this.AVATAR_PATH}${this.user.info.additionalInfo['avatar']}`;
        this.defaultAvatar = false;
      }

      if (this.user.info.additionalInfo['avatarColor']) {
        // Get the avatar color and convert to a usable RGBA format
        this.avatarBgColor = this.user.info.additionalInfo['avatarColor'];
      }
    } else {
      this.avatarBgColor = this.DEFAULT_AVATAR_COLOR;
      this.avatarImg = `${this.AVATAR_PATH}${this.DEFAULT_AVATAR_IMG}`;
      this.defaultAvatar = true;
    }
  }

  updateUserWithAvatar(avatar: Avatar): void {
    if (!this.user) {
      this.user = {};
    }

    if (!this.user.info) {
      this.user.info = {
        additionalInfo: {
          avatar: avatar.fileName,
          avatarColor: avatar.color
        }
      };
    } else if (!this.user.info.additionalInfo) {
      this.user.info.additionalInfo = {
        avatar: avatar.fileName,
        avatarColor: avatar.color
      };
    } else {
      this.user.info.additionalInfo['avatar'] = avatar.fileName;
      this.user.info.additionalInfo['avatarColor'] = avatar.color;
    }

    this.avatarBgColor = avatar.color;
    this.avatarImg = `${this.AVATAR_PATH}${avatar.fileName}`;
    this.defaultAvatar = false;

    this.closeAvatarWindow();
  }

}
