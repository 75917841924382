import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleType } from 'src/app/core/enums/role-type';
import { AppUser } from 'src/app/core/models/app-user';
import { DialogDataNewUserAdmin } from 'src/app/core/models/dialog-data-new-user-admin';
import { WsApiError } from 'src/app/core/models/ws-api-error';
import { ParseErrorService } from 'src/app/core/services/parse-error.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dialog-user-admin-new',
  templateUrl: './dialog-user-admin-new.component.html',
  styleUrls: ['../dialog-user-new/dialog-user-new.component.scss', './dialog-user-admin-new.component.scss']
})
export class DialogUserAdminNewComponent implements OnInit {

  working: boolean;
  errorMessage: string;
  errorDetail: WsApiError;
  saving: boolean;

  user: AppUser;
  dialogWidth: string;

  toggleSuffix: boolean;
  userForm: UntypedFormGroup;

  roleTypes: KeyValue<string | RoleType, string>[];

  constructor(
    private userService: UserService,
    private parseErrorService: ParseErrorService,
    public dialogRef: MatDialogRef<DialogUserAdminNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNewUserAdmin,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.working = false;
    this.errorMessage = '';
    this.saving = false;
    this.user = { info: {} };

    this.dialogWidth = this.data.dialogWidth;

    // Convert enum to key value pair for display on the page
    this.roleTypes = Object.entries(RoleType)
      .filter(([value, key]) => typeof key === 'number')
      .map(([value, key]) => ({ key, value }));

    this.checkUserRolesTypes();

    //this is a hack, but keeps insync with the enum
    this.roleTypes.forEach((roleType: KeyValue<string | RoleType, string>) => {
      if (roleType.value == 'PowerUser') {
        roleType.value = 'Power User';
      }
    });

    // remove customer role
    const customerRoleIndex = this.roleTypes.findIndex((roleType: KeyValue<string | RoleType, string>) => roleType.value == 'Customer');
    if (customerRoleIndex > -1) {
      this.roleTypes.splice(customerRoleIndex, 1);
    }

    this.createForm();
  }


  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  getKeys(obj: any) { return Object.keys(obj); }

  createForm(): void {
    this.userForm = this.fb.group({
      first: [this.user?.info?.name?.first ?? '', Validators.required],
      last: [this.user?.info?.name?.last ?? ''],
      emailAddress: [this.user?.emailAddress ?? '', [Validators.required, Validators.email]],
      roleType: [this.user?.roleType ?? '', Validators.required],
    });

  }

  userNameObjectExists(): boolean {
    if (!this.user || !this.user.info || !this.user.info.name) {
      return false;
    }
    return true;
  }

  createNameObject(): void {
    if (!this.user) {
      this.user = {};
    }
    if (!this.user.info) {
      this.user.info = {};
    }
    if (!this.user.info.name) {
      this.user.info.name = {};
    }
  }

  resetFormToDefault(): void {
    this.userForm.reset();
    this.userForm.controls['emailAddress'].setValue(this.user?.emailAddress ?? '');

    this.dialogRef.close();
  }

  isInvalid(formElement: AbstractControl, name: string = ''): boolean {
    if (formElement.invalid && (formElement.dirty || formElement.touched)) {
      return true;
    }
    return false;
  }

  getError(errors: ValidationErrors, labelName: string = ''): string {
    if (!errors) {
      return '';
    }

    if (errors['required']) {
      return labelName !== '' ? `${labelName} is required` : 'required';
    }

    if (errors['minlength']) {
      return `must at least ${errors['minlength']?.requiredLength} chars`;
    }

    if (errors['email']) {
      return 'not a valid email';
    }

    return 'Error';
  }

  checkUserRolesTypes(): void {
    const user = this.userService.userValue;

    if (user && user.roleType) {
      if (user.roleType == 'PowerUser') {
        this.removeItemFromRoleTypes('value', 'Administrator');
      }
    }

    if (user && user.roleType) {
      if (user.roleType == 'Customer' || user.roleType == 'Standard') {
        this.removeItemFromRoleTypes('value', 'Administrator');
        this.removeItemFromRoleTypes('value', 'PowerUser');
      }
    }
  }

  removeItemFromRoleTypes(key: string, value: any): void {
    this.roleTypes = this.roleTypes.filter(d => d[key] !== value);
  }

  saveUser(): void {
    this.saving = true;
    const newUser: AppUser = this.populateUserDataFromFormInput();

    this.userService.inviteManagementUser(newUser.emailAddress, newUser.roleType, newUser.info).subscribe({
      next: (response: any) => {
        if (response) {
          // update the user.roleType to the string display value
          newUser.roleType = this.roleTypes
            .find((roleType: KeyValue<string | RoleType, string>) => roleType.key == newUser.roleType).value
            .replace(' ', '').toLocaleLowerCase();

          // Close the dialog and send the message back to the parent page
          // Update the parent page (USERS) rather than do a reload of that page
          this.dialogRef.close({
            data: {
              invitedUserEmail: newUser.emailAddress,
              inviteMessage: 'An invite has been sent to',
              newUser: newUser
            }
          });

        }
        this.saving = false;
      },
      error: (err: any) => {
        // Display the error
        this.errorDetail = this.parseErrorService.getFullApiError(err);
        this.errorMessage = this.errorDetail.errorMessage;
        this.saving = false;
      }
    });
  }

  populateUserDataFromFormInput(): AppUser {
    if (!this.userNameObjectExists()) {
      this.createNameObject();
    }

    this.user.info.name.first = this.f['first'].value;

    if (this.f['last'] && this.f['last'].value.length > 0) {
      this.user.info.name.last = this.f['last'].value;
    }

    this.user.emailAddress = this.f['emailAddress'].value;

    this.user.status = 'Active';
    this.user.roleType = this.f['roleType'].value;

    return { ...this.user };
  }

}
