<div class="page-container">
    <h1>
        <ng-container *ngIf="working">
            --
        </ng-container>
        <ng-container *ngIf="!working">
            {{fullNamePageTitleDisplay(user.info?.name, user.displayName)}}
        </ng-container>
        <span class="label">user detail</span>
    </h1>


    <div class="nav nav-tabs">
        <a [routerLink]="[]" class="nav-item {{activeTab === ACTIVITY_TAB ? 'active' : ''}}" (click)="setActiveTab(ACTIVITY_TAB)">Activity</a>
        <a [routerLink]="[]" class="nav-item {{activeTab === ACCOUNT_TAB ? 'active' : ''}}" (click)="setActiveTab(ACCOUNT_TAB)">Account Info</a>
    </div>

    <div class="tab-content">
        <!-- <p style="font-size: 12px;">user key: {{userKey}}</p> -->
        <div class="error" *ngIf="errorMessage">
            <span class="far fa-exclamation-circle fa-lg icon"></span>
            {{errorMessage}}
        </div>

        <app-loading loadingSize="lg" alignText="left" *ngIf="working"></app-loading>

        <ng-container *ngIf="activeTab === ACTIVITY_TAB">
            USER ACTIVITY
        </ng-container>
        <ng-container *ngIf="activeTab === ACCOUNT_TAB">
            <ng-container *ngTemplateOutlet="userAccount; context: {user: user}"></ng-container>
        </ng-container>
    </div>

</div>


<ng-template #userAccount let-user="user">
    <div class="acct-container">

        <div class="avatar-container">
            <div class="user-avatar">
                <img src="assets/images/avatar-joe.png" title="child user" />
                <div class="user-text">
                    {{user.displayName}}
                </div>
            </div>
        </div>
        <div class="data-container">

            <div class="acct-group {{editAccountData ? 'edit-data' : ''}}">
                <ng-container *ngTemplateOutlet="userAccountData; context: {user: user}"></ng-container>
            </div>

            <div class="acct-group">
                <h3>Contact</h3>
                <div class="group-col first-col">
                    <div class="group-item">
                        <label>email</label>
                        <span class="data">
                            {{user.emailAddress ?? "--"}}
                        </span>
                    </div>
                    <div class="group-item">
                        <label>phone number</label>
                        <span class="data">
                            {{user.phoneNumber ?? '--'}}
                        </span>
                    </div>
                </div>
                <div class="group-col">
                    <div class="group-item">
                        <label>address</label>
                        <span class="data address">
                            123 Anywhere Ave
                            <span class="sub-line"></span>
                            <span class="sub-line">Saratoga Springs, UT 84045</span>
                        </span>
                    </div>
                </div>

                <div class="actions acct-actions">
                    <!-- <button type="button" class="btn btn-link" *ngIf="hasPersonalInformation(user) && !editPersonalData" (click)="editPersonalData = !editPersonalData"> -->
                    <button type="button" class="action-item btn btn-link" (click)="editAccountData = !editAccountData">
                        <span class="fas fa-pencil icon"></span>
                        Edit
                    </button>
                </div>
            </div>

            <div class="acct-group household">
                <h3>Household Members</h3>
                <div class="group-col">

                    <div class="children">
                        <ng-container *ngFor="let child of childUsers; index as i">
                            <a routerLink="user/{{child.key}}" class="child">
                                <img src="{{tempGetChildAvatar(child, i)}}" title="child user" />
                                <div class="child-text">{{child.displayName}}</div>
                            </a>
                        </ng-container>
                        <a [routerLink]="[]" class="child add-child">
                            <div class="add-icon">
                                <span class="fal fa-plus fa-lg icon"></span>
                            </div>
                            <span class="add-text">Add a person</span>
                        </a>

                    </div>
                </div>
            </div>

        </div>

    </div>
</ng-template>

<ng-template #userAccountData let-user="user">
    <h3>Account</h3>

    <ng-container *ngIf="!editAccountData">
        <div class="group-col first-col">
            <div class="group-item">
                <label>username</label>
                <span class="data">
                    {{user.displayName}}
                </span>
            </div>
            <div class="group-item">
                <label>name</label>
                <span class="data">
                    {{fullNameDisplay(user?.info?.name, '--')}}
                </span>
            </div>
        </div>
        <div class="group-col align-bottom">
            <div class="group-item">
                <label></label>
                <span class="data">
                    <!-- {{user.displayName}} -->
                </span>
            </div>
            <div class="group-item">
                <label>dob</label>
                <span class="data">
                    {{dobDisplay(user?.info?.dateOfBirth)}}
                </span>
            </div>
        </div>

        <div class="actions acct-actions">
            <!-- <button type="button" class="btn btn-link" *ngIf="hasPersonalInformation(user) && !editPersonalData" (click)="editPersonalData = !editPersonalData"> -->
            <button type="button" class="action-item btn btn-link" (click)="editAccountData = !editAccountData">
                <span class="fas fa-pencil icon"></span>
                Edit
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="editAccountData">
        <form class="form" #userForm="ngForm" (ngSubmit)="onClickSubmit(userForm.value, $event)">
            <div class="group-col first-col">
                <div class="group-item large-text-item">
                    <label>
                        username
                        <span class="required" *ngIf="(displayName.invalid && (displayName.dirty || displayName.touched))">{{getErrorText(displayName.errors)}}</span>
                    </label>
                    <span class="data">
                        <input type="text" name="displayName" class="form-control first large-text" placeholder="username" [(ngModel)]="user.displayName" required minlength="4" #displayName="ngModel" />
                    </span>
                </div>
                <div class="group-item">
                    <label>name</label>
                    <span class="data">
                        <input type="text" class="form-control short-text first" name="title" placeholder="title" [(ngModel)]="user?.info?.name && user?.info.name.title" />
                        <span class="no-wrap">
                            <input type="text" class="form-control med-text" name="first" placeholder="first name" [(ngModel)]="user?.info?.name && user?.info.name.first" />
                            <input type="text" class="form-control middle" name="middle" placeholder="middle" [(ngModel)]="user?.info?.name && user?.info.name.middle" />
                            <input type="text" class="form-control med-text" name="last" placeholder="last name" [(ngModel)]="user?.info?.name && user?.info.name.last" />
                        </span>
                        <input type="text" class="form-control short-text suffix" name="suffix" placeholder="suffix" [(ngModel)]="user?.info?.name && user?.info.name.suffix" />
                    </span>
                </div>
            </div>
            <div class="group-col align-bottom">
                <div class="group-item">
                    <label>Date of Birth</label>
                    <div class="data">
                        <select name="month" class="form-select first" [(ngModel)]="user?.info?.dateOfBirth && user?.info?.dateOfBirth.month">
                            <option value="0" disabled selected hidden>Month</option>
                            <option *ngFor="let thisMonth of months; let m = index" [value]="m+1">{{thisMonth}}</option>
                        </select>
                        <select name="day" class="form-select" [(ngModel)]="user?.info?.dateOfBirth && user?.info?.dateOfBirth.day">
                            <option value="0" disabled selected hidden>Day</option>
                            <option *ngFor="let thisDay of getDays(userForm.value)" [value]="thisDay">{{thisDay}}
                            </option>
                        </select>
                        <input type="number" class="form-control year" name="year" placeholder="year" min="1900" max="2050" [(ngModel)]="user?.info?.dateOfBirth && user?.info?.dateOfBirth.year" />
                    </div>
                </div>
            </div>
            <div class="group-col submit-form">
                <div class=" save-close">
                    <input type="button" class="btn btn-outline-dark" value="cancel" (click)="editAccountData = !editAccountData">
                    <input type="submit" class="btn btn-primary" value="submit">
                </div>
            </div>
        </form>
    </ng-container>
</ng-template>

